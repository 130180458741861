const xmlJs = require('xml-js')
const jsonPath = require('jspath')
const smpteTc = require('smpte-timecode')
const nodeTimecodes = require( 'node-timecodes')
const hex2Ascii = require('hex2ascii')
const hex2Bin = require('hex-to-binary')
const stripTags = require('striptags');
const entities = require("entities");
const ccFunc = require('./ccFunc')
const sccLookup = require('./formats/scc')
const mccLookup = require('./formats/mcc')
const stlLookup = require('./formats/stl')
const pacLookup = require('./formats/pac')

/* CAPTION IMPORT FUNCTIONS */
module.exports = {
    scc: function (fileContents, frameRate, dropFrame) {
        var captions = [];
        var fileLines = fileContents.replace(/\n+/g,"\n").split("\n");
        var buffer = new ccFunc.captionEvent();
        /* flags */
        var paintOn = false;
        var italics = false;
        var underline = false;
        var currentCaptionLine = 0;

        for (var fileLine of fileLines) {       
            if (fileLine.split("\t")[0].match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g) != null) {
                var timer = ccFunc.createSmpteTc(fileLine.split("\t")[0], frameRate, dropFrame);
                var ancData = fileLine.split("\t")[1].split(" ");
                for (var i = 0; i < ancData.length; i++) {
                    /* Figure out if this is a 4 character command or two 2 character commands */
                    if (sccLookup.ccCharset[ancData[i]] != undefined) {
                        var code = sccLookup.ccCharset[ancData[i]];
                        if (ancData[i] == ancData[i + 1]) {
                            //Duplicate codes... nothing to do this time...
                        } else if (code === '{RDC}') {
                            //Paint On Captions - Display the following lines right away
                            paintOn = true;
                            var paintOnCaption = ccFunc.captionEvent();
                            currentCaptionLine = 0;
                            paintOnCaption.style = "Paint-On";
                            paintOnCaption.incode = timer.toString();
                            captions.push(paintOnCaption);
                            timer.add(1);
                        } else if (/^{RU\d}$/.test(code)) {
                            //Roll-Up On Captions - Display the following lines right away - treat as roll up
                            paintOn = true;
                            var paintOnCaption = ccFunc.captionEvent();
                            currentCaptionLine = 0;
                            paintOnCaption.style = "Roll-Up";
                            paintOnCaption.incode = timer.toString();
                            captions.push(paintOnCaption);
                            timer.add(1);
                        } else if (code === '{ENM}') {
                            //Erase Non-displayed [buffer] Memory
                            buffer = ccFunc.captionEvent();
                            currentCaptionLine = 0;
                            timer.add(1);
                        } else if (code === '{EDM}') {
                            /*Clear the current screen of any displayed captions or text.*/
                            //console.log(timer.toString());
                            //console.log("CLEARING THE SCREEN");
                            if (captions.length === 1 && captions[0].primaryLang.filter(captionLine => {
                                return captionLine.captionText.length > 0;
                            }).length === 0 ) {
                                /* Do nothing */
                            } else if (captions.length > 0) {
                                if (captions[captions.length - 1].outcode == "" || captions[captions.length - 1].outcode == null || captions[captions.length - 1].outcode == undefined) {
                                    captions[captions.length - 1].outcode = timer.toString();
                                }
                            }
                            timer.add(1);
                        } else if (code == '{EOC}') {
                            //display the caption in the buffer on the screen
                            //console.log(timer.toString());
                            //console.log("display caption to THE SCREEN");
                            //console.log(buffer);
                            if (captions.length > 0 && captions[captions.length - 1].outcode == "") {
                                captions[captions.length - 1].outcode = timer.toString();
                                timer.add(1);
                            }

                            if (captions.length > 0 && captions[captions.length - 1].outcode == "") {
                                var captionLineId = ccFunc.getFirstEmptyLine(captions[captions.length - 1].primaryLang);
                                captions[captions.length - 1].primaryLang[captionLineId].captionText += buffer.captionText;
                                captions[captions.length - 1].primaryLang[captionLineId].positionX = buffer.positionX;
                                captions[captions.length - 1].primaryLang[captionLineId].positionY = buffer.positionY;
                            } else if (buffer.incode == "") {
                                buffer.incode = timer.toString();
                                captions.push(buffer);
                            }

                            timer.add(1);

                            if (ancData.indexOf('942c') > -1 || ancData.indexOf('1c2c') > -1 || ancData.indexOf('152c') > -1 || ancData.indexOf('9d2c') > -1) {
                                //console.log("EOC (display buffer) was prefaced by EDM (Clear screen) which means we also clear the buffer");
                                buffer = ccFunc.captionEvent();
                                currentCaptionLine = 0
                                timer.add(1);
                            }
                        } else if (code === '{RCL}') {
                            //Pop On Caption Command & Resume Caption Loading
                            //console.log("RCL found which means we can continue writing to the buffer and turn PaiintOn off");
                            paintOn = false;
                            timer.add(1);
                        } else if (code === '{NOFORMAT}') {
                            //console.log("No format code means we turn off any formatting and close the tags")
                            timer.add(1);
                        } else if (code === '{ITALICS}') {
                            //console.log("Italics code found means we close any open tags and open italics ones");
                            if (paintOn) {
                                captions[captions.length - 1].primaryLang[currentCaptionLine].italics = true;
                            } else {
                                buffer.primaryLang[currentCaptionLine].italics = true;
                            }
                            timer.add(1);
                        } else if (code === '{UNDERLINE}') {
                            if (paintOn) {
                                captions[captions.length - 1].primaryLang[currentCaptionLine].underline = true;
                            } else {
                                buffer.primaryLang[currentCaptionLine].underline = true;
                            }
                            timer.add(1);
                        } else if (code === '{ITALICS_UNDERLINE}') {
                            if (paintOn) {
                                captions[captions.length - 1].primaryLang[currentCaptionLine].underline = true;
                                captions[captions.length - 1].primaryLang[currentCaptionLine].italics = true;
                            } else {
                                buffer.primaryLang[currentCaptionLine].underline = true;
                                buffer.primaryLang[currentCaptionLine].italics = true;
                            }
                            timer.add(1);
                        } else if (code === '' || code === '{FLASH}') {
                            //console.log("Flash or no code returned.");
                            timer.add(1);
                        } else if (new RegExp(/{\d\d_\d\d}{ITALICS}/g).test(code)) {
                            //console.log("Position and formatting code returned")
                            code = code.split('_');
                            var positionY = parseInt(code[0].substring(1, 3));
                            var positionX = parseInt(code[1].substring(0, 2));

                            if (paintOn) {
                                //console.log("paintOn is true so we add the position to the last caption");
                                if (captions[captions.length - 1].primaryLang[currentCaptionLine].captionText == "") {
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionX = positionX;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionY = positionY;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].italics = true;
                                } else {
                                    captions[captions.length - 1].primaryLang[++currentCaptionLine].positionX = positionX;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionY = positionY;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].italics = true;
                                }
                            } else {
                                if (buffer.primaryLang[currentCaptionLine].captionText == "") {
                                    buffer.primaryLang[currentCaptionLine].positionX = positionX;
                                    buffer.primaryLang[currentCaptionLine].positionY = positionY;
                                    buffer.primaryLang[currentCaptionLine].italics = true;
                                } else {
                                    buffer.primaryLang[++currentCaptionLine].positionX = positionX;
                                    buffer.primaryLang[currentCaptionLine].positionY = positionY;
                                    buffer.primaryLang[currentCaptionLine].italics = true;
                                }
                            }
                            timer.add(1);
                        } else if (new RegExp(/{\d\d_\d\d}{UNDERLINE}/g).test(code)) {        
                            code = code.split('_');
                            var positionY = parseInt(code[0].substring(1, 3));
                            var positionX = parseInt(code[1].substring(0, 2));

                            if (paintOn) {
                                //console.log("paintOn is true so we add the position to the last caption");
                                if (captions[captions.length - 1].primaryLang[currentCaptionLine].captionText == "") {
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionX = positionX;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionY = positionY;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].underline = true;
                                } else {
                                    captions[captions.length - 1].primaryLang[++currentCaptionLine].positionX = positionX;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionY = positionY;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].underline = true;
                                }
                            } else {
                                if (buffer.primaryLang[currentCaptionLine].captionText == "") {
                                    buffer.primaryLang[currentCaptionLine].positionX = positionX;
                                    buffer.primaryLang[currentCaptionLine].positionY = positionY;
                                    buffer.primaryLang[currentCaptionLine].underline = true;
                                } else {
                                    buffer.primaryLang[++currentCaptionLine].positionX = positionX;
                                    buffer.primaryLang[currentCaptionLine].positionY = positionY;
                                    buffer.primaryLang[currentCaptionLine].underline = true;
                                }
                            }
                            timer.add(1);
                        } else if (code === "{CR}") {
                            currentCaptionLine++
                            timer.add(1);
                        } else if (new RegExp(/{\d\d_\d\d}/g).test(code)) {
                            code = code.split('_');
                            var positionY = parseInt(code[0].substring(1, 3));
                            var positionX = parseInt(code[1].substring(0, 2));
                            if (paintOn) {
                                //console.log("paintOn is true so we add the position to the last caption");
                                if (captions[captions.length - 1].primaryLang[currentCaptionLine].captionText == "") {
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionX = positionX;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionY = positionY;
                                } else {
                                    captions[captions.length - 1].primaryLang[++currentCaptionLine].positionX = positionX;
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].positionY = positionY;
                                }
                            } else {
                                if (buffer.primaryLang[currentCaptionLine].captionText == "") {
                                    buffer.primaryLang[currentCaptionLine].positionX = positionX;
                                    buffer.primaryLang[currentCaptionLine].positionY = positionY;
                                } else if (currentCaptionLine<3) {
                                    buffer.primaryLang[++currentCaptionLine].positionX = positionX;
                                    buffer.primaryLang[currentCaptionLine].positionY = positionY;
                                }
                            }
                            timer.add(1);
                        } else if (new RegExp(/{TO\d\d}/g).test(code)) {
                            var tab = parseInt(code.substring(3, 5));
                            if (paintOn) {
                                captions[captions.length - 1].primaryLang[currentCaptionLine].positionX += tab;
                            } else {
                                buffer.primaryLang[currentCaptionLine].positionX += tab;
                            }
                            timer.add(1);
                        } else {
                            //console("Generic Code returned meaning character: "+ code);
                            if (paintOn) {
                                captions[captions.length - 1].primaryLang[currentCaptionLine].captionText += code;
                            } else {
                                buffer.primaryLang[currentCaptionLine].captionText += code;
                            }
                            timer.add(1);
                        }
                    } else {
                        var sccCodes = [ancData[i].substring(0, 2), ancData[i].substring(2, 4)];
                        sccCodes.forEach(sccCode => {
                            if (sccLookup.ccCharset[sccCode] != undefined) {
                                var code = sccLookup.ccCharset[sccCode];
                                if (code === '{FILLER}') {
                                    /* Filler to pass time */
                                } else if (paintOn) {
                                    //console.log("Paint on true");
                                    captions[captions.length - 1].primaryLang[currentCaptionLine].captionText += code;
                                } else {
                                    //console.log("Paint on false");
                                    buffer.primaryLang[currentCaptionLine].captionText += code;
                                }
                            }
                        });

                        timer.add(1);
                    }
                }
            }
        }

        if (captions[captions.length - 1] && captions[captions.length - 1].outcode == "") {
            captions[captions.length - 1].outcode = timer.toString();
        }

        return captions;
    },
    mcc: function (captionData, frameRate, dropFrame) {
        var currentWindow = 0;
        var currentCaptionLine = 0;
        var captions = [];
        var delay = false;
        var delayAmount = 0;
        var penLocation = {
            row: "",
            column: ""
        };
        var ext = false;
        var lastTimecode;
        var displayStatus = {
            window: false,
            hide: false
        }

        /*Byte Counter*/
        var skipByte = false;
        var bytesSkipped = 0;
        var bytesToSkip = 0;
        var prevCommand = "";
        var prevCommandBuffer = "";

        var fileLines = captionData.split("\n");
        for (var j = 0; j < fileLines.length; j++) {
            var timecode = "";
            var cdp = {
                timecode: "",
                captionText: "",
                decodedText: "",
                cdp_header: {
                    cdp_length: 0,
                    cdp_frame_rate: 0,
                    time_code_present: 0,
                    ccdata_present: 0,
                    svcinfo_present: "",
                    svc_info_start: "",
                    svc_info_change: "",
                    svc_info_complete: "",
                    caption_service_active: "",
                    cdp_hdr_sequence_cntr: "",
                },
                ccdata_section: {
                    marker_bits: "",
                    cc_count: "",
                    captionPackets: []
                }
            }
            if (fileLines[j].split("\t")[0].match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g) != null) {
                var ext = false;
                var captionLine = "";
                cdp.timecode = fileLines[j].split("\t")[0];
                //console.log("\n"+cdp.timecode+"\n");            
                var ancData = fileLines[j].split("\t")[1].split("");
                ancData.forEach(ancChar => {
                    if (mccLookup.charToByte[ancChar] != undefined) {
                        captionLine += (mccLookup.charToByte[ancChar]);
                    } else {
                        captionLine += ancChar;
                    }
                });
                captionLine = captionLine.split("9669")[1].split("");
                cdp.cdp_header.cdp_length = captionLine.shift();
                cdp.cdp_header.cdp_length += captionLine.shift();
                cdp.cdp_header.cdp_frame_rate = captionLine.shift();
                var Reserved = captionLine.shift();
                var dataBuffer = captionLine.shift();
                dataBuffer += captionLine.shift();
                dataBuffer = hex2Bin(dataBuffer).split("");

                cdp.cdp_header.time_code_present = dataBuffer.shift();
                cdp.cdp_header.ccdata_present = dataBuffer.shift();
                cdp.cdp_header.svcinfo_present = dataBuffer.shift();
                cdp.cdp_header.svc_info_start = dataBuffer.shift();
                cdp.cdp_header.svc_info_change = dataBuffer.shift();
                cdp.cdp_header.svc_info_complete = dataBuffer.shift();
                cdp.cdp_header.caption_service_active = dataBuffer.shift();

                cdp.cdp_header.cdp_hdr_sequence_cntr = captionLine.shift();
                cdp.cdp_header.cdp_hdr_sequence_cntr += captionLine.shift();
                cdp.cdp_header.cdp_hdr_sequence_cntr += captionLine.shift();
                cdp.cdp_header.cdp_hdr_sequence_cntr += captionLine.shift();
                if (cdp.cdp_header.ccdata_present == 1) {
                    captionLine = captionLine.join("");
                    var indexOfCaptionData = captionLine.indexOf("72");
                    var captionInfo = captionLine.slice(indexOfCaptionData, captionLine.length - 1).split("");
                    var ccdata_id = captionInfo.shift();
                    ccdata_id += captionInfo.shift();
                    var cdpBuffer = captionInfo.shift();
                    cdpBuffer += captionInfo.shift();
                    cdpBuffer = hex2Bin(cdpBuffer);
                    cdpBuffer = cdpBuffer.split("");

                    cdp.ccdata_section.marker_bits = cdpBuffer.shift();
                    cdp.ccdata_section.marker_bits += cdpBuffer.shift();
                    cdp.ccdata_section.marker_bits += cdpBuffer.shift();

                    cdp.ccdata_section.cc_count = parseInt(cdpBuffer.join(""), 2);
                    for (var i = 0; i < cdp.ccdata_section.cc_count; i++) {

                        var captionPacket = {
                            marker_bits: "",
                            cc_valid: "",
                            cc_type: "",
                            cc_data_1: "",
                            cc_data_2: "",
                            raw: ""
                        }
                        captionPacket.raw = captionInfo.shift();
                        captionPacket.raw += captionInfo.shift();
                        captionPacket.raw += captionInfo.shift();
                        captionPacket.raw += captionInfo.shift();
                        captionPacket.raw += captionInfo.shift();
                        captionPacket.raw += captionInfo.shift();
                        var packetBuffer = captionPacket.raw.substr(0, 2);

                        packetBuffer = hex2Bin(packetBuffer).split("");
                        captionPacket.marker_bits = packetBuffer.shift();
                        captionPacket.marker_bits += packetBuffer.shift();
                        captionPacket.marker_bits += packetBuffer.shift();
                        captionPacket.marker_bits += packetBuffer.shift();
                        captionPacket.marker_bits += packetBuffer.shift();

                        captionPacket.cc_valid = packetBuffer.shift();

                        captionPacket.cc_type = packetBuffer.shift();
                        captionPacket.cc_type += packetBuffer.shift();

                        captionPacket.cc_data_1 = captionPacket.raw.substr(2, 2).toLowerCase();
                        captionPacket.cc_data_2 = captionPacket.raw.substr(4, 2).toLowerCase();

                        cdp.ccdata_section.captionPackets.push(captionPacket);
                    }

                    cdp.ccdata_section.captionPackets.forEach(captionDataPacket => {
                        var cmdArray = [];
                        //console.log(captionDataPacket);
                        /* Reference Pg.11 of  ANSI-CTA-708-E-R-2018-Final_pdf.pdf*/
                        if (captionDataPacket.cc_type == "00") {
                            if (captionDataPacket.cc_valid == "0") {
                                /* CEA-608 line 21 field 1 – do not generate run-in clock and start bit; or DTVCC padding bytes* */
                            } else if (captionDataPacket.cc_valid == "1") {
                                /* CEA-608 line 21 field 1 closed caption bytes */
                                //console.log("00 - 1");
                                /* if (sccLookup.ccCharset[captionDataPacket.cc_data_1 + captionDataPacket.cc_data_2] != undefined && sccLookup.ccCharset[captionDataPacket.cc_data_1 + captionDataPacket.cc_data_2].match(/{\d\d_\d\d}/g)) {
                                    if (mccLookup.windows[currentWindow].position) {
                                        mccLookup.windows[currentWindow].captionText += '<br />';
                                    } else {
                                        mccLookup.windows[currentWindow].position = sccLookup.ccCharset[captionDataPacket.cc_data_1 + captionDataPacket.cc_data_2].split("}")[0].substring(1, 6);
                                    }
                                } */
                            }
                        } else if (captionDataPacket.cc_type == "01") {
                            if (captionDataPacket.cc_valid == "0") {
                                /* CEA-608 line 21 field 2 – do not generate run-in clock and start bit; or DTVCC padding bytes* */

                            } else if (captionDataPacket.cc_valid == "1") {
                                /* CEA-608 line 21 field 2 closed caption bytes */
                                //console.log("01 - 1");
                                /* if (sccLookup.ccCharset[captionDataPacket.cc_data_1 + captionDataPacket.cc_data_2] != undefined && sccLookup.ccCharset[captionDataPacket.cc_data_1 + captionDataPacket.cc_data_2].match(/{\d\d_\d\d}/g)) {
                                    if (mccLookup.windows[currentWindow].position){
                                        mccLookup.windows[currentWindow].captionText += '<br />';
                                    } else {
                                        mccLookup.windows[currentWindow].position = sccLookup.ccCharset[captionDataPacket.cc_data_1 + captionDataPacket.cc_data_2].split("}")[0].substring(1, 6);
                                    } 
                                } */
                            }

                        } else if (captionDataPacket.cc_type == "10") {
                            if (captionDataPacket.cc_valid == "0") {
                                /* DTVCC padding bytes */
                            } else if (captionDataPacket.cc_valid == "1") {
                                /* CCP Data – cc_data_1 and cc_data_2 are CCP Data, (Figure 4) */
                                //console.log("10 - 1");
                                //console.log("708 bit: "+ captionDataPacket.cc_data_1);
                                //console.log("708 bit: "+ captionDataPacket.cc_data_2);
                                cmdArray.push(captionDataPacket.cc_data_1);
                                cmdArray.push(captionDataPacket.cc_data_2);
                            }
                        } else if (captionDataPacket.cc_type == "11") {
                            if (captionDataPacket.cc_valid == "0") {
                                /* DTVCC padding bytes */
                            } else if (captionDataPacket.cc_valid == "1") {
                                /* Start of CCP– cc_data_1 is a CCP Header byte, cc_data_2 is CCP Data (Figure 4) */
                                //console.log("11 - 1");
                                //console.log("708 bit: "+ captionDataPacket.cc_data_2);
                                cmdArray.push(captionDataPacket.cc_data_2);
                            }
                        }
                        cmdArray.forEach(function (ccData) {
                            if (skipByte && bytesSkipped >= bytesToSkip) {
                                skipByte = false;
                                bytesToSkip = 0;
                            } else if (skipByte) {
                                bytesSkipped++;
                                prevCommandBuffer += ccData;
                                if (bytesSkipped == bytesToSkip) {
                                    if (prevCommand == "CLW") {
                                        /*Clear Windows*/
                                        var bitmap = hex2Bin(prevCommandBuffer);
                                    } else if (prevCommand == "DSW") {
                                        /*Display Window*/
                                        var bitmap = hex2Bin(prevCommandBuffer);
                                        //console.log(bitmap);
                                        if (displayStatus.window === 'a') {
                                            mccLookup.windows[1].incode = cdp.timecode;
                                            displayStatus.window = 'b';
                                        } else if (displayStatus.window === 'b' || displayStatus.window === false) {
                                            mccLookup.windows[0].incode = cdp.timecode;
                                            displayStatus.window = 'a';
                                        }
                                        displayStatus.hide = false;

                                    } else if (prevCommand == "HDW") {
                                        /*Hide window*/
                                        var bitmap = hex2Bin(prevCommandBuffer);
                                        if (displayStatus.window === 'a') {
                                            mccLookup.windows[0].outcode = cdp.timecode;
                                            //console.log(mccLookup.windows[0]);
                                            if (mccLookup.windows[0].incode != '') {
                                                captions.push(mccLookup.windows[0]);
                                                mccLookup.windows[0] = ccFunc.captionEvent();
                                                currentCaptionLine = 0;
                                                displayStatus.hide = true;
                                            }

                                        } else if (displayStatus.window === 'b') {
                                            mccLookup.windows[1].outcode = cdp.timecode;
                                            //console.log(mccLookup.windows[1]);

                                            if (mccLookup.windows[1].incode != '') {
                                                captions.push(mccLookup.windows[1]);
                                                mccLookup.windows[1] = ccFunc.captionEvent();
                                                currentCaptionLine = 0;
                                                displayStatus.hide = true;
                                            }
                                        }
                                    } else if (prevCommand == "TGW") {
                                        /*Toggle Windows*/
                                        var bitmap = hex2Bin(prevCommandBuffer);
                                        if (displayStatus.hide) {
                                            if (displayStatus.window === 'a') {
                                                mccLookup.windows[1].incode = cdp.timecode;
                                                displayStatus.window = 'b';
                                            } else if (displayStatus.window === 'b' || displayStatus.window === false) {
                                                mccLookup.windows[0].incode = cdp.timecode;
                                                displayStatus.window = 'a';
                                            }
                                            displayStatus.hide = false;
                                        } else {
                                            if (displayStatus.window === 'a') {
                                                mccLookup.windows[1].incode = cdp.timecode;
                                                displayStatus.window = 'b';
                                                mccLookup.windows[0].outcode = cdp.timecode;
                                                if (mccLookup.windows[0].incode != '') {
                                                    captions.push(mccLookup.windows[0]);
                                                    mccLookup.windows[0] = ccFunc.captionEvent();
                                                    currentCaptionLine = 0;
                                                }
                                            } else if (displayStatus.window === false) {
                                                mccLookup.windows[0].incode = cdp.timecode;
                                                displayStatus.window = 'a';
                                            } else if (displayStatus.window === 'b' || displayStatus.window === false) {
                                                mccLookup.windows[0].incode = cdp.timecode;
                                                displayStatus.window = 'a';
                                                mccLookup.windows[1].outcode = cdp.timecode;
                                                //console.log(mccLookup.windows[1]);
                                                if (mccLookup.windows[1].incode != '') {
                                                    captions.push(mccLookup.windows[1]);
                                                    mccLookup.windows[1] = ccFunc.captionEvent();
                                                    currentCaptionLine = 0;
                                                }
                                            }
                                        }
                                    } else if (prevCommand == "DLW") {
                                        /*Delete Window*/
                                        var bitmap = hex2Bin(prevCommandBuffer);
                                        if (!displayStatus.hide) {
                                            if (displayStatus.window == 'a' && bitmap.split("")[7] == "1") {
                                                mccLookup.windows[0].outcode = cdp.timecode;
                                                //console.log(mccLookup.windows[0]);
                                                if (mccLookup.windows[0].incode != '') {
                                                    captions.push(mccLookup.windows[0]);
                                                    mccLookup.windows[0] = ccFunc.captionEvent();
                                                    currentCaptionLine = 0;
                                                    displayStatus.hide = true;
                                                }
                                            } else if (displayStatus.window == 'b' && bitmap.split("")[6] == "1") {
                                                mccLookup.windows[1].outcode = cdp.timecode;
                                                //console.log(mccLookup.windows[1]);
                                                if (mccLookup.windows[1].incode != '') {
                                                    captions.push(mccLookup.windows[1]);
                                                    mccLookup.windows[1] = ccFunc.captionEvent();
                                                    currentCaptionLine = 0;
                                                    displayStatus.hide = true;
                                                }
                                            }
                                        }
                                    } else if (prevCommand == "DLY") {
                                        /*Delay*/
                                        var bitmap = hex2Bin(prevCommandBuffer);
                                    } else if (prevCommand == "SPA") {
                                        /*Set Pen Attributes*/
                                        mccLookup.windows[currentWindow].spaSet = true;
                                    } else if (prevCommand == "SPC") {
                                        /*Set Pen Color*/
                                        mccLookup.windows[currentWindow].spcSet = true;
                                    } else if (prevCommand == "SPL") {
                                        /*Set Pen Location*/
                                        var row = parseInt(prevCommandBuffer.substr(0, 2));
                                        var column = parseInt(prevCommandBuffer.substr(2, 2));
                                        
                                        if (mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].captionText == "") {
                                            mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].positionX = column;
                                            mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].positionY = row;
                                        } else{
                                            mccLookup.windows[currentWindow].primaryLang[++currentCaptionLine].positionX = column;
                                            mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].positionY = row;
                                        }

                                        mccLookup.windows[currentWindow].splSet = true;

                                    } else if (prevCommand == "SWA") {
                                        /*Set Window Attributes*/
                                    } else if (prevCommand == "DFW") {
                                        /*Define Window*/
                                        var verticalPosition = prevCommandBuffer.substr(2, 2);
                                        verticalPosition = hex2Bin(verticalPosition);
                                        var relative = verticalPosition.substr(7, 1);
                                        verticalPosition = verticalPosition.substr(0, 7);
                                        verticalPosition = (parseInt(verticalPosition, 2));
                                        if (relative == "1") {
                                            verticalPosition = 15 - parseInt((verticalPosition / 100) * 14);
                                            mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].positionX = 0;
                                            mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].positionY = verticalPosition;
                                        } else {
                                            verticalPosition = 15 - parseInt((verticalPosition / 75) * 14);
                                            mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].positionX = 0;
                                            mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].positionY = verticalPosition;
                                        }
                                    } else if (prevCommand == "FF") {
                                        //na
                                    } else if (prevCommand == "BS") {
                                        //na
                                    } else if (prevCommand == "CW") {
                                        //na
                                    }
                                    /*BLANK OUT THE PREV COMMAND SETTINGS*/
                                    prevCommand = "";
                                    prevCommandBuffer = "";
                                }
                            }

                            if (!skipByte) {
                                if (ext) {
                                    var command = mccLookup.commands.EXT[ccData];
                                    ext = false;
                                } else {
                                    var command = mccLookup.commands[ccData];
                                }

                                if (command === undefined) {
                                    //console.log("Undefined caption code for: "+ccData);
                                } else {
                                    if (command == "{NUL}") {
                                        //Do nothing right now
                                    } else if (command == "{ETX}") {
                                        /* End of text */
                                    } else if (command == "{BS}") {
                                        //Do nothing right now
                                    } else if (command == "{FF}") {
                                        prevCommand = "FF";
                                        skipByte = true;
                                        bytesToSkip = 1;
                                        bytesSkipped = 0;
                                    } else if (command == "{CR}") {
                                        //Do nothing right now
                                    } else if (command == "{HCR}") {
                                        //Do nothing right now
                                    } else if (command == "{EXT}") {
                                        ext = true;
                                    } else if (/^{CW\d}$/.test(command)) {
                                        /*Set Current Window*/
                                        prevCommand = "CW";
                                        skipByte = true;
                                        bytesToSkip = 1;
                                        bytesSkipped = 0;
                                    } else if (/^{SKIP\d}$/.test(command)) {
                                        /*Skip number of bits*/
                                        //console.log("Skip Command found for: " + ccData);
                                        skipByte = true;
                                        bytesToSkip = parseInt(command.substr(5, 1));
                                        bytesSkipped = 0;
                                    } else if (command == "{CLW}") {
                                        prevCommand = "CLW";
                                        skipByte = true;
                                        bytesToSkip = 1;
                                        bytesSkipped = 0;
                                    } else if (command == "{DSW}") {
                                        prevCommand = "DSW";
                                        skipByte = true;
                                        bytesToSkip = 1;
                                        bytesSkipped = 0;
                                    } else if (command == "{HDW}") {
                                        prevCommand = "HDW";
                                        skipByte = true;
                                        bytesToSkip = 1;
                                        bytesSkipped = 0;
                                    } else if (command == "{TGW}") {
                                        prevCommand = "TGW";
                                        skipByte = true;
                                        bytesToSkip = 1;
                                        bytesSkipped = 0;
                                    } else if (command == "{DLW}") {
                                        prevCommand = "DLW";
                                        skipByte = true;
                                        bytesToSkip = 1;
                                        bytesSkipped = 0;
                                    } else if (command == "{DLY}") {
                                        prevCommand = "DLY";
                                        skipByte = true;
                                        bytesToSkip = 1;
                                        bytesSkipped = 0;
                                    } else if (command == "{DLC}") {
                                        delay = false;
                                        delayAmount = 0;
                                    } else if (command == "{RST}") {
                                        delay = false;
                                        delayAmount = 0;
                                    } else if (command == "{SPA}") {
                                        prevCommand = "SPA";
                                        skipByte = true;
                                        bytesToSkip = 2;
                                        bytesSkipped = 0;
                                    } else if (command == "{SPC}") {
                                        prevCommand = "SPC";
                                        skipByte = true;
                                        bytesToSkip = 3;
                                        bytesSkipped = 0;
                                    } else if (command == "{SPL}") {
                                        prevCommand = "SPL";
                                        skipByte = true;
                                        bytesToSkip = 2;
                                        bytesSkipped = 0;
                                    } else if (command == "{SWA}") {
                                        prevCommand = "SWA";
                                        skipByte = true;
                                        bytesToSkip = 4;
                                        bytesSkipped = 0;
                                    } else if (/^{DF\d}$/.test(command)) {
                                        currentWindow = command.substr(3, 1);
                                        /*console.log("Updating current window to "+currentWindow);
                                        console.log("Display Status Window: "+ displayStatus.window);
                                        console.log("Display Status Hide: "+ displayStatus.hide);

                                        console.log(mccLookup.windows[0]);
                                        console.log(mccLookup.windows[1]);*/

                                        prevCommand = "DFW";
                                        skipByte = true;
                                        bytesToSkip = 6;
                                        bytesSkipped = 0;
                                    } else if (((mccLookup.windows[currentWindow].spcSet && mccLookup.windows[currentWindow].splSet) || (mccLookup.windows[currentWindow].spcSet && mccLookup.windows[currentWindow].spaSet)) && cdp.decodedText.length > 0) {
                                        mccLookup.windows[currentWindow].primaryLang[currentCaptionLine].captionText += command;
                                    }
                                    cdp.decodedText += command;
                                }
                            }

                        });

                    });
                }
                lastTimecode = cdp.timecode;
            }
        };
        if (mccLookup.windows[0].captionText != "") {
            mccLookup.windows[0].outcode = lastTimecode;
            if (mccLookup.windows[0].incode === "") {
                // console.log("Adding missing outcode: " + captions[captions.length - 1].outcode);
                mccLookup.windows[0].incode = captions[captions.length - 1].outcode;
            }
            captions.push(mccLookup.windows[0]);
            // console.log(mccLookup.windows[0]);
            mccLookup.windows[0] = ccFunc.captionEvent();
            currentCaptionLine = 0;
        } else if (mccLookup.windows[1].primaryLang[currentCaptionLine].captionText != "") {
            mccLookup.windows[1].outcode = lastTimecode;
            if (mccLookup.windows[1].incode === "") {
                //console.log("Adding missing outcode: " + captions[captions.length - 1].outcode);
                mccLookup.windows[1].incode = captions[captions.length - 1].outcode;
            }
            captions.push(mccLookup.windows[1]);
            //console.log(mccLookup.windows[1]);
            mccLookup.windows[0] = ccFunc.captionEvent();
        }
        return captions;
    },
    srt: function (captionData, frameRate, dropFrame) {
        var captions = [];
        var captionBuffer = captionData.split("\n\n");
        captionBuffer.forEach(captionLine => {
            var newCaptionEvent = new ccFunc.captionEvent();
            var currentCaptionLine = 0;
            var captionLineArray = captionLine.split("\n").filter(lineData => {
                return lineData != "";
            });
            if (captionLineArray.length > 1) {
                newCaptionEvent.incodeMs = captionLineArray[1].split(" --> ")[0].replace(",", ".");
                newCaptionEvent.outcodeMs = captionLineArray[1].split(" --> ")[1].replace(",", ".");
                
                var i = 2;
                while (i < captionLineArray.length) {
                    if (/<b>|<\/b>/.test(captionLineArray[i])){
                        newCaptionEvent.primaryLang[currentCaptionLine].bold = true
                    }
                    if (/<i>|<\/i>/.test(captionLineArray[i])){
                        newCaptionEvent.primaryLang[currentCaptionLine].italics = true
                    }
                    if (/<u>|<\/u>/.test(captionLineArray[i])){
                        newCaptionEvent.primaryLang[currentCaptionLine].underline = true
                    }

                    newCaptionEvent.primaryLang[currentCaptionLine].captionText += ccFunc.removeHtmlTags(captionLineArray[i].trim()) + " ";
                    currentCaptionLine++
                    if (currentCaptionLine > 3){
                        currentCaptionLine = 3;
                    }
                    i++;
                }

                newCaptionEvent.primaryLang = ccFunc.centerLines(newCaptionEvent.primaryLang);
                captions.push(newCaptionEvent);
            }
        });
        return captions;
    },
    vtt: function (captionData, frameRate, dropFrame) {
        var captions = [];
        var captionBuffer = captionData.split("\n\n");
        var alignment = false;
        var positionValue = false;
        var lineValue = false;

        captionBuffer.forEach(captionLine => {
            var newCaptionEvent = new ccFunc.captionEvent();
            var currentCaptionLine = 0;
            var captionLineArray = captionLine.split("\n").filter(lineData => {
                return lineData != "";
            });
            if (captionLineArray.length > 1) {
                var captionIdentifier = false;
                var timecodeIdentifier = false;
                for (var i = 0; i < captionLineArray.length; i++) {
                    
                    if (captionLineArray[i].split(" --> ").length === 2) {
                        timecodeIdentifier = true;
                        /* Check for extra metadata in the timecode row */
                        var metadata = captionLineArray[i].split(" ");
                        if (metadata.length > 3) {
                            for (var q = 0; q < metadata.length; q++) {
                                if (/align:/g.test(metadata[q])) {
                                    alignment = metadata[q].split(":")[1].trim();
                                } else if (/position:/g.test(metadata[q])) {
                                    positionValue = metadata[q].split(":")[1].split("%")[0].trim();
                                    for (var j = 0; j < 4; j++) {
                                        newCaptionEvent.primaryLang[j].positionX = parseInt((positionValue / 100) * 32);
                                    }
                                } else if (/line:/g.test(metadata[q])) {
                                    lineValue = metadata[q].split(":")[1].split("%")[0].trim();
                                    for (var j = 0; j<4; j++){
                                        newCaptionEvent.primaryLang[j].positionY = parseInt((lineValue / 100) * 14)+j;
                                    }                                    
                                } 
                            }
                        }

                        newCaptionEvent.incodeMs = captionLineArray[i].split(" --> ")[0].replace(",", ".").split(" ")[0];
                        newCaptionEvent.outcodeMs = captionLineArray[i].split(" --> ")[1].replace(",", ".").split(" ")[0];

                        if (newCaptionEvent.incodeMs.length <= 9) {
                            newCaptionEvent.incodeMs = "00:" + newCaptionEvent.incodeMs;
                        }

                        if (newCaptionEvent.outcodeMs.length <= 9) {
                            newCaptionEvent.outcodeMs = "00:" + newCaptionEvent.outcodeMs;
                        }

                        /* Check if the last line has the same timecode values and combine them */
                        if (captions.length > 0 && newCaptionEvent.incodeMs == captions[captions.length - 1].incodeMs) {
                            newCaptionEvent.primaryLang = captions[captions.length - 1].primaryLang;
                            currentCaptionLine = ccFunc.getFirstEmptyLine(newCaptionEvent.primaryLang);
                            captions.pop();
                        }

                    } else if (!timecodeIdentifier) {
                        captionIdentifier = true;
                    } else if (timecodeIdentifier) {
                        if (captionLineArray[i].indexOf("<i>") > -1 || captionLineArray[i].indexOf("</i>") > -1) {
                            newCaptionEvent.primaryLang[currentCaptionLine].italics = true;
                        }

                        if (captionLineArray[i].indexOf("<b>") > -1 || captionLineArray[i].indexOf("</b>") > -1) {
                            newCaptionEvent.primaryLang[currentCaptionLine].bold = true;
                        }

                        if (captionLineArray[i].indexOf("<u>") > -1 || captionLineArray[i].indexOf("</u>") > -1) {
                            newCaptionEvent.primaryLang[currentCaptionLine].underline = true;
                        }

                        newCaptionEvent.primaryLang[currentCaptionLine].captionText = stripTags(captionLineArray[i]) + " ";
                        currentCaptionLine++;
                        if (currentCaptionLine > 3){
                            currentCaptionLine = 3;
                        }
                    }

                }

                if (timecodeIdentifier) {
                    if (alignment && (!positionValue || !lineValue)){
                        ccFunc.setPositionByAlignment(newCaptionEvent.primaryLang, alignment);
                    } else if (!positionValue || !lineValue) {
                        ccFunc.centerLines(newCaptionEvent.primaryLang);
                    }

                    alignment = false;
                    positionValue = false;
                    lineValue = false;
                    captions.push(newCaptionEvent);
                }

            }
        });
        return captions;
    },
    sbv: function (captionData, frameRate, dropFrame) {
        var captionBuffer = captionData.split("\n\n");
        var captions = [];
        var currentCaptionLine = 0;
        for (var i = 0; i < captionBuffer.length; i++) {
            try {
                var captionEvent = new ccFunc.captionEvent();
                currentCaptionLine = 0;
                var captionBlock = captionBuffer[i].split("\n");
                captionEvent.incodeMs = captionBlock[0].split(",")[0];
                captionEvent.outcodeMs = captionBlock[0].split(",")[1];

                if (captionEvent.incodeMs.split(":")[0].length === 1) {
                    captionEvent.incodeMs = "0" + captionEvent.incodeMs;
                }

                if (captionEvent.outcodeMs.split(":")[0].length === 1) {
                    captionEvent.outcodeMs = "0" + captionEvent.outcodeMs;
                }

                for (var j = 1; j < captionBlock.length; j++) {
                    captionBlock[j].split("[br]").forEach(captionLine => {
                        captionEvent.primaryLang[currentCaptionLine].captionText = captionLine;
                        currentCaptionLine++;
                    });
                }

                captionEvent.primaryLang = ccFunc.centerLines(captionEvent.primaryLang);
                captions.push(captionEvent);
            } catch (e) {
                console.log("Import error at caption event " + (i + 1));
                console.log(e.message);
            }

        }
        
        return captions;
    },
    /* Avid DS */
    txt: function (captionData, frameRate, dropFrame) {
        var captions = [];
        var currentCaptionLine = 0;
        var captionBuffer = captionData.split("\n\n");
        var captionLine = null;
        for (var i = 0; i < captionBuffer.length; i++) {
            try {
                var captionDataLines = captionBuffer[i].split("\n");
                var timecodeFlag = false;
                for (var j = 0; j < captionDataLines.length; j++) {
                    if (timecodeFlag) {
                        captionLine.primaryLang[currentCaptionLine].captionText += captionDataLines[j];
                        currentCaptionLine++;
                    } else {
                        var timecodes = captionDataLines[j].split(" ");
                        //console.log(timecodes);
                        if (timecodes[0].match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g) != null && timecodes[1].match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g) != null) {
                            var captionLine = new ccFunc.captionEvent();
                            currentCaptionLine = 0;
                            captionLine.incode = timecodes[0];
                            captionLine.outcode = timecodes[1];
                            timecodeFlag = true;
                        }
                    }
                }
                if (captionLine != null) {
                    captionLine.primaryLang = ccFunc.centerLines(captionLine.primaryLang);
                    captions.push(captionLine);
                    captionLine = null;
                }
            } catch (e) {
                console.log(e.message);
            }
        }
        //console.log(captions);
        return captions;
    },
    stl: function (captionData, frameRate, dropFrame) {
        var captions = [];
        var currentCaptionLine = 0;
        //console.log(captionData.length);
        /* GSI is 1024 bits */
        var GSI = captionData.substring(0, 2048);
        //console.log(GSI);
        var ebuBytes = GSI.substring(0, 896);
        var userBytes = GSI.substring(896, GSI.length);

        var charCodeTbl = ebuBytes.substring(24, 28);
        var codeTable = stlLookup.charCodeTblMap[charCodeTbl];

        /* Text and Timing Information (TTI) blocks */
        var TTI = captionData.substring(2048, captionData.length);
        var ttiBlocks = TTI.match(/.{1,256}/g);
        //console.log(ttiBlocks);
        for (var i = 0; i < ttiBlocks.length; i++) {
            var captionLine = new ccFunc.captionEvent();
            currentCaptionLine = 0;
            var groupNumber = ttiBlocks[i].substring(0, 2); //1
            var subtitleNumber = ttiBlocks[i].substring(2, 6); //2
            var extBlockNumber = ttiBlocks[i].substring(6, 8); //1
            var cumulativeStatus = ttiBlocks[i].substring(8, 10); //1
            var incode = ttiBlocks[i].substring(10, 18); //4
            var outcode = ttiBlocks[i].substring(18, 26); //4
            var positionRow = ttiBlocks[i].substring(26, 28); //1
            var justification = ttiBlocks[i].substring(28, 30); //1
            var commentFlag = ttiBlocks[i].substring(30, 32); //1
            var captionText = ttiBlocks[i].substring(32, ttiBlocks[i].length);

            var incodeHr = ccFunc.pad("00", parseInt(incode.substring(0, 2), 16), true);
            var incodeMin = ccFunc.pad("00", parseInt(incode.substring(2, 4), 16), true);
            var incodeSec = ccFunc.pad("00", parseInt(incode.substring(4, 6), 16), true);
            var incodeFr = ccFunc.pad("00", parseInt(incode.substring(6, 8), 16), true);

            var outcodeHr = ccFunc.pad("00", parseInt(outcode.substring(0, 2), 16), true);
            var outcodeMin = ccFunc.pad("00", parseInt(outcode.substring(2, 4), 16), true);
            var outcodeSec = ccFunc.pad("00", parseInt(outcode.substring(4, 6), 16), true);
            var outcodeFr = ccFunc.pad("00", parseInt(outcode.substring(6, 8), 16), true);

            captionLine.incode = incodeHr + ":" + incodeMin + ":" + incodeSec + ":" + incodeFr;
            captionLine.outcode = outcodeHr + ":" + outcodeMin + ":" + outcodeSec + ":" + outcodeFr;

            /* Comment flag 01 should be ignored */
            if (commentFlag === '00') {
                captionText = captionText.toUpperCase().match(/.{1,2}/g);

                for (var j = 0; j < captionText.length; j++) {
                    if (stlLookup.importLib[codeTable][captionText[j]] != undefined) {
                        if (stlLookup.importLib[codeTable][captionText[j]] === "LINE BREAK"){
                            if (currentCaptionLine < 3) {
                                currentCaptionLine++;
                            }                            
                        } else {
                            captionLine.primaryLang[currentCaptionLine].captionText += stlLookup.importLib[codeTable][captionText[j]];
                        }                                                
                    }
                }

                captionLine.primaryLang = ccFunc.centerLines(captionLine.primaryLang);
                captions.push(captionLine);
            }
        }
        return captions;
    },
    pac: function (captionData, frameRate, dropFrame) {
        var i = 0;
        var captions = [];
        var captionArray = captionData.match(/(..?)/g);
        var captionLine = new ccFunc.captionEvent();
        var currentCaptionLine = 0;
        captionLine.hex = "";
        var startOfCaptionText = false
        for (i; i < captionArray.length; i++) {
            captionLine.hex += captionArray[i];
            if (captionArray[i].toUpperCase() == "60") {               
                startOfCaptionText = false;
                captionLine.primaryLang = ccFunc.centerLines(captionLine.primaryLang);
                captions.push(captionLine);
                captionLine = new ccFunc.captionEvent();
                currentCaptionLine = 0;
                captionLine.hex = "";
                var hexIncode = captionArray.splice(i + 1, 4);
                var hexOutcode = captionArray.splice(i + 1, 4);
                captionLine.incode = ccFunc.formatPacTc(hexIncode);
                captionLine.outcode = ccFunc.formatPacTc(hexOutcode);
            } else if (captionArray[i].toUpperCase() == "00") {
                if (captionLine.primaryLang[0].captionText.length > 0) {
                    startOfCaptionText = false;
                }
            } else if (captionArray[i].toUpperCase() == "FE" && currentCaptionLine < 4) {
                var feIndex = i;
                startOfCaptionText = true;
                if (captionLine.primaryLang[currentCaptionLine].captionText.length > 0) {
                    currentCaptionLine++
                }
            } else if (pacLookup.ignoredCommands.indexOf(captionArray[i].toUpperCase()) > -1 || new RegExp(/0\d/g).test(captionArray[i].toUpperCase())) {

            } else if (startOfCaptionText && currentCaptionLine<4) {
                captionLine.primaryLang[currentCaptionLine].captionText += hex2Ascii(captionArray[i]);
            }
        }
        return captions.filter(captionEvent=> {
            return captionEvent.primaryLang[0].captionText.length > 0;
        });
    },
    cap: function (captionData, frameRate, dropFrame) {
        try {
            var captionDataArray = captionData.match(/.{1,2}/g);
            var captions = [];
            var currentCaptionLine = 0;
            //console.log(captionDataArray)
            /* Header is first 256 hex characters () */
            var header = captionDataArray.splice(0, 128).join("");
            while (captionDataArray.length != 0) {
                var captionLine = new ccFunc.captionEvent();
                currentCaptionLine = 0;
                var captionLineInfo = captionDataArray.splice(0, 2);
                if (/^4/g.test(captionLineInfo[1])) {
                    var incode = ccFunc.hexToDecimalTc(captionDataArray.splice(0, 4));
                } else if (/^6/g.test(captionLineInfo[1])) {
                    var incode = ccFunc.hexToDecimalTc(captionDataArray.splice(0, 4));
                    var outcode = ccFunc.hexToDecimalTc(captionDataArray.splice(0, 4));
                } else {
                    throw new Error("BAD caption line info: " + captionLineInfo.join(", "));
                }

                var unknownData = captionDataArray.splice(0, 6);
                var position = captionDataArray.splice(0, 3);
                var captionText = [];
                var lineNull = false;
                var ignoreCharacters = ["c0", "d0", "d0"];
                while (!lineNull) {
                    var text = captionDataArray.splice(0, 1).join("");
                    if (text != "00") {
                        if (text == "81") {
                            captionText.push("♪");
                        } else if (ignoreCharacters.indexOf(text) < 0) {
                            //console.log(text + " - " + hex2Ascii(text));
                            captionText.push(hex2Ascii(text));
                        }
                    } else {
                        lineNull = true;
                    }
                }

                var numberOfLines = parseInt(captionLineInfo[1].slice(1));
                //console.log("Number of lines: "+numberOfLines);
                for (var j = 1; j < numberOfLines; j++) {
                    if (j === 1) {
                        captionText.push("[br]");
                    }
                    captionDataArray.splice(0, 3);
                    var lineNull = false;
                    while (!lineNull) {
                        var text = captionDataArray.splice(0, 1).join("");
                        if (text != "00") {
                            captionText.push(hex2Ascii(text));
                        } else {
                            lineNull = true;
                        }
                    }
                }

                //console.log("Incode:"+incode);
                
                //console.log(position);
                //console.log(captionText.join(""));
                captionLine.incode = incode;
                captionLine.outcode = outcode;
                captionText.join("").replace(/Ð|À/g, "").split("[br]").forEach(lineOfText => {
                    captionLine.primaryLang[currentCaptionLine].captionText = lineOfText;
                    currentCaptionLine++;
                });

                captionLine.primaryLang = ccFunc.centerLines(captionLine.primaryLang);
                captions.push(captionLine);
            }
            //console.log(captions);
            return captions;
        } catch (e) {
            console.log(e.message);
        }

    },
    xml: function (captionData, frameRate, dropFrame) {
        var captions = [];
        var currentCaptionLine = 0;
        var captionData = captionData.replace(/\r?\n|\r/g, "").replace(/tts\:origin/gi, "tts_origin");
        var captionJson = xmlJs.xml2js(captionData, {
            compact: true,
            spaces: 4
        });
        var captionBuffer = captionJson.tt.body.div.p;
        captionBuffer.forEach(captionLine => {
            var newCaptionEvent = new ccFunc.captionEvent();
            currentCaptionLine = 0;
            if (/^\d\d:\d\d:\d\d:\d\d$/.test(captionLine._attributes.begin.toString())) {
                var timecodeType = "incode";
                newCaptionEvent.incode = captionLine._attributes.begin.toString();
                newCaptionEvent.outcode = captionLine._attributes.end.toString();
            } else {
                var timecodeType = "incodeMs";
                newCaptionEvent.incodeMs = captionLine._attributes.begin.toString();
                newCaptionEvent.outcodeMs = captionLine._attributes.end.toString();
            }

            newCaptionEvent.primaryLang[currentCaptionLine].captionText = jsonPath.apply('.._text', captionLine).toString().trim();

            if (jsonPath.apply('..tts_origin', captionLine) !== undefined) {
                var positionData = jsonPath.apply('..tts_origin', captionLine).toString();

                /*https://stackoverflow.com/questions/11088562/convert-percent-into-a-decimal-in-html-javascript*/
                var positionX = Math.round((parseFloat(positionData.split(" ")[0]) / 100.0) * 32);
                var positionY = Math.round((parseFloat(positionData.split(" ")[1]) / 100.0) * 15);
                if (!isNaN(positionX) && !isNaN(positionY)) {
                    newCaptionEvent.primaryLang[currentCaptionLine].positionX = positionX;
                    newCaptionEvent.primaryLang[currentCaptionLine].positionY = positionY;
                } else {
                    positionData = false;
                }
            }

            if (captions.length != 0 && newCaptionEvent[timecodeType] === captions[captions.length - 1][timecodeType]) {
                currentCaptionLine = ccFunc.getFirstEmptyLine(captions[captions.length - 1].primaryLang);
                captions[captions.length - 1].primaryLang[currentCaptionLine].captionText = newCaptionEvent.primaryLang[0].captionText;
                captions[captions.length - 1].primaryLang[currentCaptionLine].positionX = newCaptionEvent.primaryLang[0].positionX;
                captions[captions.length - 1].primaryLang[currentCaptionLine].positionY = newCaptionEvent.primaryLang[0].positionY;
            } else {
                if (!positionData){
                    newCaptionEvent.primaryLang = ccFunc.centerLines(newCaptionEvent.primaryLang);
                }
                captions.push(newCaptionEvent);
            }
        });
        return captions;
    },
    ttml : function (captionData, frameRate, dropFrame) {
        var captions = [];
        var currentCaptionLine = 0;
        var captionJson = xmlJs.xml2js(captionData, {
            compact: true,
            spaces: 4
        });

        var metadata = {
            language : captionJson.tt._attributes['xml:lang']
        };
        
        var captionBuffer = captionJson.tt.body.div.p;
        captionBuffer.forEach(captionLine => {
            var newCaptionEvent = new ccFunc.captionEvent();
            currentCaptionLine = 0;
            if (/^\d\d:\d\d:\d\d:\d\d$/.test(captionLine._attributes.begin.toString())) {
                var timecodeType = "incode";
                newCaptionEvent.incode = captionLine._attributes.begin.toString();
                newCaptionEvent.outcode = captionLine._attributes.end.toString();
            } else {
                var timecodeType = "incodeMs";
                newCaptionEvent.incodeMs = captionLine._attributes.begin.toString();
                newCaptionEvent.outcodeMs = captionLine._attributes.end.toString();
            }
            var captionText = entities.decodeXML(jsonPath.apply('.._text', captionLine).join(" ").trim());
            var captionTextLines = captionText.trim().split("[br]");
            for (var l = 0; l <captionTextLines.length; l++){
                if (captionTextLines[l].indexOf("[italics]") > -1) {
                    newCaptionEvent.primaryLang[l].italics = true;
                    captionTextLines[l]  = captionTextLines[l].replace(/\[italics\]/g, "");
                }
                newCaptionEvent.primaryLang[l].captionText = captionTextLines[l].trim();
            }
            
            if (jsonPath.apply('..region', captionLine) !== undefined) {
                var region = jsonPath.apply('..region', captionLine).toString();
                if (/pop/.test(region)) {
                    newCaptionEvent.style = "Pop-On"
                } else if (/paint/.test(region)) {                    
                    newCaptionEvent.style = "Paint-On"
                } else if (/roll/.test(region)) {
                    newCaptionEvent.style = "Roll-Up"
                }
            }

            if (jsonPath.apply('..tts_origin', captionLine) !== undefined) {
                var positionData = jsonPath.apply('..tts_origin', captionLine).toString();

                /*https://stackoverflow.com/questions/11088562/convert-percent-into-a-decimal-in-html-javascript*/
                var positionX = Math.round((parseFloat(positionData.split(" ")[0]) / 100.0) * 32);
                var positionY = Math.round((parseFloat(positionData.split(" ")[1]) / 100.0) * 15);
                if (!isNaN(positionX) && !isNaN(positionY)) {
                    newCaptionEvent.primaryLang[currentCaptionLine].positionX = positionX;
                    newCaptionEvent.primaryLang[currentCaptionLine].positionY = positionY;
                } else {
                    positionData = false;
                }
            }

            if (captions.length != 0 && newCaptionEvent[timecodeType] === captions[captions.length - 1][timecodeType]) {
                currentCaptionLine = ccFunc.getFirstEmptyLine(captions[captions.length - 1].primaryLang);
                captions[captions.length - 1].primaryLang[currentCaptionLine].captionText = newCaptionEvent.primaryLang[0].captionText;
                captions[captions.length - 1].primaryLang[currentCaptionLine].positionX = newCaptionEvent.primaryLang[0].positionX;
                captions[captions.length - 1].primaryLang[currentCaptionLine].positionY = newCaptionEvent.primaryLang[0].positionY;
            } else {
                if (!positionData) {
                    newCaptionEvent.primaryLang = ccFunc.centerLines(newCaptionEvent.primaryLang);
                }
                captions.push(newCaptionEvent);
            }
        });
        return captions;
    }
}