<template>
<div class="col-7" @click="updateSelectedEvent">

  <textarea :id="'captionEventInput'+Id" class="form-control" :class="{'bg-danger': error}" ref="subtitleArea" rows="3" v-model="subtitleText"></textarea>
  <div class="btn-group styleOptions pb-4" role="group" aria-label="Formatting Options">
    <button type="button" class="btn btn-secondary" @click="splitEvent($event)" title="Split Event"><i class="fas fa-object-ungroup"></i></button>
    <button type="button" class="btn btn-secondary" @click="mergeEvent" title="Merge Event"><i class="fas fa-object-group"></i></button>
    <button type="button" class="btn btn-secondary" @click="expandEvent" title="Expand Event"><i class="fas fa-arrows-alt-h"></i></button>
    <button type="button" class="btn btn-secondary" @click="compressEvent" title="Compress Event"><i class="fas fa-arrows-alt-v"></i></button>
    <button type="button" class="btn btn-secondary" @click="shiftLine('up')" title="Shift Lines Up"><i class="fas fa-level-up-alt"></i></button>
    <button type="button" class="btn btn-secondary" @click="shiftLine('down')" title="Shift Lines Down"><i class="fas fa-level-down-alt"></i></button>
    <button type="button" class="btn btn-secondary" @click="shiftWord('up')" title="Shift Word Up"><i class="fas fa-long-arrow-alt-up"></i></button>
    <button type="button" class="btn btn-secondary" @click="shiftWord('down')" title="Shift Word Down"><i class="fas fa-long-arrow-alt-down"></i></button>
    <button type="button" class="btn btn-secondary" @click="duplicate" title="Duplicate"><i class="far fa-copy"></i></button>
    <div class="btn-group" role="group">
      <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" title="Speaker Identification">
        <i class="far fa-user"></i>
      </button>
      <div class="dropdown-menu">
        <button type="button" class="dropdown-item btn-link" v-for="(speaker, index) in speakers" :key="index" @click="insertSpeaker(speaker)">{{speaker}}</button>
        <div class="dropdown-divider border-top" v-if="speakers.length > 0"></div>
        <button type="button" class="dropdown-item btn-link" href="#" data-toggle="modal" data-target="#OptionsModal" v-on:click="updateMenu('Speakers')"><i class="fas fa-plus"></i> Add speaker...</button>
      </div>
    </div>
  </div>
  <p class="float-right text-muted" style="font-size: 10px;" v-if="error">Line length exceeds {{this.$store.state.editor.autoFormat.maxChars}} characters</p>
</div>
</template>

<script>
import globalFunc from '../../assets/js/functions/globalFunc'
export default {
  name: "SubtitleEventInput",
  props: ['Id', 'selectedEvent'],
  mounted: function () {
    if (this.Id === this.selectedEvent) {
      this.$refs.subtitleArea.focus();
    }

  },
  computed: {
    error: function () {
      if (this.$store.state.editor.autoFormat.enable) {
        if (
          this.subtitleText.split("\n").filter(line => {
            return line.trim().length > this.$store.state.editor.autoFormat.maxChars;
          }).length > 0
        ) {
          return true;
        } else {
          return false;
        };
      } else {
          return false;
      }

    },
    speakers: function () {
      return this.$store.state.editor.speakers;
    },
    subtitleText: {
      get: function () {
        return this.$store.state.editor.captions[this.Id].subtitleText;
      },
      set: function (subtitleText) {
        this.$store.commit("updateSubtitleText", {
          id: this.Id,
          text: subtitleText
        });
      }
    },
  },
  methods: {
    updateSelectedEvent: function () {
      if (this.Id != this.$store.state.editor.selectedCaptionEvent) {
        this.$store.commit("selectCaptionEvent", this.Id);
      }

      if (this.$store.state.editor.player.captionPreview.videoLock) {
        var currentTime = this.$store.state.editor.captions[this.Id].incodeSec || 0;
        this.$store.commit("cueToTimeSec", currentTime);
      }

    },
    insertSpeaker: function (speaker) {
      this.$store.commit("insertSubtitleSpeakerId", {
        id: this.Id,
        speaker: speaker
      });
    },
    updateMenu: function (navOption) {
      this.$store.commit('updateOptionsNav', navOption)
    },
    splitEvent: function (event) {
      var index = this.$refs.subtitleArea.selectionStart;
      this.$store.commit("splitSubtitleEvent", {
        id: this.Id,
        index: index
      });
    },

    mergeEvent: function () {
      if (this.Id != 0) {
        this.$store.commit('mergeSubtitleEvent', this.Id);
      }
    },
    expandEvent: function () {
      this.$store.commit('expandSubtitleEvent', this.Id);
    },
    compressEvent: function () {
      this.$store.commit('compressSubtitleEvent', this.Id);
    },
    shiftLine: function (direction) {
      if ((this.Id != 0 && direction == 'up') || (this.Id != this.$store.state.editor.captions.length - 1 && direction == 'down')) {
        this.$store.commit('shiftSubtitleLine', {
          id: this.Id,
          direction: direction
        });

        if (this.$store.state.editor.captions[this.Id].subtitleText.trim() === "") {
          this.$store.commit("deleteCaptionEvent", this.Id);
        }
      }
    },
    shiftWord: function (direction) {
      if ((this.Id != 0 && direction == 'up') || (this.Id != this.$store.state.editor.captions.length - 1 && direction == 'down')) {
        this.$store.commit('shiftSubtitleWord', {
          id: this.Id,
          direction: direction
        });

        if (this.$store.state.editor.captions[this.Id].subtitleText.trim() === "") {
          this.$store.commit("deleteCaptionEvent", this.Id);
        }
      }
    },
    duplicate: function () {
      this.$store.commit('duplicateCaptionEvent', this.Id);
      this.$store.commit('selectCaptionEvent', this.Id + 1);
    }
  }
}
</script>

<style scoped>
textarea {
  background-color: #111;
  color: #fff;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  white-space: normal;
  text-align: justify;
  -moz-text-align-last: center;
  /* Firefox 12+ */
  text-align-last: center;
  font-size: 20px;
}
</style>
