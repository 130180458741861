<template>
	<ModalTemplate Title="Update Project Framerate" ModalId="ProjectFramerateModal">
		<form>
			<div class="form-group col-md-12">
				<label for="Offset Type Selection">Current Framerate: {{this.$store.state.editor.projectSettings.framerate}}</label>
			</div>
			<div class="form-group col-md-12">
				<label for="framerate-selection">New Framerate</label>
				<select class="form-control" v-model.lazy="framerate">
					<option>60</option>
					<option>59.94</option>
					<option>50</option>
					<option>30</option>
					<option>29.97</option>
					<option>25</option>
					<option>24</option>
					<option>23.98</option>
				</select>
			</div>
			<div class="col-md-12">	
				<button @click="updateFramerate" type="button" class="btn btn-primary btn-block" v-bind:class="{'disabled' : framerate == this.$store.state.editor.projectSettings.framerate}" data-dismiss="modal"><i class="far fa-check-circle"></i> Update</button>
			</div>
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	export default {
		name : 'ProjectFramerateModal',
		data () {
			return {
				framerate : "29.97"
			}
		},
		components: {
			ModalTemplate
		},
		methods : {
			updateFramerate : function(){
				this.$store.commit('updateProjectFramerate', this.framerate);	
			}
		}
	}
</script>