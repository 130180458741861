<template>
	<ModalTemplate Title="Timecode Offset" ModalId="TimecodeOffsetModal">
		<form>
			<div class="form-group col-md-12">
				<label for="File Incode">Offset Timecode</label>
				<input type="text" class="form-control" v-model.lazy="defineOffset" style="max-width:30%">
				<small class="from-text text-muted">SMPTE TC Required (hh:mm:ss:ff). e.g. 00:00:00:00
				</small>
			</div>
			<div class="form-group col-md-12">
				<label for="Offset Type Selection">Offset Type</label>
				<select class="form-control" v-model="offsetType" style="max-width:30%">
					<option>Add</option>
					<option>Subtract</option>
				</select>
			</div>	
			<div class="form-group col-md-12">
				<label for="exampleInputEmail1">Offset Start:</label>
				<input v-model="offsetLine" type="number" class="form-control" number min="1" style="max-width:30%">
				<small class="form-text text-muted">Offset will be applied to all caption lines starting at this line (Default: 1 = start)</small>
			</div>
			<div class="col-md-12">	
				<button @click="applyOffset" type="button" class="btn btn-primary btn-block" data-dismiss="modal"><i class="far fa-check-circle"></i> Apply Offset</button>
			</div>
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name : 'TimecodeOffsetModal',
		data () {
			return {
				offsetLine : 1,
				offsetTc : "00:00:00:00",
				offsetType : "Add"
			}
		},
		components: {
			ModalTemplate
		},
		methods : {
			applyOffset : function(){
        		this.$store.commit('applyOffset', {
					offset : this.offsetTc,
					offsetLine : this.offsetLine,
					offsetType : this.offsetType
				});			
	      	}
		},
		computed : {
			defineOffset: {
				get() {
					return this.offsetTc
				},
				set(tc) {
					this.offsetTc = globalFunc.tcValidate(tc, this.$store.state.editor.projectSettings.frameRate);
				}
			}

		}
	}
</script>