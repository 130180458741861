<template>
	<ul class="navbar-nav centre-nav mx-auto d-none d-md-flex" id="transportControls">
		<div class="dropdown">
			<button class="btn dropdown-toggle text-light" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i v-if="volume > 0.7" class="fas fa-volume-up"></i>
          <i v-else-if="volume===0" class="fas fa-volume-mute"></i>
          <i v-else-if="volume<0.3" class="fas fa-volume-off"></i>
          <i v-else class="fas fa-volume-down"></i>
			</button>
			<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<input type="range" class="form-control-range"  min="0" max="1" step="0.025" @change="updateVolume">
			</div>
		</div>
		<li class="nav-item">
			<button type="button" class="nav-link btn btn-link" href="#" title="Playback Speed" @click="increasePlayback">
				x{{this.$store.state.editor.playbackRate}}
			</button>
		</li>
		
		<li class="nav-item">
			<button type="button" :disabled="view==='subtitle' || view==='transcript' || view==='audio description'" class="nav-link btn btn-link" href="#" title="Align Left" @click="align('left')">
				<i class="fas fa-align-left"></i>
			</button>
		</li>
		<li class="nav-item">
			<button type="button" :disabled="view==='subtitle' || view==='transcript' || view==='audio description'" class="nav-link btn btn-link" href="#" title="Align Center" @click="align('center')">
				<i class="fas fa-align-center"></i>
			</button>
		</li>

		<li class="nav-item">
			<button type="button" :disabled="view==='subtitle' || view==='transcript' || view==='audio description'" class="nav-link btn btn-link" href="#" title="Align Right" @click="align('right')">
				<i class="fas fa-align-right"></i>
			</button>
		</li>
		<li class="nav-item">
			<button type="button" :disabled="view==='transcript' || view==='audio description'" class="nav-link btn btn-link" href="#" title="Auto Format" @click="autoFormat">
				<i class="fas fa-align-justify"></i>
			</button>
		</li>
		<li class="nav-item">
			<button :disabled="view==='transcript' || view==='audio description'" type="button" class="nav-link btn btn-link" href="#" title="Previous Caption Line" v-on:click='prevCaptionEvent'>
				<i class="fa fa-chevron-left" aria-hidden="true"></i>
			</button>
		</li>
		<li class="nav-item">
			<button :disabled="view==='transcript' || view==='audio description'" type="button" class="nav-link btn btn-link" href="#" title="Next Caption Line" v-on:click='nextCaptionEvent'>
				<i class="fa fa-chevron-right" aria-hidden="true"></i>
			</button>
		</li>
		<li class="nav-item">
			<button type="button" class="nav-link btn btn-link" href="#" title="Start" v-on:click='goToStart'>
				<i class="fa fa-step-backward" aria-hidden="true"></i>
			</button>
		</li>
		<li class="nav-item">
			<button type="button" class="nav-link btn btn-link" href="#" title="Step Backwards" v-on:click='rewind'>
				<i class="fas fa-backward"></i>
			</button>
		</li>
		<li class="nav-item" v-if="playing">
			<button type="button" class="nav-link btn btn-link" href="#" title="Pause" v-on:click='togglePlayback'>
				<i class="fa fa-pause" aria-hidden="true"></i>
			</button>
		</li>
		<li class="nav-item" v-else>
			<button type="button" class="nav-link btn btn-link" href="#" title="Play" v-on:click='togglePlayback'>
				<i class="fa fa-play" aria-hidden="true"></i>
			</button>
		</li>
		<li class="nav-item">
			<button type="button" class="nav-link btn btn-link" href="#" title="Step Forward" v-on:click='fastForward'>
				<i class="fa fa-forward" aria-hidden="true"></i>
			</button>
		</li>
		<li class="nav-item">
			<button type="button" class="nav-link btn btn-link" href="#" title="End" v-on:click='goToEnd'>
				<i class="fa fa-step-forward" aria-hidden="true"></i>
			</button>
		</li>
		<li class="nav-item ">
			<span class="nav-link color-white">
				<strong id="currentTimeRead">{{getCurrentTime}}</strong> / <strong>{{getDuration}}</strong>
			</span>
		</li>
		<SpeechInput v-if="appEnv === 'web'"/>
		<!-- <li class="nav-item" v-if="appEnv === 'desktop' && ['classic', 'default', 'subtitle'].indexOf(view) > -1">
			<button type="button" class="nav-link btn btn-link" href="#" title="Spellcheck" @click="updateSubViewerOption('spellcheck')" v-bind:class="{'green':subViewerOption === 'spellcheck'}">
				<i class="fas fa-spell-check"></i>
			</button>
		</li> -->
		<li class="nav-item">
			<button type="button" class="nav-link btn btn-link" href="#" title="Search and Replace" @click="updateSubViewerOption('search')" v-bind:class="{'green':subViewerOption === 'search'}">
				<i class="fas fa-search"></i>
			</button>
		</li>
		<li class="nav-item">
			<button type="button" class="nav-link btn btn-link" href="#" title="View Timeline" @click="toggleTimeline" v-bind:class="{'green':this.$store.state.editor.viewTimeline}">
				<i class="far fa-map"></i>
			</button>
		</li>
		<li class="nav-item" v-if="appVersion === 'pro' && appEnv === 'desktop'">
			<a href="#" data-toggle="modal" data-target="#ImportTranscriptModal" class="nav-link">
				<i class="fas fa-robot"></i>
			</a>
		</li>		
	</ul>
</template>

<script>
	import _Timecode from 'timecode'
	import _NodeTc from 'node-timecodes'
	import SpeechInput from "./SpeechInput"
	export default {
		name: 'Transport',
		data() {
			return {
				speechRecognitionSupport: false
			}
		},
		mounted : function(){
			window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
			if ('SpeechRecognition' in window) {
				this.speechRecognitionSupport = true;
			}
		},
		components : {
			SpeechInput
		},
		methods: {
			
			autoFormat  : function(){
				var captionEvent = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('autoFormat', captionEvent);
			},
			increasePlayback: function () {
				var playbackRateIndex = this.$store.state.editor.playbackRates.indexOf(this.$store.state.editor.playbackRate);
				var playback = this.$store.state.editor.playbackRates[playbackRateIndex + 1] || 1.25;
				this.$store.commit('updatePlaybackRate', playback);
			},			
			align : function(alignment) {
				this.$store.commit('clearEmptyLines', this.$store.state.editor.selectedCaptionEvent);
				this.$store.commit('alignCaptionEvent', {align: alignment, id : this.$store.state.editor.selectedCaptionEvent});
			},
			updateSubViewerOption : function (option) {
				this.$store.commit("updateSubViewerOption", option);
			},
			toggleTimeline : function() {
				this.$store.commit('viewTimeline');
			},
			togglePlayback: function () {
				mediaPlayer.togglePlay();
			},
			prevCaptionEvent: function () {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent - 1;
				if (captionEvent > -1) {
					this.$store.commit('selectCaptionEvent', captionEvent);
					this.$store.commit('focusCaptionEvent', captionEvent);

					this.$store.commit('cueToCaptionEvent', captionEvent);
				}
			},
			nextCaptionEvent: function () {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent + 1;
				if (captionEvent < this.$store.state.editor.captions.length) {
					this.$store.commit('selectCaptionEvent', captionEvent);
					this.$store.commit('focusCaptionEvent', captionEvent);
					this.$store.commit('cueToCaptionEvent', captionEvent);
				}
			},
			goToStart: function () {
				mediaPlayer.stop();
				mediaPlayer.currentTime = 0;
				this.$store.commit('selectCaptionEvent', 0);

			},
			goToEnd: function () {
				var lastEvent = this.$store.state.editor.captions.length - 1;
				this.$store.commit('selectCaptionEvent', lastEvent);
				this.$store.commit('focusCaptionEvent', lastEvent);
				this.$store.commit('cueToCaptionEvent', lastEvent);
			},
			fastForward: function () {
				mediaPlayer.forward(1);
			},
			rewind: function () {
				mediaPlayer.rewind(1);
			},
			updateVolume : function(e){
				mediaPlayer.volume = e.srcElement.value;
			}
		},
		computed: {
			appEnv : function(){
				return process.env.VUE_APP_ENV
			},
			appVersion : function(){
				return this.$store.state.editor.applicationVersion;
			},
			view : function(){
				return this.$store.state.editor.view;
			},
			framerate: function () {
				return this.$store.state.editor.projectSettings.framerate || '29.97';
			},
			getCurrentTime: function () {
				return this.$store.state.editor.currentTime || '00:00:00:00';
			},
			getDuration: function () {
				var duration = this.$store.state.editor.duration || 10;
				var durationTC = _Timecode.Timecode.init({
					framerate: this.$store.state.editor.projectSettings.framerate,
					timecode: _NodeTc.fromSeconds(duration, {
						frameRate: this.$store.state.editor.projectSettings.framerate
					})
				});
				var incode = this.$store.state.editor.projectSettings.incode || "00:00:00:00";
				durationTC.add(incode);
				return durationTC.toString();
			},
			playing: function () {
				if (this.$store.state.editor.player.status === 'playing') {
					return true;
				} else {
					return false;
				}
			},
			subViewerOption: function () {
				return this.$store.state.editor.subViewerOption;
			},

			volume : function(){
				try {
					return mediaPlayer.volume;
				} catch(e){
					console.log("Waiting for media player to load...")
				}
				
 			}
		}
	}
</script>