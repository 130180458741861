<template>
	<LrgModalTemplate Title="Welcome" ModalId="WelcomeModal">
		<div class="row text-center">
			<div class="col-12">

			<h3>Welcome to Closed Caption Creator</h3>
			<h5>Create a new project or load an existing project from store.</h5>
			</div>
			<div class="col p-5">
				<button type="button" class="btn-secondary p-5 btn w-100" @click="newProject"><h3><i class="far fa-file-alt welcomeIcon mb-3 w-100"></i> New Project</h3></button>
			</div>
			<div class="col p-5">
				<button type="button" class="btn-secondary btn w-100 h-100" @click="loadProject"><h3><i class="far fa-folder-open welcomeIcon mb-3 w-100"></i>Recent Projects</h3></button>
			</div>
		</div>
		
	</LrgModalTemplate>
</template>
<script>
	import LrgModalTemplate from './LrgModalTemplate'
	export default {
		name : 'Welcome',
		components: {
			LrgModalTemplate
		},
		methods : {
			newProject : function(){
				$('#WelcomeModal').modal('toggle');
				$('#NewProjectModal').modal('toggle');
			},
			loadProject : function(){
				$('#WelcomeModal').modal('toggle');
				$('#LoadProjectModal').modal('toggle');
			}
		}
	}
</script>
<style>
	.welcomeIcon {
		font-size: 8rem;
	}
</style>