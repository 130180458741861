<template>
<LrgModalTemplate Title="Export Audio Description" ModalId="ExportAd">
    <form>
        <p>Please select a target directory where your audio will be exported along with the project transcript</p>
        <div class="form-group">
            <button type="button" class="btn btn-secondary" @click="selectDir"><i class="far fa-folder"></i> Select Folder</button>
            <span class="ml-2">{{trgFolder}}</span>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" v-model="audioExport">
            <label class="form-check-label" >
                Export Audio
            </label>
        </div>
        <hr />
        <button type="button" class="btn btn-primary mr-2" @click="exportAd" data-dismiss="modal"><i class="fas fa-check"></i> Export</button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i> Close</button>
    </form>
</LrgModalTemplate>
</template>

<script>
import LrgModalTemplate from './LrgModalTemplate'

export default {
    name: 'ExportAudioDescription',
    data: function () {
        return {
            trgFolder: "",
            audioExport : true
        }
    },
    components: {
        LrgModalTemplate
    },

    methods: {
        exportAd: function () {
            this.$store.commit('exportAd', {
                targetFolder : this.trgFolder,
                audioExport: this.audioExport 
            });
        },
        selectDir: function () {
            const remote = require('electron').remote;
            var dialog = remote.require('electron').dialog;
            dialog.showOpenDialog({
                properties: ['openDirectory']
            }).then(path => {
                if (!path.canceled) {
                    this.trgFolder = path.filePaths[0];
                }
            });
        },
    }
}
</script>
