<template>
<ModalTemplate Title="About CC Creator" ModalId="About">
    <div>

        <p class="lead text-center">
            Closed Caption Creator V{{this.$store.state.editor.version}}
        </p>
        <div class="row">
			<div class="col-4">
				<img src="@/assets/img/CCIcon.png" class="img-fluid" />
            </div>
            <div class="col">
                <p>
                    <strong>Contact:</strong><br />
                    www.closedcaptioncreator.com<br />
                    support@closedcaptioncreator.com
                </p>
                <p>
                    <strong>License:</strong>
                   {{this.$store.state.editor.applicationVersion.toUpperCase()}}
                </p>
            </div> 
        </div>

        <hr />
        <p class="text-center small">Copyright 2020 Closed Caption Creator Inc. All rights reserved.</p>
    </div>
</ModalTemplate>
</template>

<script>
import ModalTemplate from './ModalTemplate'
export default {
    name: 'About',
    components: {
        ModalTemplate
    }
}
</script>
