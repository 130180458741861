const eol = require("eol");
const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
module.exports = {
    preProcess : {
        encode : function(events){
            /* All */
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode : function(fileContents){
            /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();
            return fileContents;
        }
    },

    decode : function(fileContents, options){
        let events = [];
        let subtitleBlocks = fileContents.split("\n");
        subtitleBlocks.forEach(block => {
            var blockInfo = block.split("\t");
            if ((/\d\d:\d\d:\d\d:\d\d/).test(blockInfo[0])){
                let event = new ccEvent();;
                event.updateStart(timecodeLib.tcToSec(blockInfo[0], options.frameRate, options.dropFrame));
                event.updateEnd(timecodeLib.tcToSec(blockInfo[1], options.frameRate, options.dropFrame));
                event.text = blockInfo[2];
                events.push(event);
            } else {
                events[events.length-1].text += "\n"+block.trim();
            }
        });

        return events;
    },

    encode : function(events, options){
        let fileContents = "";
        events.forEach(event=>{
            fileContents += timecodeLib.secToTc(event.start, options.frameRate);
            fileContents += "\t";
            fileContents += timecodeLib.secToTc(event.end, options.frameRate);
            fileContents += "\t";
            fileContents += event.text + "\n";
        });
        return fileContents.trim();
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events){
            events.forEach(event=>{
                event.syncCaptionText();
                event.alignTextCenter();
            });
            return events;
        }
    },

}