<template>
	<ModalTemplate Title="Feature Request" ModalId="FeatureRequest">
		<p>
			Please submit detailed feature requests you would like to see added to Closed Caption Creator. Please email features@closedcaptioncreator.com
		</p>	 
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	export default {
		name : 'FeatureRequest',
		data () {
			return {
				msg: 'FeatureRequest Mounted'
			}
		},
		components: {
			ModalTemplate
		}
	}
</script>