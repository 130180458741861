<template>
<div :Id="'captionEvent'+index" class="form-row p-3 adEvent" :class="{activeEvent : index === selectedEvent}">
    <div class="col-1">
        <span class="text-muted">{{index + 1}}</span>
        
        <button v-if="playerStatus==='stopped'" type="button" class="btn btn-block btn-primary btn-sm mt-2 mb-2" :class="{disabled : adText.length===0}" :disabled="adText.length===0" title="Audio Preview" @click="previewTextToSpeech"><i class="fas fa-play"></i> Prev</button>
        <button v-else type="button" class="btn btn-block btn-primary btn-sm  mt-2 mb-2" :class="{disabled : adText.length===0}" title="Audio Preview" @click="previewTextToSpeech">
            <i class="fas fa-spinner fa-spin"></i>
        </button>
        <p class="small"><i class="fas fa-stopwatch" title="Duration"></i> {{duration}}</p>
    </div>
    <div class="col-3">
        <div class="input-group mb-2">
            <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary" v-on:click='setIncode($event)'><i class="far fa-clock"></i></button>
            </div>
            <input type="text" class="form-control timecodeInput" v-on:keyup.up.exact="updateTcByFrame({add : true, subtract : false})" v-on:keyup.down.exact="updateTcByFrame({add : false, subtract : true})" placeholder="Incode" v-model.lazy="incode" @focus="cueToIncode">
        </div>
        <div class="input-group">
            <input type="range" class="custom-range" min="0.1" max="2" step="0.01" v-model="speed" :title="'Speed ('+speed+')'">
            <p class="small">{{speed}}x</p>
        </div>
    </div>
    <div class="col-7">
        <textarea ref="adTextarea" :index="'captionEventInput'+index"  class="form-control" placeholder="Audio Description Text..." rows="3" v-model="adText" @click="selectEvent"></textarea>
    </div>
    <div class="form-group col-1">
        <button type="button" class="btn btn-danger btn-sm float-right" v-on:click="removeAdEvent" title="Delete Event">
            <i class="fa fa-times" aria-hindexden="true"></i>
        </button>
    </div>
    <div class="form-group col-1">
        <button type="button" class="btn btn-success btn-block btn-sm newEventButton" v-if="index === selectedEvent" v-on:click="newAdEvent" title="New Event">
            <i class="fa fa-plus" aria-hindexden="true"></i> New
        </button>
    </div>
</div>
</template>

<script>
import firebase from '@/firebase/config'
import globalFunc from '../../assets/js/functions/globalFunc'
import globalSetting from '../../assets/js/functions/globalSettings';
import smpteTc from 'smpte-timecode'; 
export default {
    name: 'AudioDescriptionEvent',
    props: {
      index: { // index of current item
        type: Number
      },
      source: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    data: function () {
        return {
            playerStatus: "stopped", //stopped,loading,
        }
    },
    mounted : function(){
        if (this.index === this.selectedEvent){
            this.$refs.adTextarea.focus();
        }        
    },
    computed: {
        selectedEvent: function () {
            return this.$store.state.editor.selectedCaptionEvent;
        },
        duration: function () {
            if (this.$store.state.editor.audioDescription[this.index].duration){
                return this.$store.state.editor.audioDescription[this.index].duration.toFixed(2) + "S"
            } else {
                return "NA";
            }
        },
        incode: {
            get() {
                return this.$store.state.editor.audioDescription[this.index].incode;
            },
            set(incode) {
                incode = globalFunc.tcValidate(incode, this.$store.state.editor.projectSettings.framerate);
                this.$store.commit('updateIncode', {
                    id: this.index,
                    timecode: incode,
                    target: 'audioDescription'
                });
            }
        },
        speed: {
            get() {
                return this.$store.state.editor.audioDescription[this.index].speed;
            },
            set(speed) {
                this.$store.commit('updateAdEventSpeed', {
                    id: this.index,
                    speed: speed
                });
            }
        },
        adText: {
            get() {
                return this.$store.state.editor.audioDescription[this.index].text;
            },
            set(text) {
                this.$store.commit('updateAdEventText', {
                    id: this.index,
                    text: text
                });
                this.textUpdate = true;
            }
        }
    },
    methods: {
        updateTcByFrame : function(tcOptions){
            try {  
                var framerate = parseFloat(this.$store.state.editor.projectSettings.framerate) || 29.97;
                var dropFrame = globalSetting.tcFrameMap[framerate] || true;

                var tc = smpteTc(this.incode, framerate, dropFrame);
                if (tcOptions.add){
                    tc.add(1);
                } else {
                    tc.subtract(1);
                }

                this.$store.commit("updateIncode", {
                    id: this.index,
                    timecode: tc.toString(),
                    target: "audioDescription"
                });
            
                this.$store.commit("cueToTimeSec", this.$store.state.editor.audioDescription[this.index].incodeSec);

                
            } catch(e){
                console.log(e.message);
            }            
        },
        selectEvent: function () {
            if (this.index != this.$store.state.editor.selectedCaptionEvent){
                this.$store.commit('selectCaptionEvent', this.index);
            }
            
            var currentTime = this.$store.state.editor.audioDescription[this.index].incodeSec || 0;
            this.$store.commit('cueToTimeSec', currentTime);
        },
        setIncode: function (event) {
            this.$store.commit('setIncode', this.index);
        },
        newAdEvent: function () {
            this.$store.commit('insertAdEvent', this.index + 1);
            this.$store.commit('setIncode', this.index+1);
        },
        removeAdEvent: function () {
            this.$store.commit('removeAdEvent', this.index);                  
        },
        cueToIncode: function() {
            try {
                var currentTime = this.$store.state.editor.audioDescription[this.index].incodeSec || 0;
                this.$store.commit('cueToTimeSec', currentTime);

            } catch (e) {
                console.log('Warning: Failed to update current time. ' + e.message);
            }
        },
        previewTextToSpeech: function () {
            var audioPlayer = document.getElementById("adPreviewPlayer");
            if (audioPlayer.paused){
                var uid = this.$store.state.editor.projectSettings.uid;
                var eventId = this.index;
                var text = this.adText;
                var voice = this.$store.state.editor.projectSettings.voice;
                var language = voice.substr(0, 5);
                
                var speed = this.speed;
                this.playerStatus = 'loading';
                firebase.functions().httpsCallable('convertTextToSpeech')({
                    assetId: uid,
                    eventId: eventId,
                    text: text,
                    language: language,
                    voice: voice,
                    speed: speed
                }).then(res => {                    
                    audioPlayer.src = "data:audio/wav;base64," + res.data.audio;
                    audioPlayer.load();
                    this.$store.commit('cueToTimeSec', this.$store.state.editor.audioDescription[this.index].incodeSec);
                    mediaPlayer.play();
                    audioPlayer.play();                    
                    this.playerStatus = 'stopped';
                    
                    var self = this;
                    setTimeout(function(){
                        self.$store.commit('updateAdEventDuration', {
                            id: self.index,
                            duration: audioPlayer.duration
                        });
                        setTimeout(function(){
                            mediaPlayer.pause();
                        }, audioPlayer.duration*1000);
                    }, 500);
                });            
            } else {
                audioPlayer.pause();
                mediaPlayer.pause();                
            }          
        }
    },
}
</script>
<style scoped>
.adEvent {
    height: 130px;
    box-sizing: border-box;
}
.newEventButton {
    position: relative;
    bottom: 10px;
}
</style>