import { render, staticRenderFns } from "./AudioDescriptionEvent.vue?vue&type=template&id=f4755ec6&scoped=true&"
import script from "./AudioDescriptionEvent.vue?vue&type=script&lang=js&"
export * from "./AudioDescriptionEvent.vue?vue&type=script&lang=js&"
import style0 from "./AudioDescriptionEvent.vue?vue&type=style&index=0&id=f4755ec6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4755ec6",
  null
  
)

export default component.exports