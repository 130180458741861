<template>
<div id="transcriptEditorWrapper" class="rounded p-2">
    <quillEditor ref="transcriptEditor" v-model="transcriptText" :options="editorOption" @focus="onEditorFocus($event)"/>
    <hr />
    <!-- Button Group -->
    <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
        <button type="button" class="btn btn-outline-light" title="Insert Timecode" @click="insertTimecode"><i class="fas fa-stopwatch"></i></button>
        <div class="btn-group" role="group">
            <button id="speakerTranscriptList" type="button" class="btn btn-outline-light dropdown-toggle" data-toggle="dropdown" title="Speaker Identification">
                <i class="far fa-user"></i>
            </button>
            <div class="dropdown-menu">
                <button class="dropdown-item btn-link" v-for="(speaker,index) in speakers" :key="index" @click="insertSpeaker(speaker)">{{speaker}}</button>
                <div class="dropdown-divider border-top" v-if="speakers.length > 0"></div>
                <button class="dropdown-item btn-link" data-toggle="modal" data-target="#OptionsModal" v-on:click="updateMenu('Speakers')"><i class="fas fa-plus"></i> Add speaker...</button>
            </div>
        </div>
        <div class="btn-group" role="group">
            <button type="button" class="btn btn-outline-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="far fa-save"></i> Export
            </button>
            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <button class="dropdown-item btn-link" @click="saveAs('html')">HTML</button>
                <button class="dropdown-item btn-link" @click="saveAs('txt')">TXT</button>
            </div>
        </div>
        <button type="button" class="btn btn-light" @click="convertToCaptions"><i class="fas fa-retweet"></i> Import as Captions</button>
    </div>
</div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import vueQuill, { quillEditor } from 'vue-quill-editor'
var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;

export default {
    name: "TranscriptEditor",
    data: function () {
        return {
            editorOption: {
                placeholder: '',
                modules: {
                    toolbar: [
                        [{
                            'size': ['small', false, 'large', 'huge']
                        }],

                        ['bold', 'italic', 'underline'],

                        [{
                            'align': []
                        }]
                    ],
                    history: {
                        delay: 1000,
                        maxStack: 50,
                        userOnly: false
                    },
                }
            }
        }
    },
    computed: {
        speakers: function () {
            return this.$store.state.editor.speakers;
        },
        transcriptText: {
            get: function () {
                return this.$store.state.editor.transcription.text;
            },
            set: function (text) {
                this.$store.commit('updateTranscriptionText', text);
            }
        },
        transcriptEditor() {
            window.quillEditor = this.$refs.transcriptEditor.quill;
            return this.$refs.transcriptEditor.quill;
        }
    },
    methods: {
        onEditorFocus : function(event){
            var range = this.transcriptEditor.getSelection();
            if (range) {
                var selection = this.transcriptEditor.getLeaf(range.index);
                if(selection[0].text && selection[0].text.match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g)){
                    this.$store.commit('cueToTimeTc', selection[0].text);
                }
            }
        }, 
        insertSpeaker: function (speaker) {
            var range = this.transcriptEditor.getSelection();
            if (range && range.length == 0) {
                this.transcriptEditor.insertText(
                    range.index,
                    speaker + ":\n", {
                        'color': '#00ffcc',
                        'italic': true
                    }
                );
                this.transcriptEditor.focus();
            }

        },
        updateMenu: function (navOption) {
            this.$store.commit('updateOptionsNav', navOption)
        },
        saveAs: function (target) {
            var contents = this.transcriptEditor.getContents();
            if (target==='html'){
                var cfg = {};
                var converter = new QuillDeltaToHtmlConverter(contents.ops, cfg);
                var htmlContents = converter.convert();
                this.$store.commit('saveDocument', {text: htmlContents, ext:target});
            } else {   
                var outputText = "";
                contents.forEach(textLine =>{
                    outputText+= textLine.insert;
                });

                this.$store.commit('saveDocument', {text: outputText, ext:target});
            }

        },
        insertTimecode: function () {
            var range = this.transcriptEditor.getSelection();
            if (range && range.length == 0) {
                var tc = this.$store.state.editor.currentTime || "00:00:00:00";
                this.transcriptEditor.insertText(range.index, "\n\n"+tc+"\n", 'link', '#'+tc, '');
                this.transcriptEditor.focus();
            }
        },
        convertToCaptions : function(){
            var contents = this.transcriptEditor.getContents();
            this.$store.commit("updateView", 'default');
            this.$store.commit('convertTranscriptToCaptions', contents);
        }
    },
    components: {
        quillEditor
    }
}
</script>

<style>
#transcriptEditorWrapper {
    background: rgb(48, 47, 47);
    color: rgb(238, 238, 238);
}


.ql-toolbar {
    background: none !important;
    border: none !important;
}  

.ql-container.ql-snow {
    border: none;
}

.ql-editor {
    height: 66vh;
    overflow-y: scroll;

}
</style>
