<template>
<XLrgModalTemplate Title="Import Plain Text File" ModalId="ImportPlainTextModal">
  <div class="row">
    <div class="col-7">
      <h6>Preview</h6>
      <div class="form-group">
        <textarea class="form-control" rows="20" disabled v-model="previewText"></textarea>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>File Encoding</label>
        <select class="custom-select" v-model="fileEncoding">
          <option>UTF-8</option>
          <option>IBM866</option>
          <option>ISO-8859-2</option>
          <option>CP1251</option>
          <option>windows-874</option>
          <option>windows-1250</option>
          <option>windows-1251</option>
          <option>windows-1252</option>
          <option>windows-1254</option>
          <option>GBK</option>
          <option>EUC-KR</option>
          <option>UTF-16BE</option>
          <option>UTF-16LE</option>
        </select>
      </div>
      <div id="plainTextFileInputWrapper" class="form-group">
        <label for="Plain Text File Input">Please select a file</label>
        <input type="file" class="form-control-file" id="plainTextFileInput" accept=".docx,.txt">
      </div>
      <h6>Import Options</h6>
      <form>
        <div class="custom-control custom-switch mb-3">
          <input type="checkbox" class="custom-control-input" id="smartImportSwitch" v-model="smartInput">
          <label class="custom-control-label" for="smartImportSwitch">Smart Import</label>
        </div>
        <div class="form-group">
          <label>Maximum Characters Per Line</label>
          <select class="form-control" v-model="maxCharacter" :disabled="smartInput" :class="{'bg-light':smartInput}">
            <option>28</option>
            <option>32</option>
            <option>35</option>
            <option>42</option>
          </select>
        </div>
        <div class="form-group">
          <label>Maximum Lines Per Event</label>
          <select class="form-control" v-model="maxLines" :disabled="smartInput" :class="{'bg-light':smartInput}">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
          </select>
        </div>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="plainTextImportSwitch" v-model="endEventOnPunctuation" :disabled="smartInput" :class="{'bg-light':smartInput}">
          <label class="custom-control-label" for="plainTextImportSwitch">End event on punctuation
            (<strong> . ? ! </strong>)</label>
        </div>

        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="plainTextImportSwitch3" v-model="endLineOnPunctuation" :disabled="smartInput" :class="{'bg-light':smartInput}">
          <label class="custom-control-label" for="plainTextImportSwitch3">End line on punctuation
            (<strong> : , ; </strong>)</label>
        </div>

        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="plainTextImportSwitch2" v-model="endLineOnBreaks" :disabled="smartInput" :class="{'bg-light':smartInput}">
          <label class="custom-control-label" for="plainTextImportSwitch2">End event on line breaks</label>
        </div>
        <br />
        <label for="wordsPerMinute">Target Words-Per-Minute ({{wordsPerMinute}} WPM)</label>
        <input type="range" class="custom-range" min="30" max="250" step="1" id="wordsPerMinute" v-model="wordsPerMinute" :disabled="smartInput">
        <br /><br />
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="appendTranscriptOption" v-model="append">
          <label class="custom-control-label" for="appendTranscriptOption">Append <i class="fas fa-info-circle" title="Append transcript to end of project or replace entire project with new events"></i></label>
        </div>
        <br />
        <button type="button" class="btn btn-outline-light" style="margin-right:2%;" @click="importPlainTextFile('preview')"><i class="fas fa-sync-alt"></i> Preview</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="importPlainTextFile('project')"><i class="fas fa-file-import"></i> Import</button>
      </form>
    </div>
  </div>
</XLrgModalTemplate>
</template>

<script>
import XLrgModalTemplate from './XLrgModalTemplate'
import eol from 'eol'

export default {
  name: 'ImportPlainTextModal',
  data() {
    return {
      append: false,
      maxCharacter: 32,
      maxLines: 2,
      wordsPerMinute: 100,
      endEventOnPunctuation: true,
      endLineOnPunctuation: true,
      endLineOnBreaks: true,
      fileEncoding: "UTF-8",
      previewText: "Select a file and click the PREVIEW button",
      smartInput: false
    }
  },
  components: {
    XLrgModalTemplate
  },
  methods: {
    importPlainTextFile: function (target) {
      if (document.getElementById('plainTextFileInput').files[0] != undefined) {

        var self = this;
        var plainTextData = "";
        var captionBuffer = [];
        var captionEvent = {
          startTime: "",
          endTime: "",
          text: "",
          breaks: 0,
        };

        var srcFile = document.getElementById('plainTextFileInput').files[0];
        var srcFileExt = srcFile.name.split(".").pop();
        //console.log(srcFile);
        var reader = new FileReader();
        reader.onload = function (e) {
          if (srcFileExt === "docx") {
            let mammoth = require("mammoth");
            plainTextData = mammoth.extractRawText({
              arrayBuffer: reader.result
            }).then(function (result) {
              plainTextData = result.value;
              
              plainTextData = eol.lf(plainTextData.trim());
              if (self.endLineOnBreaks) {
                var fileLines = plainTextData.split("\n");
                for (var i = 0; i < fileLines.length; i++) {
                  var wordsInLine = fileLines[i].trim().split(" ");
                  for (var j = 0; j < wordsInLine.length; j++) {
                    if (captionEvent.text.length > 0 && captionEvent.text.trim().split("<br>")[captionEvent.text.trim().split("<br>").length - 1].length + wordsInLine[j].length > self.maxCharacter) {
                      if (captionEvent.breaks === self.maxLines - 1) {
                        captionBuffer.push(captionEvent);
                        captionEvent = {
                          startTime: "",
                          endTime: "",
                          text: wordsInLine[j] + " ",
                          breaks: 0
                        };
                      } else {
                        captionEvent.breaks++;
                        captionEvent.text += "<br>" + wordsInLine[j] + " ";
                      }
                    } else {
                      captionEvent.text += wordsInLine[j] + " ";
                    }

                    if (self.endEventOnPunctuation && captionEvent.text.trim() && captionEvent.text.trim()[captionEvent.text.trim().length - 1].search(/\?|\!|\./g) > -1) {
                      captionBuffer.push(captionEvent);
                      captionEvent = {
                        startTime: "",
                        endTime: "",
                        text: "",
                        breaks: 0,
                      };
                    } else if (self.endLineOnPunctuation && captionEvent.text.trim() && captionEvent.text.trim()[captionEvent.text.trim().length - 1].search(/\:|\;|\,/g) > -1) {
                      if (captionEvent.breaks === self.maxLines - 1) {
                        captionBuffer.push(captionEvent);
                        captionEvent = {
                          startTime: "",
                          endTime: "",
                          text: "",
                          breaks: 0
                        };
                      } else {
                        captionEvent.breaks++;
                        captionEvent.text += "<br>";
                      }
                    }
                  }

                  if (captionEvent.text.trim() != "") {
                    captionBuffer.push(captionEvent);
                    captionEvent = {
                      startTime: "",
                      endTime: "",
                      text: "",
                      breaks: 0,
                    };
                  }

                }
              } else {
                var wordsInLine = plainTextData.trim().replace(/\r?\n|\r/g, " ").split(" ");
                for (var j = 0; j < wordsInLine.length; j++) {
                  if (captionEvent.text.length > 0 && captionEvent.text.trim().split("<br>")[captionEvent.text.trim().split("<br>").length - 1].length + wordsInLine[j].length > self.maxCharacter) {
                    if (captionEvent.breaks === self.maxLines - 1) {
                      captionBuffer.push(captionEvent);
                      captionEvent = {
                        startTime: "",
                        endTime: "",
                        text: wordsInLine[j] + " ",
                        breaks: 0
                      };
                    } else {
                      captionEvent.breaks++;
                      captionEvent.text += "<br>" + wordsInLine[j] + " ";
                    }
                  } else {
                    captionEvent.text += wordsInLine[j] + " ";
                  }

                  if (self.endEventOnPunctuation && captionEvent.text.trim() && captionEvent.text.trim()[captionEvent.text.trim().length - 1].search(/\?|\!|\./g) > -1) {
                    captionBuffer.push(captionEvent);
                    captionEvent = {
                      startTime: "",
                      endTime: "",
                      text: "",
                      breaks: 0,
                    };
                  } else if (self.endLineOnPunctuation && captionEvent.text.trim() && captionEvent.text.trim()[captionEvent.text.trim().length - 1].search(/\:|\;|\,/g) > -1) {
                    if (captionEvent.breaks === self.maxLines - 1) {
                      captionBuffer.push(captionEvent);
                      captionEvent = {
                        startTime: "",
                        endTime: "",
                        text: "",
                        breaks: 0
                      };
                    } else {
                      captionEvent.breaks++;
                      captionEvent.text += "<br>";
                    }
                  }
                }

                if (captionEvent.text.trim() != "") {
                  captionBuffer.push(captionEvent);
                  captionEvent = {
                    startTime: "",
                    endTime: "",
                    text: "",
                    breaks: 0,
                  };
                }
              }

              var preview = "";
              var currentTime = 0;
              captionBuffer.forEach(captionEvent => {
                if (captionEvent.text.trim() != "") {
                  var duration = captionEvent.text.replace(/<br>/g, " ").split(" ").length / (self.wordsPerMinute / 60);

                  captionEvent.startTime = currentTime + 0.1;
                  captionEvent.endTime = captionEvent.startTime + duration;
                  currentTime = captionEvent.startTime + duration;

                  preview += Math.round(captionEvent.startTime) + "s --> " + Math.round(captionEvent.endTime) + "s\n";
                  preview += captionEvent.text.replace(/<br>/gi, "\n");
                  preview += "\n\n";
                }
              });

              if (target === 'preview') {
                self.previewText = preview;
              } else {
                self.$store.commit('importTranscript', {
                  captions: captionBuffer,
                  append: self.append
                });
              }
            }).catch(err => {
              console.log(err.message);
              this.$notify({
                title: 'Import Failed',
                type: 'error',
                text: 'Error readding file. ' + err.message
              });
            });
          } else {
            plainTextData = eol.lf(reader.result.trim());
            if (self.endLineOnBreaks) {
              var fileLines = plainTextData.split("\n");
              for (var i = 0; i < fileLines.length; i++) {
                var wordsInLine = fileLines[i].trim().split(" ");
                for (var j = 0; j < wordsInLine.length; j++) {
                  if (captionEvent.text.length > 0 && captionEvent.text.trim().split("<br>")[captionEvent.text.trim().split("<br>").length - 1].length + wordsInLine[j].length > self.maxCharacter) {
                    if (captionEvent.breaks === self.maxLines - 1) {
                      captionBuffer.push(captionEvent);
                      captionEvent = {
                        startTime: "",
                        endTime: "",
                        text: wordsInLine[j] + " ",
                        breaks: 0
                      };
                    } else {
                      captionEvent.breaks++;
                      captionEvent.text += "<br>" + wordsInLine[j] + " ";
                    }
                  } else {
                    captionEvent.text += wordsInLine[j] + " ";
                  }

                  if (self.endEventOnPunctuation && captionEvent.text.trim() && captionEvent.text.trim()[captionEvent.text.trim().length - 1].search(/\?|\!|\./g) > -1) {
                    captionBuffer.push(captionEvent);
                    captionEvent = {
                      startTime: "",
                      endTime: "",
                      text: "",
                      breaks: 0,
                    };
                  } else if (self.endLineOnPunctuation && captionEvent.text.trim() && captionEvent.text.trim()[captionEvent.text.trim().length - 1].search(/\:|\;|\,/g) > -1) {
                    if (captionEvent.breaks === self.maxLines - 1) {
                      captionBuffer.push(captionEvent);
                      captionEvent = {
                        startTime: "",
                        endTime: "",
                        text: "",
                        breaks: 0
                      };
                    } else {
                      captionEvent.breaks++;
                      captionEvent.text += "<br>";
                    }
                  }
                }

                if (captionEvent.text.trim() != "") {
                  captionBuffer.push(captionEvent);
                  captionEvent = {
                    startTime: "",
                    endTime: "",
                    text: "",
                    breaks: 0,
                  };
                }

              }
            } else {
              var wordsInLine = plainTextData.trim().replace(/\r?\n|\r/g, " ").split(" ");
              for (var j = 0; j < wordsInLine.length; j++) {
                if (captionEvent.text.length > 0 && captionEvent.text.trim().split("<br>")[captionEvent.text.trim().split("<br>").length - 1].length + wordsInLine[j].length > self.maxCharacter) {
                  if (captionEvent.breaks === self.maxLines - 1) {
                    captionBuffer.push(captionEvent);
                    captionEvent = {
                      startTime: "",
                      endTime: "",
                      text: wordsInLine[j] + " ",
                      breaks: 0
                    };
                  } else {
                    captionEvent.breaks++;
                    captionEvent.text += "<br>" + wordsInLine[j] + " ";
                  }
                } else {
                  captionEvent.text += wordsInLine[j] + " ";
                }

                if (self.endEventOnPunctuation && captionEvent.text.trim() && captionEvent.text.trim()[captionEvent.text.trim().length - 1].search(/\?|\!|\./g) > -1) {
                  captionBuffer.push(captionEvent);
                  captionEvent = {
                    startTime: "",
                    endTime: "",
                    text: "",
                    breaks: 0,
                  };
                } else if (self.endLineOnPunctuation && captionEvent.text.trim() && captionEvent.text.trim()[captionEvent.text.trim().length - 1].search(/\:|\;|\,/g) > -1) {
                  if (captionEvent.breaks === self.maxLines - 1) {
                    captionBuffer.push(captionEvent);
                    captionEvent = {
                      startTime: "",
                      endTime: "",
                      text: "",
                      breaks: 0
                    };
                  } else {
                    captionEvent.breaks++;
                    captionEvent.text += "<br>";
                  }
                }
              }

              if (captionEvent.text.trim() != "") {
                captionBuffer.push(captionEvent);
                captionEvent = {
                  startTime: "",
                  endTime: "",
                  text: "",
                  breaks: 0,
                };
              }
            }

            var preview = "";
            var currentTime = 0;
            captionBuffer.forEach(captionEvent => {
              if (captionEvent.text.trim() != "") {
                var duration = captionEvent.text.replace(/<br>/g, " ").split(" ").length / (self.wordsPerMinute / 60);

                captionEvent.startTime = currentTime + 0.1;
                captionEvent.endTime = captionEvent.startTime + duration;
                currentTime = captionEvent.startTime + duration;

                preview += Math.round(captionEvent.startTime) + "s --> " + Math.round(captionEvent.endTime) + "s\n";
                preview += captionEvent.text.replace(/<br>/gi, "\n");
                preview += "\n\n";
              }
            });

            if (target === 'preview') {
              self.previewText = preview;
            } else {
              self.$store.commit('importTranscript', {
                captions: captionBuffer,
                append: self.append
              });
            }
          }

        }

        if (srcFileExt === "txt") {
          reader.readAsText(srcFile, self.fileEncoding);
        } else if (srcFileExt === "docx") {
          reader.readAsArrayBuffer(srcFile);
        } else {
          this.$notify({
            title: 'Import Failed',
            type: 'error',
            text: 'File format not supported'
          });
        }
      } else {
        this.$notify({
          title: 'Plain Text Import Failed',
          type: 'error',
          text: 'No file found'
        });
      }
    }
  }
}
</script>

<style scoped>
#plainTextFileInputWrapper {
  padding: 5%;
  background-color: rgba(238, 255, 0, 0.171);
}
</style>
