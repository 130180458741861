const timecodeLib = require("../timecode.js");
const ccEvent = require("../event.js");
const Papa = require('papaparse');
const striptags = require("striptags");

//Regex variables for the different timecode formats
const smpteTimecode = /^(\d{1,2}):(\d{2}):(\d{2}):(\d{2})\.?(\d+)?$/; //00:00:40:07 (7 frames) or 00:00:40:07.1 (7 frames, 1 subframe)
const msTimecode = /^(?:(\d{1,2}):)?(\d{2}):(\d{2}\.\d{2,})$/; //01:02:43.0345555 or 02:43.03
const secondsTimecode = /^(\d*(?:\.\d*)?)$/; //75 or 75.5
const timeHMSFormat_ = /^(\d{1,2}):(\d{2}):(\d{2})\.?(\d+)?$/;


module.exports = {
    decode: function (fileContents, options) {
        let events = [];

        //Get which column the user selected for caption data
        const startIndex = options.formatOptions[0].selected.charCodeAt(0) - 65;
        const endIndex = options.formatOptions[1].selected.charCodeAt(0) - 65;
        const dialogIndex = options.formatOptions[2].selected.charCodeAt(0) - 65;

        //Converts csv string data to json objects
        let parser = Papa.parse(fileContents, { skipEmptyLines: true });

        for (let i = 0; i < parser.data.length; i++) {
            const event = new ccEvent();;

            const startTime = parseTime_(parser.data[i][startIndex]);
            const endTime = parseTime_(parser.data[i][endIndex]);

            //start time and end time will return with timecodes if it contained them
            if (startTime !== "" && endTime !== "") {
                event.start = startTime;
                event.end = endTime;

                const subtitleLines = parser.data[i][dialogIndex].split("\n");

                subtitleLines.forEach(subtitleLine => {

                    event.insertLine({
                        text: striptags(subtitleLine),
                        posX: 0,
                        posY: 0,
                        bold: /<b>|<\/b>/.test(subtitleLine),
                        italics: /<i>|<\/i>/.test(subtitleLine),
                        underline: /<u>|<\/u>/.test(subtitleLine)
                    });

                });
                event.syncSubtitleText();
                event.alignTextCenter();
                events.push(event);
            }
        }
        return events;
    },

    encode: function (events, options) {
        let fileContents = "startSec,endSec,startSmpte,endSmpte,frameRate,text\n";

        //For testing
        let csvInfo = [];
        events.forEach(event => {
            //For testing
            let subtitle = {
                startSec: event.start,
                endSec: event.end,
                startSmpte: timecodeLib.secToTc(event.start, options.frameRate),
                endSmpte: timecodeLib.secToTc(event.end, options.frameRate),
                frameRate: options.frameRate,
                text: event.text
            }

            //For testing
            csvInfo.push(subtitle);

            fileContents += event.start + "," + event.end + "," + timecodeLib.secToTc(event.start, options.frameRate) + timecodeLib.secToTc(event.end, options.frameRate) + "," + options.frameRate + ',"' + event.text.replace(/"/gmi, '""') + '"' + "\n";
        })

        /* For testing: */
        /* stringify(csvInfo, function(err, output){
            if (err){
                throw new Error(err.message);
            }
            console.log(output);
        }); */

        return fileContents;
    },

    preProcess: {
        encode: function (events) {
            /* All */
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode: function (fileContents) {
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    },
}

const parseTime_ = function (line) {
    let ret = "";
 
    if (smpteTimecode.test(line)) {
        ret = timecodeLib.tcToSec(line, 29.97);
    }

    if (msTimecode.test(line)) {
        ret = timecodeLib.tcMsToSec(line);
    }

    if (secondsTimecode.test(line)) {
        return line;
    }

    if (timeHMSFormat_.test(line)) {
        ret = timecodeLib.parseTimeFromRegex_(line, timeHMSFormat_);
    }


    return ret;
}



