<template>
    <form>
        <div class="form-group">
            <label>Default Event Duration (Seconds)</label>
            <select class="form-control" v-model.lazy="eventDuration">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
            </select><br/>
            <p class="small">Set the default duration when creating a new caption event.</p>
        </div>
    </form>
</template>
<script>
    export default {
		name : 'TimingDuration',
		data () {
			return {
				
			}
		},
		methods : {
			
		},
		computed : {
			eventDuration : {
                get : function(){
                    return this.$store.state.editor.projectSettings.defaultEventDuration;
                },
                set : function(duration){
                    this.$store.commit('updateProjectSetting', {
                        option: 'defaultEventDuration',
                        value: duration
                    });
                }
            }
		}
	}
</script>