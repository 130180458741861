<template>
	<ModalTemplate Title="Import Media" ModalId="ImportVideoModal">
		<form>
			<div class="form-group row">
				<label class="col-3 col-form-label">Media Source</label>
				<div class="col">
					<select class="form-control" v-model="updateMediaSource">
						<option>Cloud URL</option>
						<option>Local Storage</option>
						<option>Vimeo</option>
						<option>YouTube</option>
					</select>
				</div>
			</div>
			<div class="form-group row" v-if="mediaSource === 'Local Storage'">
				<label for="Video Source" class="col-3 col-form-label">Select Media</label>
				<div class="col custom-file">
					<input type="file" ref="importMedia" accept="video/*,audio/*" @change="updateMediaUrl">
					<small id="source-video-help" class="form-text text-muted">Select your source video file.</small>
				</div>
				<br/><br/>
				<br/>
			</div>
			<div class="form-group row" v-else-if="mediaSource === 'Vimeo' ||  mediaSource === 'YouTube'">
				<label for="Video Source" class="col-3 col-form-label">Video URL</label>
				<div class="col">
					<input type="text" v-model="mediaUrl" class="form-control" placeholder="e.g. https://vimeo.com/280242294 or https://www.youtube.com/watch?v=rNKMrEcrNZQ">
					<small id="source-video-help" class="form-text text-muted">Copy the video url or ID for your video.</small>
				</div>
			</div>
			<div class="form-group row" v-else>
				<label for="Video Source" class="col-3 col-form-label">Cloud URL</label>
				<div class="col">
					<input type="text" v-model="mediaUrl" class="form-control" placeholder="e.g. https://s3-us-west-2.amazonaws.com/bucket/video.mp4">
					<small id="source-video-clip" class="form-text text-muted">Copy the URL from your cloud service provider (Support for AWS S3, and Google Storage)<br /> Please note that the video must be made Public prior to captioning.</small>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-3 col-form-label">Aspect Ratio</label>				
				<div class="col-4">
					<select class="form-control" v-model="aspectRatio">
						<option>16:9</option>
						<option>4:3</option>
						<option title="Mobile">9:16</option>
					</select>
				</div>
			</div>
			<button @click="importVideoFile" type="button" class="btn btn-primary float-right" data-dismiss="modal"><i class="fas fa-file-import"></i> Import</button>
			
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	export default {
		name : 'ImportVideoModal',
		components: {
			ModalTemplate
		},
		data : function(){
			return {
				mediaSource : "Local Storage",
				mediaUrl : "",
				aspectRatio : "16:9"
			}
		},
		computed : {
			updateMediaSource : {
				get() {
					return this.mediaSource;
				},
				set(source){
					this.mediaUrl = "";
					this.mediaSource = source;				
				}
			}
		},
		methods : {
			updateMediaUrl : function() {
				this.mediaUrl = this.$refs.importMedia.files;
			},
			importVideoFile : function(){
				this.$store.commit('importMedia', {
					source : this.mediaSource,
					url : this.mediaUrl,
					aspectRatio : this.aspectRatio
				});
				
				/* Reset component data */
				this.mediaSource = "Local Storage";
				this.mediaUrl = "";

      		}
		}
	}
</script>

