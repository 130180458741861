<template>
<div id="TimelineEditor">
    <div id="TimelineEventViewer">
        <TimelinePlayhead />
        <TimelineRuler />        
        <div id="TimelineEventsWrapper" >
            <TimelineEvent v-for="(captionEvent,index) in allCaptionEvents" v-bind:Id="index" :key="captionEvent.uniqueId"/>
        </div>
    </div>
    <div id="TimelineEditorInput">
        <TimelineEventEditor/>
    </div>
</div>
</template>

<script>
import TimelineEventEditor from './TimelineEventEditor'
import TimelineRuler from './TimelineRuler'
import TimelineEvent from './TimelineEvent'
import TimelinePlayhead from './TimelinePlayhead'
export default {
    name: "TimelineEditor",
    components: {
        TimelineEventEditor,
        TimelineRuler,
        TimelineEvent,
        TimelinePlayhead
    },
    computed: {
        allCaptionEvents: function () {
            return this.$store.state.editor.captions;
        }     
    }
}
</script>

<style>
#TimelineEditorInput {
    background: rgb(22, 22, 22);
    padding-top: 1%;
}

#TimelineEventViewer {
    height: 65vh;
    background-color: rgba(0, 0, 0, 0.15);
    overflow-y: scroll;
    will-change: transform;
}

#TimelineEventsWrapper {
    position: relative;
}
</style>
