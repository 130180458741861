<template>
    <div id="player" class="webPlayerContainer">
        <iframe
        src="https://player.vimeo.com/video/76979871?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
        allowfullscreen
        allowtransparency
        allow="autoplay"
    ></iframe>
    </div>
</template>
<script>
    import Plyr from 'plyr';
    export default {
        name: 'VimeoPlayer',
        mounted: function () {
            window.mediaPlayer = new Plyr('#player', {
                "keyboard": {
                    focused: false,
                    global: false
                },
                controls : [],
            });
        }
    }
</script>