<template>
    <audio id="player" poster="../../assets/img/videoPoster.jpg" crossorigin controls></audio>
</template>
<script>
import Plyr from 'plyr';
export default {
    name: 'AudioPlayer',
    mounted : function(){
        window.mediaPlayer = new Plyr('#player', {
            "keyboard" : { 
                focused: false,
                global: false 
            },
            ratio : '16:9'
        });
    },
}
</script>
