const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const stlFunc =require('../functions/profiles/ebuStl.js');
const groupLinesByPosition = require("../functions/helpers/groupLinesByPosition.js");

module.exports = {
    decode: function (fileContents) {
        let events = [],
            ttiInfo,
            event,
            accentInfo = {
                set : false,
                value : ""
            };
        let gsi = stlFunc.decodeGsiBlock(fileContents.substring(0, 2048));
        let ttiBlocks = fileContents.substring(2048, fileContents.length).match(/.{1,256}/g);
        ttiBlocks.forEach(ttiBlock => {            
            ttiInfo = stlFunc.decodeTtiBlock(ttiBlock);
            if (ttiInfo.commentFlag === "00") {
                event = new ccEvent();
                event.insertLine();
                event.lines[event.lines.length-1].posY = ttiInfo.posY;
                event.updateStart(timecodeLib.tcToSec(stlFunc.decodeTc(ttiInfo.incode), gsi.frameRate));
                event.updateEnd(timecodeLib.tcToSec(stlFunc.decodeTc(ttiInfo.outcode), gsi.frameRate));
                ttiInfo.text.match(/.{1,2}/g).forEach(char => {
                    if (char === "f8"){

                    } else if (parseInt(char, 16) >= 0 && parseInt(char, 16) <= 31) {
                        /* TELETEXT CONTROL CODES */
                        if (stlFunc.cntrlCodes[char]){
                            event.color = stlFunc.cntrlCodes[char]
                        }
                    } else if ((parseInt(char, 16) >= 32 && parseInt(char, 16) <= 127) || (parseInt(char, 16) >= 161 && parseInt(char, 16) <= 255)) {
                        if (gsi.alphabet.toLowerCase() === 'latin' && parseInt(char, 16) !== 201 && parseInt(char, 16) !== 204 && parseInt(char, 16) >= 193 && parseInt(char, 16) <= 207 ){
                            /* EBU STL uses a floating accent principle so we need to store the accents as they come in and apply them on the next character. See document for more info. */
                            accentInfo = {
                                set : true,
                                value : parseInt(char, 16)
                            }
                        } else {
                            if (accentInfo.set){                                
                                event.lines[event.lines.length - 1].text += stlFunc.accentMapping[char.toLowerCase()][accentInfo.value];
                                accentInfo.set = false;
                            } else {
                                //console.log(parseInt(char, 16) + " : " + stlFunc.decodeChar(char, stlFunc.codePageMapping[gsi.alphabet]));
                                event.lines[event.lines.length - 1].text += stlFunc.decodeChar(char, stlFunc.codePageMapping[gsi.alphabet]);
                            }
                            
                        }
                        
                    } else if (parseInt(char, 16) >= 128 && parseInt(char, 16) <= 133) { 
                        /* Format Codes */
                        if (stlFunc.controlCodeMapping[char]){
                            if (stlFunc.controlCodeMapping[char] === "<i>"){
                                event.lines[event.lines.length - 1].italics = true;
                            } else if (stlFunc.controlCodeMapping[char] === "<u>"){
                                event.lines[event.lines.length - 1].underline = true;
                            } else if (stlFunc.controlCodeMapping[char] === "<b>"){
                                event.lines[event.lines.length - 1].bold = true;
                            }
                        }
                    } else if (parseInt(char, 16) === 138 /* 8A */) { 
                        /* Insert New Line */
                        event.insertLine();
                    }
                });    
                
                if (ttiInfo.posX === "left"){
                    event.lines.forEach(line =>{
                        line.horzAlignLeft();
                    })
                } else if (ttiInfo.posX === "right"){
                    event.lines.forEach(line =>{
                        line.horzAlignRight();
                    })
                } else {
                    event.lines.forEach(line =>{
                        line.horzAlignCenter();
                    })
                }

                accentInfo.set = false;
                event.removeBlankLines();
                event.syncSubtitleText();
                events.push(event);
            }
        });

        return events;
    },

    encode: function (events, options) {
        let fileContents = "", gsi, encodingOptions = {}, tti;
        if (options.formatOptions && options.formatOptions.length > 0){
            options.formatOptions.forEach (formatOption =>{
                encodingOptions[formatOption.name] =  formatOption.selected;
            });
        }
        
        gsi = stlFunc.encodeGsiBlock(events, encodingOptions, options);
        fileContents += gsi.concat();
        events.forEach(function(event, index, events){

            tti = stlFunc.encodeTtiBlock(event, index, encodingOptions, options);
            fileContents += tti.concat();
        });

        return fileContents;
    },

    preProcess: {
        encode: function (events) {
            return events.filter(event => {
                event.lines = groupLinesByPosition(event.lines);
                event.syncSubtitleText();
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode: function (fileContents) {
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    }

}
