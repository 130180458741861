const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");

module.exports = {
    decode: function (fileContents, options) {
        let events = [];
        const captionLines = fileContents.split("\n");
        let fontSize = 12;
        let fontStyle = "Ariel";
    
        const tcRegex = /\d{2}:\d{2}:\d{2}\:\d{2}/g;
        for (let i = 0; i < captionLines.length; i++) {

            if (captionLines[i].includes("$FontName")) {
                fontStyle = captionLines[i].split('=')[1].trim();
            }

            if (captionLines[i].includes("$FontSize")) {
                fontSize = captionLines[i].split('=')[1].trim();
            }


            const match = captionLines[i].match(tcRegex);
            
            if (match) {
                const event = new ccEvent();;
                event.font = fontStyle;
                event.size = fontSize;
                event.updateStart(timecodeLib.tcToSec(match[0], options.frameRate));
                event.updateEnd(timecodeLib.tcToSec(match[1], options.frameRate));

                //Get the caption line
                captionLines[i] = captionLines[i].replace(/\d{2}:\d{2}:\d{2}\:\d{2}(\s?,?)/g, '');
                const lines = getLines(captionLines[i]);

                lines.forEach(function (line) {
                    event.insertLine({
                        text: line,
                        posX: 0,
                        posY: 0,
                        bold: /<b>|<\/b>/.test(line),
                        italics: /<i>|<\/i>/.test(line),
                        underline: /<u>|<\/u>/.test(line)
                    });
                });
                event.syncSubtitleText();
                event.alignTextCenter();
                events.push(event);
            }
        }


        return events;
    },

    encode: function (events, options) {
        let fileContents = "//Font select and font size\n\
$FontName       = Arial\n\
$FontSize       = 30\n\n\
//Character attributes (global)\n\
$Bold           = FALSE\n\
$UnderLined     = FALSE\n\
$Italic         = FALSE\n\n\
//Position Control\n\
$HorzAlign      = Center\n\
$VertAlign      = Bottom\n\
$XOffset        = 0\n\
$YOffset        = 0\n\n\
//Contrast Control\n\
$TextContrast           = 15\n\
$Outline1Contrast       = 8\n\
$Outline2Contrast       = 15\n\
$BackgroundContrast     = 0\n\n\
//Effects Control\n\
$ForceDisplay   = FALSE\n\
$FadeIn         = 0\n\
$FadeOut        = 0\n\n\
//Other Controls\n\
$TapeOffset          = FALSE\n\
//$SetFilePathToken  = <<:>>\n\n\
//Colors\n\
$ColorIndex1    = 0\n\
$ColorIndex2    = 1\n\
$ColorIndex3    = 2\n\
$ColorIndex4    = 3\n\n\
//Subtitles\n"

        events.forEach(function (event) {
            const textLine = event.text.replace(/\n/g, "|");
            fileContents += (eol.after(`${timecodeLib.secToTc(event.start, options.frameRate)},${timecodeLib.secToTc(event.end, options.frameRate)},${textLine}`));
        });



        return fileContents;
    },

    preProcess: {
        encode: function (events) {
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode: function (fileContents) {
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    },
}

const getLines = function (line) {
    line = line.replace(/\^I/g, "").replace(/\^B/g, "").replace(/\^U/g, "");

    return line.trim().split('|');
}