const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const striptags = require("striptags");
const eol = require("eol");

module.exports = {
    decode : function(fileContents, options){
        let event;
        let events = [];
        let blocks = fileContents.split("\n\n");
        blocks.forEach(block =>{
            let eventFlag = false;
            let endFlag = false;
            let blockLines = block.split("\n");
            blockLines.forEach(blockLine => {
                if (blockLine.includes("** Caption Number")) {
                    eventFlag = true;
                    event = new ccEvent();;
                } else if (eventFlag){
                    let blockLineLength = blockLine.split(" ").length;
                    if (blockLine.includes("*T") && blockLineLength > 1){
                        let tc= blockLine.split(" ")[1];
                        event.start = timecodeLib.tcToSec(tc, options.frameRate);
                    } else if (blockLine.includes("*E") && blockLineLength > 1){
                        let tc= blockLine.split(" ")[1];
                        event.end = timecodeLib.tcToSec(tc, options.frameRate); 
                        endFlag = true;
                    } else if (blockLine.charAt(0) === "*"){
                        /* Metadata that is ignored */
                    } else {
                        if (blockLine.endsWith("\E")){
                            blockLine = blockLine.slice(0, -2);
                        }

                        event.insertLine({
                            text : striptags(blockLine),
                            posX : 0,
                            posY : 0,
                            bold : /<b>|<\/b>/.test(blockLine),
                            italics : /<i>|<\/i>/.test(blockLine),
                            underline : /<u>|<\/u>/.test(blockLine) 
                        });
                    }
                }
            }); 

            if (eventFlag){
                if (!endFlag){
                    event.end = false;
                }
                event.syncSubtitleText();
                event.alignTextCenter();
                events.push(event);
            }
        });

        return events;
    },

    encode : function(events, options){
        let fileContents = "";
        if (options.dropFrame){
            fileContents += "*DropFrame\n";
        }

        fileContents += "*WIDTH 32\n\n";

        events.forEach(function(event, i){
            let eventId = i+1
            fileContents += "** Caption Number " + eventId + "\n";
            fileContents += "*PopOn\n";
            fileContents += "*T " + timecodeLib.secToTc(event.start, options.frameRate) + "\n";
            fileContents += "*E " + timecodeLib.secToTc(event.end, options.frameRate) + "\n";
            fileContents += "*BottomUp\n";
            fileContents += "*Cf16\n";
            fileContents += event.text + "/E\n\n";
        });

        return fileContents.trim();
    },

    preProcess : {
        encode : function(events){
            /* All */
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode : function(fileContents){
            /* All */
            fileContents = eol.lf(fileContents);            
            fileContents = fileContents.trim();
            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events, options){
            events.forEach(function(event, i, events){
                if (!event.end){
                    events[i].end = events[i+1].start-(1/options.frameRate);
                }
            });

            return events;
        }
    },

}