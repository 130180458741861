<template>
	<ModalTemplate Title="Export Transcript" ModalId="ExportTranscript">
		<form>
			<div class="form-group">
				<label for="Transcript Formats">Format</label>
				<select class="form-control" v-model="format">
					<option>TXT</option>
					<option>DOCX</option>			
				</select>
			</div>
			<div class="form-group">
				<label for="Timecode Format">Timecode Option</label>
				<select class="form-control" v-model="tc">
					<option>SMPTE</option>
					<option>Seconds</option>
					<option>None</option>			
				</select>
			</div>

			
			<button @click="exportTranscriptFile" type="button" class="btn btn-primary" data-dismiss="modal"><i class="fas fa-file-export"></i> Export</button><br/><br />
			<small><i class="fas fa-bolt"></i> Powered by <strong>Closed Caption Converter</strong></small></form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	export default {
		name : 'ExportTranscriptModal',
		data () {
			return {
				format : 'TXT',
				tc : "SMPTE",
				msg: 'Export Transcript Modal Mounted'
			}
		},
		components: {
			ModalTemplate
		},
		computed : {
			appVersion : function(){
				return this.$store.state.editor.applicationVersion;
			},
		},
		methods : {
			exportTranscriptFile : function(){
				this.$store.commit('updateStatus', {
					progress	: 100,
					msg	: "Exporting",
					status	: "processing",
					display	: true
				});

				if (this.$store.state.editor.view === "subtitle") {
					this.$store.commit('copySubtitlesToCaptions');
				} else {
					this.$store.commit('copyCaptionsToSubtitles');
				}		
				
				var self = this;
				setTimeout(function(){					
					self.$store.commit('exportTranscriptFile', {source : self.source,format: self.format, tc : self.tc});
					self.$store.commit('updateStatus', {
						progress	: 100,
						msg	: "Complete",
						status	: "Export Complete",
						display	: false
					});
				},1000);					
      		}
		}
	}
</script>