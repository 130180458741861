<template>
    <form>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Project Name</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="title" placeholder="Project Title">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Asset Title</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="subtitle" placeholder="Asset Title">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Framerate</label>
            <div class="col-sm-10">
                <input type="number" class="form-control bg-dark" v-model.lazy="this.$store.state.editor.projectSettings.framerate"
                    disabled>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Incode</label>
            <div class="col-sm-10">
                <input type="text" class="form-control bg-dark" v-model.lazy="this.$store.state.editor.projectSettings.incode" disabled>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Author</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="author" placeholder="Author">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Language</label>
            <div class="col-sm-10">
                <select class="form-control" v-model="language">
                    <option v-for="(language, index) in languages" :key="index">{{language}}</option>
                </select>
            </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">House Number (UID)</label>
            <div class="col-sm-10">
                <input type="text" class="form-control bg-dark" v-model.lazy="uid" disabled placeholder="UID">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Series</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="series" placeholder="Series">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Season</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="season" placeholder="Season">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Episode</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="episode" placeholder="Episode">
            </div>
        </div>        
    </form>
</template>
<script>
    import supportedLanguages from '../../../assets/js/supportedLanguages'
    export default {
        name: 'General',
        data() {
            return {
                languages : supportedLanguages.languages
            }
        },
        mounted : function(){
            if (typeof (Storage) !== "undefined" && localStorage.getItem('cc-language')) {
				var language = localStorage.getItem('cc-language');
                this.$store.commit('updateProjectSetting', {
                    option: 'language',
                    value: language
                });
			}
        },
        computed: {
            title: {
                get: function () {
                    return this.$store.state.editor.projectSettings.title
                },
                set: function (title) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'title',
                        value: title
                    });
                }
            },
            subtitle: {
                get: function () {
                    return this.$store.state.editor.projectSettings.subtitle;
                },
                set: function (subtitle) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'subtitle',
                        value: subtitle
                    });
                }
            },
            author: {
                get: function () {
                    return this.$store.state.editor.projectSettings.author;
                },
                set: function (author) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'author',
                        value: author
                    });
                }
            },
            language: {
                get: function () {
                    return this.$store.state.editor.projectSettings.language;
                },
                set: function (language) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'language',
                        value: language
                    });

                    if (typeof(Storage) !== "undefined") {
                        localStorage.setItem("cc-language", language);
                    }
                }
            },
            version: {
                get: function () {
                    return this.$store.state.editor.projectSettings.version;
                },
                set: function (version) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'version',
                        value: version
                    });
                }
            },
            uid: {
                get: function () {
                    return this.$store.state.editor.projectSettings.uid;
                },
                set: function (uid) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'uid',
                        value: uid
                    });
                }
            },
            episode: {
                get: function () {
                    return this.$store.state.editor.projectSettings.episode;
                },
                set: function (episode) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'episode',
                        value: episode
                    });
                }
            },
            season: {
                get: function () {
                    return this.$store.state.editor.projectSettings.season;
                },
                set: function (season) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'season',
                        value: season
                    });
                }
            },
            series: {
                get: function () {
                    return this.$store.state.editor.projectSettings.series;
                },
                set: function (series) {
                    this.$store.commit('updateProjectSetting', {
                        option: 'series',
                        value: series
                    });
                }
            },
        }
    }
</script>