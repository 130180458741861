const striptags = require('striptags')

module.exports = {
    jsonOption : {
        xmlHeader: true,
        prettyPrint: true
    },
    dropModeMapping : {
        "23.976" : "nonDrop", 
        "24": "nonDrop",
        "25": "nonDrop",
        "29.97": "dropNTSC",
        "30": "nonDrop",
        "50": "nonDrop",
        "59.94": "dropNTSC",
        "60": "nonDrop"
    },
    frameRateMapping : {
        "23.976": "24",
        "24": "24",
        "25": "25",
        "29.97": "30",
        "30": "30",
        "50": "50",
        "59.94": "60",
        "60": "60"
    },
    frameRateMultiplierMap : {
        "23.976": "1000 1001",
        "24": "1 1",
        "25": "1 1",
        "29.97": "1000 1001",
        "30": "1 1",
        "50": "1 1",
        "59.94": "1000 1001",
        "60": "1 1"
    },
    newCaptionLine : function(begin, end, origin, captionText){
        return {
            name: "p",
            attrs: {
                "begin": begin,
                "end": end,
                "region": "pop1",
                "style": "basic",
                "tts:origin": origin
            },
            children : [
                {
                    name : "style"
                },
                {
                    name : "span",
                    text: striptags(captionText),
                    children : [
                        {
                            name : "style",
                            attrs: {
                                "tts:backgroundColor": "#000000FF",
                                "tts:color": "#AAAAAAFF",
                                "tts:fontSize": "18px",
                            }
                        }
                    ]
                }
            ]
        }
    },
    captionData: function (dropMode, frameRate, frameRateMultiplier, captionLines) {
        return [
            {
                name:'tt',
                attrs : {
                    "xmlns" : "http://www.w3.org/ns/ttml",
                    "xmlns:m708" : "http://www.smpte-ra.org/schemas/2052-1/2013/smpte-tt#cea708",
                    "xmlns:smpte" : "http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt",
                    "xmlns:ttm" : "http://www.w3.org/ns/ttml#metadata",
                    "xmlns:ttp" : "http://www.w3.org/ns/ttml#parameter",
                    "xmlns:tts" : "http://www.w3.org/ns/ttml#styling",
                    "ttp:dropMode": dropMode,
                    "ttp:frameRate": frameRate,
                    "ttp:frameRateMultiplier": frameRateMultiplier,
                    "ttp:timeBase" : "smpte"
                },
                children : [
                    {
                        name : "head",
                        children : [
                            {
                                name : "styling",
                                children : [
                                    {
                                        name : "style",
                                        attrs: {
                                            "tts:color": "white",
                                            "tts:fontFamily": "monospace",
                                            "tts:fontWeight": "normal",
                                            "tts:textAlign": "left",
                                            "xml:id": "basic"
                                        }
                                    }
                                ]
                            },
                            {
                                name : "layout",
                                children: [
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "pop1"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "paint"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "rollup2"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "rollup3"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "rollup4"
                                        }
                                    },
                                ]
                            },
                            {
                                name: "metadata"
                            },
                            {
                                name: "smpte:information",
                                attrs: {
                                    "m708:aspectRatio": "16:9",
                                    "m708:easyReader": "false",
                                    "m708:number" : "1"
                                }
                            }
                        ]
                    },
                    {
                        name : "body",
                        children : [
                            {
                                name : "div",
                                children : captionLines
                                
                            }
                        ]
                    }
                ]
            }
        ]
    }
}