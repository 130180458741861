const uniqueId = require('lodash/uniqueId.js')
const defaults = require('./defaults.js');
const eventLine = require('./eventLine.js');
module.exports = class Event {
    constructor(options = {
        type : "subtitle",
        start : undefined,
        end : undefined,
        duration : undefined,
        posX : 0,
        posY : 0,
        alignment : "center",
        html : "",
        text : "",
        lines : [],
        style : "Pop-On",
        speaker : 0,
        audioFile : "",
        language : "",
        confidence : null,
        font : "",
        size : 12,
        background: "#000000",
        color : "#FFFFFF",
        padding: 0,
        opacity : 100
    }) {
        this.id = uniqueId(),
        this.type = options.type || "subtitle",
        this.start = options.start,
        this.end = options.end,
        this.duration = options.duration,
        this.posX = options.posX,
        this.posY = options.posY,
        this.html = options.html,
        this.text = options.text || "",
        this.lines = options.lines || [],
        this.style = options.style || "Pop-On",
        this.speaker = options.speaker || 0,
        this.audioFile = options.audioFile || "",
        this.language = options.language || "",
        this.confidence = options.confidence,
        this.font = options.font,
        this.size = options.size || "12",
        this.background = options.background || "#000000",
        this.color = options.color || "#ffffff",
        this.alignment = options.alignment,
        this.padding = options.padding,
        this.opacity = options.opacity || 100
    }
    refreshId() {
        this.id = uniqueId();
        return this;
    }
    updateStart(seconds) {
        this.start = seconds;
        this.updateDuration();
        return this;
    }
    updateEnd(seconds) {
        this.end = seconds;
        this.updateDuration();
        return this;
    }
    updateDuration() {
        this.duration = this.end - this.start;
        return this;
    }
    offset(seconds, type) {
        switch (type) {
            case "add":
                this.start += parseFloat(seconds);
                this.end += parseFloat(seconds);
                break;
            default:
                this.start -= parseFloat(seconds);
                this.end -= parseFloat(seconds);
        }

        this.updateDuration();
        return this;
    }
    insertLine(options) {
        this.lines.push(new eventLine(options));
        return this;
    }
    updateTextByLine(text, line) {
        this.lines[line].text = text;
        return this;
    }

    vertAlignTop(rows = defaults.window.rows) {
        this.lines.forEach(function (line, index) {
            line.posY = (index / rows) * 100;
        });
        return this;
    }
    vertAlignCenter(rows = defaults.window.rows) {
        this.lines.forEach(function (line, index, lines) {
            line.posY = ((Math.ceil(rows / 2) - Math.floor(lines.length / 2 - index)) / rows) * 100;
        });
        return this;
    }
    vertAlignBottom(rows = defaults.window.rows) {
        this.lines.forEach((line, i, lines) =>{
            line.posY = ((rows - ((lines.length-1) - i)) / rows) * 100;
        });
        
        return this;
    }
    alignTextCenter() {
        this.vertAlignBottom();
        this.lines.forEach(line => {
            line.horzAlignCenter();
        });
        this.alignment = "center";
        return this;
    }
    setAlignmentByLine(columns = defaults.window.columns) {
        if (this.lines.length > 0) {
            let charLength = this.lines[0].text.trim().length;
            let xPos = this.lines[0].posX;
            let center = ((Math.max(0, (columns - charLength) / 2)) / columns) * 100;
            if (xPos < center + 3.5 && xPos > center - 3.5) {
                this.alignment = "center";
            } else if (xPos === 0) {
                this.alignment = "left";
            } else if (xPos > center) {
                this.alignment = "right";
            } else {
                if (this.lines.length > 1) {
                    charLength = this.lines[1].text.trim().length;
                    xPos = this.lines[1].posX;
                    center = ((Math.max(0, (columns - charLength) / 2)) / columns) * 100;

                    if (xPos < center + 3.5 && xPos > center - 3.5) {
                        this.alignment = "center";
                    } else {
                        this.alignment = "start";
                    }
                } else {
                    this.alignment = "start";
                }
            }
        }
        return this;
    }
    getText(){
        let text = "";
        this.lines.forEach(line => {
            text += " " + line.text.trim();
        });

        return text;
    }
    autoFormat() {
        let words = [],
            totalChars = 0,
            newLines = [];

        this.lines.forEach(line => {
            words = words.concat(line.text.trim().split(" "));
        });

        words = words.filter(word => {
            if (word.length > 0) {
                totalChars += word.length;
                return true;
            }
        });

        newLines.push(new eventLine());

        for (var i = 0; i < words.length; i++) {
            if (newLines[newLines.length - 1].text.length > 18) {
                newLines.push(new eventLine());
            }

            newLines[newLines.length - 1].text = newLines[newLines.length - 1].text.trim() + " " + words[i];
        }

        this.lines = newLines;
        return this;
    }
    removeBlankLines() {
        this.lines = this.lines.filter(line => {
            return line.text.trim().length > 0;
        });
        return this;
    }
    syncSubtitleText() {
        this.text = (this.lines.map(line => line.text.trim()).join("\n")).trim();
        return this;
    }
    syncHtmlText() {
        this.html = (this.lines.map(line => {
            let formatting = {
                prefix : "",
                suffix : ""
            }

            if (line.bold){
                formatting.prefix += "<b>";
                formatting.suffix = "</b>" + formatting.suffix;
            }
    
            if (line.italics){
                formatting.prefix += "<i>";
                formatting.suffix = "</i>" + formatting.suffix;
            }
    
            if (line.underline) {
                formatting.prefix += "<u>";
                formatting.suffix = "</u>" + formatting.suffix;
            }

            return formatting.prefix + line.text.trim() + formatting.suffix;
        }).join("\n")).trim();
        
        return this;
    }
    syncCaptionText() {
        this.lines = this.text.trim().split("\n").map(line => {
            return new eventLine({
                text: line
            });
        });
        return this;
    }
}
