<template>
    <div>
        <p v-if="view === 'caption'" class="float-right p-2 text-muted"><i class="fas fa-info-circle" title="Project Info"></i> {{this.$store.state.editor.projectSettings.title}}.ccprj <i class="fas fa-stopwatch" title="Framerate"></i> {{this.$store.state.editor.projectSettings.framerate}} <i class="fas fa-clock" title="Duration"></i> {{this.$store.state.editor.duration}} Sec <i class="fas fa-closed-captioning" title="Total Events"></i> {{this.$store.state.editor.captions.length}}</p>
        <p v-else class="float-right p-2 text-muted"><i class="fas fa-info-circle" title="Project Info"></i> {{this.$store.state.editor.projectSettings.title}}.ccprj <i class="fas fa-stopwatch" title="Framerate"></i> {{this.$store.state.editor.projectSettings.framerate}} <i class="fas fa-clock" title="Duration"></i> {{this.$store.state.editor.duration}} Sec <i class="fas fa-audio-description" title="Total Events"></i> {{this.$store.state.editor.audioDescription.length}}</p>
    </div>
</template>
<script>
export default {
    name : 'projectInfo',
    computed : {
        view : function(){
            if (this.$store.state.editor.view === 'audio description'){
                return 'ad'
            } else {
                return 'caption'
            }
        }
    }
}
</script>