const timecodeLib = require("../timecode.js");
module.exports = {
    decode : function(fileContents, options){
        throw new Error ("JSON source files are not supported");
    },

    encode : function(events, options){
        let subtitles = []; 
        events.forEach(event  =>{
            let subtitle = {
                startSec : event.start,
                endSec : event.end,
                startSmpte : timecodeLib.secToTc(event.start, options.frameRate),
                endSmpte: timecodeLib.secToTc(event.end, options.frameRate),
                frameRate : options.frameRate,
                text: event.text
            }
            subtitles.push(subtitle);
        });

        return JSON.stringify(subtitles, null, 4);
    },

    preProcess : {
        encode : function(events){
            /* All */
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode : function(fileContents){
            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}