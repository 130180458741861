const ccFunc = require('../ccFunc')
const hex2Bin = require('hex-to-binary')
const sccLookup = require('./scc')
const striptags = require('striptags')

module.exports = {
	encodeTimecode : function(frame){
		var hex = frame.toString(16);
		hex = ccFunc.pad('0000', hex, true);
		return hex;
	},
	encodeHeader : function(timer, frameRate){
		var data = "6101609669";
		data += "60"; //cdp_length
		data += this.frameRateInfoMap[frameRate].hexValue;
		data += "F"; //Reserved
		data += "7"; //time_code_present to scv_info_start
		data += "F"; //svc_info_change to Reserved;
		data += this.encodeTimecode(timer.frameCount);
		return data.toUpperCase();
	},
	packetCounter : {
		count : 0,
		counts : {
			0 : "00",
			1 : "01",
			2 : "10",
			3 : "11"
		},
		getCount : function(){
			var data = this.counts[this.count];
			this.count++;
			if (this.count > 3) {
				this.count = 0;
			}
			return data;
		}
	},
	encodeCcData: function (cc_count, max608Bytes, max708Bytes, sccData, mccData) {
		var ccData = {
			header : "",
			708 : [],
			padding : [],
			packet : ""
		};
		var ccPadding = "";
		var ccDataCount = 0;
		var mccBreak = false;
		ccData.packet = "111" + cc_count.toString(2);
		ccData.packet = "72" + parseInt(ccData.packet, 2).toString(16);
		for (var i = 0; i<cc_count; i++){
			if (i === 0){
				ccData.packet += "FC" + sccData;
			} else if (i === 1) {
				ccData.packet += "F98080"
			} else if (mccData[0] === "BREAK" && i === 2){
				mccData.shift();				
				ccData.header = "FF" + "=" + mccData.shift();
			} else if (mccData[0] === "MCCBREAK") {
				mccData.shift();
				ccData[708].push("FE0000");
				mccBreak = true;
			} else if (mccData[0] === "BREAK" && i > 2) {
				mccData.shift();
				mccBreak = true;
				ccData.padding.push("FA0000");
			} else if (i === 2 && mccData.length > 0 && ["3198", "3199"].indexOf(mccData[0] + mccData[1]) > -1) {
				ccData.header = "FF" + "=" + mccData.shift();
			} else if (i === 2 && mccData.length > 0 && (mccData[0] + mccData[1] == "2392")) {
				ccData.header = "FF" + "=" + mccData.shift();
			} else if (i === 2 && mccData.length > 0) {
				ccData.header = "FF" + "=" + "22";
			}  else if (mccData.length > 1 && i < 12 && !mccBreak) {
				var mccByte01 = mccData.shift();
				var mccByte02 = mccData.shift();
				var data = "FE" + mccByte01;				
				if (mccByte02 === "BREAK" || mccByte02 === "MCCBREAK") {
					data+="00";
					mccBreak = true;
				} else {
					data += mccByte02;
				}
				ccData[708].push(data);
			} else if (mccData.length === 1 && i < 11 && !mccBreak) {
				ccData[708].push("FE" + mccData.shift() + "00");
			} else {
				ccData.padding.push("G");
			}
		}
		if (ccData.header.length > 0){
			var packetHeader = this.packetCounter.getCount() + (ccData[708].length+1).toString(2).padStart(6, "0");
			//console.log("packet Header: " + packetHeader);
			var packetHeader = parseInt(packetHeader, 2).toString(16).toUpperCase().padStart(2, '0');
			//console.log(packetHeader);
			ccData.header = ccData.header.split("=")[0] + packetHeader + ccData.header.split("=")[1];
			return (ccData.packet + ccData.header + ccData[708].join("") + ccData.padding.join("")).toUpperCase();
		} else {
			return (ccData.packet + ccData.padding.join("")).toUpperCase();
		}
		

		
	},
	encodeCcsvcInfo : function(){
		var data = "73F3E0656E677E3FFFE1656E67C13FFFE2656E67C23FFF";
		return data;
	},
	encodeCdpFooter : function(sequenceCounter, packet){
		var data = "74";
		data += sequenceCounter;
		var packetDecoded = "";
		packet.substring(6).toUpperCase().split("").forEach(ancChar => {
			if (this.charToByte[ancChar] != undefined) {
				packetDecoded += (this.charToByte[ancChar]);
			} else {
				packetDecoded += ancChar;
			}
		});
		//console.log(packetDecoded);
		var checkSum = this.calcChecksum(packetDecoded);
		data += checkSum;
		data += "C2";
		return data.toUpperCase();
	},
	calcChecksum: function (hexstring) {
		var hexArray = hexstring.split("");
		var total = 0;
		for (var i = 0; i<hexArray.length; i++){
			total += parseInt("0x" + hexArray[i] + hexArray[++i]);
		}
		var binary = total.toString(2);
		/* take the last 8 bits */
		binary = binary.substring(binary.length - 8);
		var lastOne = (binary.lastIndexOf("1"));
		if (lastOne == -1){
			return "00";
		}
		binary = binary.split("");
		var checkSum = "";
		var found = false;
		for (var k = 0; k<binary.length; k++){
			if (k == lastOne){
				checkSum += binary[k];
				found = true;
			} else if (found){
				checkSum += binary[k];
			} else {
				if (binary[k]== "0"){
					checkSum += "1";
				} else {
					checkSum += "0";
				}
			}			
		}
		checkSum = parseInt(checkSum, 2).toString(16).toUpperCase();
		if (checkSum.length < 2){
			return "0" + checkSum;
		} else{
			return checkSum;
		}
	},
	mccEncode: function (captionEvent, primeWindow) {
		var data = "";
		var row = "12";
		var rowCounter = 0;
		var captionText = ccFunc.concatCaptionText(captionEvent.primaryLang).replace(/\n/g, "<br />");

		var rowPercent = parseInt((parseInt(row) / 14) * 100);
		//console.log("rowPercent: " + rowPercent);
		var rowBinary = rowPercent.toString(2).slice(1);
		//console.log(rowBinary);
		
		if (primeWindow){
			//console.log("Prime Window set to true. using DF0");
			data += "31 98 "; //DF0 Command
		} else {
			//console.log("Prime Window set to false. using DF1");
			data += "31 99 "; //DF1 Command
		}

		data += "1B "; //Param 01
		data += "2D "; //Param 02 Relative position down
		data += "00 "; //Param 03 Centering text/Horizontal alignment
		data += "00 "; //Param 04
		data += "1F "; //Param 05
		data += "14 "; //Param 06 Default Pen and Window styles

		/* Set Pen Attributes */
		data += "90 "; //SPA
		data += "05 "; 
		data += "03 ";

		/* Set Pen Color */
		data += "91 "; //SPC
		data += "3F ";
		data += "00 ";
		data += "3F ";

		/* SPL */
		data += "92 "; //SPL
		data += "00 "; //row
		data += "00 "; //columm
		data += "BREAK "; //Null

		//data += "FF0A31 {FE981B FE2DZ FEZ1F FE14}{90 FE0503} FE{913F FEZ3F} FE{92Z FE04Z N 22 ";

		var captionBreakArray = captionText.split("<br />");
		var charCount = 0;
		if (captionBreakArray.length > 1) {			
			for (var j = 0; j < captionBreakArray.length; j++) {
				/* Centre the first row */
				var horzAlign = "00";
				if (j>0){
					if (primeWindow) {
						//console.log("Prime Window set to true. using DF0");
						data += "BREAK 31 98 "; //DF0 Command
					} else {
						//console.log("Prime Window set to false. using DF1");
						data += "BREAK 31 99 "; //DF1 Command
					}

					data += "1B "; //Param 01
					data += "2D "; //Param 02 Relative position down
					data += "00 "; //Param 03 Centering text
					data += "0"+j.toString()+" "; //Param 04
					data += "1F "; //Param 05
					data += "14 "; //Param 06 Default Pen and Window styles

					/* Set Pen Attributes */
					data += "90 "; //SPA
					data += "05 ";
					data += "03 ";

					/* Set Pen Color */
					data += "91 "; //SPC
					data += "3F ";
					data += "00 ";
					data += "3F ";

					/* SPL */
					data += "92 "; //SPL
					data += "0" + j.toString() + " "; //row
					data += horzAlign + " "; //columm
					data += "00 "; //Null
				} else {
					data += "BREAK 23 ";
					data += "92 "; //SPL
					data += "0" + rowCounter + " ";
					data += horzAlign +" "; //columm
					data += "00 "; //Null
					data += "BREAK "; //Null
					rowCounter++
				}

				var captionTextArray = captionBreakArray[j].split('');
				for (var i = 0; i < captionTextArray.length; i++) {
					var captionValue = ccFunc.getKeyByValue(this.exportCommands, captionTextArray[i]) || "27";
					data += captionValue + " ";
					charCount++
					if (charCount == 2){
						data += "MCCBREAK" + " ";
						charCount = 0;
					}
				}
			}
		} else {
			data += "BREAK 23 ";
			data += "92 "; //SPL
			data += "0" + rowCounter + " "; //Row
			data += "00 "; //columm
			data += "00 "; //Null
			var captionTextArray = captionText.split('');
			for (var i = 0; i < captionTextArray.length; i++) {
				var captionValue = ccFunc.getKeyByValue(this.exportCommands, captionTextArray[i]) || "27";
				data += captionValue + " ";
				charCount++
				if (charCount == 2) {
					data += "MCCBREAK" + " ";
					charCount = 0;
				}
			}
		}

		return data.split(" ").filter(function (el) {
			return el != "";
		});
	},
	commands : {
		/* C0 table */
		/*Always clear the buffer whenever a command code is found*/
		"00": "{NUL}", //End of Line
		"03": "{ETX}", //Clear Screen
		"08": "{BS}", //Backspace previous character
		"0c": "{FF}", //Clear Screen
		"0d": "{CR}", //Carriage-Return - go to start of line
		"0e": "{HCR}", //Clear Line and go to start of line
		"10": "{EXT}", //Use secondary/external table lookup
		"11": "{SKIP1}",
		"12": "{SKIP1}",
		"13": "{SKIP1}",
		"14": "{SKIP1}",
		"15": "{SKIP1}",
		"16": "{SKIP1}",
		"17": "{SKIP1}",
		"18": "'", 
		"19": "'",
		"1a": "{SKIP1}",
		"1b": "{SKIP1}",
		"1c": '"',
		"1d": '"',
		"1e": "{SKIP1}",
		"1f": "{SKIP1}",
		/* C1 table */
		"80": "{CW0}", //Set current window
		"81": "{CW1}", //Set current window
		"82": "{CW2}", //Set current window
		"83": "{CW3}", //Set current window
		"84": "{CW4}", //Set current window
		"85": "{CW5}", //Set current window
		"86": "{CW6}", //Set current window
		"87": "{CW7}", //Set current window
		"88": "{CLW}", //Clear Window**
		"89": "{DSW}", //Display the Window!**
		"8a": "{HDW}", //Hide Windows**
		"8b": "{TGW}", //Hides window and displays the next window
		"8c": "{DLW}", //Deletes all windows**
		"8d": "{DLY}", //Delay in 1/10s increments
		"8e": "{DLC}", //Cancel Delay
		"8f": "{RST}", //Reset clears buffers and windows
		"90": "{SPA}", //Set Pen Attributes
		"91": "{SPC}", //Set Pen Color
		"92": "{SPL}", //Set Pen Location
		"97": "{SWA}", //Set Window Attributes
		"98": "{DF0}", //Define Window
		"99": "{DF1}", //Define Window
		"9a": "{9a}", //Define Window
		"9b": "{9b}", //Define Window
		"9c": "{9c}", //Define Window
		"9d": "{9d}", //Define Window
		"9e": "{9e}", //Define Window
		"9f": "{9f}", //Define Window
		/* G0 table */
		"20" : " ",
		"21" : "!",
		"22" : '"',
		"23" : "#",
		"24" : "$",
		"25" : "%",
		"26": "&",
		"27": "'",
		"28": "(",
		"29": ")",
		"2a": "*",
		"2b": "+",
		"2c": ",",
		"2d": "-",
		"2e": ".",
		"2f": "/",
		"30": "0",
		"31": "1",
		"32": '2',
		"33": "3",
		"34": "4",
		"35": "5",
		"36": "6",
		"37": "7",
		"38": "8",
		"39": "9",
		"3a": ":",
		"3b": ";",
		"3c": "<",
		"3d": "=",
		"3e": ">",
		"3f": "?",
		"40": "@",
		"41": "A",
		"42": 'B',
		"43": "C",
		"44": "D",
		"45": "E",
		"46": "F",
		"47": "G",
		"48": "H",
		"49": "I",
		"4a": "J",
		"4b": "K",
		"4c": "L",
		"4d": "M",
		"4e": "N",
		"4f": "O",
		"50": "P",
		"51": "Q",
		"52": 'R',
		"53": "S",
		"54": "T",
		"55": "U",
		"56": "V",
		"57": "W",
		"58": "X",
		"59": "Y",
		"5a": "Z",
		"5b": "[",
		"5c": '\\',
		"5d": "]",
		"5e": "^",
		"5f": "_",
		"60": "'",
		"61": "a",
		"62": 'b',
		"63": "c",
		"64": "d",
		"65": "e",
		"66": "f",
		"67": "g",
		"68": "h",
		"69": "i",
		"6a": "j",
		"6b": "k",
		"6c": "l",
		"6d": "m",
		"6e": "n",
		"6f": "o",
		"70": "p",
		"71": "q",
		"72": 'r',
		"73": "s",
		"74": "t",
		"75": "u",
		"76": "v",
		"77": "w",
		"78": "x",
		"79": "y",
		"7a": "z",
		"7b": "{",
		"7c": "|",
		"7d": "}",
		"7e": "`",
		"7f": "♪",
		/* G1 table */
		"a0" : " ",
		"a1": "¡",
		"a2": "￠",
		"a3": "￡",
		"a4": "¤",
		"a5": "￥",
		"a6": "¦",
		"a7": "§",
		"a8": "¨",
		"a9": "©",
		"aa": "ª",
		"ab": "«",
		"ac": "￢",
		"ad": "-",
		"ae": "®",
		"af": "￣",
		"b0": "°",
		"b1": "±",
		"b2": "²",
		"b3": "³",
		"b4": "´",
		"b5": "µ",
		"b6": "¶",
		"b7": "·",
		"b8": "¸",
		"b9": "¹",
		"ba": "º",
		"bb": "»",
		"bc": "¼",
		"bd": "½",
		"be": "¾",
		"bf": "¿",
		"c0": "À",
		"c1": "Á",
		"c2": "Â",
		"c3": "Ã",
		"c4": "Ä",
		"c5": "Å",
		"c6": "Æ",
		"c7": "Ç",
		"c8": "È",
		"c9": "É",
		"ca": "Ê",
		"cb": "Ë",
		"cc": "Ì",
		"cd": "Í",
		"ce": "Î",
		"cf": "Ï",
		"d0": "Ð",
		"d1": "Ñ",
		"d2": "Ò",
		"d3": "Ó",
		"d4": "Ô",
		"d5": "Õ",
		"d6": "Ö",
		"d7": "×",
		"d8": "",
		"d9": "Ù",
		"da": "Ú",
		"db": "Û",
		"dc": "Ü",
		"dd": "Ý",
		"de": "Þ",
		"df": "ß",
		"e0": "à",
		"e1": "á",
		"e2": "â",
		"e3": "ã",
		"e4": "ä",
		"e5": "å",
		"e6": "æ",
		"e7": "ç",
		"e8": "è",
		"e9": "é",
		"ea": "ê",
		"eb": "ë",
		"ec": "ì",
		"ed": "í",
		"ee": "î",
		"ef": "ï",
		"f0": "ð",
		"f1": "ñ",
		"f2": "ò",
		"f3": "ó",
		"f4": "ô",
		"f5": "õ",
		"f6": "ö",
		"f7": "÷",
		"f8": "ø",
		"f9": "ù",
		"fa": "ú",
		"fb": "û",
		"fc": "ü",
		"fd": "ý",
		"fe": "þ",
		"ff": "ÿ",
		
		EXT : {
			/* C2 table */
			"00" : "{NUL}",
			"01" : "{NUL}",
			"02" : "{NUL}",
			"03" : "{NUL}",
			"04" : "{NUL}",
			"05" : "{NUL}",
			"06" : "{NUL}",
			"07" : "{NUL}",
			"08" : "{SKIP1}",
			"09" : "{SKIP1}",
			"0a" : "{SKIP1}",
			"0b" : "{SKIP1}",
			"0c" : "{SKIP1}",
			"0d" : "{SKIP1}",
			"0e" : "{SKIP1}",
			"0f" : "{SKIP1}",
			"10" : "{SKIP2}",
			"11" : "{SKIP2}",
			"12" : "{SKIP2}",
			"13" : "{SKIP2}",
			"14" : "{SKIP2}",
			"15" : "{SKIP2}",
			"16" : "{SKIP2}",
			"17" : "{SKIP2}",
			"18" : "{SKIP3}",
			"19" : "{SKIP3}",
			"1a" : "{SKIP3}",
			"1b" : "{SKIP3}",
			"1c" : "{SKIP3}",
			"1d" : "{SKIP3}",
			"1e" : "{SKIP3}",
			"1f" : "{SKIP3}",
			/* C3 table */
			"80" : "{SKIP4}",
			"81" : "{SKIP4}",
			"82" : "{SKIP4}",
			"83" : "{SKIP4}",
			"84" : "{SKIP4}",
			"85" : "{SKIP4}",
			"86" : "{SKIP4}",
			"87" : "{SKIP4}",
			"88" : "{SKIP5}",
			"89" : "{SKIP5}",
			"8a" : "{SKIP5}",
			"8b" : "{SKIP5}",
			"8c" : "{SKIP5}",
			"8d" : "{SKIP5}",
			"8e" : "{SKIP5}",
			"8f" : "{SKIP5}",
			/* G2 table */
			"20": " ",
			"21": " ",
			"25": "…",
			"2a": "Š",
			"2c": "Œ",
			"30": "█",
			"31": "'",
			"32": "'",
			"33": "“",
			"34": "”",
			"35": "•",
			"39": "™",
			"3a": "š",
			"3c": "œ",
			"3d": "℠",
			"3f": "Ÿ",
			"76": "⅛",
			"77": "⅜",
			"78": "⅝",
			"79": "⅞",
			"7a": "│",
			"7b": "┐",
			"7c": "└",
			"7d": "─",
			"7e": "┘",
			"7f": "♪",
			/* G3 table */
			"a0": "[CC]"
		}	
		
	},
	exportCommands: {
		/* C0 table */
		/*Always clear the buffer whenever a command code is found*/
		"00": "{NUL}", //End of Line
		"03": "{ETX}", //Clear Screen
		"08": "{BS}", //Backspace previous character
		"0c": "{FF}", //Clear Screen
		"0d": "{CR}", //Carriage-Return - go to start of line
		"0e": "{HCR}", //Clear Line and go to start of line
		"10": "{EXT}", //Use secondary/external table lookup
		"11": "{SKIP1}",
		"12": "{SKIP1}",
		"13": "{SKIP1}",
		"14": "{SKIP1}",
		"15": "{SKIP1}",
		"16": "{SKIP1}",
		"17": "{SKIP1}",
		"27": "'",
		"27": "'",
		"1a": "{SKIP1}",
		"1b": "{SKIP1}",
		"22": '"',
		"22": '"',
		"1e": "{SKIP1}",
		"1f": "{SKIP1}",
		/* C1 table */
		"80": "{CW0}", //Set current window
		"81": "{CW1}", //Set current window
		"82": "{CW2}", //Set current window
		"83": "{CW3}", //Set current window
		"84": "{CW4}", //Set current window
		"85": "{CW5}", //Set current window
		"86": "{CW6}", //Set current window
		"87": "{CW7}", //Set current window
		"88": "{CLW}", //Clear Window**
		"89": "{DSW}", //Display the Window!**
		"8a": "{HDW}", //Hide Windows**
		"8b": "{TGW}", //Hides window and displays the next window
		"8c": "{DLW}", //Deletes all windows**
		"8d": "{DLY}", //Delay in 1/10s increments
		"8e": "{DLC}", //Cancel Delay
		"8f": "{RST}", //Reset clears buffers and windows
		"90": "{SPA}", //Set Pen Attributes
		"91": "{SPC}", //Set Pen Color
		"92": "{SPL}", //Set Pen Location
		"97": "{SWA}", //Set Window Attributes
		"98": "{DF0}", //Define Window
		"99": "{DF1}", //Define Window
		"9a": "{9a}", //Define Window
		"9b": "{9b}", //Define Window
		"9c": "{9c}", //Define Window
		"9d": "{9d}", //Define Window
		"9e": "{9e}", //Define Window
		"9f": "{9f}", //Define Window
		/* G0 table */
		"20": " ",
		"21": "!",
		"22": '"',
		"23": "#",
		"24": "$",
		"25": "%",
		"26": "&",
		"27": "'",
		"28": "(",
		"29": ")",
		"2a": "*",
		"2b": "+",
		"2c": ",",
		"2d": "-",
		"2e": ".",
		"2f": "/",
		"30": "0",
		"31": "1",
		"32": '2',
		"33": "3",
		"34": "4",
		"35": "5",
		"36": "6",
		"37": "7",
		"38": "8",
		"39": "9",
		"3a": ":",
		"3b": ";",
		"3c": "<",
		"3d": "=",
		"3e": ">",
		"3f": "?",
		"40": "@",
		"41": "A",
		"42": 'B',
		"43": "C",
		"44": "D",
		"45": "E",
		"46": "F",
		"47": "G",
		"48": "H",
		"49": "I",
		"4a": "J",
		"4b": "K",
		"4c": "L",
		"4d": "M",
		"4e": "N",
		"4f": "O",
		"50": "P",
		"51": "Q",
		"52": 'R',
		"53": "S",
		"54": "T",
		"55": "U",
		"56": "V",
		"57": "W",
		"58": "X",
		"59": "Y",
		"5a": "Z",
		"5b": "[",
		"5c": '\\',
		"5d": "]",
		"5e": "^",
		"5f": "_",
		"60": "'",
		"61": "a",
		"62": 'b',
		"63": "c",
		"64": "d",
		"65": "e",
		"66": "f",
		"67": "g",
		"68": "h",
		"69": "i",
		"6a": "j",
		"6b": "k",
		"6c": "l",
		"6d": "m",
		"6e": "n",
		"6f": "o",
		"70": "p",
		"71": "q",
		"72": 'r',
		"73": "s",
		"74": "t",
		"75": "u",
		"76": "v",
		"77": "w",
		"78": "x",
		"79": "y",
		"7a": "z",
		"7b": "{",
		"7c": "|",
		"7d": "}",
		"7e": "`",
		"7f": "♪",
		/* G1 table */
		"a0": " ",
		"a1": "¡",
		"a2": "￠",
		"a3": "￡",
		"a4": "¤",
		"a5": "￥",
		"a6": "¦",
		"a7": "§",
		"a8": "¨",
		"a9": "©",
		"aa": "ª",
		"ab": "«",
		"ac": "￢",
		"ad": "-",
		"ae": "®",
		"af": "￣",
		"b0": "°",
		"b1": "±",
		"b2": "²",
		"b3": "³",
		"b4": "´",
		"b5": "µ",
		"b6": "¶",
		"b7": "·",
		"b8": "¸",
		"b9": "¹",
		"ba": "º",
		"bb": "»",
		"bc": "¼",
		"bd": "½",
		"be": "¾",
		"bf": "¿",
		"c0": "À",
		"c1": "Á",
		"c2": "Â",
		"c3": "Ã",
		"c4": "Ä",
		"c5": "Å",
		"c6": "Æ",
		"c7": "Ç",
		"c8": "È",
		"c9": "É",
		"ca": "Ê",
		"cb": "Ë",
		"cc": "Ì",
		"cd": "Í",
		"ce": "Î",
		"cf": "Ï",
		"d0": "Ð",
		"d1": "Ñ",
		"d2": "Ò",
		"d3": "Ó",
		"d4": "Ô",
		"d5": "Õ",
		"d6": "Ö",
		"d7": "×",
		"d8": "",
		"d9": "Ù",
		"da": "Ú",
		"db": "Û",
		"dc": "Ü",
		"dd": "Ý",
		"de": "Þ",
		"df": "ß",
		"e0": "à",
		"e1": "á",
		"e2": "â",
		"e3": "ã",
		"e4": "ä",
		"e5": "å",
		"e6": "æ",
		"e7": "ç",
		"e8": "è",
		"e9": "é",
		"ea": "ê",
		"eb": "ë",
		"ec": "ì",
		"ed": "í",
		"ee": "î",
		"ef": "ï",
		"f0": "ð",
		"f1": "ñ",
		"f2": "ò",
		"f3": "ó",
		"f4": "ô",
		"f5": "õ",
		"f6": "ö",
		"f7": "÷",
		"f8": "ø",
		"f9": "ù",
		"fa": "ú",
		"fb": "û",
		"fc": "ü",
		"fd": "ý",
		"fe": "þ",
		"ff": "ÿ",

		EXT: {
			/* C2 table */
			"00": "{NUL}",
			"01": "{NUL}",
			"02": "{NUL}",
			"03": "{NUL}",
			"04": "{NUL}",
			"05": "{NUL}",
			"06": "{NUL}",
			"07": "{NUL}",
			"08": "{SKIP1}",
			"09": "{SKIP1}",
			"0a": "{SKIP1}",
			"0b": "{SKIP1}",
			"0c": "{SKIP1}",
			"0d": "{SKIP1}",
			"0e": "{SKIP1}",
			"0f": "{SKIP1}",
			"10": "{SKIP2}",
			"11": "{SKIP2}",
			"12": "{SKIP2}",
			"13": "{SKIP2}",
			"14": "{SKIP2}",
			"15": "{SKIP2}",
			"16": "{SKIP2}",
			"17": "{SKIP2}",
			"18": "{SKIP3}",
			"19": "{SKIP3}",
			"1a": "{SKIP3}",
			"1b": "{SKIP3}",
			"1c": "{SKIP3}",
			"1d": "{SKIP3}",
			"1e": "{SKIP3}",
			"1f": "{SKIP3}",
			/* C3 table */
			"80": "{SKIP4}",
			"81": "{SKIP4}",
			"82": "{SKIP4}",
			"83": "{SKIP4}",
			"84": "{SKIP4}",
			"85": "{SKIP4}",
			"86": "{SKIP4}",
			"87": "{SKIP4}",
			"88": "{SKIP5}",
			"89": "{SKIP5}",
			"8a": "{SKIP5}",
			"8b": "{SKIP5}",
			"8c": "{SKIP5}",
			"8d": "{SKIP5}",
			"8e": "{SKIP5}",
			"8f": "{SKIP5}",
			/* G2 table */
			"20": " ",
			"21": " ",
			"25": "…",
			"2a": "Š",
			"2c": "Œ",
			"30": "█",
			"31": "'",
			"32": "'",
			"33": "“",
			"34": "”",
			"35": "•",
			"39": "™",
			"3a": "š",
			"3c": "œ",
			"3d": "℠",
			"3f": "Ÿ",
			"76": "⅛",
			"77": "⅜",
			"78": "⅝",
			"79": "⅞",
			"7a": "│",
			"7b": "┐",
			"7c": "└",
			"7d": "─",
			"7e": "┘",
			"7f": "♪",
			/* G3 table */
			"a0": "[CC]"
		}

	},
	windows : {
            0: {
            	incode: "",
            	outcode: "",
            	incodeSec: "",
            	outcodeSec:  "",
            	incodeMs: "",
            	outcodeMs: "",
				style: "Pop-On",
				spcSet: false,
				spaSet: false,
				splSet: false,
				confidence: false,
            	primaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	],
            	secondaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: 0,
            			positionY: 10,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: 0,
            			positionY: 11,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: 0,
            			positionY: 12,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: 0,
            			positionY: 13,
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	]
            },
            1: {
            	incode: "",
            	outcode: "",
            	incodeSec: "",
            	outcodeSec: "",
            	incodeMs: "",
            	outcodeMs: "",
				style: "Pop-On",
				confidence: false,
            	spcSet: false,
            	spaSet: false,
            	splSet: false,
            	primaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	],
            	secondaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: 0,
            			positionY: 10,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: 0,
            			positionY: 11,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: 0,
            			positionY: 12,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: 0,
            			positionY: 13,
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	]
            },
            2: {
            	incode: "",
            	outcode: "",
            	incodeSec: "",
            	outcodeSec: "",
            	incodeMs: "",
            	outcodeMs: "",
				style: "Pop-On",
				confidence: false,
            	spcSet: false,
            	spaSet: false,
            	splSet: false,
            	primaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	],
            	secondaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: 0,
            			positionY: 10,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: 0,
            			positionY: 11,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: 0,
            			positionY: 12,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: 0,
            			positionY: 13,
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	]
            },
            3: {
            	incode: "",
            	outcode: "",
            	incodeSec: "",
            	outcodeSec: "",
            	incodeMs: "",
            	outcodeMs: "",
				style: "Pop-On",
				confidence: false,
            	spcSet: false,
            	spaSet: false,
            	splSet: false,
            	primaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	],
            	secondaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: 0,
            			positionY: 10,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: 0,
            			positionY: 11,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: 0,
            			positionY: 12,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: 0,
            			positionY: 13,
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	]
            },
            4: {
            	incode: "",
            	outcode: "",
            	incodeSec: "",
            	outcodeSec: "",
            	incodeMs: "",
            	outcodeMs: "",
				style: "Pop-On",
				confidence: false,
            	spcSet: false,
            	spaSet: false,
            	splSet: false,
            	primaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	],
            	secondaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: 0,
            			positionY: 10,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: 0,
            			positionY: 11,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: 0,
            			positionY: 12,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: 0,
            			positionY: 13,
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	]
            },
            5: {
            	incode: "",
            	outcode: "",
            	incodeSec: "",
            	outcodeSec: "",
            	incodeMs: "",
            	outcodeMs: "",
				style: "Pop-On",
				confidence: false,
            	spcSet: false,
            	spaSet: false,
            	splSet: false,
            	primaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	],
            	secondaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: 0,
            			positionY: 10,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: 0,
            			positionY: 11,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: 0,
            			positionY: 12,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: 0,
            			positionY: 13,
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	]
            },
            6: {
            	incode: "",
            	outcode: "",
            	incodeSec: "",
            	outcodeSec: "",
            	incodeMs: "",
            	outcodeMs: "",
				style: "Pop-On",
				confidence: false,
            	spcSet: false,
            	spaSet: false,
            	splSet: false,
            	primaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	],
            	secondaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: 0,
            			positionY: 10,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: 0,
            			positionY: 11,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: 0,
            			positionY: 12,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: 0,
            			positionY: 13,
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	]
            },
            7: {
            	incode: "",
            	outcode: "",
            	incodeSec: "",
            	outcodeSec: "",
            	incodeMs: "",
            	outcodeMs: "",
				style: "Pop-On",
				confidence: false,
            	spcSet: false,
            	spaSet: false,
            	splSet: false,
            	primaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: "",
            			positionY: "",
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	],
            	secondaryLang: [{
            			id: 0,
            			captionText: "",
            			positionX: 0,
            			positionY: 10,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 1,
            			captionText: "",
            			positionX: 0,
            			positionY: 11,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 2,
            			captionText: "",
            			positionX: 0,
            			positionY: 12,
            			bold: false,
            			italics: false,
            			underline: false
            		},
            		{
            			id: 3,
            			captionText: "",
            			positionX: 0,
            			positionY: 13,
            			bold: false,
            			italics: false,
            			underline: false
            		}
            	]
            },

        },
	charToByte  :  {
		G : "FA0000",
		H : "FA0000FA0000",
		I : "FA0000FA0000FA0000",
		J : "FA0000FA0000FA0000FA0000",
		K : "FA0000FA0000FA0000FA0000FA0000",
		L : "FA0000FA0000FA0000FA0000FA0000FA0000",
		M : "FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
		N : "FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
		O : "FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
		P : "FB8080",
		Q : "FC8080",
		R : "FD8080",
		S : "9669",
		T : "6101",
		U : "E10000",
		Z : "00"
	},
	frameRateMap : {
		"1" : "24000/1001",
		"2"	: "24",
		"3"	: "25", 
		"4"	: "30000/1001", 
		"5" : 30, 
		"6"	: 50,
		"7"	: "60000 / 1001",
		"8"	: 60
	},
	frameRateInfoMap: {
		"29.97" : {
			mccValue: "30DF",
			hexValue : "4",
			cc_count : 20,
			"max608Bytes" : 4,
			"max708Bytes": 36
		}, 
		"59.94": {
			mccValue: "60DF",
			hexValue: "7",
			cc_count: 10,
			"max608Bytes": 2,
			"max708Bytes": 18
		},
		"23.976": {
			mccValue: "24",
			hexValue: "1",
			cc_count: 25,
			"max608Bytes": 4,
			"max708Bytes": 44
		},
		"24": {
			mccValue: "24",
			hexValue: "2",
			cc_count: 25,
			"max608Bytes": 4,
			"max708Bytes": 44
		},
		"25": {
			mccValue: "25",
			hexValue: "3",
			cc_count: 24,
			"max608Bytes": 4,
			"max708Bytes": 44
		},
		"30": {
			mccValue: "30",
			hexValue: "5",
			cc_count: 20,
			"max608Bytes": 4,
			"max708Bytes": 36
		},
		"50": {
			mccValue: "50",
			hexValue: "6",
			cc_count: 12,
			"max608Bytes": 2,
			"max708Bytes": 22
		},
		"60": {
			mccValue: "60",
			hexValue: "8",
			cc_count: 10,
			"max608Bytes": 2,
			"max708Bytes": 18
		},
	},
	mccHeader : "File Format=MacCaption_MCC V2.0\n\n",
	mccAnc : "\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\n\/\/ Computer Prompting and Captioning Company\n\/\/ Ancillary Data Packet Transfer File\n\/\/\n\/\/ Permission to generate this format is granted provided that\n\/\/   1. This ANC Transfer file format is used on an as-is basis and no warranty is given, and\n\/\/   2. This entire descriptive information text is included in a generated .mcc file.\n\/\/\n\/\/ General file format:\n\/\/   HH:MM:SS:FF(tab)[Hexadecimal ANC data in groups of 2 characters]\n\/\/     Hexadecimal data starts with the Ancillary Data Packet DID (Data ID defined in S291M)\n\/\/       and concludes with the Check Sum following the User Data Words.\n\/\/     Each time code line must contain at most one complete ancillary data packet.\n\/\/     To transfer additional ANC Data successive lines may contain identical time code.\n\/\/     Time Code Rate=[24, 25, 30, 30DF, 50, 60, 60DF]\n\/\/\n\/\/   ANC data bytes may be represented by one ASCII character according to the following schema:\n\/\/     G  FAh 00h 00h\n\/\/     H  2 x (FAh 00h 00h)\n\/\/     I  3 x (FAh 00h 00h)\n\/\/     J  4 x (FAh 00h 00h)\n\/\/     K  5 x (FAh 00h 00h)\n\/\/     L  6 x (FAh 00h 00h)\n\/\/     M  7 x (FAh 00h 00h)\n\/\/     N  8 x (FAh 00h 00h)\n\/\/     O  9 x (FAh 00h 00h)\n\/\/     P  FBh 80h 80h\n\/\/     Q  FCh 80h 80h\n\/\/     R  FDh 80h 80h\n\/\/     S  96h 69h\n\/\/     T  61h 01h\n\/\/     U  E1h 00h 00h 00h\n\/\/     Z  00h\n\/\/\n\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\n\n",
	mccUuid : "UUID=",
	mccCreation : "Creation Program=Closed Caption Creator ",
	mccDate : "Creation Date=",
	mccTime : "Creation Time=",
	mccTc   : "Time Code Rate="
}