<template>
  <div id="loginForm">
    <div id="loginFormWrapper">
      <div class="col-md-4 offset-md-4 align-self-center" id="loginContent">
        <div id="loginContentWrapper" >
          <div class="text-center mb-4">
            <img src="@/assets/img/favicon.png" width="120px">
          </div>
          
          <h5>Welcome to Closed Caption Creator</h5>
          <p>Please login to begin</p>
          <hr class="loginHr"/>
          <div id="firebaseui-auth-container"></div>
          <hr/>
          <br/>
          <p class="small text-muted">support@closedcaptioncreator.com</p>
        </div>
        
      </div>      
    </div>
  </div>
</template>

<script>
  import firebase from '@/firebase/config'
  import * as firebaseui from 'firebaseui'
  export default {
    name: 'Login',
    mounted() {      
      let self = this;
      if (this.$route.query.prevPath){
        var redirectPath = this.$route.query.prevPath;
      } else {
        var redirectPath = "/";
      }

      let uiConfig = {
        'credentialHelper': firebaseui.auth.CredentialHelper.NONE,
        signInOptions: [{
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false
        }],
        callbacks: {
          signInSuccessWithAuthResult() {
            localStorage.setItem('authenticated', true)
            window.location.href = redirectPath
          }
        },

      }
      let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
      ui.start("#firebaseui-auth-container", uiConfig);
      setTimeout(function(){
        Chargebee.registerAgain();
      }, 500);
    }
  }
</script>
<style>
  

  #loginContent {
    padding-top: 25vh;
    padding: 0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .firebaseui-title {
    font-size: 18px;
  }

  #loginForm {
    padding-top:20vh;
    width: 100vw;
    height: 100vh;
    background-color: #145251;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23145251'/%3E%3Cstop offset='1' stop-color='%23228f7d'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%2336998a' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%2336998a' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%2336998a' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%2336998a' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
  }

  #loginContentWrapper {
    background-color: rgb(32, 32, 32);
    padding: 5%;
  }

  .loginHr {
    background-color: #ddd;
  }

  .firebaseui-button {
    border-radius: 91px 91px 91px 91px;
    -moz-border-radius: 91px 91px 91px 91px;
    -webkit-border-radius: 91px 91px 91px 91px;
    border: 1px solid #1eaca9;
    background-color: #1d8f8d;
    color: #efefef;
    font-size: 1.5em;
    width: 100%;
    margin-top: 1%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .firebaseui-text-input-error {
    font-size: 1.0em;
    color: #bd2c2c;
    font-weight: bold;
    padding: 1%;
  }

  .firebaseui-link {
    color: #bd2c2c;
    font-weight: bold;
    padding: 1%;
  }

  .firebaseui-link:hover {
    color: #e21717;
    font-weight: bold;
    
  }

  .firebaseui-input {
    border-radius: 5px;
    margin-left: 1%;
    padding: 1%;
    min-width: 100%;
    border: none;
  }

  .firebaseui-input-invalid {
    border-radius: 5px;
    margin-left: 1%;
    padding: 1%;
    min-width: 100%;
    border: none;
  }

  .firebaseui-id-password {
    border-radius: 5px;
    margin-left: 1%;
    padding: 1%;
    min-width: 100%;
    border: none;
  }
</style>