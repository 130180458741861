<template>
	<div id="captionPreviewWrapperMobile">
		<p class="captionPreviewMobile text-center w-100" :class="{'d-none' : captionEventText.length===0}" v-html="captionEventText"></p>
	</div>
</template>

<script>
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name: 'CaptionPreviewMobile',
		computed: {
			captionEventText: function () {
				if (this.$store.state.editor.player.captionPreview.previewPlay && this.$store.state.editor.player.status == 'playing') {
					var currentTimeSec = this.$store.state.editor.currentTimeSec;

					function getCurrentCaptionLine(captionData) {
						if (captionData) {							
							return (captionData.incodeSec) <= currentTimeSec && (captionData.outcodeSec) >=
								currentTimeSec;
						}
					}

					var captionEvent = this.$store.state.editor.captions.filter(getCurrentCaptionLine)[0];

					if (captionEvent){
						if (this.$store.state.editor.player.captionPreview.captionLock){
							var eventId = this.$store.state.editor.captions.map(function(x) {return x.uniqueId; }).indexOf(captionEvent.uniqueId);
							this.$store.commit("selectCaptionEvent", eventId);
							this.$store.commit('focusCaptionEvent', eventId);
						}
						return globalFunc.concatCaptionText(captionEvent.primaryLang).replace(/(?:\n)/g, '<br>');
					} else {
						return [];
					}
					

				} else {
					if (this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent]){						
						return globalFunc.concatCaptionText(this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent].primaryLang).replace(/(?:\n)/g, '<br>');
					} else {
						return globalFunc.concatCaptionText(this.$store.state.editor.captions[0].primaryLang).replace(/(?:\n)/g, '<br>');
					}					 
				}
			}
		}
	}
</script>
<style scoped>
#captionPreviewWrapperMobile {
	z-index: 1000;
	position: absolute;
	bottom: 0;
	width: 80%;
	height: 25%;
	margin: 5.7% 0 0 13.7%;
	overflow: hidden;
}
.captionPreviewMobile {
	background-color: #000;
	display: inline-block;
	padding: 0.75% 0% 0.75% 0.6%;
	position: fixed;
	bottom: 15%;
	font-size: 1.2vw;
	position: absolute;
	font-family: "Courier New", Courier, monospace;
}

</style>