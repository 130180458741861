<template>
<div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Speaker Id</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>

            <tr v-for="(speaker, index) in speakers" :key="index">
                <td>{{speaker}}</td>
                <td><button class="btn btn-outline-danger btn-sm" @click="removeSpeaker(speaker)"><i class="fas fa-minus"></i> Remove</button></td>
            </tr>

        </tbody>
    </table>
    <hr />
    <form v-on:submit.prevent="addSpeaker">
        <div class="form-group">
            <label>Add Speaker</label>
            <input type="text" v-model="speakerName" class="form-control" placeholder="Speaker Name">
        </div>
        <button type="button" class="btn btn-success" :disabled="speakerName.length===0" @click="addSpeaker"><i class="fas fa-plus"></i> Create</button>
        <button type="button" class="btn btn-outline-danger ml-2" v-if="speakers.length>0" @click="removeAll"><i class="fas fa-minus"></i> Remove All</button>

    </form>
</div>
</template>

<script>
export default {
    name: 'Metadata',
    data() {
        return {
            speakerName: ""
        }
    },
    computed: {
        speakers: function () {
            return this.$store.state.editor.speakers;
        }
    },
    methods: {
        removeAll: function () {
            this.$store.commit('removeAllSpeakers');
        },
        removeSpeaker: function (speakerName) {
            this.$store.commit('removeSpeaker', speakerName);
        },
        addSpeaker: function () {
            this.$store.commit('addSpeaker', this.speakerName);
            this.speakerName = "";
        }
    }
}
</script>
