<template>
<LrgModalTemplate Title="Automatic Captioning" ModalId="AutomaticCaptioningModal">
    <form v-on:submit.prevent="autoTranscribe">
        <div class="form-group">
            <label for="Language">Original Language <i class="fas fa-info-circle" title="The original source language that will be transcribed to text"></i></label>
            <select class="form-control" v-model="language" :disabled="status.show">
                <option v-for="(language, index) in languages" :key="index">{{language[0]}}</option>
            </select>
        </div>
        <div class="form-group" v-if="countries">
            <label for="Language">Dialect</label>
            <select class="form-control" v-model="dialect" :disabled="status.show">
                <option v-for="(country, index) in countries" :key="index">{{country[1]}}</option>
            </select>
        </div>
        <div class="form-group" v-if="language === 'English'">
            <label>Phrase Hints <i class="fas fa-info-circle" title="Comma seperated list of phrases/words used to improve the quality of the transcription results."></i></label>
            <textarea class="form-control" v-model="phrases" rows="3" :disabled="status.show" placeholder="e.g. weather, the fire was hot, Patrick Stewart"></textarea>
        </div>
        <button type="button" class="btn btn-primary" @click="autoTranscribe" :disabled="status.show"><i class="far fa-check-square"></i> Start</button>

        <div v-show="status.show" class='row p-2 mt-4'>
            <div class="col-10">
                <p>Audio Extraction: {{status.extractAudio.status}} ({{status.extractAudio.progress}}) <i v-if="status.extractAudio.status==='Complete'" class="fas fa-check text-success"></i></p>
                <p :class="{'text-muted':status.upload.status==='-'}">File Upload: {{status.upload.status}} ({{status.upload.progress}}%) <i v-if="status.upload.status==='Complete'" class="fas fa-check text-success"></i></p>
                <p :class="{'text-muted':status.transcribe.status==='-'}">Transcribing: {{status.transcribe.status}} ({{status.transcribe.progress}}%) (Id: {{status.transcribe.jobId}}) <i class="fas fa-info-circle" title="The Id is a reference to the Job Id - please provide this number to support in the case of a failure"></i></p>
            </div>
            <div class="col">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>

    </form>
</LrgModalTemplate>
</template>

<script>
import LrgModalTemplate from './LrgModalTemplate'
import supportedLanguages from '@/assets/js/transcriptionLanguages.js'
import firebase from '@/firebase/config'
import storage from '@/firebase/storage'
const axios = require('axios');
export default {
    name: 'AutomaticCaptioning',
    data() {
        return {
            speakers: 2,
            languages: supportedLanguages.languages,
            language: "English",
            dialect: "United States",
            phrases : "",
            status: {
                show: false,
                extractAudio: {
                    progress: 0,
                    status: "In Progress"
                },
                upload: {
                    progress: 0,
                    status: "-"
                },
                transcribe: {
                    jobId: "",
                    progress: 0,
                    status: "-"
                }
            }
        }
    },
    computed: {
        countries: function () {
            var languages = JSON.parse(JSON.stringify(this.languages))
            var selectedLang = this.language;
            var languageList = languages.filter(lang => {
                return lang[0] === selectedLang;
            });

            if (languageList[0].length > 2) {
                languageList[0].shift();
                this.dialect = languageList[0][0][1];
                return languageList[0];
            } else {
                return false;
            }
        }
    },
    components: {
        LrgModalTemplate
    },
    methods: {
        autoTranscribe: function () {
            try {
                /* 1. Extract Audio */
                this.status.show = true;
                const ffmpegPath = require('electron').remote.getGlobal('ffmpegpath');
                const fs = require("fs");
                console.log(ffmpegPath);
                const spawn = require('child_process').spawn;
                var self = this;
                var userId = firebase.auth().currentUser.uid;
                var videoFilePath = this.$store.state.editor.projectSettings.mediaInfo.path;
                var audioFilePath = videoFilePath.substr(0, videoFilePath.lastIndexOf(".")) + ".wav";
                var audioFile = audioFilePath.split('\\').pop().split('/').pop();
                console.log(audioFilePath);

                /* Determine Language Code */
                for (var i = 0; i < this.languages.length; i++) {
                    if (this.languages[i][0] === this.language) {
                        if (this.languages[i].length === 2) {
                            var languageCode = this.languages[i][1].join("");
                            break;
                        } else {
                            for (var country = 1; country < this.languages[i].length; country++) {
                                if (this.languages[i][country][1] === this.dialect) {
                                    var languageCode = this.languages[i][country][0];
                                    break;
                                }
                            }
                            break;
                        }
                    }
                }

                var hints = this.phrases.split(",");

                //console.log(languageCode);
                var args = [
                    "-y",
                    "-i",
                    videoFilePath,
                    "-ac",
                    "1",
                    "-acodec", 
                    "pcm_mulaw",
                    "-ar",
                    "44100",
                    audioFilePath
                ];

                var proc = spawn(ffmpegPath, args);
                proc.stdout.on('data', function (data) {
                    var ffmpegLog = data.toString().replace(/= +/g, "=").split(" ").filter(logEntry => {
                        return logEntry != ""
                    });

                    if (ffmpegLog[0].indexOf("frame=") > -1) {
                        self.status.extractAudio.progress = ffmpegLog[0];
                    }
                });

                proc.on('close', function () {
                    console.log("Extraction complete");
                    self.status.extractAudio.status = "Complete";
                    self.status.extractAudio.progress = "100%";
                    self.status.upload.status = "In Progress";
                    self.status.upload.progress = "0";

                    var cloudPath = "users/" + userId + "/audio/" + audioFile;
                    var storageRef = storage.ref().child(cloudPath);
                    var fileExistsRef = storage.ref().child(cloudPath);

                    fileExistsRef.getDownloadURL().then(function (url) {
                        console.log("File already uploaded");
                        self.status.upload.status = "Complete";
                        self.status.upload.progress = "100";
                        self.status.transcribe.status = "Submitting Job...";
                        self.status.transcribe.progress = "0";
                        setTimeout(function () {
                            console.log("Submitting transcription job");
                            firebase.functions().httpsCallable('automaticTranscribeNew')({
                                filePath: cloudPath,
                                speakers: self.speakers,
                                language: languageCode,
                                phrases : hints
                            }).then(res => {
                                //console.log(res.data)
                                var jobId = res.data;
                                self.status.transcribe.jobId = jobId;
                                console.log("Job Id: "+ jobId);
                                self.status.transcribe.status = "In Progress";
                                var apiKey = "AIzaSyDAfurqIfhEalK8tYtVe19H2HZBZexwnUE";
                                var url = 'https://speech.googleapis.com/v1/operations/' + jobId + '?key=' + apiKey;
                                var jobComplete = false;
                                //console.log("URL: " + url);
                                var updateJobStatus = setInterval(function () {
                                    axios.get(url).then(response => {                                        
                                        var done = response.data.done;
                                        var progressPercent = parseInt(response.data.metadata.progressPercent);

                                        if (isNaN(progressPercent)) {
                                            progressPercent = 0;
                                        }

                                        if (progressPercent == 100 && done == true) {
                                            clearInterval(updateJobStatus);
                                            var results = JSON.stringify(response.data.response.results);
                                            if(!results){                                                
                                                self.status = {
                                                    show: false,
                                                    extractAudio: {
                                                        progress: 0,
                                                        status: "In Progress"
                                                    },
                                                    upload: {
                                                        progress: 0,
                                                        status: "-"
                                                    },
                                                    transcribe: {
                                                        jobId: "",
                                                        progress: 0,
                                                        status: "-"
                                                    }
                                                }
                                                self.$notify({
                                                    title: 'Automatic Captioning',
                                                    type: 'error',
                                                    text: 'Automatic caption job failed. No data was returned'
                                                });
                                                throw new Error ("Caption Extraction Failed");
                                            }
                                            self.$store.commit('updateAssetTranscriptionText', results);
                                            $('#AutomaticCaptioningModal').modal('toggle');
                                            $('#ImportTranscriptModal').modal('toggle');
                                            self.status = {
                                                show: false,
                                                extractAudio: {
                                                    progress: 0,
                                                    status: "In Progress"
                                                },
                                                upload: {
                                                    progress: 0,
                                                    status: "-"
                                                },
                                                transcribe: {
                                                    jobId: "",
                                                    progress: 0,
                                                    status: "-"
                                                }
                                            }

                                            self.$notify({
                                                title: 'Automatic Captioning',
                                                type: 'success',
                                                text: 'Automatic caption job is complete'
                                            });
                                        } else {
                                            self.status.transcribe.progress = progressPercent;
                                            //console.log("Updating job progress: " + progressPercent);
                                        }
                                    });
                                }, 5000);
                            });
                        }, 5000);
                    }).catch(function (err) {
                        console.log("File does not exist...uploading...");
                        // app://./Users/cccreator/Downloads/Tigercat%20WFB%20Financing%20Final%20ProRes%204444%20Vimeo.wav

                        //Solution from: https://stackoverflow.com/questions/5933565/how-to-create-initialize-the-file-object-using-file-path-html5
                        var getFileBlob = function (url, cb) {
                            var contents = fs.readFileSync(url)
                            cb(new Blob([contents]));  
                        };

                        var blobToFile = function (blob, name) {
                            console.log(blob.size);
                            blob.lastModifiedDate = new Date();
                            blob.name = name;
                            return blob;
                        };

                        var getFileObject = function (filePathOrUrl, cb) {
                            filePathOrUrl = filePathOrUrl.replace('app://.', "");
                            getFileBlob(filePathOrUrl, function (blob) {
                                cb(blobToFile(blob, 'audio.wav'));
                            });
                        };

                        getFileObject(audioFilePath, function (fileObject) {
                            var uploadTask = storageRef.put(fileObject);
                            uploadTask.on('state_changed', function progress(snapshot) {
                                if (snapshot.bytesTransferred === snapshot.totalBytes) {
                                    self.status.upload.status = "Complete";
                                    self.status.upload.progress = "100";
                                    self.status.transcribe.status = "Submitting Job...";
                                    self.status.transcribe.progress = "0";
                                    setTimeout(function () {
                                        console.log("Submitting transcribe job...");
                                        firebase.functions().httpsCallable('automaticTranscribeNew')({
                                            filePath: cloudPath,
                                            speakers: self.speakers,
                                            language: languageCode,
                                            phrases : hints
                                        }).then(res => {
                                            //console.log(res.data)
                                            var jobId = res.data;
                                            self.status.transcribe.jobId = jobId;
                                            self.status.transcribe.status = "In Progress";
                                            var apiKey = "AIzaSyDAfurqIfhEalK8tYtVe19H2HZBZexwnUE";
                                            var url = 'https://speech.googleapis.com/v1/operations/' + jobId + '?key=' + apiKey;
                                            var jobComplete = false;
                                            //console.log("URL: " + url);
                                            var updateJobStatus = setInterval(function () {
                                                axios.get(url).then(response => {
                                                    //console.log(response.data);
                                                    var done = response.data.done;
                                                    var progressPercent = parseInt(response.data.metadata.progressPercent);

                                                    if (isNaN(progressPercent)) {
                                                        progressPercent = 0;
                                                    }

                                                    if (progressPercent == 100 && done == true) {
                                                        clearInterval(updateJobStatus);
                                                        var results = JSON.stringify(response.data.response.results);
                                                        if(!results){                                                
                                                            self.status = {
                                                                show: false,
                                                                extractAudio: {
                                                                    progress: 0,
                                                                    status: "In Progress"
                                                                },
                                                                upload: {
                                                                    progress: 0,
                                                                    status: "-"
                                                                },
                                                                transcribe: {
                                                                    jobId: "",
                                                                    progress: 0,
                                                                    status: "-"
                                                                }
                                                            }
                                                            self.$notify({
                                                                title: 'Automatic Captioning',
                                                                type: 'error',
                                                                text: 'Automatic caption job failed. No data was returned'
                                                            });
                                                            throw new Error ("Caption Extraction Failed");
                                                        }
                                                        self.$store.commit('updateAssetTranscriptionText', results);
                                                        $('#AutomaticCaptioningModal').modal('toggle');
                                                        $('#ImportTranscriptModal').modal('toggle');
                                                        self.status = {
                                                            show: false,
                                                            extractAudio: {
                                                                progress: 0,
                                                                status: "In Progress"
                                                            },
                                                            upload: {
                                                                progress: 0,
                                                                status: "-"
                                                            },
                                                            transcribe: {
                                                                jobId: "",
                                                                progress: 0,
                                                                status: "-"
                                                            }
                                                        }

                                                        self.$notify({
                                                            title: 'Automatic Captioning',
                                                            type: 'success',
                                                            text: 'Automatic caption job is complete'
                                                        });
                                                    } else {
                                                        self.status.transcribe.progress = progressPercent;
                                                        //console.log("Updating job progress: " + progressPercent);
                                                    }

                                                });
                                            }, 5000);
                                        });
                                    }, 5000);
                                } else {
                                    var uploadProgress = Math.round((snapshot.bytesTransferred / snapshot
                                        .totalBytes) * 100);
                                    self.status.upload.progress = uploadProgress;
                                }
                            });
                        });
                    });
                });
            } catch(e){
                console.log(e.message);
                this.status = {
                    show: false,
                    extractAudio: {
                        progress: 0,
                        status: "In Progress"
                    },
                    upload: {
                        progress: 0,
                        status: "-"
                    },
                    transcribe: {
                        jobId: "",
                        progress: 0,
                        status: "-"
                    }
                }
                this.$notify({
                    title: 'Automatic Captioning',
                    type: 'error',
                    text: 'Automatic caption job failed. ' +e.message
                });
                throw new Error ("Caption Extraction Failed");
            }
        }
    }
}
</script>
<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>