<template>
    <div id="previewControls" class="col-3">
        <div class="row">
            <button class="btn btn-outline-secondary btn-block mr-3" @click="enableCaptionLock" :class="{active : this.$store.state.editor.player.captionPreview.captionLock}" title="Captions Lock To Video"><i class="far fa-closed-captioning"></i><i class="fas fa-lock"></i></button>
        </div>
        <div class="row">
            <button class="btn btn-outline-secondary mb-2 mt-2 mr-3 btn-block" @click="enableVideoLock" :class="{active : this.$store.state.editor.player.captionPreview.videoLock}" title="Video Lock To Captions"><i class="fas fa-film"></i><i class="fas fa-lock"></i></button>
        </div>
        <div class="row">
            <button class="btn btn-outline-secondary mb-2 mr-3 btn-block" @click="enablePreviewPlay" :class="{active : this.$store.state.editor.player.captionPreview.previewPlay}" title="Preview Play"><i class="fas fa-play"></i>/<i class="far fa-eye"></i></button>
        </div>
        
        <div class="row" v-if="aspectRatio != '9:16'">
            <div class="col-1">
                <label class="col-form-label">LN#</label>
            </div>
            <div class="col mb-2 mt-2" >
                <input class="form-control" type="number" min="1" :max="captionLength" v-model.lazy="selectedLine">
            </div>
        </div>        
    </div>
</template>
<script>
export default {
    name: "PreviewControls",
    methods : {
        enableCaptionLock : function(){
            this.$store.commit("updateLockOption", 'captionLock');
        },

        enableVideoLock : function(){
            this.$store.commit("updateLockOption", 'videoLock');
        },

        enablePreviewPlay : function(){
            this.$store.commit("updateLockOption", 'previewPlay');
        }
    },

    computed : {
        aspectRatio : function(){
            return this.$store.state.editor.player.aspectRatio;
        },
        captionLength : function(){
            return this.$store.state.editor.captions.length
        },
        selectedLine : {
            get : function(){
                return this.$store.state.editor.selectedCaptionEvent+1
            },
            set : function(lineNumber){
                lineNumber--;
                if (lineNumber < this.$store.state.editor.captions.length){
                    this.$store.commit('selectCaptionEvent', lineNumber);
                    this.$store.commit('focusCaptionEvent', lineNumber);
                } else {
                    this.$store.commit('selectCaptionEvent', this.$store.state.editor.captions.length-1);
                    this.$store.commit('focusCaptionEvent', this.$store.state.editor.captions.length-1);
                }                     
            }
        }
    }
}
</script>
<style>
    .btn-outline-secondary.active:hover {
        color: #fff;
        background-color: #7e7e7e !important;
        border-color: #3f3f3f; /*set the color you want here*/
    }

    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #1a1a1a !important;
        border-color: #000000; /*set the color you want here*/
    }
</style>