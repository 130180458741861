<template>
	<ModalTemplate Title="Bug Report" ModalId="BugReport">
		<p>
			Please submit detailed bug reports of any issues you may experience. For caption import/export issues we recommend e-mailing us your project or caption file(s) to bugs@closedcaptioncreator.com
		</p>
		
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	export default {
		name : 'BugReport',
		data () {
			return {
				msg: 'BugReport Mounted'
			}
		},
		components: {
			ModalTemplate
		}
	}
</script>