const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");
module.exports = {
    decode: function (fileContents, options) {
        let events = [];
        let event = false;
        let fileLines = fileContents.split("\n");
        fileLines.forEach(fileLine => {
            if (fileLine.split(",").length === 2 && /\d\d:\d\d:\d\d\.\d\d/.test(fileLine.split(",")[0]) && /\d\d:\d\d:\d\d\.\d\d/.test(fileLine.split(",")[1])) {
                if (event) {
                    event.syncSubtitleText();
                    event.alignTextCenter();
                    events.push(event);
                }

                event = new ccEvent();;

                event.start = timecodeLib.tcToSec(fileLine.split(",")[0].replace(".", ":"), options.frameRate);

                event.end = timecodeLib.tcToSec(fileLine.split(",")[1].replace(".", ":"), options.frameRate);

            } else if (event){
                fileLine.split("[br]").forEach(subtitleLine =>{
                    event.insertLine({
                        text : subtitleLine.trim(),
                        posX : 0,
                        posY : 0,
                        bold : false,
                        italics : false,
                        underline : false
                    });
                });                
            }
        });

        event.syncSubtitleText();
        event.alignTextCenter();
        events.push(event);
        return events;
    },

    encode: function (events, options) {
        let fileContents = "";
        events.forEach(event=>{
            let tcIn = timecodeLib.secToTc(event.start, options.frameRate);

            let tcOut = timecodeLib.secToTc(event.end, options.frameRate);


            fileContents += tcIn.substring(0,8) + "." + tcIn.substring(9);
            fileContents+= ",";
            fileContents += tcOut.substring(0,8) + "." + tcIn.substring(9);
            fileContents+= "\n";

            event.lines.forEach(line =>{
                fileContents += line.text + "[br]";
            });

            //Remove last [br]
            fileContents = fileContents.slice(0, -4);
            fileContents += "\n";
        });
        return fileContents.trim();
    },

    preProcess: {
        encode: function (events) {
            /* All */
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode: function (fileContents) {
            /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    }

}
