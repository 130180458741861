import {v1 as uuidv1} from 'uuid'
const smpteTc = require('smpte-timecode')
const _Timecode = require('timecode')
const moment = require('moment')
const jsonXml = require('jsontoxml')
const jsonPath = require('jspath')
const eol = require('eol')
const entities = require("entities");
const xmlEntities = require("xml-entities");
const ccFunc = require('./ccFunc')
const sccLookup = require('./formats/scc')
const mccLookup = require('./formats/mcc')
const stlLookup = require('./formats/stl')
const pacLookup = require('./formats/pac')
const xmlLookup = require('./formats/xml')
const ttmlLookup = require('./formats/ttml')

export default {
    csv: function (captions, frameRate, dropFrame) {
        var captionData = "Incode,Outcode,IncodeSec,OutcodeSec,Text\n";
        for (var i = 0; i < captions.length; i++) {
            captionData += captions[i].incode + ",";
            captionData += captions[i].outcode + ",";
            captionData += captions[i].incodeSec + ",";
            captionData += captions[i].outcodeSec + ",";
            var captionText = ccFunc.concatCaptionText(captions[i].primaryLang);

            var parser = new DOMParser;
            var dom = parser.parseFromString(
                '<!doctype html><body>' + captionText,
                'text/html');
            var captionText = dom.body.textContent;
            captionData += '"' + captionText.replace(/"+/gmi, '""') + "\"\n";
        }
        return captionData;
    },
    srt: function (captions, frameRate, dropFrame) {
        var captionData = "";
        for (var i = 0; i < captions.length; i++) {
            try {
                captionData += (i + 1) + "\n";
                captionData += captions[i].incodeMs.replace(".", ",") + " --> " + captions[i].outcodeMs.replace(".", ",") + "\n";
                captionData += ccFunc.concatCaptionText(captions[i].primaryLang);
                captionData += "\n\n";
            } catch (e) {
                console.log(e.message);
                console.log(i);
                throw new error(e.message + ": error at index " + i);
            }
        }
        return captionData.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
    },
    vtt: function (captions, frameRate, dropFrame, metadata, webVttOptions) {
        var captionData = "WEBVTT\n\n";
        if (metadata && metadata.length > 0 && webVttOptions && webVttOptions.encodeMetadata) {
            captionData += "NOTE\n";
            for (var q = 0; q < metadata.length; q++) {
                captionData += metadata[q].key + ":" + metadata[q].value + "\n";
            }

            captionData += "\n";
        }
        for (var i = 0; i < captions.length; i++) {
            captionData += (i + 1) + "\n";
            captionData += captions[i].incodeMs + " --> " + captions[i].outcodeMs;

            if (webVttOptions && webVttOptions.encodeMetadata && webVttOptions.encodePosition) {
                var line = Math.round(((parseInt(captions[i].primaryLang[0].positionY) / 15) * 100) * 100) / 100 + "%";
                var positionCol = Math.round(((parseInt(captions[i].primaryLang[0].positionX) / 32) * 100) * 100) / 100 + "%";
                captionData += " align:start line:" + line + " position:" + positionCol;
            }

            captionData += "\n";
            captionData += ccFunc.concatCaptionText(captions[i].primaryLang);
            captionData += "\n\n";
        }
        return captionData.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
    },
    sbv: function (captions, frameRate, dropFrame) {
        var captionData = "";
        for (var i = 0; i < captions.length; i++) {
            captionData += captions[i].incodeMs + "," + captions[i].outcodeMs + "\n";
            captionData += ccFunc.concatCaptionText(captions[i].primaryLang);
            captionData += "\n\n";
        }
        return captionData.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
    },
    txt: function (captions, frameRate, dropFrame) {
        var captionData = "@ This file written with Closed Caption Converter (www.closedcaptionconverter.com)\n" + "<begin subtitles>\n";
        for (var i = 0; i < captions.length; i++) {
            if (captions[i].primaryLang[0].captionText.length > 0) {
                captionData += captions[i].incode + " " + captions[i].outcode + "\n";
                captionData += ccFunc.concatCaptionText(captions[i].primaryLang);
                captionData += "\n\n";
            }
        }
        captionData += "<end subtitles>";
        return eol.crlf(captionData.replace(new RegExp('(\n){3,}', 'gim'), "\n\n"));
    },
    stl: function (captions, frameRate, dropFrame, metadata, webVttOptions, useMsTimecode, incode) {
        var captionData = "";
        var dfc = stlLookup.dfcMapping[frameRate];
        if (!dfc) {
            throw new Error("STL export only supports framerates 25, and 30. Technical spec can be found here https://tech.ebu.ch/docs/tech/tech3264.pdf");
        } else {
            var hexTotalCaptionLines = "";
            captions.length.toString().split('').forEach(function (val) {
                hexTotalCaptionLines += "3" + val;
            });

            hexTotalCaptionLines = ccFunc.pad("0000000000", hexTotalCaptionLines, true).replace(/00/g, "30").replace(/300/g, "303");

            var hexIncode = stlLookup.calcHeaderTc(incode);
            captionData += stlLookup.GSI(dfc, hexTotalCaptionLines, hexIncode);

        }
        for (var i = 0; i < captions.length; i++) {
            if (captions[i].captionText != "") {
                //console.log(captions[i].captionText);
                var ttiBlock = stlLookup.encodeCaptionLine(i, captions[i].incode, captions[i].outcode, captions[i].primaryLang);
                captionData += ttiBlock;
                //console.log(ttiBlock);
            }
        }

        return captionData.toLowerCase();
    },
    xml: function (captions, frameRate, dropFrame) {
        var jsonCaptionData = [];
        var dropMode = xmlLookup.dropModeMapping[frameRate];
        var ttmlFrameRate = xmlLookup.frameRateMapping[frameRate];
        var frameRateMultiplier = xmlLookup.frameRateMultiplierMap[frameRate];
        for (var i = 0; i < captions.length; i++) {
            var incode = captions[i].incodeMs;
            var outcode = captions[i].outcodeMs;
            var captionLines = ccFunc.concatCaptionText(captions[i].primaryLang).trim().split("\n");

            for (var j = 0; j < captionLines.length; j++) {
                var origin = "auto";
                var newCaptionLine = xmlLookup.newCaptionLine(incode, outcode, origin, captionLines[j])
                jsonCaptionData.push(newCaptionLine);
            }
        }
        var captionData = xmlLookup.captionData(dropMode, ttmlFrameRate, frameRateMultiplier, jsonCaptionData);
        return (jsonXml(captionData, xmlLookup.jsonOption));
    },
    ttml: function (captions, frameRate, dropFrame, metadata, webVttOptions, msTimecode) {
        var jsonCaptionData = [];
        var dropMode = ttmlLookup.dropModeMapping[frameRate];
        var ttmlFrameRate = ttmlLookup.frameRateMapping[frameRate];
        var frameRateMultiplier = ttmlLookup.frameRateMultiplierMap[frameRate];
        for (var i = 0; i < captions.length; i++) {
            if (msTimecode){
                var incode = captions[i].incodeMs;
                var outcode = captions[i].outcodeMs;
            } else{
                var incode = captions[i].incode;
                var outcode = captions[i].outcode;
            }
            
            var captionLines = captions[i].primaryLang.filter(captionLine => {
                return captionLine.captionText.length > 0;
            })

            for (var j = 0; j < captionLines.length; j++) {
                var positionX = (captionLines[j].positionX / 32 * 100).toFixed(2) + "%";
                var positionY = (captionLines[j].positionY / 15 * 100).toFixed(2) + "%";
                var origin = positionX + " " + positionY;
                if (captions[i].style == "Paint-On") {
                    var region = "paint" + (j + 1);
                } else if (captions[i].style == "Roll-Up") {
                    var region = "rollup2";
                } else {
                    var region = 'pop' + (j + 1);
                }

                var captionText = captionLines[j].captionText.trim();
                var captionLength = captionText.length;
                var extent = (captionLength / 32 * 100).toFixed(2) + "% 5.33%";
                
                if (captionLines[j].italics){
                    var newCaptionLine = ttmlLookup.newCaptionLineItalic(incode, outcode, origin, extent, region, xmlEntities.encode(captionLines[j].captionText.trim()))
                } else {
                    var newCaptionLine = ttmlLookup.newCaptionLine(incode, outcode, origin, extent, region, xmlEntities.encode(captionLines[j].captionText.trim()));
                }
                jsonCaptionData.push(newCaptionLine);
            }
        }
        var captionData = ttmlLookup.captionData(dropMode, ttmlFrameRate, frameRateMultiplier, jsonCaptionData);
        return (jsonXml(captionData, ttmlLookup.jsonOption));
    },
    dfxp : function (captions, frameRate, dropFrame, metadata, webVttOptions, msTimecode) {
        var jsonCaptionData = [];
        var dropMode = ttmlLookup.dropModeMapping[frameRate];
        var ttmlFrameRate = ttmlLookup.frameRateMapping[frameRate];
        var frameRateMultiplier = ttmlLookup.frameRateMultiplierMap[frameRate];
        for (var i = 0; i < captions.length; i++) {
            if (msTimecode){
                var incode = captions[i].incodeMs;
                var outcode = captions[i].outcodeMs;
            } else{
                var incode = captions[i].incode;
                var outcode = captions[i].outcode;
            }
            
            var captionLines = captions[i].primaryLang.filter(captionLine => {
                return captionLine.captionText.length > 0;
            })

            for (var j = 0; j < captionLines.length; j++) {
                var positionX = (captionLines[j].positionX / 32 * 100).toFixed(2) + "%";
                var positionY = (captionLines[j].positionY / 15 * 100).toFixed(2) + "%";
                var origin = positionX + " " + positionY;
                if (captions[i].style == "Paint-On") {
                    var region = "paint" + (j + 1);
                } else if (captions[i].style == "Roll-Up") {
                    var region = "rollup2";
                } else {
                    var region = 'pop' + (j + 1);
                }

                var captionText = captionLines[j].captionText.trim();
                var captionLength = captionText.length;
                var extent = (captionLength / 32 * 100).toFixed(2) + "% 5.33%";
                
                if (captionLines[j].italics){
                    var newCaptionLine = ttmlLookup.newCaptionLineItalic(incode, outcode, origin, extent, region, xmlEntities.encode(captionLines[j].captionText.trim()))
                } else {
                    var newCaptionLine = ttmlLookup.newCaptionLine(incode, outcode, origin, extent, region, xmlEntities.encode(captionLines[j].captionText.trim()));
                }
                jsonCaptionData.push(newCaptionLine);
            }
        }
        var captionData = ttmlLookup.captionData(dropMode, ttmlFrameRate, frameRateMultiplier, jsonCaptionData);
        return (jsonXml(captionData, ttmlLookup.jsonOption));
    },
    fcpxml: function (captions, frameRate, dropFrame) {
        var jsonCaptionData = [];
        var frameDuration = fcpXmlLookup.frameDurationMap[frameRate];

        for (var i = 0; i < captions.length; i++) {
            var incode = captions[i].incode;
            var outcode = captions[i].outcode;
            var newCaptionLine = fcpXmlLookup.newCaptionLine(i, incode, outcode, captions[i].captionText, frameRate, dropFrame, frameDuration);
            jsonCaptionData.push(newCaptionLine);
        }
        if (frameRate == "29.97" || frameRate == "59.94") {
            var sequenceFormat = {
                format: "r0",
                tcFormat: "DF"
            }
        } else {
            var sequenceFormat = {
                format: "r0"
            }
        }

        var captionData = fcpXmlLookup.captionData(frameDuration, jsonCaptionData, sequenceFormat);
        return (jsonXml(captionData, fcpXmlLookup.jsonOption));
    },
    scc: function (captions, frameRate, dropFrame) {
        var display = false;
        var timer = ccFunc.createSmpteTc(captions[0].incode, frameRate, dropFrame);
        try { 
            timer.subtract(100);
        } catch (e){
            var timer = ccFunc.createSmpteTc("00:00:00:01", frameRate, dropFrame);
        }
        
        var buffer = "";
        var captionData = "Scenarist_SCC V1.0";

        for (var x = 0; x < captions.length; x++) {
            var captionLine = captions[x];
            var incode = ccFunc.createSmpteTc(captionLine.incode, frameRate, dropFrame);
            var outcode = ccFunc.createSmpteTc(captionLine.outcode, frameRate, dropFrame);
            var style = captionLine.style || "Pop-On";
            if (style == "Pop-On") {
                if (buffer === "") {
                    var encodedData = sccLookup.encodeText(captionLine.primaryLang, style);
                    buffer = encodedData.data;
                    captionData += "\n\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + "9420 " + buffer;
                    timer.add(encodedData.encodeTime + 1);

                    if (incode.frameCount <= timer.frameCount) {
                        if (display) {
                            /* 
                                942c = clear screen; 
                                942f = display buffer; 
                                94ae = clear buffer; 
                            */
                            captionData += " 942c 942f 94ae";
                            buffer = "";
                            timer.add(4);
                        } else {
                            captionData += " 942f 94ae";
                            buffer = "";
                            timer.add(2);
                            display = true;
                        }
                    } else {
                        if (display) {
                            if (timer.frameCount <= ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame).frameCount) {
                                captionData += "\n\n" + ccFunc.formatTimecodeString(ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame).toString(), dropFrame) + "\t" + "942c";
                                timer = ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame);
                                timer.add(1);
                                display = false;
                                if (incode.frameCount <= timer.frameCount) {
                                    captionData += " 942f 94ae";
                                    buffer = "";
                                    timer.add(3);
                                    display = true;
                                } else {
                                    captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + "942f 94ae";
                                    timer = incode;
                                    buffer = "";
                                    timer.add(3);
                                    display = true;
                                }
                            } else {
                                captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + "942c 942f 94ae";
                                timer = incode;
                                buffer = "";
                                timer.add(5);
                            }
                        } else {
                            captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + "942f 94ae";
                            timer = incode;
                            buffer = "";
                            timer.add(3);
                            display = true;
                        }
                    }
                }

            } else if (style == "Roll-Up") {
                var encodedData = sccLookup.encodeText(captionLine.primaryLang, style);
                var rowCount = captionLine.primaryLang.filter(captionLine => {
                    return captionLine.captionText.length > 0;
                }).length;

                if (rowCount == 1 || rowCount == 2) {
                    var startCode = "9425 ";
                } else if (rowCount == 3) {
                    var startCode = "9426 ";
                } else {
                    var startCode = "94a7 ";
                }

                buffer = encodedData.data;

                if (incode.frameCount <= timer.frameCount) {
                    if (display) {
                        /* 
                            942c = clear screen; 
                            942f = display buffer; 
                            94ae = clear buffer; 
                        */
                        captionData += "\n\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + startCode + buffer;
                        timer.add(encodedData.encodeTime + 2);
                        buffer = "";
                    } else {
                        captionData += "\n\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + startCode + buffer;
                        timer.add(encodedData.encodeTime + 1);
                        buffer = "";
                        display = true;
                    }
                } else {
                    if (display) {
                        if (timer.frameCount <= ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame).frameCount) {
                            captionData += "\n\n" + ccFunc.formatTimecodeString(ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame).toString(), dropFrame) + "\t" + "942c";
                            timer = ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame);
                            timer.add(1);
                            display = false;
                            if (incode.frameCount <= timer.frameCount) {
                                captionData += " " + startCode + buffer;
                                timer.add(encodedData.encodeTime + 1);
                                buffer = "";
                                display = true;
                            } else {
                                captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + startCode + buffer;
                                timer = incode;
                                timer.add(encodedData.encodeTime + 1);
                                buffer = "";
                                display = true;
                            }
                        } else {
                            captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + startCode + buffer;
                            timer = incode;
                            timer.add(encodedData.encodeTime + 1);
                            buffer = "";
                        }
                    } else {
                        captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + startCode + buffer;
                        timer = incode;
                        timer.add(encodedData.encodeTime + 1);
                        buffer = "";
                        display = true;
                    }
                }
            } else {
                /* Default to Paint-On */
                if (buffer === "") {
                    var encodedData = sccLookup.encodeText(captionLine.primaryLang, style);
                    buffer = encodedData.data;
                    if (incode.frameCount <= timer.frameCount) {
                        if (display) {
                            /* 
                                942c = clear screen; 
                                942f = display buffer; 
                                94ae = clear buffer; 
                            */
                            captionData += "\n\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + "942c 9429 " + buffer;
                            timer.add(encodedData.encodeTime + 2);
                            buffer = "";
                        } else {
                            captionData += "\n\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + "9429 " + buffer;
                            timer.add(encodedData.encodeTime + 1);
                            buffer = "";
                            display = true;
                        }
                    } else {
                        if (display) {
                            if (timer.frameCount <= ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame).frameCount) {
                                captionData += "\n\n" + ccFunc.formatTimecodeString(ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame).toString(), dropFrame) + "\t" + "942c";
                                timer = ccFunc.createSmpteTc(captions[x - 1].outcode, frameRate, dropFrame);
                                timer.add(1);
                                display = false;
                                if (incode.frameCount <= timer.frameCount) {
                                    captionData += " 9429 " + buffer;
                                    timer.add(encodedData.encodeTime + 1);
                                    buffer = "";
                                    display = true;
                                } else {
                                    captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + "9429 " + buffer;
                                    timer = incode;
                                    timer.add(encodedData.encodeTime + 1);
                                    buffer = "";
                                    display = true;
                                }
                            } else {
                                captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + "942c 9429 " + buffer;
                                timer = incode;
                                timer.add(encodedData.encodeTime + 1);
                                buffer = "";
                            }
                        } else {
                            captionData += "\n\n" + ccFunc.formatTimecodeString(incode.toString(), dropFrame) + "\t" + "9429 " + buffer;
                            timer = incode;
                            timer.add(encodedData.encodeTime + 1);
                            buffer = "";
                            display = true;
                        }
                    }
                }
            }
        }
        if (display) {
            if (timer.frameCount <= ccFunc.createSmpteTc(captions[captions.length - 1].outcode, frameRate, dropFrame).frameCount) {
                captionData += "\n\n" + ccFunc.formatTimecodeString(ccFunc.createSmpteTc(captions[captions.length - 1].outcode, frameRate, dropFrame).toString(), dropFrame) + "\t" + "942c";
                timer = ccFunc.createSmpteTc(captions[captions.length - 1].outcode, frameRate, dropFrame);
                timer.add(1);
            } else {
                captionData += " 942c";
                timer.add(1);
            }
            display = false;
        }
        return captionData.replace(/  /g, " ");
    },
    mcc: function (captions, frameRate, dropFrame, metadata, webVttOptions, useMsTimecode, incode, dtv) {
        var display = false;
        var primeWindow = false;
        try { 
            timer.subtract(100);
        } catch (e){
            var timer = ccFunc.createSmpteTc("00:00:00:01", frameRate, dropFrame);
        }

        var hexValue = mccLookup.frameRateInfoMap[frameRate].hexValue;
        var cc_count = mccLookup.frameRateInfoMap[frameRate].cc_count;
        var max608Bytes = mccLookup.frameRateInfoMap[frameRate].max608Bytes;
        var max708Bytes = mccLookup.frameRateInfoMap[frameRate].max708Bytes;
        var uuid = uuidv1() + "\n";
        var d = moment().format('dddd[,] MMMM DD[,] YYYY') + "\n"; //Wednesday, August 22,  2018
        var t = moment().format('hh:mm:ss') + "\n"; //20:55:38
        var tc = mccLookup.frameRateInfoMap[frameRate].mccValue;
        var captionData = mccLookup.mccHeader + mccLookup.mccAnc + mccLookup.mccUuid + uuid + mccLookup.mccCreation + ccFunc.version + mccLookup.mccDate + d + mccLookup.mccTime + t + mccLookup.mccTc + tc + "\n";

        for (var x = 0; x < captions.length; x++) {
            var captionLine = captions[x];
            var incode = ccFunc.createSmpteTc(captionLine.incode, frameRate, dropFrame);
            var outcode = ccFunc.createSmpteTc(captionLine.outcode, frameRate, dropFrame);
            if (dtv){
                var sccEncodedData = {
                    data: "8080",
                    encodeTime: 1
                };
            } else {
                var sccEncodedData = sccLookup.encodeText(captionLine.primaryLang, captionLine.position, captionLine.alignment, "Pop-On");
            }
            

            sccEncodedData = sccEncodedData.data.split(" ").filter(function (el) {
                return el != "";
            });

            var mccEncodedData = mccLookup.mccEncode(captionLine, primeWindow = !primeWindow);
            for (var n = 0; n < sccEncodedData.length; n++) {
                var header = mccLookup.encodeHeader(timer, frameRate);
                if (timer.frameCount === 0) {
                    var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], false, timer.frameCount);
                } else {
                    var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], mccEncodedData, timer.frameCount);
                }
                var svcInfoSection = mccLookup.encodeCcsvcInfo();
                var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + (header + ccDataSection + svcInfoSection + cdpFooter).toUpperCase();
                timer.add(1);
            }

            if (incode.frameCount <= timer.frameCount) {
                if (display) {
                    /* 
                        942c = clear screen; 
                        942f = display buffer; 
                        94ae = clear buffer; 
                    */
                    sccEncodedData = ["942c", "942f", "94ae"];
                    if (primeWindow) {
                        mccEncodedData = ["8B", "03", "88", "FE", "8C", "FE"];
                    } else {
                        mccEncodedData = ["8B", "03", "88", "FD", "8C", "FD"];
                    }

                    for (var n = 0; n < sccEncodedData.length; n++) {
                        var header = mccLookup.encodeHeader(timer, frameRate);
                        var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], mccEncodedData, timer.frameCount);
                        var svcInfoSection = mccLookup.encodeCcsvcInfo();
                        var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                        captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + header + ccDataSection + svcInfoSection + cdpFooter;
                        timer.add(1);
                    }
                } else {
                    sccEncodedData = ["942f", "94ae"]; //Display buffer, erase buffer
                    if (primeWindow) {
                        mccEncodedData = ["89", "01", "88", "FE", "8C", "FE"];
                    } else {
                        mccEncodedData = ["89", "02", "88", "FD", "8C", "FD"];
                    }
                    for (var n = 0; n < sccEncodedData.length; n++) {
                        var header = mccLookup.encodeHeader(timer, frameRate);
                        var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], mccEncodedData, timer.frameCount);
                        var svcInfoSection = mccLookup.encodeCcsvcInfo();
                        var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                        captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + header + ccDataSection + svcInfoSection + cdpFooter;
                        timer.add(1);
                    }
                    display = true;
                }
            } else {
                if (display) {
                    var prevCaptionLine = captions[x - 1];
                    var prevOutcode = ccFunc.createSmpteTc(prevCaptionLine.outcode, frameRate, dropFrame);
                    while (timer.frameCount < prevOutcode.frameCount) {
                        sccEncodedData = ["8080"];
                        for (var n = 0; n < sccEncodedData.length; n++) {
                            var header = mccLookup.encodeHeader(timer, frameRate);
                            var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], false, timer.frameCount);
                            var svcInfoSection = mccLookup.encodeCcsvcInfo();
                            var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                            captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + header + ccDataSection + svcInfoSection + cdpFooter;
                            timer.add(1);
                        }
                    }
                    sccEncodedData = ["942c"]; //Erase Displayed Memory
                    if (primeWindow) {
                        mccEncodedData = ["88", "FE", "8C", "FE"];
                    } else {
                        mccEncodedData = ["88", "FD", "8C", "FD"];
                    }
                    for (var n = 0; n < sccEncodedData.length; n++) {
                        var header = mccLookup.encodeHeader(timer, frameRate);
                        var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], mccEncodedData, timer.frameCount);
                        var svcInfoSection = mccLookup.encodeCcsvcInfo();
                        var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                        captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + header + ccDataSection + svcInfoSection + cdpFooter;
                        timer.add(1);
                    }
                    display = false;
                }

                while (timer.frameCount < incode.frameCount) {
                    sccEncodedData = ["8080"];
                    for (var n = 0; n < sccEncodedData.length; n++) {
                        var header = mccLookup.encodeHeader(timer, frameRate);
                        var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], false, timer.frameCount);
                        var svcInfoSection = mccLookup.encodeCcsvcInfo();
                        var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                        captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + header + ccDataSection + svcInfoSection + cdpFooter;
                        timer.add(1);
                    }
                }
                sccEncodedData = ["942f", "94ae"]; //display the caption in the buffer on the screen && Clear Buffer
                if (primeWindow) {
                    mccEncodedData = ["89", "01", "88", "FE", "8C", "FE"]; /* Display Window 1  and delete Window 2*/
                } else {
                    mccEncodedData = ["89", "02", "88", "FD", "8C", "FD"]; /* Display Window 2 and delete Window 1*/
                }
                for (var n = 0; n < sccEncodedData.length; n++) {
                    var header = mccLookup.encodeHeader(timer, frameRate);
                    var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], mccEncodedData, timer.frameCount);
                    var svcInfoSection = mccLookup.encodeCcsvcInfo();
                    var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                    captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + header + ccDataSection + svcInfoSection + cdpFooter;
                    timer.add(1);
                }
                display = true;
            }
        }

        if (display) {
            var prevCaptionLine = captions[x - 1];
            var prevOutcode = ccFunc.createSmpteTc(prevCaptionLine.outcode, frameRate, dropFrame);
            while (timer.frameCount < prevOutcode.frameCount) {
                sccEncodedData = ["8080"];
                for (var n = 0; n < sccEncodedData.length; n++) {
                    var header = mccLookup.encodeHeader(timer, frameRate);
                    var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], false, timer.frameCount);
                    var svcInfoSection = mccLookup.encodeCcsvcInfo();
                    var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                    captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + header + ccDataSection + svcInfoSection + cdpFooter;
                    timer.add(1);
                }
            }

            sccEncodedData = ["942c"]; /* Clear Screen */
            mccEncodedData = ["88", "FF", "8C", "FF"];
            for (var n = 0; n < sccEncodedData.length; n++) {
                var header = mccLookup.encodeHeader(timer, frameRate);
                var ccDataSection = mccLookup.encodeCcData(cc_count, max608Bytes, max708Bytes, sccEncodedData[n], mccEncodedData, timer.frameCount);
                var svcInfoSection = mccLookup.encodeCcsvcInfo();
                var cdpFooter = mccLookup.encodeCdpFooter(header.substr(header.length - 4), header + ccDataSection + svcInfoSection + "74" + header.substr(header.length - 4));
                captionData += "\n" + ccFunc.formatTimecodeString(timer.toString(), dropFrame) + "\t" + header + ccDataSection + svcInfoSection + cdpFooter;
                timer.add(1);
            }
            display = false;
        }

        return captionData.replace(/  /g, " ");
    }
}