<template>
<div class="col-7" @keyup.up.exact="navigate($event)" @keyup.46.exact="backspace($event)" @keyup.down.exact="navigate($event)" @keyup.enter.exact="navigate($event)" @keyup.ctrl.66.exact="updateLineFormatById('bold', $event)" @keyup.ctrl.73.exact="updateLineFormatById('italics',  $event)" @keyup.ctrl.85.exact="updateLineFormatById('underline', $event)">

    <input Id="TimelineEventEditorInputA" name="0" type="text" class="captionLineInput" v-bind:class="{'bold' : isBold(0), 'underline' : isUnderline(0), 'italics' : isItalic(0), 'bg-danger' : lineA.length > this.$store.state.editor.autoFormat.maxChars}" v-model.trim="lineA" @blur="updateSelectedLine($event)" @focus="updateLineLength(0)">

    <input name="1" type="text" class="captionLineInput" v-bind:class="{'bold' : isBold(1), 'underline' : isUnderline(1), 'italics' : isItalic(1), 'bg-danger' : lineB.length > this.$store.state.editor.autoFormat.maxChars}" v-model.trim="lineB" @blur="updateSelectedLine($event)" @focus="updateLineLength(1)">

    <input name="2" type="text" class="captionLineInput" v-bind:class="{'bold' : isBold(2), 'underline' : isUnderline(2), 'italics' : isItalic(2), 'bg-danger' : lineC.length > this.$store.state.editor.autoFormat.maxChars}" v-model.trim="lineC" @blur="updateSelectedLine($event)" @focus="updateLineLength(2)">

    <input name="3" type="text" class="captionLineInput" v-bind:class="{'bold' : isBold(3), 'underline' : isUnderline(3), 'italics' : isItalic(3), 'bg-danger' : lineD.length > this.$store.state.editor.autoFormat.maxChars}" v-model.trim="lineD" @blur="updateSelectedLine($event)" @focus="updateLineLength(3)">

    <div class="btn-group styleOptions pb-4" role="group" aria-label="Formatting Options">
        <button type="button" class="btn btn-secondary" @click="updateLineFormat('bold')" title="Bold"><i class="fas fa-bold"></i></button>
        <button type="button" class="btn btn-secondary" @click="updateLineFormat('italics')" title="Italics"><i class="fas fa-italic"></i></button>
        <button type="button" class="btn btn-secondary" @click="updateLineFormat('underline')" title="Underline"><i class="fas fa-underline"></i></button>
        <button type="button" class="btn btn-secondary" @click="splitEvent($event)" title="Split Event"><i class="fas fa-object-ungroup"></i></button>
        <button type="button" class="btn btn-secondary" @click="mergeEvent" title="Merge Event"><i class="fas fa-object-group"></i></button>
        <button type="button" class="btn btn-secondary" @click="expandEvent" title="Expand Event"><i class="fas fa-arrows-alt-h"></i></button>
        <button type="button" class="btn btn-secondary" @click="compressEvent" title="Compress Event"><i class="fas fa-arrows-alt-v"></i></button>
        <button type="button" class="btn btn-secondary" @click="shiftLine('up')" title="Shift Lines Up"><i class="fas fa-level-up-alt"></i></button>
        <button type="button" class="btn btn-secondary" @click="shiftLine('down')" title="Shift Lines Down"><i class="fas fa-level-down-alt"></i></button>
        <button type="button" class="btn btn-secondary" @click="shiftWord('up')" title="Shift Word Up"><i class="fas fa-long-arrow-alt-up"></i></button>
        <button type="button" class="btn btn-secondary" @click="shiftWord('down')" title="Shift Word Down"><i class="fas fa-long-arrow-alt-down"></i></button>
        <button type="button" class="btn btn-secondary" @click="duplicate" title="Duplicate"><i class="far fa-copy"></i></button>
        <div class="btn-group" role="group">
            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" title="Speaker Identification">
                <i class="far fa-user"></i>
            </button>
            <div class="dropdown-menu">
                <button type="button" class="dropdown-item btn-link" v-for="(speaker, index) in speakers" :key="index" @click="insertSpeaker(speaker)">{{speaker}}</button>
                <div class="dropdown-divider border-top" v-if="speakers.length > 0"></div>
                <button type="button" class="dropdown-item btn-link" href="#" data-toggle="modal" data-target="#OptionsModal" v-on:click="updateMenu('Speakers')"><i class="fas fa-plus"></i> Add speaker...</button>
            </div>
        </div>
    </div>
    <p class="float-right" :class="{'text-danger' : lineLength > this.$store.state.editor.autoFormat.maxChars, 'text-muted' : lineLength <= this.$store.state.editor.autoFormat.maxChars}">{{lineLength}}/{{this.$store.state.editor.autoFormat.maxChars}}</p>
</div>
</template>

<script>
export default {
    name: "TimelineEventEditorInput",
    data: function () {
        return {
            selectedLine: false,
            lineLength : 0
        }
    },
    computed: {
        Id: function () {
            return this.$store.state.editor.selectedCaptionEvent;
        },
        speakers: function () {
            return this.$store.state.editor.speakers;
        },
        
        lineA: {
            get: function () {
                return this.$store.state.editor.captions[this.Id].primaryLang[0].captionText;
            },
            set: function (captionText) {
                this.lineLength = captionText.length;
                this.$store.commit("updateCaptionText", {
                    id: this.Id,
                    line: 0,
                    text: captionText
                });
            }
        },
        lineB: {
            get: function () {
                return this.$store.state.editor.captions[this.Id].primaryLang[1].captionText;
            },
            set: function (captionText) {
                this.lineLength = captionText.length;
                this.$store.commit("updateCaptionText", {
                    id: this.Id,
                    line: 1,
                    text: captionText
                })
            }
        },
        lineC: {
            get: function () {
                return this.$store.state.editor.captions[this.Id].primaryLang[2].captionText;
            },
            set: function (captionText) {
                this.lineLength = captionText.length;
                this.$store.commit("updateCaptionText", {
                    id: this.Id,
                    line: 2,
                    text: captionText
                })
            }
        },
        lineD: {
            get: function () {
                return this.$store.state.editor.captions[this.Id].primaryLang[3].captionText;
            },
            set: function (captionText) {
                this.lineLength = captionText.length;
                this.$store.commit("updateCaptionText", {
                    id: this.Id,
                    line: 3,
                    text: captionText
                })
            }
        }
    },
    methods: {
        updateLineLength : function(lineNumber){
            this.lineLength = this.$store.state.editor.captions[this.Id].primaryLang[lineNumber].captionText.length || 0;
        },
        insertSpeaker : function(speaker){
            this.$store.commit("insertSpeakerId", {id: this.Id, speaker: speaker});         
        },
        updateMenu: function (navOption) {
            this.$store.commit('updateOptionsNav', navOption)
        },
        navigate: function (event) {
            event.preventDefault();
            var lineNumber = parseInt(event.path[0].name);
            if (event.code === "ArrowUp" && lineNumber != 0) {
                event.path[1].childNodes[lineNumber - 1].focus();

            } else if (event.code === "ArrowDown" && lineNumber != 3) {
                event.path[1].childNodes[lineNumber + 1].focus();
            } else if (event.code === "Enter" && lineNumber != 3) {
                var selectionStart = event.target.selectionStart;
                this.$store.commit('cutTextToNextLine', {
                    event: this.Id,
                    line: lineNumber,
                    index: selectionStart
                });
                event.path[1].childNodes[lineNumber + 1].focus();
            }
        },
        backspace: function (event) {
            event.preventDefault();
            var selectionStart = event.target.selectionStart;
            var lineNumber = parseInt(event.path[0].name);
            var captionTextLength = this.$store.state.editor.captions[this.Id].primaryLang[lineNumber].captionText.length;
            if (selectionStart === 0 && lineNumber > 0) {
                if (captionTextLength > 0) {
                    var originalCaptionText = this.$store.state.editor.captions[this.Id].primaryLang[lineNumber].captionText;
                    var originalCaptionTextArray = originalCaptionText.split(" ");
                    var prevCaptionText = this.$store.state.editor.captions[this.Id].primaryLang[lineNumber - 1].captionText;
                    prevCaptionText += " " + originalCaptionTextArray.shift();
                    this.$store.commit("updateCaptionText", {
                        id: this.Id,
                        line: lineNumber - 1,
                        text: prevCaptionText
                    });
                    this.$store.commit("updateCaptionText", {
                        id: this.Id,
                        line: lineNumber,
                        text: originalCaptionTextArray.join(" ")
                    });
                }
                event.path[1].childNodes[lineNumber - 1].focus();
            } else if (captionTextLength === selectionStart && lineNumber < 3) {
                var originalCaptionText = this.$store.state.editor.captions[this.Id].primaryLang[lineNumber + 1].captionText;
                var originalCaptionTextArray = originalCaptionText.split(" ");
                var prevCaptionText = this.$store.state.editor.captions[this.Id].primaryLang[lineNumber].captionText;
                prevCaptionText += " " + originalCaptionTextArray.shift();
                this.$store.commit("updateCaptionText", {
                    id: this.Id,
                    line: lineNumber,
                    text: prevCaptionText
                });
                this.$store.commit("updateCaptionText", {
                    id: this.Id,
                    line: lineNumber + 1,
                    text: originalCaptionTextArray.join(" ")
                });
            }
        },

        updateLineFormat: function (style) {
            if (this.selectedLine) {
                this.$store.commit('updateLineFormat', {
                    id: this.Id,
                    line: this.selectedLine,
                    style: style
                });
            }
        },

        updateLineFormatById: function (style, event) {
            event.preventDefault();
            event.stopPropagation();
            var line = document.activeElement.name || 0;
            this.$store.commit('updateLineFormat', {
                id: this.Id,
                line: line,
                style: style
            });
        },
        updateSelectedLine: function (event) {
            this.selectedLine = event.target.name;
        },
        isBold: function (line) {
            return this.$store.state.editor.captions[this.Id].primaryLang[line].bold;
        },
        isItalic: function (line) {
            return this.$store.state.editor.captions[this.Id].primaryLang[line].italics;
        },
        isUnderline: function (line) {
            return this.$store.state.editor.captions[this.Id].primaryLang[line].underline;
        },
        splitEvent: function (event) {
            event.preventDefault();
            var self = this;
            this.$store.commit('clearEmptyLines', this.Id);
            this.$store.commit('splitEvent', this.Id);
        },
        mergeEvent: function () {
            if (this.Id != 0) {
                var self = this;
                this.$store.commit('clearEmptyLines', this.Id);
                this.$store.commit('clearEmptyLines', this.Id - 1);
                this.$store.commit('mergeEvent', this.Id);
                setTimeout(function () {
                    self.$store.commit('selectCaptionEvent', self.Id - 1);
                    self.$store.commit('focusCaptionEvent', self.Id - 1);
                    self.$store.commit('alignCaptionEvent', {
                        id: self.Id - 1,
                        align: "even"
                    });
                }, 50);
            }
        },
        expandEvent: function () {
            this.$store.commit('clearEmptyLines', this.Id);
            this.$store.commit('expandEvent', this.Id);
            this.$store.commit('alignCaptionEvent', {
                align: 'center',
                id: this.Id
            });
        },
        compressEvent: function () {
            this.$store.commit('clearEmptyLines', this.Id);
            this.$store.commit('compressEvent', this.Id);
            this.$store.commit('alignCaptionEvent', {
                align: 'center',
                id: this.Id
            });
        },
        shiftLine: function (direction) {
            if ((this.Id != 0 && direction == 'up') || (this.Id != this.$store.state.editor.captions.length - 1 && direction == 'down')) {
                this.$store.commit('clearEmptyLines', this.Id);
                this.$store.commit('shiftLine', {
                    id: this.Id,
                    direction: direction
                });
                this.$store.commit('clearEmptyLines', this.Id);
            }
        },
        shiftWord: function (direction) {
            if ((this.Id != 0 && direction == 'up') || (this.Id != this.$store.state.editor.captions.length - 1 && direction == 'down')) {
                this.$store.commit('clearEmptyLines', this.Id);
                this.$store.commit('shiftWord', {
                    id: this.Id,
                    direction: direction
                });
                this.$store.commit('clearEmptyLines', this.Id);
            }
        },
        duplicate: function () {
            this.$store.commit('duplicateCaptionEvent', this.Id);
            this.$store.commit('selectCaptionEvent', this.Id + 1);
        }
    }
}
</script>
