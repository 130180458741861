<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Metadata</th>
                    <th scope="col">Value</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>

                <tr v-for="(value, key) in this.$store.state.editor.metadata" :key="key">
                    <td>{{value.key}}</td>
                    <td>{{value.value}}</td>
                    <td><button class="btn btn-outline-danger btn-sm" @click="removeMetadata(key)">
                    <i class="fas fa-minus"></i> Remove</button></td>
                </tr>

            </tbody>
        </table>
        <hr />
        <form>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label"><strong>Add Metadata</strong></label>
                <div class="col-sm-4">
                    <input type="text" v-model="metadata.key" class="form-control" placeholder="Name">
                </div>
                <div class="col-sm-1">
                    <p class="text-center lead">:</p>
                </div>
                <div class="col-sm-4">
                    <input type="text" v-model="metadata.value" class="form-control" placeholder="Value">
                </div>
                <div class="col-sm-3">
                    <button type="button" class="btn btn-block btn-outline-success" @click="addMetadata"><i
                            class="fas fa-plus"></i> Create</button>
                </div>
            </div>

        </form>
    </div>
</template>
<script>
    export default {
        name: 'Metadata',
        data() {
            return {
                metadata: {
                    key: "",
                    value: ""
                }
            }
        },
        methods: {
            removeMetadata: function (index) {
                this.$store.commit('updateProjectMetadata', {
                    remove: true,
                    id: index
                });
            },
            addMetadata: function () {
                if (this.metadata.key != "" && this.metadata.key != undefined) {
                    if (this.metadata.value != "" && this.metadata.value != undefined) {
                        this.$store.commit('updateProjectMetadata', {
                            add: true,
                            key: this.metadata.key,
                            value: this.metadata.value
                        });

                        this.metadata.value = "";
                        this.metadata.key = "";
                        this.$notify({
                            title: 'Metadata Added',
                            type: 'success',
                            text: 'Metadata created successfully'
                        });
                    } else {
                        this.$notify({
                            title: 'Metadata Error',
                            type: 'error',
                            text: 'Metadata value required'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Metadata Error',
                        type: 'error',
                        text: 'Metadata name value required'
                    });
                }
            }
        }
    }
</script>