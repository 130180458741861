module.exports = {
    decode : function(fileContents, options){
        let events = [];
        return events;
    },

    encode : function(events, options){
        let fileContents = "";
        return fileContents;
    },

    preProcess : {
        encode : function(events){
            return events;
        },

        decode : function(fileContents){
            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}