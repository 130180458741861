import Vue from 'vue'
import app from '@/main';
import globalFunc from '../assets/js/functions/globalFunc'
import globalSetting from '../assets/js/functions/globalSettings'
import smpteTc from 'smpte-timecode'
import { Document, Packer, Paragraph, TextRun } from 'docx'
import eol from 'eol';
import _Timecode from 'timecode'
import _NodeTc from 'node-timecodes'
import _XmlJs from 'xml-js'
import fileSaver from 'file-saver'
import { v1 as uuidv1 } from 'uuid'
import axios from 'axios'
import firebase from '@/firebase/config'
import storage from '@/firebase/storage'
import db from '@/firebase/firestore'
const ccLib = require('@/external/cc-lib');

const state = {
    version: '1.7.21',
    versionNumber: 21,
    onlineStatus: false,
    view: "subtitle",
    viewTimeline: true,
    subViewerOption: "",
    waveformPath: "",
    projectSettings: {
        title: 'Untitled',
        framerate: '29.97',
        incode: '00:00:00:00',
        incodeMs: '',
        unixIncode: '',
        fileUrl: '',
        fileType: '',
        playerType: 'video',
        defaultPosition: '11_00',
        defaultEventDuration: 2,
        language: 'en-US',
        uid: null,
        author: "",
        team: "",
        state: "",
        version: "",
        episode: "",
        season: "",
        series: "",
        mediaInfo: {},
        voice: "en-US-Wavenet-D"
    },
    autoFormat: {
        maxLines: 4,
        maxChars: 32,
        topLine: 12,
        enable: false
    },
    metadata: [],
    speakers: [],
    webVttOptions: {
        encodeMetadata: false,
        encodePosition: false
    },
    audioDescription: [
        {
            uniqueId: "0",
            incode: "00:00:00:00",
            incodeSec: "0",
            incodeMs: "00:00:00.000",
            duration: 2.000,
            speed: "1.00",
            text: ""
        }
    ],
    captions: [
        {
            uniqueId: "0",
            incode: "00:00:00:00",
            outcode: "00:00:02:00",
            incodeSec: "0",
            outcodeSec: "2",
            incodeMs: "",
            outcodeMs: "",
            style: "Pop-On",
            subtitleText: "",
            primaryLang: [
                {
                    id: 0,
                    captionText: "",
                    positionX: 0,
                    positionY: 10,
                    bold: false,
                    italics: false,
                    underline: false
                }, {
                    id: 1,
                    captionText: "",
                    positionX: 0,
                    positionY: 11,
                    bold: false,
                    italics: false,
                    underline: false
                }, {
                    id: 2,
                    captionText: "",
                    positionX: 0,
                    positionY: 12,
                    bold: false,
                    italics: false,
                    underline: false
                }, {
                    id: 3,
                    captionText: "",
                    positionX: 0,
                    positionY: 13,
                    bold: false,
                    italics: false,
                    underline: false
                }
            ],
            secondaryLang: [
                {
                    id: 0,
                    captionText: "",
                    positionX: 0,
                    positionY: 10,
                    bold: false,
                    italics: false,
                    underline: false
                }, {
                    id: 1,
                    captionText: "",
                    positionX: 0,
                    positionY: 11,
                    bold: false,
                    italics: false,
                    underline: false
                }, {
                    id: 2,
                    captionText: "",
                    positionX: 0,
                    positionY: 12,
                    bold: false,
                    italics: false,
                    underline: false
                }, {
                    id: 3,
                    captionText: "",
                    positionX: 0,
                    positionY: 13,
                    bold: false,
                    italics: false,
                    underline: false
                }
            ]
        }
    ],
    /* Manual Transcription */
    transcription: {
        text: ""
    },
    duration: 250,
    currentTime: '',
    currentTimeSec: 0,
    selectedCaptionEvent: 0,
    captionFormat: 'SRT',
    srcContent: 'Default',
    maxFrame: 29,
    supportedCaptionFormats: [
        'SCC',
        'MCC',
        'TXT',
        'SRT',
        'VTT',
        'SUB',
        'SBV',
        'STL',
        'XML',
        'TTML',
        'DFXP',
        'CAP',
        'PAC'
    ],
    playbackRate: '1.00',
    playbackRates: [
        1.25,
        1.50,
        1.75,
        2.00,
        2.5,
        3,
        3.5,
        4,
        0.25,
        0.50,
        0.75,
        1.00
    ],
    player: {
        status: 'pause',
        fastForward: false,
        rewind: false,
        aspectRatio: "16:9",
        audioTracks: [],
        selectedAudioTrack: 0,
        loaded: false,
        captionPreview: {
            captionLock: false,
            videoLock: false,
            previewPlay: true
        }
    },
    searchOptions: {
        searchResults: [],
        searchResult: 0
    },
    multiSelect: {
        enabled: false,
        captionEvents: []
    },
    optionsNav: "general",
    zoom: 100,
    clear: false,
    plainTextOptions: {
        importOption: 'option1',
        maxCharacters: true,
        maxNumberOfCharacters: 28,
        punctuation: true,
        avgWordsPerSecond: 2,
        maxNumberOfLines: 1
    },
    adPreview: "",
    status: {
        progress: 0,
        msg: 'Testing',
        status: 'processing', // processing, complete, error
        display: false
    },
    applicationVersion: "pro", /* starter or pro */
    pluginList: [],
    assetInfo: {},
    /* Ai Transcription */
    assetTranscription: {
        assetId: "",
        text: ""
    },
    currentState: ""
}

const mutations = {
    backupProjectToLocalStorage: function (state) {
        window.requestAnimationFrame(function () {
            localStorage.setItem("backupProject", JSON.stringify(state.captions));
        });
    },
    updateOnlineStatus: function (state, status) {
        state.onlineStatus = status;
    },
    updateClearStatus: function (state, status) {
        state.clear = status;
    },
    automaticTranslation: function (state, language) {
        app.$notify({ title: 'Please Wait', type: 'warn', text: 'Automatic Translation in Progress' });
        var i = 0;
        var progress = state.captions.length;
        var captionLength = state.captions.length;
        state.status.progress = 0;
        state.status.msg = "Translating...";
        state.status.status = "";
        state.status.display = true;
        for (i; i < captionLength; i++) {
            if ((state.multiSelect.enabled && state.multiSelect.captionEvents.indexOf(i) > -1) || !state.multiSelect.enabled) {
                firebase.functions().httpsCallable('translateCaptionEvent')({ language: language, captionEvent: i, captionLines: state.captions[i].primaryLang }).then(res => {
                    for (var j = 0; j < state.captions[res.data.captionEvent].primaryLang.length; j++) {
                        if (state.captions[res.data.captionEvent].primaryLang[j].captionText.trim().length > 0) {
                            state.captions[res.data.captionEvent].primaryLang[j].captionText = res.data.translation.shift()[0];
                        }
                    }
                    state.status.progress = (((captionLength - (--progress)) / captionLength) * 100).toFixed();
                    state.status.msg = "Translating...";
                    state.status.status = "";
                    state.status.display = true;

                    if (progress < 1) {
                        state.status.display = false;
                        app.$notify({ title: 'Automatic Translation', type: 'success', text: 'Updating captions with new translation' });

                        if (state.view === 'subtitle') {
                            state.captions.forEach(caption => {
                                caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
                            });
                        }

                    } else {
                        state.status.display = true;
                    }
                });
            }
        }


    },
    updateApplicationVersion: function (state, version) {
        state.applicationVersion = version;
    },
    updatePluginList: function (state, plugins) {
        try {
            if (plugins && plugins.length > 0) {
                plugins.forEach(plugin => {
                    if (plugin.id) {
                        state.pluginList.push(plugin.id.split("-")[0]);
                    }
                });
            }
        } catch (e) {
            console.log(e.message);
            console.log("Failed to load plugins");
            state.pluginList = [];
        }
    },
    updatePlayerStatus: function (state, status) {
        state.player.status = status;
    },
    updatePlayerLoadState: function (state, status) {
        state.player.loaded = status;
    },
    updateLockOption: function (state, lockOption) {
        state.player.captionPreview[lockOption] = !state.player.captionPreview[lockOption];
        if (state.player.captionPreview.captionLock) {
            state.player.captionPreview.previewPlay = true;
        }
    },
    updateFormatOption: function (state, formatOption) {
        state.autoFormat[formatOption.option] = formatOption.value;
    },
    snapToSceneChanges: function (state, sceneChangeOptions) {
        app.$notify({ title: 'Scene Change Snap', text: 'Snapping to scene changes' });
        var sceneChanges = sceneChangeOptions.sceneChanges;
        var threshholdSec = parseInt(sceneChangeOptions.threshhold) / 1000;

        for (var i = 0; i < state.captions.length; i++) {
            var caption = state.captions[i];
            for (var j = 0; j < sceneChanges.length; j++) {
                try {
                    var sceneChange = parseFloat(sceneChanges[j]);
                    if ((caption.incodeSec + threshholdSec >= sceneChange && sceneChange >= caption.incodeSec) || (caption.incodeSec - threshholdSec <= sceneChange && sceneChange <= caption.incodeSec)) {
                        app.$notify({
                            title: 'Scene Change Snap',
                            type: 'success',
                            text: "Incode of " + caption.incodeSec + " matches a scene change at " + sceneChange
                        });
                        var incodeTc = _Timecode.Timecode.init({
                            framerate: state.projectSettings.framerate,
                            timecode: _NodeTc.fromSeconds(sceneChange, { frameRate: state.projectSettings.framerate }),
                            drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
                        });

                        incodeTc.add(state.projectSettings.incode);
                        caption.incode = incodeTc.toString();
                        caption.incodeSec = sceneChange;

                    } else if ((caption.outcodeSec + threshholdSec >= sceneChange && sceneChange >= caption.outcodeSec) || (caption.outcodeSec - threshholdSec <= sceneChange && sceneChange <= caption.outcodeSec)) {
                        app.$notify({
                            title: 'Scene Change Snap',
                            type: 'success',
                            text: "Outcode of " + caption.outcodeSec + " matches a scene change at " + sceneChange
                        });
                        var outcodeTc = _Timecode.Timecode.init({
                            framerate: state.projectSettings.framerate,
                            timecode: _NodeTc.fromSeconds(sceneChange, { frameRate: state.projectSettings.framerate }),
                            drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
                        });

                        outcodeTc.add(state.projectSettings.incode);
                        caption.outcode = outcodeTc.toString();
                        caption.outcodeSec = sceneChange;
                    }
                } catch (e) {
                    console.log(e.message);
                }
            }
        }

        app.$notify({ title: 'Scene Change Snap', type: 'success', text: 'Snap to scene changes completed' });
    },
    generateWaveform: function (state) {
        if (process.env.VUE_APP_ENV === "desktop") {
            var videoFilePath = state.projectSettings.mediaInfo.path;
            var waveformFilePath = videoFilePath.substr(0, videoFilePath.lastIndexOf(".")) + ".png";
            const ffmpegPath = require('electron').remote.getGlobal('ffmpegpath');
            const spawn = require('child_process').spawn;
            var args = [
                "-y",
                "-i",
                videoFilePath,
                "-filter_complex",
                "compand,showwavespic=s=1920x120",
                "-frames:v",
                "1",
                waveformFilePath
            ];

            var proc = spawn(ffmpegPath, args);
            proc.on('close', function () {
                if (process.env.VUE_APP_OS === 'Linux') {
                    state.waveformPath = "file://" + escape(waveformFilePath);
                } else {
                    state.waveformPath = waveformFilePath;
                }
            });

            proc.stdout.on('data', function (data) {
                console.log(data.toString());
            });
        }
    },
    viewTimeline: function (state) {
        state.viewTimeline = !state.viewTimeline
    },
    updateProjectIncode: function (state, incode) {
        if (incode != state.projectSettings.incode) {
            var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate];
            var captions = JSON.parse(JSON.stringify(state.captions));
            try {
                for (var i = 0; i < captions.length; i++) {
                    var newIncode = _Timecode.Timecode.init({ framerate: state.projectSettings.framerate, timecode: captions[i].incode, drop_frame: dropFrame });

                    newIncode.subtract(state.projectSettings.incode);
                    newIncode.add(incode);

                    var newOutcode = _Timecode.Timecode.init({ framerate: state.projectSettings.framerate, timecode: captions[i].outcode, drop_frame: dropFrame });

                    newOutcode.subtract(state.projectSettings.incode);
                    newOutcode.add(incode);

                    captions[i].incode = newIncode.toString();
                    captions[i].outcode = newOutcode.toString();
                }

                var newCurrentTime = _Timecode.Timecode.init({ framerate: state.projectSettings.framerate, timecode: state.currentTime, drop_frame: dropFrame });
                newCurrentTime.subtract(state.projectSettings.incode);
                newCurrentTime.add(incode);
                state.currentTime = newCurrentTime.toString();

            } catch (e) {
                console.log(e.message);
                console.log("Unable to apply new incode to caption line: " + i);
                app.$notify({
                    title: 'Update Project Incode',
                    type: 'error',
                    text: 'Failed to update incode of project. ' + e.message
                });
            }

            state.projectSettings.incode = incode;
            state.captions = captions;
            state.captions.forEach(caption => {
                caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
            });
            app.$notify({ title: 'Update Project Incode', type: 'success', text: 'Incode update successful' });
        }
    },
    updateProjectFramerate: function (state, framerate) {
        if (framerate != state.projectSettings.framerate) {
            var dropFrame = globalSetting.tcFrameMap[framerate];
            var incode = state.projectSettings.incode;
            var captions = JSON.parse(JSON.stringify(state.captions));
            try {
                for (var i = 0; i < captions.length; i++) {
                    var newIncode = _Timecode.Timecode.init({
                        framerate: framerate,
                        timecode: _NodeTc.fromSeconds(captions[i].incodeSec, { frameRate: framerate }),
                        drop_frame: dropFrame
                    });

                    newIncode.add(incode);

                    var newOutcode = _Timecode.Timecode.init({
                        framerate: state.projectSettings.framerate,
                        timecode: _NodeTc.fromSeconds(captions[i].outcodeSec, { frameRate: framerate }),
                        drop_frame: dropFrame
                    });

                    newOutcode.add(incode);

                    captions[i].incode = newIncode.toString();
                    captions[i].outcode = newOutcode.toString();
                }

                var newCurrentTime = _Timecode.Timecode.init({
                    framerate: state.projectSettings.framerate,
                    timecode: _NodeTc.fromSeconds(state.currentTimeSec, { frameRate: framerate }),
                    drop_frame: dropFrame
                });

                newCurrentTime.add(incode);
                state.currentTime = newCurrentTime.toString();

            } catch (e) {
                console.log(e.message);
                console.log("Unable to apply new framerate to caption line: " + i);
                app.$notify({
                    title: 'Update Project Framerate',
                    type: 'error',
                    text: 'Failed to update framerate of project. ' + e.message
                });
            }

            state.projectSettings.framerate = framerate;
            if (['29.97', '23.98', '59.94'].indexOf(framerate) > -1) {
                state.maxFrame = parseInt(framerate.split('.')[0]);
            } else {
                state.maxFrame = framerate - 1;
            } state.captions = captions;
            state.captions.forEach(caption => {
                caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
            });
            app.$notify({ title: 'Update Project Framerate', type: 'success', text: 'Framerate update successful' });
        }
    },
    updateProjectSetting: function (state, setting) {
        state.projectSettings[setting.option] = setting.value;
    },
    updateWebVttOption: function (state, setting) {
        state.webVttOptions[setting.option] = setting.value;
    },
    updateProjectMetadata: function (state, metadata) {
        if (metadata.add) {
            state.metadata.push({ key: metadata.key, value: metadata.value });
        } else if (metadata.remove) {
            state.metadata.splice(metadata.id, 1);
        }
    },

    addSpeaker: function (state, speaker) {
        state.speakers.push(speaker);
    },

    removeSpeaker: function (state, speaker) {
        state.speakers.splice(state.speakers.indexOf(speaker), 1);
    },

    removeAllSpeakers: function (state) {
        state.speakers = [];
    },
    insertSubtitleSpeakerId: function (state, speakerInfo) {
        state.captions[speakerInfo.id].subtitleText = speakerInfo.speaker + ":\n" + state.captions[speakerInfo.id].subtitleText;
    },
    insertSpeakerId: function (state, speakerInfo) {
        if (state.captions[speakerInfo.id].primaryLang[3].captionText.length > 0) {
            state.captions[speakerInfo.id].primaryLang[2].captionText += " " + state.captions[speakerInfo.id].primaryLang[3].captionText;
        }
        for (var i = 3; i > 0; i--) {
            state.captions[speakerInfo.id].primaryLang[i].captionText = state.captions[speakerInfo.id].primaryLang[i - 1].captionText;
            state.captions[speakerInfo.id].primaryLang[i].positionX = state.captions[speakerInfo.id].primaryLang[i - 1].positionX;
            state.captions[speakerInfo.id].primaryLang[i].positionY = state.captions[speakerInfo.id].primaryLang[i - 1].positionY;
            state.captions[speakerInfo.id].primaryLang[i].bold = state.captions[speakerInfo.id].primaryLang[i - 1].bold;
            state.captions[speakerInfo.id].primaryLang[i].italics = state.captions[speakerInfo.id].primaryLang[i - 1].italics;
            state.captions[speakerInfo.id].primaryLang[i].underline = state.captions[speakerInfo.id].primaryLang[i - 1].underline;
        }
        state.captions[speakerInfo.id].primaryLang[0].captionText = speakerInfo.speaker + ":";
        state.captions[speakerInfo.id].primaryLang[0].italics = true;
        state.captions[speakerInfo.id].primaryLang[0].bold = false;
        state.captions[speakerInfo.id].primaryLang[0].underline = false;
        state.captions[speakerInfo.id].primaryLang[0].positionY = state.captions[speakerInfo.id].primaryLang[1].positionY - 1;
    },
    updateOptionsNav: function (state, navOption) {
        state.optionsNav = navOption;
    },
    updateCaptionFormat: function (state, captionFormat) {
        state.captionFormat = captionFormat;
    },
    updateOrCreateProjectId: function (state, uuid) {
        if (uuid) {
            state.projectSettings.uid = uuid;
        } else {
            state.projectSettings.uid = uuidv1();
        }

    },
    createNewProject: function (state, projectSettings) {
        state.projectSettings.title = projectSettings.title;
        state.projectSettings.incode = projectSettings.projectIncode;
        state.projectSettings.framerate = projectSettings.framerate;
        state.projectSettings.uid = uuidv1();
        /* Reset Defaults */
        state.currentTime = projectSettings.projectIncode;
        state.currentTimeSec = '0';
        state.selectedCaptionEvent = 0;

        var dropFrame = globalSetting.tcFrameMap[projectSettings.framerate];
        var frameRate = globalSetting.frameRateMap[projectSettings.framerate.toString()];
        var outcodeFrames = parseInt(projectSettings.framerate) * parseInt(state.projectSettings.defaultEventDuration);
        var outcode = smpteTc(projectSettings.projectIncode, frameRate, dropFrame).add(outcodeFrames).toString();
        var captionEvent = ccLib.createCaptionEvent(projectSettings.projectIncode, outcode, 0.1, state.projectSettings.defaultEventDuration);

        state.captions = [];
        state.captions.push(captionEvent);
        if (process.env.VUE_APP_MODE != "plugin") {
            app.$notify({ title: 'New Project', type: 'success', text: 'Project created successfully' });
        }
    },
    importMedia: function (state, mediaInfo) {
        try { /* Reset Defaults */
            state.currentTimeSec = '0';
            state.selectedCaptionEvent = 0;
            state.projectSettings.mediaInfo.source = mediaInfo.source;
            var aspectRatio = mediaInfo.aspectRatio || "16:9";
            state.player.aspectRatio = aspectRatio;
            if (mediaInfo.source === "Local Storage") {
                var URL = window.URL || window.webkitURL;
                var srcFile = mediaInfo.url[0];

                state.projectSettings.mediaInfo.fileType = srcFile.type;
                state.projectSettings.mediaInfo.path = srcFile.path;
                state.projectSettings.mediaInfo.fileName = srcFile.name;

                /* Determine if it's audio or video */
                if (srcFile.type.split("/")[0] === "audio") {
                    state.projectSettings.playerType = "audio";
                } else {
                    state.projectSettings.playerType = "video";
                }

                /* Workaround for MOV files */
                if (srcFile.type === "video/quicktime") {
                    state.projectSettings.fileType = "video/mp4";
                } else {
                    state.projectSettings.fileType = srcFile.type;
                }

                if (mediaInfo.projectImport) {
                    state.projectSettings.fileUrl = srcFile.path;
                } else {
                    state.projectSettings.fileUrl = URL.createObjectURL(srcFile)
                }

                Vue.nextTick(function () {
                    mediaPlayer.source = {
                        type: state.projectSettings.playerType,
                        sources: [
                            {
                                src: state.projectSettings.fileUrl || "",
                                type: state.projectSettings.fileType
                            }
                        ]
                    }
                });

            } else if (mediaInfo.source === "Cloud URL") {
                state.projectSettings.fileType = 'video/mp4';
                state.projectSettings.fileUrl = mediaInfo.url;
                state.projectSettings.playerType = "video";

                Vue.nextTick(function () {
                    mediaPlayer.source = {
                        type: state.projectSettings.playerType,
                        sources: [
                            {
                                src: state.projectSettings.fileUrl,
                                type: state.projectSettings.fileType
                            }
                        ]
                    }
                    mediaPlayer.ratio = aspectRatio;
                });

            } else if (mediaInfo.source === "Vimeo") {
                state.projectSettings.fileUrl = mediaInfo.url;
                state.projectSettings.playerType = "vimeo";

                Vue.nextTick(function () {
                    mediaPlayer.source = {
                        type: 'video',
                        sources: [
                            {
                                src: state.projectSettings.fileUrl,
                                provider: 'vimeo'
                            }
                        ]
                    }
                    mediaPlayer.ratio = aspectRatio;
                });
            } else {
                state.projectSettings.fileUrl = mediaInfo.url;
                state.projectSettings.playerType = "youtube";
                Vue.nextTick(function () {
                    mediaPlayer.source = {
                        type: 'video',
                        sources: [
                            {
                                src: state.projectSettings.fileUrl,
                                provider: 'youtube'
                            }
                        ]
                    }
                    mediaPlayer.ratio = aspectRatio;
                });
            }
        } catch (e) {
            console.log("Media import failed");
            console.log(e.message);

            app.$notify({
                title: 'Error',
                type: 'error',
                text: 'Media import failed with error: ' + e.message
            });
        }
    },
    updateCurrentTime: function (state, time) {
        try {
            state.currentTimeSec = time;
            var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate];
            var frameRate = globalSetting.frameRateMap[state.projectSettings.framerate.toString()];
            var incode = smpteTc(state.projectSettings.incode, frameRate, dropFrame);
            var frames = parseInt(time * frameRate);
            state.currentTime = incode.add(frames).toString();
        } catch (e) {
            console.log(e.message);
        }

    },
    playerUpdateCurrentTime: function (state, time) {
        try {
            state.currentTimeSec = time;
            var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate];
            var frameRate = globalSetting.frameRateMap[state.projectSettings.framerate.toString()];
            var incode = smpteTc(state.projectSettings.incode, frameRate, dropFrame);
            var frames = parseInt(time * frameRate);
            state.currentTime = incode.add(frames).toString();
            if (state.view === 'default') {
                setTimeout(function () {
                    var playheadElement = document.getElementById('TimelinePlayhead');
                    playheadElement.scrollIntoView({ block: 'center', behaviour: 'smooth' });
                }, 200);

            }
        } catch (e) {
            console.log(e.message);
        }

    },
    updateDurationToLatest: function (state) {
        state.duration = state.captions[state.captions.length - 1].outcodeSec;
    },
    updateDuration: function (state, duration) {
        state.duration = duration;
    },

    updateCaptionStyle: function (state, style) {
        state.captions[style.id].style = style.style;
    },
    updateLineFormat: function (state, formatting) {
        state.captions[formatting.id].primaryLang[formatting.line][formatting.style] = !state.captions[formatting.id].primaryLang[formatting.line][formatting.style];
    },
    insertCompletedEvent: function (state, captionEvent) {
        state.captions.push(captionEvent);
    },

    insertCaptionEventInPlace: function (state, index) {
        var framerate = state.projectSettings.framerate || 29.97;
        var dropFrame = globalSetting.tcFrameMap[framerate] || true;
        var incode = state.currentTime || "00:00:00:00";
        var incodeSec = state.currentTimeSec || 0;
        var outcodeSec = incodeSec + parseInt(state.projectSettings.defaultEventDuration) || 3;
        try {
            var outcode = _Timecode.Timecode.init({ framerate: framerate, timecode: incode, drop_frame: dropFrame });
            outcode.add(state.projectSettings.defaultEventDuration * framerate);
        } catch (e) {
            var outcode = "00:00:03:00"
        }

        var captionEvent = ccLib.createCaptionEvent(incode, outcode.toString(), incodeSec, outcodeSec);
        state.captions.splice(index, 0, captionEvent);
        state.selectedCaptionEvent = index;
    },

    insertCaptionEvent: function (state) {
        var framerate = state.projectSettings.framerate || 29.97;
        var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate] || true;
        var incode = state.currentTime || "00:00:00:00";
        var incodeSec = state.currentTimeSec || 0;
        var outcodeSec = incodeSec + parseInt(state.projectSettings.defaultEventDuration) || 3;
        try {
            var outcode = _Timecode.Timecode.init({ framerate: framerate, timecode: incode, drop_frame: dropFrame });
            outcode.add(state.projectSettings.defaultEventDuration * framerate);
        } catch (e) {
            var outcode = "00:00:03:00"
        }

        var captionEvent = ccLib.createCaptionEvent(incode, outcode.toString(), incodeSec, outcodeSec);
        state.captions.push(captionEvent);
        state.selectedCaptionEvent = state.captions.length - 1;
    },

    deleteCaptionEvent: function (state, captionEvent) {
        if (state.captions.length > 1) {
            state.captions.splice(captionEvent, 1);
            state.selectedCaptionEvent = (captionEvent - 1) < 0 ? 0 : (captionEvent - 1);
        } else {
            app.$notify({ title: 'Error', type: 'error', text: 'Unable to delete caption event' });
        }
    },
    duplicateCaptionEvent: function (state, captionEventId) {
        var original = JSON.parse(JSON.stringify(state.captions[captionEventId]));
        var captionEvent = ccLib.createCaptionEvent(original.incode, original.outcode, original.incodeSec, original.outcodeSec, original.incodeMs, original.outcodeMs, original.style, original.primaryLang, original.secondaryLang, original.subtitleText);

        if (state.view === "default") {
            state.captions.push(captionEvent);
        } else {
            state.captions.splice(captionEventId + 1, 0, captionEvent);
        }
    },
    fixLineOverlap: function (state, captionEvent) {
        var startingYPosition = state.captions[captionEvent].primaryLang[0].positionY;
        if (startingYPosition > 11 || startingYPosition == undefined) {
            startingYPosition = 11;
        }

        for (var i = 0; i < 4; i++) {
            state.captions[captionEvent].primaryLang[i].positionY = startingYPosition + i;
        }
    },
    updateStartingLine: function (state, line) {
        if (line > 14) {
            line = 14;
        } else if (line < 0) {
            line = 0;
        }

        for (var i = 0; i < state.captions.length; i++) {
            if ((state.multiSelect.enabled && state.multiSelect.captionEvents.indexOf(i) > -1) || !state.multiSelect.enabled) {
                var linesWithText = state.captions[i].primaryLang.filter(captionLine => {
                    return captionLine.captionText.length > 0;
                });
                if (linesWithText.length + line > 15) {
                    var offset = 15 - (line - linesWithText.length);
                } else {
                    var offset = 0;
                }
                for (var j = 0; j < 4; j++) {
                    state.captions[i].primaryLang[j].positionY = (line + j) - offset;
                }
            }
        }
    },
    autoFormat: function (state, eventId) {
        var maxChars = state.autoFormat.maxChars;
        var maxLines = state.autoFormat.maxLines - 1;
        var topLine = state.autoFormat.topLine - 1;

        var currentEvent = state.captions[eventId];
        var currentLine = 0;
        if (state.view === "subtitle") {
            var captionText = state.captions[eventId].subtitleText.replace(/\n/g, " ").trim();
        } else {
            var captionText = globalFunc.concatCaptionText(state.captions[eventId].primaryLang, " ").trim();
        }

        var captionTextArray = captionText.split(" ");
        var avgCharsPerLine = captionText.length / maxLines;

        /* Timecode Data */
        var incodeSec = state.captions[eventId].incodeSec;
        var outcodeSec = state.captions[eventId].outcodeSec;
        var incode = state.captions[eventId].incode;
        var outcode = state.captions[eventId].outcode;

        currentEvent.subtitleText = "";
        currentEvent.primaryLang.forEach(line => {
            line.captionText = "";
        });

        while (captionTextArray.length > 0) {
            var word = " " + captionTextArray.shift();

            if (currentEvent.primaryLang[currentLine].captionText.length + word.length > maxChars) {
                currentLine++;
                currentEvent.subtitleText = currentEvent.subtitleText.trim() + "\n";
            }

            if (currentLine > maxLines) {
                for (var j = 0; j < currentEvent.primaryLang.length; j++) {
                    var captionLine = currentEvent.primaryLang[j];
                    captionLine.positionY = topLine + j;
                    if (captionLine.captionText.length < 32) {
                        captionLine.positionX = parseInt((32 - captionLine.captionText.length) / 2);
                    } else {
                        captionLine.positionX = 0;
                    }
                }
                var captionEvent = ccLib.createCaptionEvent(incode, outcode, incodeSec, outcodeSec);
                state.captions.splice(++eventId, 0, captionEvent);
                var currentEvent = state.captions[eventId];
                var currentLine = 0;
            }
            currentEvent.subtitleText = currentEvent.subtitleText + " " + word.trim();
            (currentEvent.primaryLang[currentLine].captionText += word).trim();

            if (currentEvent.primaryLang[currentLine].captionText.length > avgCharsPerLine && captionTextArray.length > 1) {
                currentLine++
                currentEvent.subtitleText = currentEvent.subtitleText.trim() + "\n";
            } else if (currentEvent.primaryLang[currentLine].captionText.length + 4 > captionTextArray.join(" ").length && captionTextArray.length > 2) {
                currentLine++
                currentEvent.subtitleText = currentEvent.subtitleText.trim() + "\n";
            }
        }

        for (var j = 0; j < currentEvent.primaryLang.length; j++) {
            var captionLine = currentEvent.primaryLang[j];
            captionLine.positionY = topLine + j;
            if (captionLine.captionText.length < 32 && captionLine.captionText.length != 0) {
                captionLine.positionX = parseInt((32 - captionLine.captionText.length) / 2);
            } else {
                captionLine.positionX = 0;
            }
        }
    },
    alignCaptionEvent: function (state, alignmentData) {
        var alignment = alignmentData.align;
        var captionEvent = alignmentData.id;
        if (alignment === "top") {
            for (var i = 0; i < 4; i++) {
                state.captions[captionEvent].primaryLang[i].positionY = i + 1;
            }
        } else if (alignment === "middle") {
            for (var i = 0; i < 4; i++) {
                state.captions[captionEvent].primaryLang[i].positionY = i + 6;
            }
        } else if (alignment === "bottom") {
            for (var i = 0; i < 4; i++) {
                state.captions[captionEvent].primaryLang[i].positionY = i + 11;
            }
        } else {
            state.captions[captionEvent].primaryLang.forEach(captionLine => {
                if (alignment === "left") {
                    captionLine.positionX = 0;
                } else if (alignment === "center") {
                    if (captionLine.captionText.length < 32) {
                        captionLine.positionX = parseInt((32 - captionLine.captionText.length) / 2);
                    } else {
                        captionLine.positionX = 0;
                    }
                } else if (alignment === "right") {
                    if (captionLine.captionText.length > 32 || captionLine.captionText.length === 0) {
                        captionLine.positionX = 0;
                    } else {
                        captionLine.positionX = parseInt(32 - captionLine.captionText.length);

                    }
                }
            });
        }
    },
    updateCaptionLinePosition: function (state, positionData) {
        var firstLine = true;
        while (positionData.id < 4) {
            if (firstLine) {
                var xOffset = positionData.x - state.captions[positionData.event].primaryLang[positionData.id].positionX;
                state.captions[positionData.event].primaryLang[positionData.id].positionY = positionData.y || 0;
                state.captions[positionData.event].primaryLang[positionData.id].positionX = positionData.x || 0;
            } else {
                var newXPosition = state.captions[positionData.event].primaryLang[positionData.id].positionX + xOffset;

                if (newXPosition > 32) {
                    state.captions[positionData.event].primaryLang[positionData.id].positionX = Math.abs(32 - state.captions[positionData.event].primaryLang[positionData.id].captionText.length);
                } else if (newXPosition < 0) {
                    state.captions[positionData.event].primaryLang[positionData.id].positionX = 0;
                } else {
                    state.captions[positionData.event].primaryLang[positionData.id].positionX = newXPosition;
                } state.captions[positionData.event].primaryLang[positionData.id].positionY = positionData.y;
            } positionData.y++;
            positionData.id++;
            firstLine = false;
        }
    },
    cutTextToNextLine: function (state, textToCut) {
        var selectedText = state.captions[textToCut.event].primaryLang[textToCut.line].captionText.slice(textToCut.index).trim();
        if (selectedText.length > 0) {
            state.captions[textToCut.event].primaryLang[textToCut.line + 1].captionText = selectedText + " " + state.captions[textToCut.event].primaryLang[textToCut.line + 1].captionText;

            state.captions[textToCut.event].primaryLang[textToCut.line].captionText = state.captions[textToCut.event].primaryLang[textToCut.line].captionText.substring(0, textToCut.index);
        }
    },
    updateCaptionText: function (state, captionData) {
        state.captions[captionData.id].primaryLang[captionData.line].captionText = captionData.text;
        state.captions[captionData.id].confidence = false;
    },
    updateSubtitleText: function (state, captionData) {
        state.captions[captionData.id].subtitleText = captionData.text;
        state.captions[captionData.id].confidence = false;
    },
    updateIncode: function (state, captionData) {
        try {
            var inputTimecode = captionData.timecode;
            var incode = state.projectSettings.incode;
            var framerate = state.projectSettings.framerate;
            var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate];
            var timecode = _Timecode.Timecode.init({ framerate: framerate, timecode: inputTimecode, drop_frame: dropFrame });
            timecode.subtract(incode);

            var tcSec = timecode.hours * 3600;
            tcSec += timecode.minutes * 60;
            tcSec += timecode.seconds;
            tcSec += timecode.frames / framerate;

            state[captionData.target][captionData.id].incode = captionData.timecode;
            state[captionData.target][captionData.id].incodeSec = tcSec;
        } catch (e) {
            console.log(e.message);
        }
    },
    updateCaptionOutcode: function (state, captionData) {
        try {
            var inputTimecode = captionData.timecode;
            var incode = state.projectSettings.incode;
            var framerate = state.projectSettings.framerate;
            var timecode = _Timecode.Timecode.init({
                framerate: framerate,
                timecode: inputTimecode,
                drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
            });
            timecode.subtract(incode);

            var tcSec = timecode.hours * 3600;
            tcSec += timecode.minutes * 60;
            tcSec += timecode.seconds;
            tcSec += timecode.frames / framerate;

            state.captions[captionData.id].outcode = captionData.timecode;
            state.captions[captionData.id].outcodeSec = tcSec;
        } catch (e) {
            console.log(e.message);
        }

    },
    updateCaptionTimecodeSec: function (state, timeData) {
        var offset = parseFloat(timeData.offset / 50);
        var currentIncodeSec = state.captions[timeData.id].incodeSec;
        var currentOutcodeSec = state.captions[timeData.id].outcodeSec;
        var incodeSec = parseFloat(currentIncodeSec) + offset;
        var outcodeSec = parseFloat(currentOutcodeSec) + offset;

        try {
            var incodeTc = _Timecode.Timecode.init({
                framerate: state.projectSettings.framerate,
                timecode: _NodeTc.fromSeconds(incodeSec, { frameRate: state.projectSettings.framerate }),
                drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
            });
        } catch (e) {
            console.log(e.message);
            var incodeTc = _Timecode.Timecode.init({
                framerate: state.projectSettings.framerate,
                timecode: "00:00:00:00",
                drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
            });
        }


        try {
            var outcodeTc = _Timecode.Timecode.init({
                framerate: state.projectSettings.framerate,
                timecode: _NodeTc.fromSeconds(outcodeSec, { frameRate: state.projectSettings.framerate }),
                drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
            });
        } catch (e) {
            console.log(e.message);
            var outcodeTc = _Timecode.Timecode.init({
                framerate: state.projectSettings.framerate,
                timecode: "00:00:00:00",
                drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
            });
        }

        incodeTc.add(state.projectSettings.incode);
        outcodeTc.add(state.projectSettings.incode);
        state.captions[timeData.id].incode = incodeTc.toString();
        state.captions[timeData.id].incodeSec = incodeSec;
        state.captions[timeData.id].outcode = outcodeTc.toString();
        state.captions[timeData.id].outcodeSec = outcodeSec;
    },
    updateCaptionIncodeSec: function (state, timeData) {
        var offset = parseFloat(timeData.offset / 50);
        var currentIncodeSec = state.captions[timeData.id].incodeSec;
        var incodeSec = parseFloat(currentIncodeSec) + offset;
        if (incodeSec > state.captions[timeData.id].outcodeSec) { // update the outcode by the same amount
            var outcodeSec = state.captions[timeData.id].outcodeSec + offset;
            var outcodeTc = _Timecode.Timecode.init({
                framerate: state.projectSettings.framerate,
                timecode: _NodeTc.fromSeconds(outcodeSec, { frameRate: state.projectSettings.framerate }),
                drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
            });
            outcodeTc.add(state.projectSettings.incode);
            state.captions[timeData.id].outcode = outcodeTc.toString();
            state.captions[timeData.id].outcodeSec = outcodeSec;
        }
        var incodeTc = _Timecode.Timecode.init({
            framerate: state.projectSettings.framerate,
            timecode: _NodeTc.fromSeconds(incodeSec, { frameRate: state.projectSettings.framerate }),
            drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
        });

        incodeTc.add(state.projectSettings.incode);
        state.captions[timeData.id].incode = incodeTc.toString();
        state.captions[timeData.id].incodeSec = incodeSec;
    },
    updateCaptionOutcodeSec: function (state, timeData) {
        var offset = parseFloat(timeData.offset / 50);
        var currentOutcodeSec = state.captions[timeData.id].outcodeSec;
        var outcodeSec = parseFloat(currentOutcodeSec) + offset;
        if (outcodeSec < state.captions[timeData.id].incodeSec) { // update the incode by the same amount
            var incodeSec = state.captions[timeData.id].incodeSec + offset;
            if (incodeSec < 0) {
                incodeSec = 0;
            }
            var incodeTc = _Timecode.Timecode.init({
                framerate: state.projectSettings.framerate,
                timecode: _NodeTc.fromSeconds(incodeSec, { frameRate: state.projectSettings.framerate }),
                drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
            });
            incodeTc.add(state.projectSettings.incode);
            state.captions[timeData.id].incode = incodeTc.toString();
            state.captions[timeData.id].incodeSec = incodeSec;
        }
        var outcodeTc = _Timecode.Timecode.init({
            framerate: state.projectSettings.framerate,
            timecode: _NodeTc.fromSeconds(outcodeSec, { frameRate: state.projectSettings.framerate }),
            drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
        });

        outcodeTc.add(state.projectSettings.incode);
        state.captions[timeData.id].outcode = outcodeTc.toString();
        state.captions[timeData.id].outcodeSec = outcodeSec;
    },

    setIncode: function (state, captionEvent) {
        var currentTime = state.currentTime;
        var currentTimeSec = state.currentTimeSec;
        var view = state.view;
        if (currentTime != null && currentTime != '') {
            if (view === 'audio description' && state.audioDescription[captionEvent]) {
                state.audioDescription[captionEvent].incode = currentTime;
                state.audioDescription[captionEvent].incodeSec = currentTimeSec;
            } else if (state.captions[captionEvent]) {
                state.captions[captionEvent].incode = currentTime;
                state.captions[captionEvent].incodeSec = currentTimeSec;
            }

        }
    },
    setOutcode: function (state, captionEvent) {
        var currentTime = state.currentTime;
        var currentTimeSec = state.currentTimeSec;

        if (currentTime != null && currentTime != '' && state.captions[captionEvent]) {
            state.captions[captionEvent].outcode = currentTime;
            state.captions[captionEvent].outcodeSec = currentTimeSec;
        }
    },

    convertTranscriptToCaptions: function (state, contents) {
        var captions = [];
        var captionEvent = ccLib.createCaptionEvent();
        var currentLine = 0;
        var frameRate = globalSetting.frameRateMap[state.projectSettings.framerate.toString()];
        var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate];

        contents.ops.forEach(transcriptLine => {
            if (transcriptLine.attributes && transcriptLine.attributes.link) {
                if (!captionEvent.incode) {
                    captionEvent.incode = "00:00:00:00";
                    captionEvent.incodeSec = "0";
                    captionEvent.outcode = "00:00:02:00";
                    captionEvent.outcodeSec = "2.00"
                }
                captions.push(captionEvent);
                captionEvent = ccLib.createCaptionEvent();
                captionEvent.incode = transcriptLine.insert;
                captionEvent.incodeSec = ccLib.tc.tcToSec(transcriptLine.insert);
                captionEvent.outcodeSec = captionEvent.incodeSec + state.projectSettings.defaultEventDuration;
                captionEvent.outcode = ccLib.tc.secToTc(captionEvent.outcodeSec, frameRate, dropFrame);
                currentLine = 0;
            } else if (transcriptLine.insert) {
                eol.lf(transcriptLine.insert).split("\n").forEach(captionLine => {
                    if (captionLine.trim().length > 0) {
                        captionEvent.primaryLang[currentLine].captionText = captionLine.trim();
                        currentLine++;
                        if (currentLine > 3) {
                            if (!captionEvent.incode) {
                                captionEvent.incode = "00:00:00:00";
                                captionEvent.incodeSec = "0";
                                captionEvent.outcode = "00:00:02:00";
                                captionEvent.outcodeSec = "2.00"
                            }
                            captions.push(captionEvent);
                            captionEvent = ccLib.createCaptionEvent();
                            currentLine = 0;
                        }
                    }
                });
            } else {
                console.log("Unknown option");
                console.log(transcriptLine);
            }
        });

        captions.push(captionEvent);
        state.captions = captions;
    },
    saveDocument: function (state, contents) {
        var blob = new Blob([contents.text], { type: "text/plain;charset=utf-8" });
        fileSaver.saveAs(blob, state.projectSettings.title + "." + contents.ext);
        app.$notify({ title: 'Transcript Saved', type: 'success', text: 'Transcript saved to disk' });
    },
    saveProject: function (state) {
        let loader = app.$loading.show();
        let userInfo = firebase.auth().currentUser;
        let projectFileRef = storage.ref().child('users/' + userInfo.uid + '/projects/' + state.projectSettings.uid + '.ccprj');
        let uploadJob = projectFileRef.putString(JSON.stringify(state));
        uploadJob.on('state_changed', function (snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
        },
            function (error) {
                console.log(error.message);
                app.$notify({ title: 'Project Save', type: 'error', text: 'Failed to save project. ' + error.message });
                loader.hide();
            },
            function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...

                db.collection("users").doc(userInfo.uid).collection('projects').doc(state.projectSettings.uid).set({
                    title: state.projectSettings.title,
                    uid: state.projectSettings.uid,
                    author: userInfo.email,
                    appVersion: state.version,
                    frameRate: state.projectSettings.framerate,
                    updated: Date.now(),
                    archived: false
                }).then(res => {
                    app.$notify({ title: 'Project Save', type: 'success', text: 'Project saved successfully.' });
                    loader.hide();
                }).catch(error => {
                    console.log(error.message);
                    app.$notify({ title: 'Project Save', type: 'error', text: 'Failed to save project. ' + error.message });
                    loader.hide();
                });
            });
    },
    exportProject: function (state) {
        if (state.view === 'subtitle') {
            state.captions.forEach(caption => {
                var subtitleText = caption.subtitleText;
                var subtitleLines = subtitleText.split("\n");
                var numberOfLines = subtitleLines.length;
                for (var i = 0; i < numberOfLines; i++) {
                    if (i > 3) {
                        caption.primaryLang[3].captionText += " " + subtitleLines[i];
                        caption.primaryLang[3].positionX = Math.max(0, parseInt((32 - caption.primaryLang[3].captionText.length) / 2));
                    } else {
                        caption.primaryLang[i].captionText = subtitleLines[i];
                        caption.primaryLang[i].positionY = 11 + i;
                        caption.primaryLang[i].positionX = Math.max(0, parseInt((32 - caption.primaryLang[i].captionText.length) / 2));
                    }
                }
            });
        }
        var projectData = JSON.stringify(state);
        var blob = new Blob([projectData], { type: "text/plain;charset=utf-8" });
        fileSaver.saveAs(blob, state.projectSettings.title + ".ccprj");
        app.$notify({ title: 'Project Saved', type: 'success', text: 'Project saved to disk' });
    },
    exportDataFile: function (state, fileOptions) {
        try {
            var title = state.projectSettings.title.replace(/[^a-z0-9]/gi, '').toUpperCase();
            var ext = '.' + fileOptions.format.toLowerCase();
            var debug = false;
            var fileName = title + ext;
            var outputData = {
                ad: state.audioDescription,
                captions: state.captions,
                settings: state.projectSettings
            }

            var blob = new Blob([JSON.stringify(outputData)], { type: "text/plain;charset=utf-8" });

            fileSaver.saveAs(blob, fileName);
            app.$notify({ title: 'Data Export', type: 'success', text: 'Export complete' });
        } catch (e) {
            console.log(e.message);
        }
    },
    appendToTranscriptText: function (state, text) {
        state.transcription.text += text;
    },
    updateTranscriptionText: function (state, text) {
        state.transcription.text = text;
    },
    exportTranscriptFile: function (state, transcriptOptions) {
        try {
            var title = state.projectSettings.title.replace(/[^a-z0-9]/gi, '').toUpperCase();
            var ext = '.' + transcriptOptions.format.toLowerCase();

            var fileName = title + ext;
            var outputData = "Title: " + fileName.toUpperCase() + "\n";
            outputData += "Date: " + new Date() + "\n\n"; // data to be written to file;
            var captions = JSON.parse(JSON.stringify(state.captions));
            for (var i = 0; i < captions.length; i++) {
                if (transcriptOptions.tc === "SMPTE") {
                    outputData += captions[i].incode + " --> " + captions[i].outcode + "\n";
                } else if (transcriptOptions.tc === "Seconds") {
                    outputData += captions[i].incodeSec + " --> " + captions[i].outcodeSec + "\n";
                }

                outputData += captions[i].subtitleText + "\n\n";
            }


            if (ext == ".docx") {
                outputData = eol.crlf(outputData);
                const doc = new Document();
                // const paragraph = new Paragraph(outputData.trim());

                doc.addSection({
                    properties: {},
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun(outputData)
                            ],
                        }),
                    ],
                });

                Packer.toBlob(doc).then(blob => {
                    fileSaver.saveAs(blob, fileName);
                });
            } else {
                var blob = new Blob([outputData.trim()], { type: "text/plain;charset=utf-8" });
                fileSaver.saveAs(blob, fileName);
            }

            app.$notify({ title: 'Transcript Export', type: 'success', text: 'Export complete' });
        } catch (e) {
            console.log(e.message);
            state.status.progress = 100;
            state.status.msg = "ERROR";
            state.status.status = "Complete";
            state.status.display = false;

            app.$notify({ title: 'Transcript Export Failed', type: 'error', text: e.message });
        }
    },
    exportCaptionFile: function (state) {
        let appEnv = process.env.VUE_APP_ENV;
        let appOs = process.env.VUE_APP_OS;
        let self = this;
        let defaultEncoding = "utf-8";
        var title = state.projectSettings.title.replace(/[^a-z0-9]/gi, '').toUpperCase();
        var ext = '.' + state.captionFormat.toLowerCase();
        var frameRate = globalSetting.frameRateMap[state.projectSettings.framerate.toString()];
        var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate];

        if (ext === '.dfxp' || ext === '.ttml') {
            ext = '.xml';
        } else if (ext === ".avid ds (txt)") {
            ext = '.txt';
        }

        var fileName = title + ext;
        var outputData = ''; // data to be written to file;
        var captions = JSON.parse(JSON.stringify(state.captions.filter(captionEvent => {
            return globalFunc.concatCaptionText(captionEvent.primaryLang).trim().length > 0;
        })));

        captions = ccLib.calculateTimecodes(captions, frameRate, dropFrame);

        outputData = ccLib.export(captions, state.captionFormat.toLowerCase(), frameRate, dropFrame, state.metadata, state.webVttOptions, false, state.projectSettings.incode);

        if (state.captionFormat.toLowerCase() === "stl") {
            defaultEncoding = "hex";
        }

        if (appEnv === "desktop" && appOs === "Windows") {
            const { remote } = require('electron'),
                fs = require('fs'),
                dialog = remote.dialog,
                WIN = remote.getCurrentWindow();
            let options = {
                title: "Save Caption File",
                buttonLabel: "Save File",
                defaultPath: fileName,
                filters: [
                    {
                        name: 'Custom File Type',
                        extensions: [ext]
                    }, {
                        name: 'All Files',
                        extensions: ['*']
                    }
                ]
            }
            dialog.showSaveDialog(WIN, options).then(directory => {
                console.log(directory.filePath);

                fs.writeFile(directory.filePath, outputData, defaultEncoding, function (err) {
                    if (err) {
                        app.$notify({
                            title: 'File Error', text: 'Error saving file', type: 'error' /* warn, error, success */
                        });
                        throw err;
                    }
                    app.$notify({
                        title: 'File Saved', text: 'File successfully saved', type: 'success' /* warn, error, success */
                    });
                });
            });
        } else {
            console.log("saving using web");
            if (defaultEncoding === "hex") {
                var byteArray = new Uint8Array(outputData.match(/.{2}/g).map(e => parseInt(e, 16)));
                var blob = new Blob([byteArray], { type: "application/octet-stream" });
            } else if (defaultEncoding === "utf-16le") {
                var blob = new Blob([outputData], { type: "text/plain;charset=utf-16le" });
            } else {
                var blob = new Blob([outputData], { type: "text/plain;charset=utf-8" });
            }

            fileSaver.saveAs(blob, fileName, { autoBom: true });
        }
    },

    importCaptionsFromProject: function (state, project) {
        if (state.view === 'transcript') {
            state.view = "default";
        }
        console.log(project.captions);
        state.captions = project.captions;
        var highestId = (Math.max.apply(Math, state.captions.map(function (o) {
            return o.uniqueId;
        })));
        for (var i = 0; i < highestId + 2; i++) {
            ccLib.createCaptionEvent()
        }
    },

    importSettingsFromProject: function (state, project) {
        state.projectSettings = project.projectSettings;
    },
    importMetadataFromProject: function (state, project) {
        state.metadata = project.metadata;
    },
    importSpeakersFromProject: function (state, project) {
        if (project.speakers) {
            state.speakers = project.speakers;
        }
    },
    importTranscriptFromProject: function (state, project) {
        if (project.transcription) {
            state.transcription = project.transcription;
        }
    },
    importAiTranscriptFromProject: function (state, project) {
        if (project.assetTranscription) {
            state.assetTranscription = project.assetTranscription;
        }
    },
    importPlainTextFile: function (state, settings) {
        if (settings.fileSize > 0) {
            try {
                state.captions = [];
                var importOption = state.plainTextOptions.importOption;
                var maxLines = parseInt(state.plainTextOptions.maxNumberOfLines);
                var maxCharacters = state.plainTextOptions.maxNumberOfCharacters;
                var seconds = 0;
                var reader = new FileReader();
                reader.onload = function (e) {
                    var content = eol.lf(reader.result);
                    if (importOption === 'option1') { // option1- import each line as is
                        content = content.replace(/\t/g, " ");
                        var contentLines = content.split("\n").filter(function (el) {
                            return el != null && el.trim() != "";
                        });

                        for (var i = 0; i < contentLines.length; i++) {
                            seconds += (1 / state.projectSettings.framerate);
                            var captionEvent = ccLib.createCaptionEvent();
                            for (var k = 0; k < maxLines && i < contentLines.length; k++, i++) {
                                captionEvent.priaryLang[k].captionText = contentLines[i];
                            }

                            var calcTimecodes = globalFunc.calculateTimecodeFromText(captionEvent, state.projectSettings.framerate, seconds, state.plainTextOptions.avgWordsPerSecond, state.projectSettings.incode);
                            captionEvent = calcTimecodes.event;
                            seconds = calcTimecodes.time;

                            state.captions.push(captionEvent);
                            i += k - 1;
                        }
                    } else { // option2 - combine lines to import
                        var word = 0;
                        var currentLine = 1;
                        var punctuation = state.plainTextOptions.punctuation;
                        var acceptedPunctuation = [
                            '?',
                            '.',
                            ':',
                            ';',
                            '!',
                            ']'
                        ];
                        var captionEvent = ccLib.createCaptionEvent();
                        var currentCaptionLine = 0;
                        // Sanitize input
                        content = content.replace(/\t/g, " ").replace(/\n\n/g, "\n").replace(/\n/g, " ");
                        content = content.split(" ");
                        for (var j = 0; j < content.length; j++) {
                            var word = content[j];
                            if (maxCharacters > 0 && captionEvent.primaryLang[0].captionText != "" && (captionEvent.captionText.split('<br>')[captionEvent.captionText.split('<br>').length - 1] + word).length > maxCharacters) {
                                if (currentLine == maxLines) {
                                    var calcTimecodes = globalFunc.calculateTimecodeFromText(captionEvent, state.projectSettings.framerate, seconds, state.plainTextOptions.avgWordsPerSecond, state.projectSettings.incode);
                                    captionEvent = calcTimecodes.event;
                                    seconds = calcTimecodes.time;
                                    state.captions.push(captionEvent);
                                    var captionEvent = ccLib.createCaptionEvent();
                                    captionEvent.captionText = word + " ";
                                    currentLine = 1;
                                } else {
                                    captionEvent.captionText += ("<br>" + word + " ");
                                    currentLine++
                                }
                            } else if (punctuation && acceptedPunctuation.indexOf(word[word.length - 1]) > -1) {
                                captionEvent.captionText += word;
                                var calcTimecodes = globalFunc.calculateTimecodeFromText(captionEvent, state.projectSettings.framerate, seconds, state.plainTextOptions.avgWordsPerSecond, state.projectSettings.incode);
                                captionEvent = calcTimecodes.event;
                                seconds = calcTimecodes.time;
                                state.captions.push(captionEvent);
                                var captionEvent = ccLib.createCaptionEvent();
                                currentLine = 1;
                            } else {
                                captionEvent.captionText += (word + " ");
                            }
                        }
                    } state.captions.forEach(caption => {
                        caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
                    });
                }
                reader.readAsText(settings.srcFile);
                app.$notify({ title: 'File Import', type: 'success', text: 'File imported successfully' });
            } catch (e) {
                console.log(e.message);
                app.$notify({ title: 'File Import', type: 'error', text: 'Project file failed to import' });
            }
        }
    },
    importCaptionFile: function (state, settings) {
        var captions = [];
        if (state.view === 'transcript') {
            state.view = "default";
        }

        var captionData = settings.captionData;
        var frameRate = globalSetting.frameRateMap[state.projectSettings.framerate.toString()];
        var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate];
        var incode = settings.fileIncode;
        var fileExt = settings.fileExt;
        var debug = false;

        captions = ccLib.import(captionData, fileExt, frameRate, dropFrame);
        captions = ccLib.offset(captions, frameRate, dropFrame, ccLib.getAutomaticOffsetTime(captions), 'subtract');
        captions = ccLib.applyIncodeOffset(state.projectSettings.incode, captions, frameRate, dropFrame);

        if (settings.spliceOption) {
            state.captions.splice(state.selectedCaptionEvent, 0, ...captions);
            state.captions.forEach(caption => {
                caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
            });
        } else {
            state.selectedCaptionEvent = 0;
            state.captions = captions;
            state.captions.forEach(caption => {
                caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
            });
        }
    },
    updateOption: function (state, option) {
        state[option.type] = option.value;
    },
    applyOffset: function (state, offsetParams) {
        var offset = offsetParams.offset;
        var projectIncode = state.projectSettings.incode;
        var frameRate = globalSetting.frameRateMap[state.projectSettings.framerate.toString()];
        var dropFrame = globalSetting.tcFrameMap[state.projectSettings.framerate];
        var i = offsetParams.offsetLine - 1;
        if (i < 0) {
            i = 0;
        }
        for (i; i < state.captions.length; i++) {
            try {
                if ((state.multiSelect.enabled && state.multiSelect.captionEvents.indexOf(i) > -1) || !state.multiSelect.enabled) {
                    var captionEvent = state.captions[i];
                    var incode = _Timecode.Timecode.init({
                        framerate: state.projectSettings.framerate,
                        timecode: captionEvent.incode.replace(";", ":"),
                        drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
                    });

                    var outcode = _Timecode.Timecode.init({
                        framerate: state.projectSettings.framerate,
                        timecode: captionEvent.outcode.replace(";", ":"),
                        drop_frame: globalSetting.tcFrameMap[state.projectSettings.framerate]
                    });

                    if (offsetParams.offsetType == "Add") {
                        incode.add(offset);
                        outcode.add(offset);

                    } else {
                        try {
                            incode.subtract(offset)
                        } catch (e) {
                            console.log(e.message);
                            incode = "00:00:00:00";
                        }

                        try {
                            outcode.subtract(offset);
                        } catch (e) {
                            console.log(e.message);
                            outcode = "00:00:00:00";
                        }
                    } state.captions[i].incode = incode.toString();
                    state.captions[i].outcode = outcode.toString();

                    try {
                        incode.subtract(projectIncode);
                    } catch (e) {
                        console.log(e.message);
                        incode = "00:00:00:00";
                    }
                    try {
                        outcode.subtract(projectIncode);
                    } catch (e) {
                        console.log(e.message);
                        outcode = "00:00:00:00";
                    }

                    state.captions[i].incodeSec = _NodeTc.toSeconds(incode.toString().replace(";", ":"));
                    state.captions[i].outcodeSec = _NodeTc.toSeconds(outcode.toString().replace(";", ":"));
                }
            } catch (e) {
                app.$notify({
                    title: 'Apply Offset',
                    type: 'error',
                    text: e.message + ": " + state.captions[i]
                });

                console.log(e.message);
            }
        }

        app.$notify({ title: 'Apply Offset', type: 'success', text: 'Offset Applied' });
    },
    updatePlaybackRate: function (state, playback) {
        state.playbackRate = playback;
        if (mediaPlayer.speed != playback) {
            mediaPlayer.speed = playback;
            app.$notify({
                title: 'Playback Speed',
                type: 'success',
                text: 'Playback speed set to ' + playback
            });
        }
    },
    updateStatus: function (state, currentStatus) {

        if (currentStatus.progress != null) {
            state.status.progress = currentStatus.progress;
        }
        if (currentStatus.msg != null) {
            state.status.msg = currentStatus.msg;
        }
        if (currentStatus.status != null) {
            state.status.status = currentStatus.status;
        }

        if (currentStatus.progress == 100) {
            state.status.display = false;
        } else if (currentStatus.display != null) {
            state.status.display = currentStatus.display;
        }

    },
    fixReversal: function (state) {
        try { // Fix Reversal
            state.captions.forEach(function (captionEvent) {
                if (captionEvent && captionEvent.incodeSec > captionEvent.outcodeSec) {
                    var incode = captionEvent.incode;
                    var incodeMs = captionEvent.incodeMs;
                    var incodeSec = captionEvent.incodeSec;
                    var outcode = captionEvent.outcode;
                    var outcodeMs = captionEvent.outcodeMs;
                    var outcodeSec = captionEvent.outcodeSec;
                    captionEvent.incode = outcode;
                    captionEvent.incodeMs = outcodeMs;
                    captionEvent.incodeSec = outcodeSec;
                    captionEvent.outcode = incode;
                    captionEvent.outcodeMs = incodeMs;
                    captionEvent.outcodeSec = incodeSec;
                }
            });
            app.$notify({ title: 'Apply Reversal', type: 'success', text: 'Fix reversal applied' });
        } catch (e) {
            console.log(e.message);
            app.$notify({ title: 'Apply Reversal', type: 'error', text: 'Fix reversal failed to apply' });
        }
    },
    fixOverlap: function (state) {
        for (var i = 0; i < state.captions.length; i++) {
            try {
                if (state.captions[i] && state.captions[i - 1] && state.captions[i - 1].outcodeSec > state.captions[i].incodeSec) {
                    state.captions[i - 1].outcode = state.captions[i].incode;
                    state.captions[i - 1].outcodeSec = state.captions[i].incodeSec;
                    state.captions[i - 1].outcodeMs = state.captions[i].incodeMs;
                }
            } catch (e) {
                console.log(e.message);
            }
        }
    },
    fixOrder: function (state, id) {
        try {
            var selectedCaptionEvent = state.captions[state.selectedCaptionEvent];
            state.captions.sort(function (a, b) {
                return parseFloat(a.incodeSec) - parseFloat(b.incodeSec);
            });

            state.selectedCaptionEvent = state.captions.indexOf(selectedCaptionEvent);

            app.$notify({ title: 'Order Correction', type: 'success', text: 'Order correction applied' });
        } catch (e) {
            console.log(e.message);
            app.$notify({ title: 'Order Correction', type: 'error', text: 'Order correction failed' });
        }
    },
    uppercaseAll: function (state) {
        try {
            for (var i = 0; i < state.captions.length; i++) {
                if ((state.multiSelect.enabled && state.multiSelect.captionEvents.indexOf(i) > -1) || !state.multiSelect.enabled) {
                    state.captions[i].subtitleText = state.captions[i].subtitleText.toUpperCase();
                    for (var j = 0; j < 4; j++) {
                        state.captions[i].primaryLang[j].captionText = state.captions[i].primaryLang[j].captionText.toUpperCase();
                    }
                }
            }
            app.$notify({ title: 'Uppercase Captions', type: 'success', text: 'Uppercase styling applied' });
        } catch (e) {
            console.log(e.message);
            app.$notify({ title: 'Uppercase Captions', type: 'error', text: e.message });
        }
    },
    updateSubViewerOption: function (state, option) {
        if (state.subViewerOption === option) {
            state.subViewerOption = "";
        } else {
            state.subViewerOption = option;
        }

    },
    toggleMultiSelect: function (state) {
        state.multiSelect.enabled = !state.multiSelect.enabled;
        if (!state.multiSelect.enabled) {
            state.multiSelect.captionEvents = [];
        }
    },
    search: function (state, searchInfo) {
        state.searchOptions.searchResults = [];
        if (searchInfo.confidence) {
            for (var i = 0; i < state.captions.length; i++) {
                if (state.captions[i].confidence <= searchInfo.confidenceValue) {
                    state.searchOptions.searchResults.push(i);
                }
            }
        } else if (searchInfo.errors) {
            for (var i = 0; i < state.captions.length; i++) {
                if (state.captions[i].primaryLang.filter(captionLine => {
                    return captionLine.captionText.length > 32;
                }).length > 0) {
                    state.searchOptions.searchResults.push(i);
                }
            }
        } else {
            for (var i = 0; i < state.captions.length; i++) {
                if (state.view === 'subtitle') {
                    if (searchInfo.caseSensitive && state.captions[i].subtitleText.indexOf(searchInfo.searchTerm) > -1) {
                        state.searchOptions.searchResults.push(i);
                    } else if (state.captions[i].subtitleText.toUpperCase().indexOf(searchInfo.searchTerm.toUpperCase()) > -1) {
                        state.searchOptions.searchResults.push(i);
                    }
                } else if (state.captions[i].primaryLang.filter(captionLine => {
                    if (searchInfo.caseSensitive) {
                        return captionLine.captionText.indexOf(searchInfo.searchTerm) > -1;
                    } else {
                        return captionLine.captionText.toUpperCase().indexOf(searchInfo.searchTerm.toUpperCase()) > -1;
                    }
                }).length > 0) {
                    state.searchOptions.searchResults.push(i);
                }
            }
        }
    },
    replace: function (state, searchInfo) {
        var searchTerm = new RegExp(searchInfo.searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi");
        if (searchInfo.replaceAll) {
            for (var i = 0; i < state.searchOptions.searchResults.length; i++) {
                var captionEvent = state.searchOptions.searchResults[i];

                if (state.view === 'subtitle') {
                    state.captions[captionEvent].subtitleText = state.captions[captionEvent].subtitleText.replace(searchTerm, searchInfo.replaceTerm);
                } else {
                    state.captions[captionEvent].primaryLang.forEach(captionLine => {
                        captionLine.captionText = captionLine.captionText.replace(searchTerm, searchInfo.replaceTerm);
                        searchTerm.lastIndex = 0;
                    });
                }
            }
        } else {
            var captionEvent = state.searchOptions.searchResults[state.searchOptions.searchResult];
            if (state.view === 'subtitle') {
                state.captions[captionEvent].subtitleText = state.captions[captionEvent].subtitleText.replace(searchTerm, searchInfo.replaceTerm);
            } else {
                state.captions[captionEvent].primaryLang.forEach(captionLine => {
                    captionLine.captionText = captionLine.captionText.replace(searchTerm, searchInfo.replaceTerm);
                });
            }
        }
    },
    updateSearchResult: function (state, resultId) {
        if (resultId === 0) {
            state.searchOptions.searchResult = 0;
        } else if (state.searchOptions.searchResult + resultId > -1 && state.searchOptions.searchResult + resultId < state.searchOptions.searchResults.length) {
            state.searchOptions.searchResult += resultId;
        } else {
            state.searchOptions.searchResult = 0;
        }
    },
    selectCaptionEvent: function (state, id) {
        if (id || id === 0) {
            state.selectedCaptionEvent = id;
        }
    },
    focusCaptionEvent: function (state, captionEvent) {
        try {
            if (state.view === 'default') {
                document.getElementById('TimelineEventEditorInputA').focus();
            } else if (document.getElementById('captionEventInput' + captionEvent) != null) {
                document.getElementById('captionEventInput' + captionEvent).focus();
            } else {
                $('section').scrollTop(captionEvent * 180);
            }
        } catch (e) {
            console.log(e.message);
        }

    },
    selectEvent: function (state, id) {
        var index = state.multiSelect.captionEvents.indexOf(id);
        if (index > -1) {
            state.multiSelect.captionEvents.splice(index, 1);
        } else {
            state.multiSelect.captionEvents.push(id);
        }
    },
    selectMultiEvent: function (state, id) {
        var startingLine = parseInt(state.multiSelect.captionEvents.sort(function (a, b) {
            return a - b
        })[0]) || 0;

        state.multiSelect.captionEvents = [];
        for (startingLine; startingLine <= parseInt(id); startingLine++) {
            state.multiSelect.captionEvents.push(startingLine);
        };
    },

    cueToCaptionEvent: function (state, captionEvent) {
        var cueToTime = state.captions[captionEvent].incodeSec || 0;
        mediaPlayer.currentTime = cueToTime;
    },
    cueToAdEvent: function (state, adEvent) {
        var cueToTime = state.audioDescription[adEvent].incodeSec || 0;
        mediaPlayer.currentTime = cueToTime;
    },
    cueToTimeTc: function (state, tc) {
        try {
            var incode = state.projectSettings.incode;
            var framerate = state.projectSettings.framerate;
            var dropFrame = globalSetting.tcFrameMap[framerate];

            var timecode = _Timecode.Timecode.init({ framerate: framerate, timecode: tc, drop_frame: dropFrame });

            if (incode != "00:00:00:00") {
                timecode.subtract(incode);
            }

            var seconds = timecode.hours * 3600;
            seconds += timecode.minutes * 60;
            seconds += timecode.seconds;
            seconds += timecode.frames / framerate;

            mediaPlayer.currentTime = seconds;
        } catch (e) {
            console.log(seconds);
            console.log("Unable to update Current Time: " + e.message);
        }
    },
    cueToTimeSec: function (state, seconds) {
        mediaPlayer.currentTime = seconds;
    },
    updateWps: function (state, average) {
        if (average < 11 && average > 0) {
            state.plainTextOptions.avgWordsPerSecond = average;
        }
    },
    updateMaxLines: function (state, maxLines) {
        state.plainTextOptions.maxNumberOfLines = maxLines;
    },
    updateImportOption: function (state, option) {
        state.plainTextOptions.importOption = option;
    },
    updateMaxCharacter: function (state, max) {
        state.plainTextOptions.maxCharacters = max;
    },
    updateMaxNumberCharacter: function (state, max = 1) {
        if (max < 101 && max >= 0) {
            state.plainTextOptions.maxNumberOfCharacters = max;
        }
    },
    updatePunctuation: function (state, punctuation) {
        state.plainTextOptions.punctuation = punctuation;
    },
    togglePlayback: function (state) {
        mediaPlayer.togglePlay();
    },
    copySubtitlesToCaptions: function (state) {
        state.captions.forEach(caption => {
            var subtitleText = caption.subtitleText.trim();
            var subtitleLines = subtitleText.split("\n");
            var numberOfLines = subtitleLines.length;

            if (numberOfLines < 4) {
                for (var i = 0; i < 4; i++) {
                    if (subtitleLines[i]){
                        caption.primaryLang[i].captionText = subtitleLines[i].trim();
                    } else {
                        caption.primaryLang[i].captionText = "";
                    }
                    
                    caption.primaryLang[i].positionY = 11 + i;
                    caption.primaryLang[i].positionX = Math.max(0, parseInt((32 - caption.primaryLang[i].captionText.length) / 2));
                }
            } else {
                for (var i = 0; i < numberOfLines; i++) {
                    if (i > 3) {
                        (caption.primaryLang[3].captionText += " " + subtitleLines[i]).trim();
                        caption.primaryLang[3].positionX = Math.max(0, parseInt((32 - caption.primaryLang[3].captionText.length) / 2));
                    } else {
                        caption.primaryLang[i].captionText = subtitleLines[i].trim();
                        caption.primaryLang[i].positionY = 11 + i;
                        caption.primaryLang[i].positionX = Math.max(0, parseInt((32 - caption.primaryLang[i].captionText.length) / 2));
                    }
                }
            }
            
        });
    },
    copyCaptionsToSubtitles: function (state) {
        state.captions.forEach(caption => {
            caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
        });
    },
    updateView: function (state, view) {
        if (state.view === 'subtitle') {
            state.captions.forEach(caption => {
                var subtitleText = caption.subtitleText;
                var subtitleLines = subtitleText.split("\n");
                var numberOfLines = subtitleLines.length;
                for (var i = 0; i < numberOfLines; i++) {
                    if (i > 3) {
                        caption.primaryLang[3].captionText += " " + subtitleLines[i];
                        caption.primaryLang[3].positionX = Math.max(0, parseInt((32 - caption.primaryLang[3].captionText.length) / 2));
                    } else {
                        caption.primaryLang[i].captionText = subtitleLines[i];
                        caption.primaryLang[i].positionY = 11 + i;
                        caption.primaryLang[i].positionX = Math.max(0, parseInt((32 - caption.primaryLang[i].captionText.length) / 2));
                    }
                }
            });
        } else if (state.view != 'transcript' && view === "subtitle") {
            state.captions.forEach(caption => {
                caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
            });
        } else {
            state.captions.forEach(caption => {
                caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
            });
        } state.selectedCaptionEvent = 0;
        state.view = view;

    },
    clearEmptyLines: function (state, eventId) {
        var linesWithText = state.captions[eventId].primaryLang.filter(captionLine => {
            return captionLine.captionText.length > 0;
        });

        for (var i = 0; i < 4; i++) {
            if (linesWithText[i]) {
                state.captions[eventId].primaryLang[i].captionText = linesWithText[i].captionText;
                state.captions[eventId].primaryLang[i].positionX = linesWithText[i].positionX;
                state.captions[eventId].primaryLang[i].positionY = linesWithText[i].positionY;
                state.captions[eventId].primaryLang[i].bold = linesWithText[i].bold;
                state.captions[eventId].primaryLang[i].italics = linesWithText[i].italics;
                state.captions[eventId].primaryLang[i].underline = linesWithText[i].underline;
            } else {
                state.captions[eventId].primaryLang[i].captionText = "";
                state.captions[eventId].primaryLang[i].bold = false;
                state.captions[eventId].primaryLang[i].italics = false;
                state.captions[eventId].primaryLang[i].underline = false;
            }
        }
    },
    mergeSubtitleEvent: function (state, captionEvent) {
        state.selectCaptionEvent = captionEvent - 1;
        state.captions[captionEvent - 1].subtitleText += " " + state.captions[captionEvent].subtitleText;
        state.captions.splice(captionEvent, 1);
    },
    mergeEvent: function (state, captionEvent) {
        var projectIncode = state.projectSettings.incode;
        var framerate = state.projectSettings.framerate;
        var dropframe = globalSetting.tcFrameMap[framerate];

        var linesWithText = state.captions[captionEvent].primaryLang.filter(captionLine => {
            return captionLine.captionText.length > 0;
        });

        if (linesWithText.length > 0) {

            var linesWithTextPrevEvent = state.captions[captionEvent - 1].primaryLang.filter(captionLine => {
                return captionLine.captionText.length > 0;
            });

            for (var i = 0; i < linesWithText.length; i++) {
                if (linesWithTextPrevEvent.length + i < 4) {
                    state.captions[captionEvent - 1].primaryLang[linesWithTextPrevEvent.length + i].captionText += linesWithText[i].captionText;
                    state.captions[captionEvent - 1].primaryLang[linesWithTextPrevEvent.length + i].positionX = linesWithText[i].positionX;
                    state.captions[captionEvent - 1].primaryLang[linesWithTextPrevEvent.length + i].positionY = linesWithText[i].positionY;
                    state.captions[captionEvent - 1].primaryLang[linesWithTextPrevEvent.length + i].bold = linesWithText[i].bold;
                    state.captions[captionEvent - 1].primaryLang[linesWithTextPrevEvent.length + i].italics = linesWithText[i].italics;
                    state.captions[captionEvent - 1].primaryLang[linesWithTextPrevEvent.length + i].underline = linesWithText[i].underline;
                } else {
                    state.captions[captionEvent - 1].primaryLang[3].captionText += " " + linesWithText[i].captionText;
                    state.captions[captionEvent - 1].primaryLang[3].positionX = linesWithText[i].positionX;
                    state.captions[captionEvent - 1].primaryLang[3].positionY = linesWithText[i].positionY;
                    state.captions[captionEvent - 1].primaryLang[3].bold = linesWithText[i].bold;
                    state.captions[captionEvent - 1].primaryLang[3].italics = linesWithText[i].italics;
                    state.captions[captionEvent - 1].primaryLang[3].underline = linesWithText[i].underline;
                }
            }

            var incodeSec = state.captions[captionEvent].incodeSec;
            var outcodeSec = state.captions[captionEvent].outcodeSec;

            var duration = Math.abs(parseFloat(outcodeSec - incodeSec));
            state.captions[captionEvent - 1].outcodeSec = parseFloat(state.captions[captionEvent - 1].outcodeSec) + duration;
            state.captions[captionEvent - 1].outcode = globalFunc.secToTcString(state.captions[captionEvent - 1].outcodeSec, framerate, dropframe, projectIncode);

        }

        state.captions.splice(captionEvent, 1);
    },
    splitSubtitleEvent: function (state, splitDetails) {
        var projectIncode = state.projectSettings.incode;
        var framerate = state.projectSettings.framerate;
        var dropframe = globalSetting.tcFrameMap[framerate];
        var newCaptionEvent = ccLib.createCaptionEvent();
        var captionEvent = splitDetails.id;
        var index = splitDetails.index;
        if (index != 0) {
            var captionText01 = state.captions[captionEvent].subtitleText.substring(0, index);
            var captionText02 = state.captions[captionEvent].subtitleText.substring(index);

            state.captions[captionEvent].subtitleText = captionText01.trim();
            newCaptionEvent.subtitleText = captionText02.trim();

            var incodeSec = state.captions[captionEvent].incodeSec;
            var outcodeSec = state.captions[captionEvent].outcodeSec;

            var newIncodeSec = ((outcodeSec - incodeSec) / 2) + incodeSec;
            var newOutcodeSec = newIncodeSec - (1 / parseFloat(framerate));

            newCaptionEvent.incodeSec = newIncodeSec;
            newCaptionEvent.outcodeSec = outcodeSec;
            newCaptionEvent.incode = globalFunc.secToTcString(newIncodeSec, framerate, dropframe, projectIncode);
            newCaptionEvent.outcode = globalFunc.secToTcString(outcodeSec, framerate, dropframe, projectIncode);
            state.captions[captionEvent].outcodeSec = newOutcodeSec;
            state.captions[captionEvent].outcode = globalFunc.secToTcString(newOutcodeSec, framerate, dropframe, projectIncode);

            state.captions.splice(captionEvent + 1, 0, newCaptionEvent);
        }
    },
    splitEvent: function (state, captionEvent) {
        var projectIncode = state.projectSettings.incode;
        var framerate = state.projectSettings.framerate;
        var dropframe = globalSetting.tcFrameMap[framerate];
        var newCaptionEvent = ccLib.createCaptionEvent();
        var linesWithText = state.captions[captionEvent].primaryLang.filter(captionLine => {
            return captionLine.captionText.length > 0;
        });

        if (linesWithText.length != 0 && linesWithText.length == 1) {
            var lineLength = linesWithText[0].captionText.length;
            var avgTextPerLine = parseInt(lineLength / 2);
            var captionTextArray = linesWithText[0].captionText.split(" ");
            var captionText01 = "";
            var captionText02 = "";

            for (var i = 0; i < captionTextArray.length; i++) {
                if (captionText01.length === 0 || captionText01.length <= avgTextPerLine) {
                    captionText01 += captionTextArray[i] + " ";
                } else {
                    captionText02 += captionTextArray[i] + " ";
                }
            }

            state.captions[captionEvent].primaryLang[0].captionText = captionText01.trim();
            newCaptionEvent.primaryLang[0].captionText = captionText02.trim();
            newCaptionEvent.primaryLang[0].positionX = state.captions[captionEvent].primaryLang[0].positionX;
            newCaptionEvent.primaryLang[0].positionY = state.captions[captionEvent].primaryLang[0].positionY;
            newCaptionEvent.primaryLang[0].bold = state.captions[captionEvent].primaryLang[0].bold;
            newCaptionEvent.primaryLang[0].italics = state.captions[captionEvent].primaryLang[0].italics;
            newCaptionEvent.primaryLang[0].underline = state.captions[captionEvent].primaryLang[0].underline;

        } else if (linesWithText.length != 0 && linesWithText.length == 2) {
            newCaptionEvent.primaryLang[0].captionText = state.captions[captionEvent].primaryLang[1].captionText
            newCaptionEvent.primaryLang[0].positionX = state.captions[captionEvent].primaryLang[0].positionX;
            newCaptionEvent.primaryLang[0].positionY = state.captions[captionEvent].primaryLang[0].positionY;
            newCaptionEvent.primaryLang[0].bold = state.captions[captionEvent].primaryLang[0].bold;
            newCaptionEvent.primaryLang[0].italics = state.captions[captionEvent].primaryLang[0].italics;
            newCaptionEvent.primaryLang[0].underline = state.captions[captionEvent].primaryLang[0].underline;

            state.captions[captionEvent].primaryLang[1].captionText = "";
            state.captions[captionEvent].primaryLang[1].bold = false;
            state.captions[captionEvent].primaryLang[1].italics = false;
            state.captions[captionEvent].primaryLang[1].underline = false;

        } else if (linesWithText.length != 0 && linesWithText.length == 3) {
            newCaptionEvent.primaryLang[0].captionText = state.captions[captionEvent].primaryLang[2].captionText
            newCaptionEvent.primaryLang[0].positionX = state.captions[captionEvent].primaryLang[0].positionX;
            newCaptionEvent.primaryLang[0].positionY = state.captions[captionEvent].primaryLang[0].positionY;
            newCaptionEvent.primaryLang[0].bold = state.captions[captionEvent].primaryLang[0].bold;
            newCaptionEvent.primaryLang[0].italics = state.captions[captionEvent].primaryLang[0].italics;
            newCaptionEvent.primaryLang[0].underline = state.captions[captionEvent].primaryLang[0].underline;

            state.captions[captionEvent].primaryLang[2].captionText = "";
            state.captions[captionEvent].primaryLang[2].bold = false;
            state.captions[captionEvent].primaryLang[2].italics = false;
            state.captions[captionEvent].primaryLang[2].underline = false;

        } else if (linesWithText.length != 0 && linesWithText.length == 4) {
            newCaptionEvent.primaryLang[0].captionText = state.captions[captionEvent].primaryLang[2].captionText
            newCaptionEvent.primaryLang[0].positionX = state.captions[captionEvent].primaryLang[0].positionX;
            newCaptionEvent.primaryLang[0].positionY = state.captions[captionEvent].primaryLang[0].positionY;
            newCaptionEvent.primaryLang[0].bold = state.captions[captionEvent].primaryLang[0].bold;
            newCaptionEvent.primaryLang[0].italics = state.captions[captionEvent].primaryLang[0].italics;
            newCaptionEvent.primaryLang[0].underline = state.captions[captionEvent].primaryLang[0].underline;

            newCaptionEvent.primaryLang[1].captionText = state.captions[captionEvent].primaryLang[3].captionText
            newCaptionEvent.primaryLang[1].positionX = state.captions[captionEvent].primaryLang[1].positionX;
            newCaptionEvent.primaryLang[1].positionY = state.captions[captionEvent].primaryLang[1].positionY;
            newCaptionEvent.primaryLang[1].bold = state.captions[captionEvent].primaryLang[1].bold;
            newCaptionEvent.primaryLang[1].italics = state.captions[captionEvent].primaryLang[1].italics;
            newCaptionEvent.primaryLang[1].underline = state.captions[captionEvent].primaryLang[1].underline;

            state.captions[captionEvent].primaryLang[2].captionText = "";
            state.captions[captionEvent].primaryLang[2].bold = false;
            state.captions[captionEvent].primaryLang[2].italics = false;
            state.captions[captionEvent].primaryLang[2].underline = false;

            state.captions[captionEvent].primaryLang[3].captionText = "";
            state.captions[captionEvent].primaryLang[3].bold = false;
            state.captions[captionEvent].primaryLang[3].italics = false;
            state.captions[captionEvent].primaryLang[3].underline = false;
        }

        var incodeSec = state.captions[captionEvent].incodeSec;
        var outcodeSec = state.captions[captionEvent].outcodeSec;

        var newIncodeSec = ((outcodeSec - incodeSec) / 2) + incodeSec;
        var newOutcodeSec = newIncodeSec - (1 / parseFloat(framerate));

        newCaptionEvent.incodeSec = newIncodeSec;
        newCaptionEvent.outcodeSec = outcodeSec;
        newCaptionEvent.incode = globalFunc.secToTcString(newIncodeSec, framerate, dropframe, projectIncode);
        newCaptionEvent.outcode = globalFunc.secToTcString(outcodeSec, framerate, dropframe, projectIncode);
        state.captions[captionEvent].outcodeSec = newOutcodeSec;
        state.captions[captionEvent].outcode = globalFunc.secToTcString(newOutcodeSec, framerate, dropframe, projectIncode);

        state.captions.splice(captionEvent + 1, 0, newCaptionEvent);
    },

    expandSubtitleEvent: function (state, captionEvent) {
        var captionLines = state.captions[captionEvent].subtitleText.trim().split("\n");
        if (captionLines.length > 1) {
            var words = captionLines[captionLines.length - 1].split(" ");
            (captionLines[captionLines.length - 2] += " " + words.shift()).trim();
            captionLines[captionLines.length - 1] = words.join(" ").trim();
            state.captions[captionEvent].subtitleText = captionLines.join("\n").trim();
        }
    },

    expandEvent: function (state, captionEvent) {
        var maxLineLength = false;
        var linesWithText = state.captions[captionEvent].primaryLang.filter(captionLine => {
            if (captionLine.captionText.length >= 28) {
                maxLineLength = true;
            }
            return captionLine.captionText.length > 0;
        });

        if (linesWithText.length > 1 && !maxLineLength) {
            for (var i = linesWithText.length - 1; i > 0; i--) {
                var line = linesWithText[i].captionText.trim().split(" ");
                linesWithText[i - 1].captionText += " " + line.shift();
                linesWithText[i].captionText = line.join(" ");
            }
        }
    },
    compressSubtitleEvent: function (state, captionEvent) {
        var move = false;
        var subtitleTextLines = state.captions[captionEvent].subtitleText.split("\n");
        for (var i = 0; i < subtitleTextLines.length; i++) {
            if (!move && subtitleTextLines[i + 1] && subtitleTextLines[i].length > subtitleTextLines[i + 1].length) {
                var words = subtitleTextLines[i].split(" ");
                subtitleTextLines[i + 1] = (words.pop().trim() + " " + subtitleTextLines[i + 1]).trim();
                subtitleTextLines[i] = words.join(" ").trim();
                state.captions[captionEvent].subtitleText = subtitleTextLines.join("\n").trim();
                move = true;
            }
        }
        if (!move) {
            var n = state.captions[captionEvent].subtitleText.trim().lastIndexOf(" ");
            state.captions[captionEvent].subtitleText = state.captions[captionEvent].subtitleText.substr(0, n).trim() + "\n" + state.captions[captionEvent].subtitleText.substr(n + 1).trim();
        }
    },
    compressEvent: function (state, captionEvent) {
        var move = false;
        var linesWithText = state.captions[captionEvent].primaryLang.filter(captionLine => {
            return captionLine.captionText.length > 0;
        });

        for (var i = 0; i < linesWithText.length; i++) {
            var line = linesWithText[i].captionText.trim().split(" ");
            if (i < 3 && !move && line.length > 1 && state.captions[captionEvent].primaryLang[i + 1].captionText.length < linesWithText[i].captionText.length) {
                state.captions[captionEvent].primaryLang[i + 1].captionText = line.pop() + " " + state.captions[captionEvent].primaryLang[i + 1].captionText;
                linesWithText[i].captionText = line.join(" ");
                move = true;
            }
        }
    },
    shiftSubtitleLine: function (state, lineData) {
        if (state.captions[lineData.id].subtitleText.length > 0) {
            var subtitleLines = state.captions[lineData.id].subtitleText.trim().split("\n");
            if (lineData.direction == 'up') {
                state.captions[lineData.id - 1].subtitleText += "\n" + subtitleLines.shift();
                state.captions[lineData.id].subtitleText = subtitleLines.join("\n");
                state.selectedCaptionEvent = lineData.id - 1;
            } else {
                state.captions[lineData.id + 1].subtitleText = subtitleLines.pop() + "\n" + state.captions[lineData.id + 1].subtitleText;
                state.captions[lineData.id].subtitleText = subtitleLines.join("\n");
                state.selectedCaptionEvent = lineData.id + 1;
            }
        }
    },
    shiftLine: function (state, lineData) {
        var linesWithText = state.captions[lineData.id].primaryLang.filter(captionLine => {
            return captionLine.captionText.length > 0;
        });

        if (linesWithText.length > 0 && lineData.direction == 'up') {
            var line = linesWithText[0];
            var emptyLine = globalFunc.getFirstEmptyLine(state.captions[lineData.id - 1].primaryLang);

            state.captions[lineData.id - 1].primaryLang[emptyLine].captionText = (state.captions[lineData.id - 1].primaryLang[emptyLine].captionText + " " + line.captionText).trim();

            line.captionText = "";
        } else if (linesWithText.length > 0) {
            var line = linesWithText[linesWithText.length - 1];
            var emptyLine = globalFunc.getFirstEmptyLine(state.captions[lineData.id + 1].primaryLang);

            if (emptyLine == 3 && state.captions[lineData.id + 1].primaryLang[3].captionText.length > 0) {
                state.captions[lineData.id + 1].primaryLang[0].captionText = (state.captions[lineData.id + 1].primaryLang[0].captionText + " " + line.captionText).trim();
            } else {
                for (var i = emptyLine; i > 0; i--) {
                    state.captions[lineData.id + 1].primaryLang[i].captionText = state.captions[lineData.id + 1].primaryLang[i - 1].captionText;
                    state.captions[lineData.id + 1].primaryLang[i].positionX = state.captions[lineData.id + 1].primaryLang[i - 1].positionX;
                    state.captions[lineData.id + 1].primaryLang[i].bold = state.captions[lineData.id + 1].primaryLang[i - 1].bold;
                    state.captions[lineData.id + 1].primaryLang[i].italics = state.captions[lineData.id + 1].primaryLang[i - 1].italics;
                    state.captions[lineData.id + 1].primaryLang[i].underline = state.captions[lineData.id + 1].primaryLang[i - 1].underline;
                }

                state.captions[lineData.id + 1].primaryLang[0].captionText = line.captionText.trim();
            } line.captionText = "";
        }
    },
    shiftSubtitleWord: function (state, lineData) {
        if (state.captions[lineData.id].subtitleText.length > 0) {
            var subtitleWords = state.captions[lineData.id].subtitleText.trim().split(" ");
            if (lineData.direction == 'up') {
                state.captions[lineData.id - 1].subtitleText += " " + subtitleWords.shift();
                state.captions[lineData.id].subtitleText = subtitleWords.join(" ");
                state.selectedCaptionEvent = lineData.id - 1;
            } else {
                state.captions[lineData.id + 1].subtitleText = subtitleWords.pop() + " " + state.captions[lineData.id + 1].subtitleText;
                state.captions[lineData.id].subtitleText = subtitleWords.join(" ");
                state.selectedCaptionEvent = lineData.id + 1;
            }
        }
    },
    shiftWord: function (state, wordData) {
        var linesWithText = state.captions[wordData.id].primaryLang.filter(captionLine => {
            return captionLine.captionText.length > 0;
        });

        if (linesWithText.length > 0 && wordData.direction == 'up') {
            var line = linesWithText[0];
            var captionTextArray = line.captionText.trim().split(" ");
            var word = captionTextArray.shift();

            var prevLinesWithText = state.captions[wordData.id - 1].primaryLang.filter(captionLine => {
                return captionLine.captionText.length > 0;
            });

            if (prevLinesWithText.length > 0) {
                prevLinesWithText[prevLinesWithText.length - 1].captionText += " " + word;
            } else {
                prevLinesWithText[0].captionText = word;
            } linesWithText[0].captionText = captionTextArray.join(" ");

        } else if (linesWithText.length > 0) {
            var line = linesWithText[linesWithText.length - 1];
            var captionTextArray = line.captionText.trim().split(" ");
            var word = captionTextArray.pop();
            state.captions[wordData.id + 1].primaryLang[0].captionText = word + " " + state.captions[wordData.id + 1].primaryLang[0].captionText;
            linesWithText[linesWithText.length - 1].captionText = captionTextArray.join(" ");
        }
    },
    storeAssetInfo: function (state, assetInfo) {
        state.assetInfo = assetInfo;
    },
    automaticSync: function (state, results) {
        var words = [];
        JSON.parse(results).forEach(result => {
            if (result.alternatives.transcript) {
                console.log(result.alternatives.transcript);
                result.words.forEach(word => {
                    words.push(word);
                })
            }
        });
        console.log(results);
        console.log(words);
    },

    updateAssetTranscriptionText: function (state, text) {
        state.assetTranscription.text = text;
    },

    updateTranscriptionAssetId: function (state, assetId) {
        state.assetTranscription.assetId = assetId;
    },
    importTranscript: function (state, transcriptOptions) {
        var transcript = transcriptOptions.captions;
        var append = transcriptOptions.append;
        var view = state.view;

        if (!Array.isArray(transcript)) {
            throw new Error("Transcript file empty");
        }

        var captions = [];
        var framerate = state.projectSettings.framerate;
        var dropFrame = globalSetting.tcFrameMap[framerate];
        transcript.forEach(transcriptEvent => {
            if (transcriptEvent.text != "") {
                var newLine = ccLib.createCaptionEvent();
                var currentCaptionLine = 0;
                transcriptEvent.text.trim().split("<br>").forEach(transcriptLine => {
                    newLine.primaryLang[currentCaptionLine].captionText = transcriptLine;
                    currentCaptionLine++;
                })

                newLine.incodeSec = parseFloat(transcriptEvent.startTime.toString().replace("s", ""));
                newLine.outcodeSec = parseFloat(transcriptEvent.endTime.toString().replace("s", ""));

                newLine.incode = _Timecode.Timecode.init({
                    framerate: framerate,
                    timecode: _NodeTc.fromSeconds(newLine.incodeSec, { frameRate: framerate }),
                    drop_frame: dropFrame
                }).toString();

                newLine.outcode = _Timecode.Timecode.init({
                    framerate: framerate,
                    timecode: _NodeTc.fromSeconds(newLine.outcodeSec, { frameRate: framerate }),
                    drop_frame: dropFrame
                }).toString();

                if (transcriptEvent.confidence) {
                    var confSum = transcriptEvent.confidence.reduce(function (a, b) {
                        return a + b;
                    });
                    newLine.confidence = Math.floor((confSum / transcriptEvent.confidence.length) * 100);
                }

                captions.push(newLine);
            }
        });

        if (captions.length > 0) {
            if (view === "transcript") {
                state.transcription.text = ""
                captions.forEach(captionEvent => {
                    state.transcription.text += '<p><a href="#' + captionEvent.incode + '">' + captionEvent.incode + '</a></p>';
                    captionEvent.primaryLang.forEach(captionLine => {
                        if (captionLine.captionText != "") {
                            state.transcription.text += '<p>' + captionLine.captionText + '</p>';
                        }
                    });
                    state.transcription.text += '<p><br></p>';
                });
            } else {
                if (append) {
                    state.captions = state.captions.concat(captions);
                    state.captions.forEach(caption => {
                        caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
                    });
                } else {
                    state.selectedCaptionEvent = 0;
                    state.captions = captions;
                    state.captions.forEach(caption => {
                        caption.subtitleText = globalFunc.concatCaptionText(caption.primaryLang);
                    });
                }
            }
        } else {
            throw new Error("Transcript file faild to import");
        }

    },
    importCaptionsFromStore: function (state, project) {
        if (state.view === 'transcript') {
            state.view = "default";
        }
        if (project.version && project.version.split(".")[1] == state.version.split(".")[1]) {
            state.captions = JSON.parse(project.captions);
            var highestId = (Math.max.apply(Math, state.captions.map(function (o) {
                return o.uniqueId;
            })));
            for (var i = 0; i < highestId + 2; i++) {
                var captionEvent = ccLib.createCaptionEvent();
            }
        } else {
            var captionEvents = [];
            var captions = JSON.parse(project.captions);
            captions.forEach(captionEvent => {
                try {
                    if (captionEvent != null && captionEvent.incode && captionEvent.captionText) {
                        var newCaptionEvent = ccLib.createCaptionEvent(captionEvent.incode, captionEvent.outcode, captionEvent.incodeSec, captionEvent.outcodeSec);
                        var captionLines = captionEvent.captionText.replace(/&nbsp;/g, "<br>").split("<br>");
                        for (var i = 0; i < captionLines.length; i++) {
                            newCaptionEvent.primaryLang[i].captionText = captionLines[i].replace(/<[^>]*>?/gm, '');
                        }
                        captionEvents.push(newCaptionEvent);
                    }
                } catch (e) {
                    console.log(e.message);
                }
            });

            if (captionEvents.length > 0) {
                state.captions = captionEvents;
            }

        }
    },

    importSettingsFromStore: function (state, project) {
        if (project.version && project.version.split(".")[1] == state.version.split(".")[1]) {
            try {
                state.projectSettings = JSON.parse(project.projectSettings);
            } catch (e) {
                state.projectSettings = project.projectSettings;
            }

        }
    },
    importMetadataFromStore: function (state, project) {
        if (project.version && project.version.split(".")[1] == state.version.split(".")[1]) {
            try {
                state.metadata = JSON.parse(project.metadata);
            } catch (e) {
                state.metadata = project.metadata;
            }
        }
    },
    importSpeakersFromStore: function (state, project) {
        try {
            state.speakers = JSON.parse(project.speakers);
        } catch (e) {
            state.speakers = [];
        }
    },

    importAdFromProject: function (state, project) {
        try {
            state.audioDescription = project.audioDescription;
        } catch (e) {
            var adEvent = ccLib.createAdEvent();
            state.audioDescription = [adEvent];
        }

        var highestId = (Math.max.apply(Math, state.audioDescription.map(function (o) {
            return o.uniqueId;
        })));
        for (var i = 0; i < highestId + 2; i++) {
            ccLib.createAdEvent();
        }
    },
    clearProjectStore: function (state) {
        var newCaptionEvent = ccLib.createCaptionEvent();
        state.captions = [newCaptionEvent];
        state.duration = 10,
            state.currentTime = '',
            state.currentTimeSec = 0,
            state.selectedCaptionEvent = 0,
            state.assetInfo = {};
        state.assetTranscription = {
            assetId: "",
            text: ""
        }
    },
    /* Audio Description */
    insertAdEvent: function (state, index) {
        state.audioDescription.splice(index, 0, ccLib.createAdEvent());
        state.selectedCaptionEvent = index;
    },
    removeAdEvent: function (state, index) {
        if (state.audioDescription.length > 1) {
            state.audioDescription.splice(index, 1);
            state.selectedCaptionEvent = (index - 1) < 0 ? 0 : (index - 1);
        } else {
            app.$notify({ title: 'Error', type: 'error', text: 'Unable to delete AD event' });
        }
    },
    updateAdEventSpeed: function (state, speedDetails) {
        state.audioDescription[speedDetails.id].speed = speedDetails.speed;
    },
    updateAdEventText: function (state, textDetails) {
        state.audioDescription[textDetails.id].text = textDetails.text;
    },
    updateAdEventDuration: function (state, durationDetails) {
        state.audioDescription[durationDetails.id].duration = durationDetails.duration;
    },
    exportAd: function (state, exportOptions) {
        var i = 0;
        var targetFolder = exportOptions.targetFolder;
        var progress = state.audioDescription.length;
        var adLength = state.audioDescription.length;
        var adTranscript = "Incode,File,Text\n";
        var adSrtFile = "";
        var adSrtId = 1;
        var language = state.projectSettings.voice.substr(0, 5);
        var voice = state.projectSettings.voice;
        if (adLength > 0) {
            const fs = require("fs");
            state.status.progress = 0;
            state.status.msg = "Exporting DV";
            state.status.status = "";
            state.status.display = true;
            for (i; i < adLength; i++) {
                if (state.audioDescription[i].text.trim().length > 0) {
                    adTranscript += state.audioDescription[i].incode + ",";
                    adTranscript += i + '.wav,';
                    adTranscript += '"' + state.audioDescription[i].text.trim() + '"' + "\n";

                    var adSrtIncode = _NodeTc.fromSeconds(state.audioDescription[i].incodeSec, { ms: true });
                    var adSrtOutcode = _NodeTc.fromSeconds(state.audioDescription[i].incodeSec + state.audioDescription[i].duration, { ms: true });

                    adSrtIncode = adSrtIncode.substr(0, 8) + "," + adSrtIncode.substr(9);
                    adSrtOutcode = adSrtOutcode.substr(0, 8) + "," + adSrtOutcode.substr(9);
                    adSrtFile += adSrtId++ + "\n";
                    adSrtFile += adSrtIncode + " --> " + adSrtOutcode + "\n";
                    adSrtFile += state.audioDescription[i].text.replace(/\n\s*\n/g, '\n').trim() + "\n\n";
                    if (exportOptions.audioExport) {
                        firebase.functions().httpsCallable('convertTextToSpeech')({
                            assetId: state.projectSettings.uid,
                            eventId: i,
                            text: state.audioDescription[i].text,
                            language: language,
                            voice: voice,
                            speed: state.audioDescription[i].speed
                        }).then(res => {
                            console.log("Text To Speech Completed");
                            var rawAudio = res.data.audio;
                            var eventId = res.data.eventId;
                            fs.writeFile(targetFolder + "/" + eventId + ".wav", rawAudio, 'base64', function (err) {
                                if (err) {
                                    console.log(err);
                                }
                                state.status.progress = (((adLength - (--progress)) / adLength) * 100).toFixed();
                                state.status.msg = "Exporting Audio Description";
                                state.status.status = "";
                                state.status.display = true;
                                if (progress < 1) {
                                    state.status.display = false;
                                    app.$notify({ title: 'Audio Description Export', type: 'success', text: 'Audio Description Export Complete' });
                                } else {
                                    state.status.display = true;
                                }
                            });
                        });
                    } else {
                        state.status.display = false;
                    }
                } else {
                    --progress
                }
            }

            fs.writeFile(targetFolder + "/transcript.csv", adTranscript, function (err) {
                if (err) {
                    console.log("Failed to write AD transcript");
                    console.log(err);
                }
            });

            fs.writeFile(targetFolder + "/transcript.srt", adSrtFile, function (err) {
                if (err) {
                    console.log("Failed to write SRT file");
                    console.log(err);
                }
            });
        }
    }
}

const actions = {
    loadAiProject: function (context, assetInfo) {
        context.commit('storeAssetInfo', assetInfo);
        var assetId = assetInfo.assetId;
        var teamId = assetInfo.teamId;
        var uid = assetInfo.assetId;
        context.commit('updateProjectSetting', {
            option: 'uid',
            value: uid
        });
        if (assetInfo.framerate === "24000/1001") {
            var framerate = "23.976"
        } else {
            var framerate = assetInfo.framerate
        } context.commit('createNewProject', {
            title: assetInfo.title,
            framerate: framerate,
            projectIncode: assetInfo.incode
        });
        var source = assetInfo.mediaSource;
        if (source == "Local Storage") {
            source = "Cloud URL";
        }
        var url = assetInfo.proxyUrl || assetInfo.mediaUrl;

        context.commit('importMedia', {
            source: source,
            url: url,
            importType: "direct"
        });

        app.$Progress.start();
        var projectRef = storage.ref().child(teamId + "/" + assetId + "/projects/" + assetId + '.json');
        projectRef.getDownloadURL().then(function (url) {
            axios.get(url).then(response => {
                app.$Progress.finish();
                context.commit('importCaptionsFromProject', response.data);
            });
        }).catch(function (err) {
            console.log("No Project Found");
            app.$Progress.finish();
        });
    },
    publish: function (context) {
        var assetId = context.state.assetInfo.assetId;
        var teamId = context.state.assetInfo.teamId;
        var projectRef = storage.ref(teamId + "/" + assetId + "/projects/" + assetId + '.json');
        projectRef.putString(JSON.stringify(context.state));
    },
    loadTranscript: function (context, assetInfo) {
        if (assetInfo.transcribed) {
            var assetId = assetInfo.assetId;
            var teamId = assetInfo.teamId;
            context.commit('updateAssetTranscriptionText', "Loading...");
            context.commit('updateTranscriptionAssetId', assetId);
            var assetRef = storage.ref(teamId + "/" + assetId + "/transcripts/" + assetId + '.json');
            assetRef.getDownloadURL().then(function (url) {
                axios.get(url).then(response => {
                    context.commit('updateAssetTranscriptionText', JSON.stringify(response.data, null, 4));
                });
            }).catch(function (error) {
                console.log(error);
                context.commit('updateAssetTranscriptionText', "NO TRANSCRIPT FILE TO DISPLAY. PLEASE SUBMIT JOB TO GENERATE");
            });
        } else {
            state.assetTranscription.text = "NO TRANSCRIPT FILE TO DISPLAY. PLEASE SUBMIT JOB TO GENERATE";
            state.assetTranscription.houseNumber = assetInfo.houseNumber;
        }
    },
    updateAssetState: function (context, stateInfo) {
        var progressSuffix = "";
        var newState = globalSetting.workflow[stateInfo.state][stateInfo.trigger];
        state.currentState = newState;
        if (newState === "CC In Progress") {
            if (context.state.captions.length < 2) {
                progressSuffix = " (0%)"
            } else {
                var duration = context.state.duration;
                var latestOutcode = Math.max.apply(Math, context.state.captions.map(function (captionEvent) {
                    return captionEvent.outcodeSec;
                }));
                var progress = Math.round(((latestOutcode / duration) * 100) * 100) / 100;
                if (progress > 100) {
                    progress = 99;
                }
                progressSuffix = " (" + progress + "%)"
            }
        }
        db.collection("teams").doc(stateInfo.teamId).collection('assets').doc(stateInfo.assetId).update({
            state: newState + progressSuffix
        });
    },
    updateCompletedTime: function (context, stateInfo) {
        var time = new Date().getTime().toString();
        db.collection("teams").doc(stateInfo.teamId).collection('assets').doc(stateInfo.assetId).update({ progress: 100, completed_time: time });
    },
    updateUserOwner: function (context, stateInfo) {
        var newState = globalSetting.workflow[stateInfo.state][stateInfo.trigger];
        if (newState === "CC In Progress") {
            db.collection("teams").doc(stateInfo.teamId).collection('assets').doc(stateInfo.assetId).update({ captionedBy: stateInfo.user });
        } else if (newState === "Review In Progress") {
            db.collection("teams").doc(stateInfo.teamId).collection('assets').doc(stateInfo.assetId).update({ reviewedBy: stateInfo.user });
        }

    },
    loadProjectFile: function (context, project) {
        context.commit('importCaptionsFromProject', project);
        context.commit('importSettingsFromProject', project);
        context.commit('importMetadataFromProject', project);
        context.commit('importSpeakersFromProject', project);
        context.commit('importTranscriptFromProject', project);
        context.commit('importAdFromProject', project);
        context.commit('importAiTranscriptFromProject', project);
        if (project.projectSettings.mediaInfo.source === "Local Storage") {
            context.commit('importMedia', {
                projectImport: true,
                source: project.projectSettings.mediaInfo.source,
                url: [
                    {
                        type: project.projectSettings.mediaInfo.fileType,
                        path: project.projectSettings.mediaInfo.path,
                        name: project.projectSettings.mediaInfo.fileName
                    }
                ]
            });
        } else {
            context.commit('importMedia', {
                source: project.projectSettings.mediaInfo.source,
                url: project.projectSettings.fileUrl
            });
        }

        app.$notify({ title: 'Project Import', type: 'success', text: 'Project has been imported successfully' });
    },
    importProjectFile: function (context, settings) {
        try {
            if (settings.fileSize > 0 && settings.fileExt.toLowerCase() === 'ccprj') {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var project = JSON.parse(reader.result);
                    if (project.version.split('.', 2).join() == context.state.version.split('.', 2).join()) {
                        context.commit('importCaptionsFromProject', project);
                        context.commit('importSettingsFromProject', project);
                        context.commit('importMetadataFromProject', project);
                        context.commit('importSpeakersFromProject', project);
                        context.commit('importTranscriptFromProject', project);
                        context.commit('importAdFromProject', project);
                        context.commit('importAiTranscriptFromProject', project);
                        if (project.projectSettings.mediaInfo.source === "Local Storage") {
                            context.commit('importMedia', {
                                projectImport: true,
                                source: project.projectSettings.mediaInfo.source,
                                url: [
                                    {
                                        type: project.projectSettings.mediaInfo.fileType,
                                        path: project.projectSettings.mediaInfo.path,
                                        name: project.projectSettings.mediaInfo.fileName
                                    }
                                ]
                            });
                        } else {
                            context.commit('importMedia', {
                                source: project.projectSettings.mediaInfo.source,
                                url: project.projectSettings.fileUrl
                            });
                        }

                        app.$notify({ title: 'Project Import', type: 'success', text: 'Project has been imported successfully' });
                    } else {
                        app.$notify({ title: 'Project Import Failed', type: 'error', text: 'Project has failed to import due to version compatibility. Please contat support for help' });
                    }
                }
                reader.readAsText(settings.srcFile);
            }
        } catch (e) {
            console.log(e);
            state.status.progress = 100;
            state.status.msg = "ERROR";
            state.status.status = "Complete";
            state.status.display = false;

            app.$notify({ title: 'Project Import Failed', type: 'error', text: e.message });
            console.log(e.message);
        }
    }

}

export default { state, mutations, actions }
