<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated slideInUp"
    leave-active-class="animated slideOutDown"
  >
    <div id="timeline" v-if="viewTimeline" @click="cueToTime" @drag="cueToTime" draggable="true">
      <img
        :src="waveform"
        class="float-left img-fluid w-100"
        id="waveformDisplay"
      />
      <div
        class="compressedTimelineEvent"
        :style="{ left: 0 + '%', width: currentTime + '%' }"
      >
        <p class="text-center currentTimeReading">
          {{ this.$store.state.editor.currentTime }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TimelineCompressed",
  computed: {
    viewTimeline: function () {
      return this.$store.state.editor.viewTimeline;
    },
    currentTime: function () {
      return (
        (this.$store.state.editor.currentTimeSec /
          this.$store.state.editor.duration) *
        100
      );
    },
    waveform: function () {
      return this.$store.state.editor.waveformPath;
    },
  },

  methods: {

    cueToTime: function (event) {
      if(event.offsetX !== 0){
        var totalWidth = document.getElementById("timeline").offsetWidth;
        var offsetX = event.offsetX;
        var currentTime =
          (offsetX / totalWidth) * this.$store.state.editor.duration;
        this.$store.commit("cueToTimeSec", currentTime);
      }
    },
  },
};
</script>
<style>
.compressedTimelineEvent {
  position: absolute;
  height: 100%;
  background: rgba(2, 0, 36, 0.5);
  background: linear-gradient(
    90deg,
    rgba(19, 48, 75, 0.5) 0%,
    rgba(19, 58, 83, 0.561) 45%,
    rgba(39, 86, 139, 0.623) 80%
  );
  pointer-events: none;
  transition: all 0.5s ease;
}
#waveformDisplay {
  max-height: 6rem;
}

.currentTimeReading{
  padding-top: 2rem;
  font-size: 1.5rem;
}
</style>