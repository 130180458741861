import Vue from 'vue'
import Router from 'vue-router'
import Editor from './views/Editor.vue'
import Login from './views/Login.vue'

Vue.use(Router)
const router = new Router({
  mode : 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Editor',
      component: Editor,
      meta: {
        protected: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    }
  ]
});

export default router;