<template>
<li class="nav-item">
    <button class="nav-link btn btn-link" href="#" title="Voice Input" @click="toggleSpeechInput" :class="{'red animated flash infinite' : speechRecognitionOn}">
        <i class="fas fa-microphone"></i>
    </button>
</li>
</template>

<script>
import globalFunc from '../../assets/js/functions/globalFunc'
const ccLib = require('@/external/cc-lib');
export default {
    name: 'SpeechInput',
    data: function () {
        return {
            speechRecognitionOn: false,
            startTime: 0,
            startTimeSec: 0
        }
    },
    methods: {
        toggleSpeechInput: function () {
            if (this.speechRecognitionOn) {
                if (recognition) {
                    recognition.stop();
                }
                this.speechRecognitionOn = false;
            } else {
                window.recognition = new window.SpeechRecognition();
                recognition.continuous = true;
                recognition.interimResults = true;
                recognition.lang = this.$store.state.editor.projectSettings.language;

                var firstEvent = true;
                recognition.onerror = (event) => {
                    console.log("Error:");
                    console.log(event);
                    recognition.stop();
                    recognition.start();
                }

                recognition.onresult = (event) => {
                    if (this.$store.state.editor.view === 'transcript') {
                        var lastEvent = event.results[event.results.length - 1];
                        if (lastEvent.isFinal == true) {
                            var transcriptEditor = window.quillEditor;
                            var range = transcriptEditor.getSelection();
                            if (range && range.length == 0) {
                                transcriptEditor.insertText(
                                    range.index,
                                    lastEvent[0].transcript.trim() + "\n"
                                );
                                transcriptEditor.focus();
                            }
                        }
                    } else {
                        if (firstEvent) {
                            firstEvent = false;
                            this.startTime = this.$store.state.editor.currentTime;
                            this.startTimeSec = this.$store.state.editor.currentTimeSec;
                        }
                        var lastEvent = event.results[event.results.length - 1];
                        if (lastEvent.isFinal == true) {
                            var captionEvent = ccLib.createCaptionEvent(
                                this.startTime,
                                this.$store.state.editor.currentTime,
                                this.startTimeSec,
                                this.$store.state.editor.currentTimeSec
                            );

                            if (lastEvent[0].transcript.length <= 128) {
                                captionEvent.primaryLang = globalFunc.autoFormatTextToLines(lastEvent[0].transcript, captionEvent.primaryLang);
                            } else {
                                captionEvent.primaryLang = globalFunc.distributeTextEvenly(lastEvent[0].transcript, captionEvent.primaryLang);
                            }

                            captionEvent.subtitleText = globalFunc.concatCaptionText(captionEvent.primaryLang);

                            var captionId = this.$store.state.editor.captions.length;
                            this.$store.commit("insertCompletedEvent", captionEvent);
                            this.$store.commit("selectCaptionEvent", captionId);
                            firstEvent = true;
                        }
                    }
                }
                
                recognition.start();
                this.speechRecognitionOn = true;
            }

        }
    }
}
</script>
