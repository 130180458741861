const eol = require('eol')

/* Preprocess prior to caption export */
module.exports = {
    import: {
        all: function (fileContents) {
            return eol.lf(fileContents.trim());
        },
        srt: function (fileContents) {
            return fileContents.replace(/'(\n){3,}'/g, "\n\n");
        },
        vtt: function (fileContents) {
            return fileContents.replace(/'(\n){3,}'/g, "\n\n");
        },
        scc: function (fileContents) {
            return fileContents.toLowerCase();
        },
        mcc: function (fileContents) {
            return fileContents;
        },
        sbv: function (fileContents) {
            return fileContents.replace(/'(\n){3,}'/g, "\n\n");
        },
        txt: function (fileContents) {
            return fileContents
        },
        stl: function (fileContents) {
            return fileContents
        },
        pac: function (fileContents) {
            return fileContents
        },
        cap: function (fileContents) {
            return fileContents
        },
        xml: function (fileContents) {
            return fileContents
        },
        csv: function (fileContents) {
            return fileContents
        },
        dfxp: function (fileContents) {
            return fileContents
        },
        ttml: function (fileContents) {
            return fileContents.replace(/<br\s*[\/]?>/gi, "[br]").replace(/<span[^>]*>/gi, "[italics]").replace(/<\/span>/gi, "").replace(/\r?\n|\r/g, "").replace(/tts\:origin/gi, "tts_origin");
        },
    },
    export: {
        all: function (captions, frameRate, dropFrame) {
            for (var i = 0; i < captions.length; i++){
                captions[i].primaryLang = captions[i].primaryLang.filter(captionLine => {
                    return captionLine.captionText.length > 0;
                });
            }       
            return captions.filter(captionEvent => {
                return captionEvent.incode != "" && captionEvent.outcode != "" && captionEvent.primaryLang[0] && captionEvent.primaryLang[0].captionText != ""
            });
        },
        scc: function (captions) {
            return captions;
        },
        srt: function (captions, frameRate, dropFrame) {
            return captions;
        },
        vtt: function (captions) {
            return captions;
        },
        sbv: function (captions) {
            return captions;
        },
        stl: function (captions) {
            return captions;
        },
        xml: function (captions) {
            return captions;
        },
        ttml: function (captions) {
            return captions
        },
        mcc: function (captions) {
            return captions;
        },
        csv: function (captions) {
            return captions
        },
        dfxp: function (captions) {
            return captions
        },
        txt: function (captions) {
            return captions;
        },
    },
}