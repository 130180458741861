<template>
	<div class='CaptionTimerControls d-none d-xl-block'>
		<div class="row">
			<div id="captionTimerControlList" class="col">
				<div id="currentOnAirCaptionText" class="row">
					<div class="col" style="height: 50px;">
						
							<textarea v-model="captionText.now"  class="form-control text-center captionTimerControlInput" disabled></textarea>

					</div>
					<div class="col">
						<button @click="clearEvent" class="btn btn-outline-light btn-block mt-2" :class="{active:clear}"><i class="fas fa-snowplow"></i> Clear</button>
					</div>
				</div>
				<div id="comingUpNextCaptionText" class="row">
					<div class="col" style="height: 50px;">
							<textarea v-model="captionText.next" class="form-control text-center captionTimerControlInput" disabled></textarea>

					</div>
					<div class="col">
						<button @click="takeNow" class="btn btn-primary btn-block mt-2"><i class="fas fa-play"></i> Take Now</button>
					</div>
				</div>
				<div class="row" v-if="aspectRatio != '9:16'">
					<div class="col" style="height: 50px;">

							<textarea v-model="captionText.preview" class="form-control text-center captionTimerControlInput text-muted" disabled></textarea>

					</div>
					<div class="col">
						<p class="text-muted mt-2">PREVIEW</p>
					</div>
				</div>
			</div>
			<PreviewControls/>
		</div>
	</div>
</template>

<script>
	import globalFunc from '../../assets/js/functions/globalFunc'
	import PreviewControls from './PreviewControls'
	export default {
		components : {
			PreviewControls
		},
		name: 'CaptionTimerControls',
		methods : { 
			takeNow : function(){
				var captionEvent = this.$store.state.editor.selectedCaptionEvent;
				if (!this.clear){
					this.$store.commit('setOutcode', captionEvent - 1);
				} else {
					this.$store.commit('updateClearStatus', false);
				}
				
				this.$store.commit('setIncode', captionEvent);
				if (this.$store.state.editor.captions[captionEvent + 1]) {
					this.$store.commit('selectCaptionEvent', captionEvent + 1);
					if (this.$store.state.editor.view != "default"){
						this.$store.commit('focusCaptionEvent', captionEvent + 1);
					}	
				} else {
					mediaPlayer.pause();
				}
			},
			clearEvent : function(){
				this.$store.commit('updateClearStatus', true);
				var captionEvent = this.$store.state.editor.selectedCaptionEvent - 1;
				this.$store.commit('setOutcode', captionEvent);
			}
		},
		computed : {
			clear : function(){
				return this.$store.state.editor.clear;
			},
			aspectRatio : function(){
				return this.$store.state.editor.player.aspectRatio;
			},
			captionText : function(){
				var now = "";
				var next = "";
				var preview = "";

				if (this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent-1]){
					if (this.$store.state.editor.view === 'subtitle'){
						now = this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent-1].subtitleText;
					} else {
						now = globalFunc.concatCaptionText(this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent-1].primaryLang);
					}
					
				}

				if (this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent]){
					if (this.$store.state.editor.view === 'subtitle'){
						next = this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent].subtitleText;
					} else {
						next = globalFunc.concatCaptionText(this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent].primaryLang) || "";
					}
					
				}

				if (this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent+1]){
					if (this.$store.state.editor.view === 'subtitle'){
						preview = this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent+1].subtitleText;
					} else {
						preview = globalFunc.concatCaptionText(this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent+1].primaryLang) || "";
					}
					
				}

				return {
					now: now.trim(),
					next: next.trim(),
					preview : preview.trim()
				}
				
			}
		}
	}
</script>
<style>
	.captionTimerControlInput {
		margin : 0;
		padding: 0;
		font-size: 12px;
		border: none !important;		
		background-color: transparent !important;
		resize: none;
	}

	#captionTimerControlList .row {
		margin: 0 1%;
	}

	#currentOnAirCaptionText {
		padding: 0.5% 0;	
		background-color: rgba(21, 139, 104, 0.377);
	}

	#currentOnAirCaptionText textarea{
		color: rgb(161, 161, 161);
	}

	#comingUpNextCaptionText {
		padding: 0.5% 0;		
		background-color: rgba(255, 255, 255, 0.1);
	}

	#comingUpNextCaptionText textarea {
		color: #fff !important;
	}
</style>