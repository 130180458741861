<template>
<div :Id="'captionEvent'+index" class="form-row captionEvent" :class="{activeEvent : index === selectedEvent}">
    <button type="button" class="btn btn-success btn-block btn-sm newEventButton" v-if="index === selectedEvent" v-on:click="addEvent" title="New Event">
            <i class="fa fa-plus" aria-hidden="true"></i> New
    </button>
    <div class="col-1">        
        <p class="text-muted">{{index + 1}}</p>
        <p class="small">{{wpm}} WPM</p>
        <p class="small">{{cpm}} CPM</p>
        <p class="small" v-if="confidence">
            <i class="fas fa-brain"></i>
            {{confidence}}
        </p>
        <button v-if="multiSelect" @click="multiSelectEvent" type="button" class="btn" :class="{'btn-secondary' : !multiSelected, 'btn-success':multiSelected}">
            <i class="fas fa-check-circle" v-if="multiSelected"></i>
            <i class="far fa-circle" v-else></i>
        </button>
    </div>
    <div class="col-3">
        <div class="input-group mb-2">
            <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary" v-on:click="setIncode($event)">
                    <i class="far fa-clock"></i>
                </button>
            </div>
            <input type="text" class="form-control timecodeInput" v-model.lazy="incode" v-on:click="updateCurrentTime('incodeSec')" v-on:keyup.up.exact="updateTcByFrame({tc : 'incode',add : true, subtract : false})" v-on:keyup.down.exact="updateTcByFrame({tc : 'incode',add : false, subtract : true})" placeholder="Incode"/>
        </div>
        <div class="input-group">
            <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary" v-on:click="setOutcode($event)">
                    <i class="far fa-clock"></i>
                </button>
            </div>
            <input type="text" class="form-control timecodeInput" v-model.lazy="outcode" v-on:click="updateCurrentTime('outcodeSec')" v-on:keyup.up.exact="updateTcByFrame({tc : 'outcode',add : true, subtract : false})" v-on:keyup.down.exact="updateTcByFrame({tc : 'outcode',add : false, subtract : true})" placeholder="Outcode" />
        </div>
    </div>
    <SubtitleEventInput v-bind:Id="index" v-bind:selectedEvent="selectedEvent"/>
    <div class="form-group col-1">
        <button type="button" class="btn btn-danger btn-sm float-right" v-on:click="deleteEvent" title="Delete Event">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="form-group col-1">
        <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
        
        </transition>
    </div>
</div>
</template>

<script>
import SubtitleEventInput from "./SubtitleEventInput";
import globalFunc from "../../assets/js/functions/globalFunc";
import globalSetting from '../../assets/js/functions/globalSettings';
import smpteTc from 'smpte-timecode'; 
export default {
    name: "SubtitleEvent",
    props: {
      index: { // index of current item
        type: Number
      },
      source: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    components: {
        SubtitleEventInput
    },
    computed: {
        multiSelect : function(){
            return this.$store.state.editor.multiSelect.enabled 
        },
        multiSelected : function(){
            if (this.$store.state.editor.multiSelect.captionEvents.indexOf(this.index) > -1){
                return true;
            } else {
                return false;
            }
        },
        selectedEvent: function () {
            return this.$store.state.editor.selectedCaptionEvent;
        },
        wpm: function () {
            var numberOfWords = this.source.subtitleText.trim().split(" ").length;
            
            return (
                Math.round((numberOfWords/(this.source.outcodeSec - this.source.incodeSec)) * 60) || "-"
            );
        },
        cpm : function(){
            var numberOfChars = this.source.subtitleText.trim().split("").length;
            return (
                Math.round((numberOfChars/(this.source.outcodeSec - this.source.incodeSec)) * 60) || "-"
            );
        },

        confidence: function () {
            return this.source.confidence;
        },
        incode: {
            get() {
                return this.source.incode;
            },
            set(incode) {
                incode = globalFunc.tcValidate(
                    incode,
                    this.$store.state.editor.projectSettings.framerate
                );
                this.$store.commit("updateIncode", {
                    id: this.index,
                    timecode: incode,
                    target: "captions"
                });
            }
        },
        outcode: {
            get() {
                return this.source.outcode;
            },
            set(outcode) {
                outcode = globalFunc.tcValidate(
                    outcode,
                    this.$store.state.editor.projectSettings.framerate
                );
                this.$store.commit("updateCaptionOutcode", {
                    id: this.index,
                    timecode: outcode
                });
            }
        }
    },
    methods: {
        updateTcByFrame : function(tcOptions){
            try {  
                var framerate = parseFloat(this.$store.state.editor.projectSettings.framerate) || 29.97;
                var dropFrame = globalSetting.tcFrameMap[framerate] || true;

                var tc = smpteTc(this[tcOptions.tc], framerate, dropFrame);
                if (tcOptions.add){
                    tc.add(1);
                } else {
                    tc.subtract(1);
                }

                if (tcOptions.tc === 'incode'){
                    this.$store.commit("updateIncode", {
                        id: this.index,
                        timecode: tc.toString(),
                        target: "captions"
                    });
                } else {
                    this.$store.commit("updateCaptionOutcode", {
                        id: this.index,
                        timecode: tc.toString()
                    });
                } 
                
                if (this.$store.state.editor.player.captionPreview.videoLock) {
                    this.$store.commit("cueToTimeSec", this.source[tcOptions.tc+'Sec']);
                }

            } catch(e){
                console.log(e.message);
            }            
        },
        
        multiSelectEvent : function(event){
            if (event.shiftKey){
                this.$store.commit('selectMultiEvent', this.index);
            } else {
                this.$store.commit('selectEvent', this.index);
            }				
        },
        setIncode: function (event) {
            this.$store.commit("setIncode", this.index);
        },
        setOutcode: function (event) {
            this.$store.commit("setOutcode", this.index);
        },
        deleteEvent: function () {
            this.$store.commit("deleteCaptionEvent", this.index);
        },
        addEvent: function () {
            this.$store.commit("insertCaptionEventInPlace", this.index+1);
        },
        updateCurrentTime: function (event) {
            if (this.index != this.$store.state.editor.selectedCaptionEvent) {
                this.$store.commit("selectCaptionEvent", this.index);
            }
            
            try {
                if (event === "incodeSec") {
                    var currentTime =
                        this.source.incodeSec || 0;
                } else {
                    var currentTime =
                        this.source.outcodeSec || 0;
                }

                if (this.$store.state.editor.player.captionPreview.videoLock) {
                    this.$store.commit("cueToTimeSec", currentTime);
                }
            } catch (e) {
                console.log("Warning: Failed to update current time. " + e.message);
            }
        }
    }
};
</script>
<style scoped>
.newEventButton {
    position: absolute;
    bottom: -8%;
    width : 60px;
    z-index:999;
}
</style>