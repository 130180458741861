const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");
const sccFunc = require("../functions/profiles/scenerist.js");
const sccLookup = require("../dict/608.js");

module.exports = {
  decode: function (fileContents, options) {
    let events = [];
    let buffer = new ccEvent(),
      screen = false,
      clock,
      displayStyle = false,
      sccCode;

    buffer.insertLine();
    /* Split File lines and filter anything that doesn't have a timecode */
    let fileLines = fileContents.split("\n").filter((fileLine) => {
      return /^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g.test(
        fileLine.split("\t")[0]
      );
    });

    fileLines.forEach((fileLine) => {
      clock = timecodeLib.createTc(
        fileLine.split("\t")[0],
        options.frameRate,
        options.dropFrame
      );

      /* Get all caption codes */
      let captionCodes = fileLine.split("\t")[1].trim().split(" ");
      captionCodes.forEach(function (code, index, codes) {
        if (code.length !== 4) {
          throw new Error(
            "SCC decode error at timecode: " +
              clock.toString() +
              "\nCommand length exceeds limit: " +
              code
          );
        }

        if (
          (sccLookup.all[code] && code !== codes[index + 1]) ||
          sccLookup.specialChars.indexOf(sccLookup.all[code]) > -1
        ) {
          sccCode = sccLookup.all[code];

          if (sccCode === "{RESUME LOADING}") {
            displayStyle = "popOn";
            if (buffer.lines.length > 0 && buffer.lines[0].text != "") {
              buffer = new ccEvent();
              buffer.insertLine();
            }
          } else if (sccCode === "{PAINT ON}") {
            displayStyle = "paintOn";
            screen = new ccEvent({
              style: "Paint-On",
              start: timecodeLib.tcToSec(clock.toString(), options.frameRate),
            });

            screen.insertLine();
          } else if (sccCode === "{ROLLUP2}") {
            displayStyle = "rollUp2";
            if (screen && !screen.end) {
              screen.end = timecodeLib.tcToSec(
                clock.toString(),
                options.frameRate
              );
              events.push(screen);
            }
            screen = new ccEvent({
              style: "Roll-Up",
              start: timecodeLib.tcToSec(clock.toString(), options.frameRate),
            });

            screen.insertLine({
              posY: (14 / 14) * 100,
            });
          } else if (sccCode === "{ROLLUP3}") {
            displayStyle = "rollUp3";
            if (screen && !screen.end) {
              screen.end = timecodeLib.tcToSec(
                clock.toString(),
                options.frameRate
              );
              events.push(screen);
            }
            screen = new ccEvent({
              style: "Roll-Up",
              start: timecodeLib.tcToSec(clock.toString(), options.frameRate),
            });

            screen.insertLine({
              posY: (13 / 14) * 100,
            });
          } else if (sccCode === "{ROLLUP4}") {
            displayStyle = "rollUp4";
            if (screen && !screen.end) {
              screen.end = timecodeLib.tcToSec(
                clock.toString(),
                options.frameRate
              );
              events.push(screen);
            }
            screen = new ccEvent({
              style: "Roll-Up",
              start: timecodeLib.tcToSec(clock.toString(), options.frameRate),
            });

            screen.insertLine({
              posY: (12 / 14) * 100,
            });
          } else if (sccCode === "{TEXT MSG}") {
            /* Not Supported */
          } else if (sccCode === "{RESUME TEXT MSG}") {
            /* Not Supported */
          } else if (sccCode === "{CLEAR BUFFER}") {
            buffer = new ccEvent();
            buffer.insertLine();
          } else if (sccCode === "{CLEAR DISPLAY}") {
            if (screen && screen.end === undefined) {
              screen.end = timecodeLib.tcToSec(
                clock.toString(),
                options.frameRate
              );
              events.push(screen);
            }
          } else if (sccCode === "{DISPLAY BUFFER}") {
            /* Clear what's on the screen if there is something still being shown */
            if (screen && screen.end === undefined) {
              screen.end = timecodeLib.tcToSec(
                clock.toString(),
                options.frameRate
              );
              events.push(screen);
            }

            buffer.start = timecodeLib.tcToSec(
              clock.toString(),
              options.frameRate
            );
            screen = buffer;
            buffer = new ccEvent();
            buffer.insertLine();
          } else if (sccCode === "{ITALICS}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].italics = true;
              buffer.lines[buffer.lines.length - 1].text += " ";
            } else {
              screen.lines[screen.lines.length - 1].italics = true;
              screen.lines[screen.lines.length - 1].text += " ";
            }
          } else if (sccCode === "{UNDERLINE}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].underline = true;
              buffer.lines[buffer.lines.length - 1].text += " ";
            } else {
              screen.lines[screen.lines.length - 1].underline = true;
              screen.lines[screen.lines.length - 1].text += " ";
            }
          } else if (sccCode === "{ITALICS_UNDERLINE}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].italics = true;
              buffer.lines[buffer.lines.length - 1].underline = true;
              buffer.lines[buffer.lines.length - 1].text += " ";
            } else {
              screen.lines[screen.lines.length - 1].italics = true;
              screen.lines[screen.lines.length - 1].underline = true;
              screen.lines[screen.lines.length - 1].text += " ";
            }
          } else if (/{\d\d_\d\d}{ITALICS_UNDERLINE}/.test(sccCode)) {
            let posX = sccCode.substring(4, 6);
            let posY = sccCode.substring(1, 3);

            if (displayStyle === "popOn") {
              buffer.insertLine({
                italics: true,
                underline: true,
                posX: (posX / 31) * 100,
                posY: (posY / 14) * 100,
              });
            } else {
              screen.insertLine({
                italics: true,
                underline: true,
                posX: (posX / 31) * 100,
                posY: (posY / 14) * 100,
              });
            }
          } else if (/{\d\d_\d\d}{ITALICS}/.test(sccCode)) {
            let posX = sccCode.substring(4, 6);
            let posY = sccCode.substring(1, 3);
            if (displayStyle === "popOn") {
              buffer.insertLine({
                italics: true,
                posX: (posX / 31) * 100,
                posY: (posY / 14) * 100,
              });
            } else {
              screen.insertLine({
                italics: true,
                posX: (posX / 31) * 100,
                posY: (posY / 14) * 100,
              });
            }
          } else if (/{\d\d_\d\d}{UNDERLINE}/.test(sccCode)) {
            let posX = sccCode.substring(4, 6);
            let posY = sccCode.substring(1, 3);
            if (displayStyle === "popOn") {
              buffer.insertLine({
                underline: true,
                posX: (posX / 31) * 100,
                posY: (posY / 14) * 100,
              });
            } else {
              screen.insertLine({
                underline: true,
                posX: (posX / 31) * 100,
                posY: (posY / 14) * 100,
              });
            }
          } else if (/{\d\d_\d\d}/.test(sccCode)) {
            let posX = sccCode.substring(4, 6);
            let posY = sccCode.substring(1, 3);
            if (displayStyle === "popOn") {
              buffer.insertLine({
                posX: (posX / 31) * 100,
                posY: (posY / 14) * 100,
              });
            } else if (displayStyle === "paintOn") {
              screen.insertLine({
                posX: (posX / 31) * 100,
                posY: (posY / 14) * 100,
              });
            }
          } else if (sccCode.includes("TAB")) {
            let xOffset = parseInt(sccCode.split("TAB")[1]);
            if (displayStyle === "popOn") {
              if (buffer.lines.length === 0) {
                buffer.insertLine();
              }
              buffer.lines[buffer.lines.length - 1].posX +=
                (xOffset / 31) * 100;
            } else {
              screen.lines[screen.lines.length - 1].posX +=
                (xOffset / 31) * 100;
            }
          } else if (sccCode.includes("{COLOR:")) {
            let color = sccCode.split(":")[1].split(";")[0].split("}")[0];
            let underline = sccCode.includes("UNDERLINE");
            if (displayStyle === "popOn") {
              buffer.color = sccFunc.colorMapping[color];
              buffer.lines[buffer.lines.length - 1].underline = underline;
              buffer.lines[buffer.lines.length - 1].text += " ";
            } else {
              screen.color = sccFunc.colorMapping[color];
              screen.lines[screen.lines.length - 1].underline = underline;
              screen.lines[screen.lines.length - 1].text += " ";
            }
          } else if (sccCode.includes("{BACKGROUND:")) {
            let bgColor = sccCode.split(":")[1].split(";")[0].split("}")[0];
            let opacity = 1;
            if (sccCode.includes("TRANSPARENT") && bgColor != "NONE") {
              opacity = 0.5;
            } else if (bgColor === "NONE") {
              opacity = 0;
            }

            if (displayStyle === "popOn") {
              buffer.background = sccFunc.colorMapping[bgColor];
              buffer.opacity = opacity;
            } else {
              screen.background = sccFunc.colorMapping[bgColor];
              screen.opacity = opacity;
            }
          } else if (sccCode === "{NEW LINE}") {
            if (displayStyle === "popOn") {
              buffer.insertLine();
            } else if (displayStyle === "rollUp2") {
              if (screen.lines.length === 2) {
                screen.end = timecodeLib.tcToSec(
                  clock.toString(),
                  options.frameRate
                );
                events.push(screen);
                screen = new ccEvent({
                  start: timecodeLib.tcToSec(
                    clock.toString(),
                    options.frameRate
                  ),
                  style: "Roll-Up",
                });

                screen.insertLine({
                  posY: (14 / 14) * 100,
                });
              } else {
                screen.insertLine({
                  posY: ((14 + screen.lines.length) / 14) * 100,
                });
              }
            } else if (displayStyle === "rollUp3") {
              if (screen.lines.length === 3) {
                screen.end = timecodeLib.tcToSec(
                  clock.toString(),
                  options.frameRate
                );
                events.push(screen);
                screen = new ccEvent({
                  start: timecodeLib.tcToSec(
                    clock.toString(),
                    options.frameRate
                  ),
                  style: "Roll-Up",
                });

                screen.insertLine({
                  posY: (13 / 14) * 100,
                });
              } else {
                screen.insertLine({
                  posY: ((13 + screen.lines.length) / 14) * 100,
                });
              }
            } else if (displayStyle === "rollUp4") {
              if (screen.lines.length === 4) {
                screen.end = timecodeLib.tcToSec(
                  clock.toString(),
                  options.frameRate
                );
                events.push(screen);
                screen = new ccEvent({
                  start: timecodeLib.tcToSec(
                    clock.toString(),
                    options.frameRate
                  ),
                  style: "Roll-Up",
                });

                screen.insertLine({
                  posY: (12 / 14) * 100,
                });
              } else {
                screen.insertLine({
                  posY: ((14 + screen.lines.length) / 14) * 100,
                });
              }
            } else {
              screen.insertLine({ style: "Paint-On" });
            }
          } else if (sccCode === "{BACKSPACE}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].text = buffer.lines[
                buffer.lines.length - 1
              ].text.slice(0, -1);
            } else {
              screen.lines[screen.lines.length - 1].text = screen.lines[
                buffer.lines.length - 1
              ].text.slice(0, -1);
            }
          } else if (sccCode === "{DELETE ROW}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].text = "";
              buffer.lines[buffer.lines.length - 1].posY = 0;
            } else {
              screen.lines[screen.lines.length - 1].text = "";
              screen.lines[screen.lines.length - 1].posY = 0;
            }
          } else if (sccCode !== "{FILLER}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].text += sccCode;
            } else {
              screen.lines[screen.lines.length - 1].text += sccCode;
            }
          }

          // Add 1 frame for each byte (or hex group);
          clock.add(1);
        } else if (sccLookup.all[code] === undefined) {
          [code.substring(0, 2), code.substring(2, 4)]
            .filter((sccCode) => {
              return (
                sccLookup.all[sccCode] !== undefined &&
                sccLookup.all[sccCode] !== "{FILLER}"
              );
            })
            .forEach((sccCode) => {
              if (displayStyle === "popOn") {
                if (buffer.lines.length === 0) {
                  buffer.insertLine();
                }
                buffer.lines[buffer.lines.length - 1].text +=
                  sccLookup.all[sccCode];
              } else if (screen.lines) {
                screen.lines[screen.lines.length - 1].text +=
                  sccLookup.all[sccCode];
              }
            });

          // Add 1 frame for each byte (or hex group);
          clock.add(1);
        }
      });
    });

    return events;
  },

  encode: function (events, options) {
    let fileContents = "Scenarist_SCC V1.0",
      encodingOptions = {},
      incode,
      outcodeOfPrevEvent,
      encodedText,
      encodedTextString,
      encodeTime,
      frameDifference,
      displayFlag = false,
      channel = "ch01",
      clock;

    if (options.formatOptions && options.formatOptions.length > 0) {
      options.formatOptions.forEach((formatOption) => {
        encodingOptions[formatOption.name] = formatOption.selected;
      });
    }

    if (encodingOptions["Channel"]) {
      channel = encodingOptions["Channel"].toLowerCase();
    }

    if (encodingOptions["Incode"] && encodingOptions["Incode"] !== ""){
      try {
        clock = timecodeLib.createTc(
          encodingOptions["Incode"],
          options.frameRate,
          options.dropFrame
        )
      } catch(err){
        throw new Error("Encoding Option for Incode must be in SMPTE timecode format")
      }
    } else {
      clock = timecodeLib.createTc(
        timecodeLib.secToTc(events[0].start, options.frameRate),
        options.frameRate,
        options.dropFrame
      );

      try {
        clock.subtract(100);
      } catch (e) {
        clock = timecodeLib.createTc(
          "00:00:00:01",
          options.frameRate,
          options.dropFrame
        );
      }
    }     

    events.forEach(function (event, index, events) {
      incode = timecodeLib.createTc(
        timecodeLib.secToTc(event.start, options.frameRate),
        options.frameRate,
        options.dropFrame
      );

      encodedText = sccFunc.encodeEvent(event, channel);
      encodedTextString = sccFunc.encodedTextToString(encodedText);
      encodeTime = sccFunc.calculateEncodeTime(encodedTextString);
      if (event.style === "Pop-On") {
        fileContents +=
          "\n\n" +
          timecodeLib.formatTimecodeString(
            clock.toString(),
            options.dropFrame
          ) +
          "\t" +
          encodedTextString;

        clock.add(encodeTime);

        if (incode.frameCount <= clock.frameCount && incode.frameCount >= clock.frameCount - 2) {
          frameDifference = Math.max(0, clock.frameCount - (incode.frameCount + 1));
          for (let j = 0; j < frameDifference; j++) {
            fileContents += " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
          }

          fileContents +=
            " " + sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

          fileContents +=
            " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

          displayFlag = true;
          clock.add(2 + frameDifference);
        } else {
          if (displayFlag) {
            outcodeOfPrevEvent = timecodeLib.createTc(
              timecodeLib.secToTc(events[index - 1].end, options.frameRate),
              options.frameRate,
              options.dropFrame
            );

            if (outcodeOfPrevEvent.frameCount <= clock.frameCount && outcodeOfPrevEvent.frameCount >= clock.frameCount-2) {
              frameDifference = Math.max(
                0,
                clock.frameCount - (outcodeOfPrevEvent.frameCount + 1)
              );

              for (let j = 0; j < frameDifference; j++) {
                fileContents +=
                  " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
              }

              fileContents +=
                " " +
                sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

              displayFlag = false;
              clock.add(1 + frameDifference);

              clock = incode;
              fileContents +=
                "\n\n" +
                timecodeLib.formatTimecodeString(
                  clock.toString(),
                  options.dropFrame
                ) +
                "\t" +
                sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

              fileContents +=
                " " +
                sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

              displayFlag = true;
              clock.add(2);
            } else {
              clock = outcodeOfPrevEvent;
              if (incode.frameCount <= clock.frameCount + 1) {
                fileContents +=
                  "\n\n" +
                  timecodeLib.formatTimecodeString(
                    clock.toString(),
                    options.dropFrame
                  ) +
                  "\t" +
                  sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

                fileContents +=
                  " " +
                  sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                displayFlag = true;
                clock.add(2);
              } else {
                fileContents +=
                  "\n\n" +
                  timecodeLib.formatTimecodeString(
                    clock.toString(),
                    options.dropFrame
                  ) +
                  "\t" +
                  sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

                displayFlag = false;
                clock.add(1);

                clock = incode;
                fileContents +=
                  "\n\n" +
                  timecodeLib.formatTimecodeString(
                    clock.toString(),
                    options.dropFrame
                  ) +
                  "\t" +
                  sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

                fileContents +=
                  " " +
                  sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                displayFlag = true;
                clock.add(2);
              }
            }
          } else if (incode.frameCount <= clock.frameCount && incode.frameCount >= clock.frameCount - 2) {
            frameDifference = Math.max(
              0,
              clock.frameCount - (incode.frameCount + 1)
            );
            for (let j = 0; j < frameDifference; j++) {
              fileContents +=
                " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
            }

            fileContents +=
              " " +
              sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

            fileContents +=
              " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

            displayFlag = true;
            clock.add(2 + frameDifference);
          } else {
            clock = incode;

            fileContents +=
              "\n\n" +
              timecodeLib.formatTimecodeString(
                clock.toString(),
                options.dropFrame
              ) +
              "\t" +
              sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

            fileContents +=
              " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

            displayFlag = true;
            clock.add(2);
          }
        }
        /* RollUp or PaintOn */
      } else {
        if (displayFlag) {
          outcodeOfPrevEvent = timecodeLib.createTc(
            timecodeLib.secToTc(events[index - 1].end, options.frameRate),
            options.frameRate,
            options.dropFrame
          );

          if (outcodeOfPrevEvent.frameCount <= clock.frameCount && outcodeOfPrevEvent.frameCount >= clock.frameCount - 2) {
            frameDifference = Math.max(
              0,
              clock.frameCount - (outcodeOfPrevEvent.frameCount + 1)
            );
            for (let j = 0; j < frameDifference; j++) {
              fileContents +=
                " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
            }

            fileContents +=
              " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

            displayFlag = false;
            clock.add(1 + frameDifference);
          } else {
            clock = outcodeOfPrevEvent;

            fileContents +=
              "\n\n" +
              timecodeLib.formatTimecodeString(
                clock.toString(),
                options.dropFrame
              ) +
              "\t" +
              sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

            displayFlag = false;
            clock.add(1);
          }
        }

        if (incode.frameCount <= clock.frameCount && incode.frameCount >= clock.frameCount - 2) {
          frameDifference = Math.max(
            0,
            clock.frameCount - (incode.frameCount + 1)
          );

          for (let j = 0; j < frameDifference; j++) {
            fileContents +=
              " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
          }

          fileContents += " " + encodedTextString;
          displayFlag = true;
          clock.add(encodeTime + frameDifference);
        } else {
          clock = incode;

          fileContents +=
            "\n\n" +
            timecodeLib.formatTimecodeString(
              clock.toString(),
              options.dropFrame
            ) +
            "\t" +
            encodedTextString;

          displayFlag = true;
          clock.add(encodeTime);
        }
      }
    });

    if (displayFlag) {
      outcodeOfPrevEvent = timecodeLib.createTc(
        timecodeLib.secToTc(events[events.length - 1].end, options.frameRate),
        options.frameRate,
        options.dropFrame
      );

      if (outcodeOfPrevEvent.frameCount <= clock.frameCount && outcodeOfPrevEvent.frameCount >= clock.frameCount-2) {
        frameDifference = Math.max(
          0,
          clock.frameCount - (outcodeOfPrevEvent.frameCount + 1)
        );
        for (let j = 0; j < frameDifference; j++) {
          fileContents +=
            " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
        }

        fileContents +=
          " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

        displayFlag = false;
        clock.add(1 + frameDifference);
      } else {
        clock = outcodeOfPrevEvent;
        fileContents +=
          "\n\n" +
          timecodeLib.formatTimecodeString(
            clock.toString(),
            options.dropFrame
          ) +
          "\t" +
          sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");
        fileContents +=
          " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");
        fileContents +=
          " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

        displayFlag = false;
        clock.add(3);
      }
    }

    if (encodingOptions["Line Endings"]) {
      if (encodingOptions["Line Endings"].toLowerCase() === "windows") {
        fileContents = eol.crlf(fileContents);
      } else if (
        encodingOptions["Line Endings"].toLowerCase() === "macintosh"
      ) {
        fileContents = eol.cr(fileContents);
      }
    }

    return fileContents;
  },
  preProcess: {
    encode: function (events, options) {
      return events.filter((event) => {
        if (!sccFunc.verifyFormatting(event.lines)) {
          event.autoFormat(options);
          event.alignTextCenter();
        }

        event.syncSubtitleText();
        return event.start != "" && event.end != "" && event.text != "";
      });
    },

    decode: function (fileContents) {
      /* All */
      fileContents = eol.lf(fileContents);
      fileContents = fileContents.trim();

      /* Specific */
      fileContents = fileContents.toLowerCase();
      return fileContents;
    },
  },
  postProcess: {
    encode: function (fileContents) {
      return fileContents;
    },
    decode: function (events) {
      return events.filter((event) => {
        event.removeBlankLines();
        event.lines.forEach((line) => {
          line.text = line.text
            .replace(/AÀ/g, "À")
            .replace(/AÂ/g, "Â")
            .replace(/CÇ/g, "Ç")
            .replace(/EÉ/g, "É")
            .replace(/EÈ/g, "È")
            .replace(/EÊ/g, "Ê")
            .replace(/EË/g, "Ë")
            .replace(/eë/g, "ë")
            .replace(/IÎ/g, "Î")
            .replace(/IÏ/g, "Ï")
            .replace(/lï/g, "ï")
            .replace(/OÔ/g, "Ô")
            .replace(/UÙ/g, "Ù")
            .replace(/uù/g, "ù")
            .replace(/UÛ/g, "Û")
            .replace(/AÃ/g, "Ã")
            .replace(/aã/g, "ã")
            .replace(/IÍ/g, "Í")
            .replace(/IÌ/g, "Ì")
            .replace(/iì/g, "ì")
            .replace(/OÒ/g, "Ò")
            .replace(/oò/g, "ò")
            .replace(/OÕ/g, "Õ")
            .replace(/oõ/g, "õ")
            .replace(/AÄ/g, "Ä")
            .replace(/aä/g, "ä")
            .replace(/OÖ/g, "Ö")
            .replace(/oö/g, "ö")
            .replace(/AÅ/g, "Å")
            .replace(/aå/g, "å")
            .replace(/'’/g, "'")
            .replace(/íI/g, "I")
            .replace(/<«/g, "«")
            .replace(/>»/g, "»")
            .replace(/♪♪/gim, "♪")
            .replace(/éá/g, "é");
        });

        event.setAlignmentByLine();
        event.syncSubtitleText();

        return event.start != "" && event.end != "" && event.text != "";
      });
    },
  },
};
