<template>
<nav class="navbar navbar-expand-md custom-navbar fixed-top shadow">
  <!-- <span class="navbar-brand m-2 h1"><i class="far fa-closed-captioning"></i></span> -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fas fa-bars" style="color:white;"></i>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" style="background-color:#333333;">
    <ul class="navbar-nav pull-sm-left">
      <!-- Left Nav -->
      <!-- FILE -->
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="supportedContentDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-bars"></i> File
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li>
            <button class="dropdown-item btn btn-link" href="#" data-toggle="modal" data-target="#NewProjectModal">
              <i class="fa fa-file" aria-hidden="true"></i> New Project
            </button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="saveProject">
              <i class="far fa-save"></i> Save Project
            </button>
          </li>
          
          <li>
            <button type="button" class="dropdown-item btn btn-link" data-toggle="modal" data-target="#LoadProjectModal">
              <i class="fas fa-download"></i> Open Project
            </button>
          </li>
          <!-- IMPORT -->
          <li>
            <button class="dropdown-item btn btn-link" @click.stop="expandMenu('import')">
              <i class="fas fa-file-import"></i> Import <i v-if="expand.import" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
            </button>
          </li>

          <ul class="submenu" v-if="expand.import">
            <li>
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ImportCaptionsModal">
                <i class="far fa-closed-captioning"></i>
                Caption File
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ImportVideoModal">
                <i class="fas fa-file-video"></i>
                Media File
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ImportPlainTextModal">
                <i class="fas fa-edit"></i>
                Plain Text File
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ImportProjectModal">
                <i class="fas fa-briefcase"></i> Project File
              </a>
            </li>
            <li v-if="appVersion === 'pro'">
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ImportTranscriptModal">
                <i class="far fa-file-alt"></i>
                AI Transcript
              </a>
            </li>
          </ul>
          <!-- IMPORT END -->
          <!-- EXPORT -->
          <li>
            <button class="dropdown-item btn btn-link" @click.stop="expandMenu('export')">
              <i class="fas fa-file-export"></i> Export <i v-if="expand.export" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
            </button>
            <ul class="submenu" v-if="expand.export">
              <li>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ExportTranscript">
                  <i class="far fa-file-word"></i>
                  Transcript File
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ExportCaptionModal">
                  <i class="far fa-closed-captioning"></i>
                  Caption File
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ExportData">
                  <i class="far fa-file-code"></i>
                  Data File
                </a>
              </li>
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="exportProject">
                  <i class="fas fa-briefcase"></i> Project File
                </button>
              </li>
              <li v-if="appEnv === 'desktop'">
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ExportVideo">
                  <i class="fas fa-film"></i>
                  Video File
                </a>
              </li>
              <li v-if="appEnv === 'desktop' && plugins.indexOf('ad')>-1">
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ExportAd">
                  <i class="fas fa-audio-description"></i>
                  Audio Description (DV)
                </a>
              </li>
            </ul>
          </li>
          <!-- EXPORT END -->
          <!-- VIEW -->
          <div class="dropdown-divider border-top border-secondary"></div>
          <li>
            <button class="dropdown-item btn btn-link" @click.stop="expandMenu('workspace')">
              <i class="fas fa-th-large"></i> Workspaces <i v-if="expand.workspace" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
            </button>
            <ul class="submenu" v-if="expand.workspace">
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="updateView('default')" v-bind:class="{'text-info':activeView==='default'}">
                  <i class="fas fa-digital-tachograph"></i>
                  Default
                </button>
              </li>
              <!-- <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="updateView('ccEditor')" v-bind:class="{'text-info':activeView==='ccEditor'}">
                  <i class="fas fa-digital-tachograph"></i>
                  CC Editor *NEW*
                </button>
              </li> -->
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="updateView('subtitle')" v-bind:class="{'text-info':activeView==='subtitle'}">
                  <i class="fas fa-align-center"></i>
                  Subtitle
                </button>
              </li>
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="updateView('classic')" v-bind:class="{'text-info':activeView==='classic'}">
                  <i class="fas fa-bars"></i>
                  Classic
                </button>
              </li>
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="updateView('transcript')" v-bind:class="{'text-info':activeView==='transcript'}">
                  <i class="far fa-file-alt"></i>
                  Transcript
                </button>
              </li>
              <li v-if="appEnv === 'desktop' && plugins.indexOf('ad')>-1">
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="updateView('audio description')" v-bind:class="{'text-info':activeView==='audio description'}">
                  <i class="fas fa-audio-description"></i>
                  Audio Description (DV)
                </button>
              </li>
            </ul>
          </li>
          <li>
            <button class="dropdown-item btn btn-link" @click.stop="expandMenu('view')">
              <i class="far fa-eye"></i> View <i v-if="expand.view" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
            </button>
            <ul class="submenu" v-if="expand.view">
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="zoomIn" v-bind:class="{'text-info':activeView==='transcript'}">
                  <i class="fas fa-search-plus"></i>
                  Zoom In
                </button>
              </li>
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="zoomOut" v-bind:class="{'text-info':activeView==='transcript'}">
                  <i class="fas fa-search-minus"></i>
                  Zoom Out
                </button>
              </li>
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="toggleFullScreen" v-bind:class="{'text-info':activeView==='transcript'}">
                  <i class="fas fa-expand"></i>
                  Fullscreen
                </button>
              </li>
            </ul>
          </li>
          <!-- VIEW END -->
          <div v-if="appEnv === 'desktop'" class="dropdown-divider border-top border-secondary"></div>
          <li v-if="appEnv === 'desktop'">
            <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="quit">
              <i class="fas fa-door-open"></i> Quit
            </button>
          </li>
        </ul>
      </li>
      <!-- FILE END -->
      <!-- EDIT -->
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="supportedContentDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="far fa-edit"></i> Edit
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li v-if="['classic', 'default'].indexOf(activeView) > -1">
            <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="removeBlankLines">
              <i class="fas fa-ghost"></i> Remove Empty Events
            </button>
          </li>
          <li v-if="['classic', 'default'].indexOf(activeView) > -1">
            <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="fixLineLength">
              <i class="far fa-life-ring"></i> Fix Line Length
            </button>
          </li>
          <li v-if="['classic', 'default', 'subtitle'].indexOf(activeView) > -1">
            <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="fixOrder">
              <i class="fas fa-sort-amount-down"></i> Fix Ordering
            </button>
          </li>
          <li v-if="['classic', 'default', 'subtitle'].indexOf(activeView) > -1">
            <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="fixOverlap">
              <i class="fa fa-clone" aria-hidden="true"></i> Fix Overlap
            </button>
          </li>
          <li v-if="['classic', 'default', 'subtitle'].indexOf(activeView) > -1">
            <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="autoFormat">
              <i class="fas fa-align-justify"></i> Auto Format
            </button>
          </li>
          <li v-if="['classic', 'default', 'subtitle'].indexOf(activeView) > -1">
            <button class="dropdown-item btn btn-link" @click.stop="expandMenu('merge')">
              <i class="fas fa-object-group"></i> Merge All <i v-if="expand.merge" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
            </button>
            <ul class="submenu" v-if="expand.merge">
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="mergeEvents('even')">Even Events</button>
              </li>
              <li>
                <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="mergeEvents('odd')">Odd Events</button>
              </li>
            </ul>
          </li>
          <li>
            <button class="dropdown-item btn btn-link" href="#" data-toggle="modal" data-target="#ShortcutKeys">
              <i class="far fa-keyboard"></i> Shortcut Keys
            </button>
          </li>
          <div class="dropdown-divider border-top border-secondary" v-if="['classic', 'default', 'subtitle'].indexOf(activeView) > -1"></div>
          <li v-if="['classic', 'default', 'subtitle'].indexOf(activeView) > -1">
            <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="removeEvents">
              <i class="fas fa-times"></i> Remove Events
            </button>
          </li>
          <div class="dropdown-divider border-top border-secondary"></div>
          <li>
            <button class="dropdown-item btn btn-link" href="#" data-toggle="modal" data-target="#OptionsModal">
              <i class="fas fa-cogs"></i> Options
            </button>
          </li>
        </ul>
      </li>
      <!-- EDIT END -->
      <!-- FORMAT -->
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="supportedContentDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-italic"></i> Format
        </a>
        <div class="dropdown-menu" aria-labelledby="supportedContentDropdown">
      <li>
        <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="uppercaseAll">
          <i class="fas fa-font"></i> Uppercase
        </button>
      </li>

      <li v-if="['classic', 'default'].indexOf(activeView) > -1">
        <button class="dropdown-item btn btn-link" @click.stop="expandMenu('position')">
          <i class="fab fa-buromobelexperte"></i> Position <i v-if="expand.position" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
        </button>
        <ul class="submenu" v-if="expand.position">
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'top', x: 'left'})">Top Left</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'top', x: 'center'})">Top Center</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'top', x: 'right'})">Top Right</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'middle', x: 'left'})">Center Left</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'middle', x: 'center'})">Center</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'middle', x: 'right'})">Center Right</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'bottom', x: 'left'})">Bottom Left</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'bottom', x: 'center'})">Bottom Center</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" v-on:click="positionAll({y: 'bottom', x: 'right'})">Bottom Right</button>
          </li>
        </ul>
      </li>
      <li v-if="['classic', 'default'].indexOf(activeView) > -1">
        <button class="dropdown-item btn btn-link" @click.stop="expandMenu('style')">
          <i class="fas fa-object-group"></i> Style <i v-if="expand.style" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
        </button>
        <ul class="submenu" v-if="expand.style">
          <li>
            <button type="button" class="dropdown-item btn btn-link" href="#" v-on:click="styleAll('Pop-On')">Pop-On</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" href="#" v-on:click="styleAll('Roll-Up')">Roll-Up</button>
          </li>
          <li>
            <button type="button" class="dropdown-item btn btn-link" href="#" v-on:click="styleAll('Paint-On')">Paint-On</button>
          </li>
        </ul>
      </li>
      <li v-if="['classic', 'default'].indexOf(activeView) > -1">
        <button type="button" class="dropdown-item btn btn-link" data-toggle="modal" data-target="#StartingLineNumberModal">
          <i class="fas fa-sort-amount-down"></i> Update Vertical Position
        </button>
      </li>
  </div>
  </li>
  <!-- FORMAT END -->
  <!-- TIMECODE START -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="supportedContentDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="far fa-clock"></i> Timecode
    </a>
    <div class="dropdown-menu" aria-labelledby="supportedContentDropdown">
  <li>
    <button class="dropdown-item btn btn-link" data-toggle="modal" data-target="#TimecodeOffsetModal">
      <i class="fas fa-arrows-alt-h"></i> Timecode Offset
    </button>
  </li>
  <li>
    <button class="dropdown-item btn btn-link" data-toggle="modal" data-target="#ProjectIncodeModal">
      <i class="fas fa-step-backward"></i> Update Project Incode
    </button>
  </li>
  <li>
    <button class="dropdown-item btn btn-link" data-toggle="modal" data-target="#ProjectFramerateModal">
      <i class="fas fa-stopwatch"></i> Update Project Framerate
    </button>
  </li>
  </div>
  </li>
  <!-- TIMECODE END -->
  <!-- INSERT -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="supportedContentDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fas fa-plus-circle"></i> Insert
    </a>
    <div class="dropdown-menu" aria-labelledby="supportedContentDropdown">
  <li>
    <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="insertCaptionEvent">
      <i class="fas fa-stream"></i> Caption Event
    </button>
  </li>
  <li>
    <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="insert('█')">█ Full Block</button>
  </li>
  <li>
    <button class="dropdown-item btn btn-link" @click.stop="expandMenu('musicNote')">
      ♪ Music Note <i v-if="expand.musicNote" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
    </button>
    <ul class="submenu" v-if="expand.musicNote">
      <li>
        <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="insert('♪')">♪ Music Note (Single)</button>
      </li>
      <li>
        <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="insert('♪♪')">♪♪ Music Note (Double)</button>
      </li>
      <li>
        <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="insertSurround('♪')">♪...♪ Music Note (Surround)</button>
      </li>
    </ul>
  </li>
  </div>
  </li>
  <!-- INSERT End -->
  <!-- TOOLS -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="supportedContentDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fas fa-tools"></i> Tools
    </a>
    <div class="dropdown-menu" aria-labelledby="supportedContentDropdown">
  <li v-if="activeView === 'default'">
    <button type="button" class="dropdown-item btn btn-link" href="#" v-on:click="viewTimeline" v-bind:class="{'text-info':timelineOn}">
      <i class="far fa-map"></i> Timeline View
    </button>
  </li>
  <li>
    <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="updateSubViewerOption('search')" v-bind:class="{'text-info':subViewerOption==='search'}">
      <i class="fas fa-search"></i> Search
    </button>
  </li>
  <li v-if="appEnv === 'desktop' && activeView != 'transcript'">
    <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="updateSubViewerOption('spellcheck')" v-bind:class="{'text-info':subViewerOption==='spellcheck'}">
      <i class="fas fa-spell-check"></i> Spellcheck
    </button>
  </li>
  <li>
    <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="toggleMultiSelect" v-bind:class="{'text-info':multiSelectView}">
      <i class="far fa-check-square"></i> Multi-Select
    </button>
  </li>
  <li v-if="multiSelectView">
    <button type="button" class="dropdown-item btn btn-link" href="#" @click.stop="selectInvert">
      <i class="fas fa-adjust"></i> Invert Selection
    </button>
  </li>
  <li v-if="appEnv=== 'desktop'">
    <button type="button" class="dropdown-item btn btn-link" href="#" data-toggle="modal" data-target="#SceneChangeModal" v-bind:class="{'disabled':!playerLoadStatus}">
      <i class="far fa-images"></i> Scene Change Detection
    </button>
  </li>
  <!-- Automation Tools Start -->
  <li v-if="appVersion === 'pro'">
    <button class="dropdown-item btn btn-link" @click.stop="expandMenu('aiTools')">
      <i class="fas fa-brain"></i> Ai Tools <i v-if="expand.aiTools" class="fas fa-caret-down"></i> <i v-else class="fas fa-caret-right"></i>
    </button>
    <ul class="submenu" v-if="expand.aiTools">
      <li>
        <button class="dropdown-item btn btn-link" data-toggle="modal" data-target="#AutomaticTranslationModal">

          <i class="fas fa-language"></i> Automatic Translation

        </button>
      </li>
      <li>
        <button v-if="appEnv === 'desktop'" class="dropdown-item btn btn-link" href="#" data-toggle="modal" data-target="#AutomaticCaptioningModal">
          <i class="fas fa-comment-dots"></i> Automatic Captioning
        </button>
      </li>
      <!-- <a v-if="appEnv === 'desktop'" class="dropdown-item btn" :class="{'disabled':!playerLoadStatus}" href="#" data-toggle="modal"  data-target="#AutomaticTimingModal">
                                <i class="fas fa-business-time"></i> Automatic Timing
                            </a> -->
    </ul>
  </li>
  <!-- Automation Tools End -->
  
  </div>
  </li>
  <!-- TOOLS END -->
  </ul>

  <ProgressBar v-if="statusDisplay"></ProgressBar>
  <Transport v-else></Transport>

  <ul class="navbar-nav pull-sm-right">
    <!-- Right NAV -->
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-life-ring"></i> Help
      </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="supportedContentDropdown">
        <button class="btn btn-link dropdown-item" type="buton" data-toggle="modal" data-target="#FeatureRequest">
          <i class="fa fa-desktop" aria-hidden="true"></i> Request a new feature...
        </button>
        <button class="btn btn-link dropdown-item" type="buton" data-toggle="modal" data-target="#BugReport">
          <i class="fa fa-bug" aria-hidden="true"></i> Report an issue...
        </button>
        <button class="btn btn-link dropdown-item" type="buton" data-toggle="modal" data-target="#About">
          <i class="far fa-question-circle"></i> About CC Creator
        </button>
        <button class="btn btn-link dropdown-item" type="buton" @click.stop="restoreProject">
          <i class="fas fa-ambulance"></i> Restore Project
        </button>
        <div class="dropdown-divider border-top border-secondary" v-if="appMode === 'standalone'"></div>
        <button type="button" class="dropdown-item btn btn-link" v-if="appMode === 'standalone'" href="#" @click.stop="logout">
          <i class="fas fa-door-open"></i> Logout
        </button>
      </div>
    </li>
  </ul>
  </div>
</nav>
</template>

<script>
import Transport from "./Transport";
import ProgressBar from "./ProgressBar";
import globalFunc from "../../assets/js/functions/globalFunc";
import firebase from '@/firebase/config'
export default {
  name: "Toolbar",
  data: function () {
    return {
      zoomLevel : 100,
      expand: {
        position: false,
        import: false,
        export: false,
        view: false,
        workspace: false,
        merge: false,
        position: false,
        style: false,
        musicNote: false,
        aiTools: false
      }
    }
  },
  created: function () {
    if (typeof (Storage) !== "undefined") {
      if (localStorage.getItem('defaultView')) {
        var view = localStorage.getItem('defaultView');
        this.$store.commit("updateView", view);
      }
    }
  },
  methods: {
    expandMenu: function (menu) {
      this.expand[menu] = !this.expand[menu];
      this.expand
    },
    restoreProject: function () {
      var acceptRestore = false;
      var self = this;
      acceptRestore = confirm("Are you sure you wish to restore a project from local storage?");
      if (acceptRestore) {
        if (typeof (Storage) !== "undefined") {
          if (localStorage.getItem('backupProject')) {
            var project = {};
            project.captions = JSON.parse(localStorage.getItem('backupProject'));
            self.$store.commit('importCaptionsFromProject', project);
          } else {
            alert("There is no project to restore");
          }
        }

      }

    },
    zoomIn: function () {
        this.zoomLevel += 10;
        document.body.style.zoom= this.zoomLevel/100;
    },
    zoomOut: function () {
        this.zoomLevel -= 10;
        document.body.style.zoom= this.zoomLevel/100;
    },

    toggleFullScreen: function () {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
    },
    quit: function () {
      const remote = require('electron').remote
      remote.getCurrentWindow().close();
    },
    logout: function () {
      firebase.auth().signOut();
      try {
        setTimeout(function () {
          const remote = require('electron').remote
          remote.getCurrentWindow().close();
        }, 1000);

      } catch (e) {
        console.log(e.message);
      }
    },
    
    goHome: function () {
      var confirmation = true;
      confirmation = window.confirm("Would you like to publish your work?");
      if (confirmation) {
        this.$store.dispatch("publish");
      }

      confirmation = window.confirm("Are you sure you wish to go home?");
      if (confirmation) {
        this.$store.dispatch("updateAssetState", {
          assetId: this.$store.state.editor.assetInfo.assetId,
          teamId: this.$store.state.editor.assetInfo.teamId,
          state: this.$store.state.editor.currentState,
          trigger: "back"
        });
        this.$router.push({
          name: "Home"
        });
      } else {
        if (this.$store.state.editor.currentState === "CC Required") {
          this.$store.dispatch("updateAssetState", {
            assetId: this.$store.state.editor.assetInfo.assetId,
            teamId: this.$store.state.editor.assetInfo.teamId,
            state: this.$store.state.editor.currentState,
            trigger: "same"
          });
        }
      }
    },
    publish: function () {
      var confirmation = true;
      confirmation = window.confirm("Would you like to publish your work?");
      if (confirmation) {
        this.$store.dispatch("publish");
      }

      confirmation = window.confirm(
        "Would you like to mark this project as complete?"
      );
      if (
        confirmation &&
        this.$store.state.editor.currentState === "CC In Progress"
      ) {
        confirmation = window.confirm(
          "Would you like to have your project reviewed?"
        );
        if (confirmation) {
          this.$store.dispatch("updateAssetState", {
            assetId: this.$store.state.editor.assetInfo.assetId,
            teamId: this.$store.state.editor.assetInfo.teamId,
            state: this.$store.state.editor.currentState,
            trigger: "next"
          });
        } else {
          this.$store.dispatch("updateAssetState", {
            assetId: this.$store.state.editor.assetInfo.assetId,
            teamId: this.$store.state.editor.assetInfo.teamId,
            state: this.$store.state.editor.currentState,
            trigger: "skip"
          });
        }
        this.$router.push({
          name: "Home"
        });
      } else if (confirmation) {
        this.$store.dispatch("updateAssetState", {
          assetId: this.$store.state.editor.assetInfo.assetId,
          teamId: this.$store.state.editor.assetInfo.teamId,
          state: this.$store.state.editor.currentState,
          trigger: "next"
        });

        this.$router.push({
          name: "Home"
        });
      } else {
        this.$store.dispatch("updateAssetState", {
          assetId: this.$store.state.editor.assetInfo.assetId,
          teamId: this.$store.state.editor.assetInfo.teamId,
          state: this.$store.state.editor.currentState,
          trigger: "same"
        });
      }
    },
    complete: function () {
      var confirmation = true;
      confirmation = window.confirm("Would you like to publish any changes?");
      if (confirmation) {
        this.$store.dispatch("publish");
      }

      this.$store.dispatch("updateAssetState", {
        assetId: this.$store.state.editor.assetInfo.assetId,
        teamId: this.$store.state.editor.assetInfo.teamId,
        state: this.$store.state.editor.currentState,
        trigger: "next"
      });
      this.$router.push({
        name: "Home"
      });
    },

    insert: function (character) {
      if (this.multiSelectView) {
        var captionEvents = this.$store.state.editor.multiSelect.captionEvents;
      } else {
        var captionEvents = [this.$store.state.editor.selectedCaptionEvent]
      }

      if (this.activeView === 'subtitle') {
        captionEvents.forEach(captionEvent => {
          var subtitleText = this.$store.state.editor.captions[captionEvent].subtitleText;
          this.$store.commit("updateSubtitleText", {
            id: captionEvent,
            text: subtitleText + " " + character
          });
        });
      } else if (['default', 'classic'].indexOf(this.activeView) > -1) {
        captionEvents.forEach(captionEvent => {
          var emptyLine = globalFunc.getFirstEmptyLine(
            this.$store.state.editor.captions[captionEvent].primaryLang
          );

          if (emptyLine === 0) {
            emptyLine = 1;
          }
          var captionText = this.$store.state.editor.captions[captionEvent]
            .primaryLang[emptyLine - 1].captionText;
          this.$store.commit("updateCaptionText", {
            id: captionEvent,
            line: emptyLine - 1,
            text: captionText + " " + character
          });
        });
      }
    },

    insertSurround: function (character) {
      if (this.multiSelectView) {
        var captionEvents = this.$store.state.editor.multiSelect.captionEvents;
      } else {
        var captionEvents = [this.$store.state.editor.selectedCaptionEvent]
      }

      if (this.activeView === 'subtitle') {
        captionEvents.forEach(captionEvent => {
          var subtitleText = this.$store.state.editor.captions[captionEvent].subtitleText;
          this.$store.commit("updateSubtitleText", {
            id: captionEvent,
            text: character + " " + subtitleText.trim() + " " + character
          });
        });
      } else if (['default', 'classic'].indexOf(this.activeView) > -1) {
        captionEvents.forEach(captionEvent => {
          this.$store.commit("clearEmptyLines", captionEvent);

          var firstLineOfText = this.$store.state.editor.captions[captionEvent]
            .primaryLang[0].captionText.trim();

          this.$store.commit("updateCaptionText", {
            id: captionEvent,
            line: 0,
            text: character + " " + firstLineOfText
          });

          var emptyLine = globalFunc.getFirstEmptyLine(
            this.$store.state.editor.captions[captionEvent].primaryLang
          );

          if (emptyLine === 0) {
            emptyLine = 1;
          }

          var lastLineOfText = this.$store.state.editor.captions[captionEvent]
            .primaryLang[emptyLine - 1].captionText.trim();

          this.$store.commit("updateCaptionText", {
            id: captionEvent,
            line: emptyLine - 1,
            text: lastLineOfText + " " + character
          });
        });
      }
    },
    insertCaptionEvent: function () {
      var captionEvent = this.$store.state.editor.selectedCaptionEvent;
      this.$store.commit("insertCaptionEventInPlace", captionEvent + 1);
    },
    exportProject: function () {
      this.$store.commit("exportProject");
    },
    saveProject : function(){
      this.$store.commit("saveProject");
    },
    removeEvents: function () {
      if (!this.multiSelectView) {
        this.$store.commit("deleteCaptionEvent", this.$store.state.editor.selectedCaptionEvent);
      } else {
        var i = this.$store.state.editor.captions.length - 1;
        for (i; i > -1; i--) {
          if (this.$store.state.editor.multiSelect.captionEvents.indexOf(i) > -1) {
            this.$store.commit("deleteCaptionEvent", i);
          }
        }

        this.$store.commit("toggleMultiSelect");
      }

    },
    removeBlankLines: function () {
      var i = this.$store.state.editor.captions.length - 1;
      while (i != -1) {
        if (
          globalFunc.concatCaptionText(this.$store.state.editor.captions[i].primaryLang).trim().length == 0
        ) {
          this.$store.commit("deleteCaptionEvent", i);
        }
        --i;
      }
    },
    fixLineLength: function () {
      for (var i = 0; i < this.$store.state.editor.captions.length; i++) {
        this.$store.commit(
          "clearEmptyLines",
          this.$store.state.editor.selectedCaptionEvent
        );
        if (
          this.$store.state.editor.captions[i].primaryLang.filter(
            captionLine => {
              return captionLine.captionText.length > 32;
            }
          ).length > 0
        ) {
          this.$store.commit("alignCaptionEvent", {
            align: "even",
            id: i
          });
        }
      }
    },
    fixOrder: function () {
      this.$store.commit("fixReversal");
      this.$store.commit("fixOrder");
    },
    fixOverlap: function () {
      this.$store.commit("fixReversal");
      this.$store.commit("fixOrder");
      this.$store.commit("fixOverlap");
    },
    mergeEvents: function (mergeOption) {
      var i = this.$store.state.editor.captions.length
      for (i; i > 1; i--) {
        if ((mergeOption === "even" && i % 2 === 0) || (mergeOption === "odd" && i % 2 > 0)) {
          this.$store.commit("mergeEvent", i - 1);
          this.$store.commit("fixLineOverlap", i - 2);
        }
      }
    },
    autoFormat: function () {
      var i = this.$store.state.editor.captions.length - 1;
      for (i; i > -1; i--) {
        if ((this.multiSelectView && this.$store.state.editor.multiSelect.captionEvents.indexOf(i) > -1) || !this.multiSelectView) {
          this.$store.commit('autoFormat', i);
        }
      }
    },
    uppercaseAll: function () {
      this.$store.commit("uppercaseAll");
    },
    styleAll: function (style) {
      for (var i = 0; i < this.$store.state.editor.captions.length; i++) {
        if (
          (this.multiSelectView &&
            this.$store.state.editor.multiSelect.captionEvents.indexOf(i) >
            -1) ||
          !this.multiSelectView
        ) {
          this.$store.commit("updateCaptionStyle", {
            id: i,
            style: style
          });
        }
      }
    },
    /* Accepts object:  {y: "center", x: "right"}*/
    positionAll: function (position) {
      for (var i = 0; i < this.$store.state.editor.captions.length; i++) {
        if (
          (this.multiSelectView &&
            this.$store.state.editor.multiSelect.captionEvents.indexOf(i) >
            -1) ||
          !this.multiSelectView
        ) {
          this.$store.commit("alignCaptionEvent", {
            id: i,
            align: position.x
          });
          this.$store.commit("alignCaptionEvent", {
            id: i,
            align: position.y
          });
        }
      }
    },

    updateSubViewerOption: function (option) {
      this.$store.commit("updateSubViewerOption", option);
    },

    selectInvert: function () {
      for (var i = 0; i < this.$store.state.editor.captions.length; i++) {
        this.$store.commit("selectEvent", i);
      }
    },
    toggleMultiSelect: function () {
      this.$store.commit("toggleMultiSelect");
    },
    viewTimeline: function () {
      if (this.$store.state.editor.captions.length > 800) {
        this.$notify({
          title: "Timeline",
          type: "warning",
          text: "Timeline is disabled due to the number of caption lines (>800) or duration (>1h)"
        });

        if (this.$store.state.editor.viewTimeline) {
          this.$store.commit("viewTimeline");
        }
      } else {
        if (this.$store.state.editor.viewTimeline) {
          this.$store.commit("viewTimeline");
        } else {
          this.$store.commit("viewTimeline");
        }
      }
    },
    updateView: function (view) {
      this.$store.commit("updateView", view);
      if (typeof (Storage) !== "undefined") {
        localStorage.setItem("defaultView", view);
      }
    }
  },
  computed: {
    plugins: function () {
      return this.$store.state.editor.pluginList;
    },
    appVersion: function () {
      return this.$store.state.editor.applicationVersion;
    },
    appEnv: function () {
      return process.env.VUE_APP_ENV
    },
    appMode: function () {
      return process.env.VUE_APP_MODE
    },
    statusDisplay: function () {
      return this.$store.state.editor.status.display;
    },
    playerLoadStatus: function () {
      return this.$store.state.editor.player.loaded;
    },
    timelineOn: function () {
      return this.$store.state.editor.viewTimeline;
    },
    subViewerOption: function () {
      return this.$store.state.editor.subViewerOption;
    },
    multiSelectView: function () {
      return this.$store.state.editor.multiSelect.enabled;
    },
    activeView: function () {
      return this.$store.state.editor.view;
    }
  },
  components: {
    Transport,
    ProgressBar
  }
};
</script>

<style>
.navbar-brand {
  color: rgb(209, 209, 209) !important;
  font-size: 1.1em !important;
  font-weight: bold;
}

.custom-navbar button {
  color: rgb(192, 192, 192);
}

.submenu {
  list-style: none;
  padding: 0;
  background-color: rgb(63, 61, 61);
}

.submenu>li>a {
  color: #fff;
}

.submenu>li>button {
  color: #fff;
}
</style>
