<template>
	<div class="TimelineEvent" :style="{top: incode +'px', height: duration + 'px'}" :Id="'captionEvent'+Id" :class="{TimelineSeletedEvent: seletedEvent}" @click="selectEvent">
		<div class="topHandle" draggable="true" @drag="updateIncode"><i class="fas fa-chevron-up"></i></div>
		<button v-if="multiSelect" class="btn selectButton" type="button" @click="multiSelectEvent" v-bind:class="{'btn-success' : selected, 'btn-secondary': !selected}">
			<i v-if="selected" class="fas fa-check-circle"></i>
			<i v-else class="far fa-circle"></i>
		</button>
		<p class='noselect captionEventText' v-html="eventText" draggable="true" @drag="updateTimecode"></p>
		<div class="bottomHandle" draggable="true" @drag="updateOutcode"><i class="fas fa-chevron-down"></i></div>
	</div>
	
</template>

<script>
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name: 'TimelineEvent',
		props : ['Id'],
		computed : {
			multiSelect : function(){
				return this.$store.state.editor.multiSelect.enabled 
			},
			selected : function(){
				if (this.$store.state.editor.multiSelect.captionEvents.indexOf(this.Id) > -1){
					return true;
				} else {
					return false;
				}
			},
			incode : function(){
				return this.$store.state.editor.captions[this.Id].incodeSec * 50;
			},

			duration : function(){
				var outcodeSec = parseFloat(this.$store.state.editor.captions[this.Id].outcodeSec);
				var incodeSec = parseFloat(this.$store.state.editor.captions[this.Id].incodeSec);
				return (outcodeSec - incodeSec) * 50;
			},
			seletedEvent : function(){
				if (this.Id === this.$store.state.editor.selectedCaptionEvent){
					return  true;
				} else {
					return false;
				}
			},
			eventText : function(){
				return globalFunc.concatCaptionText(this.$store.state.editor.captions[this.Id].primaryLang).replace(/(?:\n)/g, '<br>');				
			}
		},
		methods : {
			multiSelectEvent : function(event){
				if (event.shiftKey){
					this.$store.commit('selectMultiEvent', this.Id);
				} else {
					this.$store.commit('selectEvent', this.Id);
				}				
			},
			selectEvent : function(){
				if (!this.seletedEvent){
					this.$store.commit('selectCaptionEvent', this.Id);
				}
				
				if (this.$store.state.editor.player.captionPreview.videoLock){
					var currentTime = this.$store.state.editor.captions[this.Id].incodeSec || 0;
					this.$store.commit('cueToTimeSec', currentTime);
				}
			},
			updateIncode : function(event){
				if (event.screenX != "0"){
					this.$store.commit('updateCaptionIncodeSec', {
						id : this.Id,
						offset : event.offsetY
					});
				}
			},
			updateOutcode: function(event){
				if (event.screenX != "0"){
					this.$store.commit('updateCaptionOutcodeSec', {
						id : this.Id,
						offset : event.offsetY
					});
				}
				
			},
			updateTimecode: function(event){
				if (event.screenX != "0"){
					this.$store.commit('updateCaptionTimecodeSec', {
						id : this.Id,
						offset : event.offsetY
					});
				}
			}
		}
	}
</script>
<style scoped>
	.TimelineEvent {
		position: absolute;
		width: 85%;		
		background-color: rgba(17, 94, 145, 0.6);
		border-radius: 25px;
		margin-left: 10%;
		text-align: center;
		overflow:hidden;
		border: solid rgb(207, 207, 207) 1px;
	}

	.TimelineSeletedEvent {
		border: solid rgb(212, 183, 14) 1px;
		background-color: #007ACC;
		color: #fff;
		z-index: 500;
	}

	.captionEventText { 
		margin: 15px 0;
		padding: 1px 2px;
		background-color: #000;
		display: inline-block;
		white-space: pre-line;
		font-family: monospace;
		font-size: 16px;

	}

	.dragHandle {
		position:absolute;
		left:0;
		padding: 15px;
	} 
	.topHandle {
		width: 100%;
		background-color: rgba(255,255,255,0.1);
		position: absolute;
		top: 0;
		font-size: 8px;
	}
	.bottomHandle {
		width: 100%;
		background-color: rgba(255,255,255,0.1);
		position: absolute;
		bottom: 0;
		font-size: 8px;
	}

	.selectButton {
		border: none;
		position:absolute;
		left: 5px;
		top: 17px;
	}
</style>