const parser = require("./smpteTtml.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");
const format = require('xml-formatter');

module.exports = {
    decode : function(fileContents, options){
        return parser.decode(fileContents, options);
    },

    encode : function(events, options){
        let fileContents = eol.after("<?xml version=\"1.0\" encoding=\"UTF-8\"?>");
        fileContents += eol.after("<tt xml:lang=\"en\"");

        fileContents += eol.after("xmlns=\"http://www.w3.org/ns/ttml\"");
        fileContents += eol.after("xmlns:tts=\"http://www.w3.org/ns/ttml#styling\"");
        fileContents += eol.after("xmlns:ttm=\"http://www.w3.org/ns/ttml#metadata\"");
        fileContents += eol.after("xmlns:smpte=\"http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt\"");
        fileContents += eol.after("xmlns:m608=\"http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608\"");
        fileContents += eol.after("xmlns:ttp=\"http://www.w3.org/ns/ttml#parameter\"");
        fileContents += eol.after("ttp:profile=\"http://www.netflix.com/ns/ttml/profile/NFTT-SDH\"");
        fileContents += eol.after(`ttp:timeBase=\"media\" ttp:frameRate=\"${options.frameRate}\">`);

        fileContents += eol.after("<head>");
        fileContents += eol.after("<metadata>");
        fileContents += eol.after("<ttm:desc>Closed Caption Creator</ttm:desc>");
        fileContents += eol.after("<smpte:information");
        fileContents += eol.after("xmlns:m608=\"http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608\"");
        fileContents += eol.after("origin=\"http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608\"");
        fileContents += eol.after("mode=\"Preserved\" m608:channel=\"CC1\" m608:captionService=\"F1C1CC\"");
        fileContents += eol.after("/>");
        fileContents += eol.after("</metadata>");
        fileContents += eol.after("<styling>");
        fileContents += eol.after("<style xml:id='basic' tts:color='white' tts:backgroundColor='transparent' tts:fontSize='100%' tts:fontWeight='normal' tts:fontStyle='normal'/>");
        fileContents += eol.after("</styling>");
        fileContents += eol.after("<layout>");
        fileContents += eol.after("<region xml:id='top' tts:backgroundColor='transparent' tts:showBackground='whenActive' tts:origin='10% 10%' tts:extent='80% 80%' tts:displayAlign='before'></region>");
        fileContents += eol.after("<region xml:id='bottom' tts:backgroundColor='transparent' tts:showBackground='whenActive' tts:origin='10% 10%' tts:extent='80% 80%' tts:displayAlign='after'></region>");
        fileContents += eol.after("</layout>");
        fileContents += eol.after("</head>");
        fileContents += eol.after("<body>");
        fileContents += eol.after("<div>");


 
        for (const event_ of events) {

            let eventLine = "";
            for (let i = 0; i < event_.lines.length; i++) {

                eventLine += (`${event_.lines[i].text}<br/>`).trim();
            }
            eventLine = eventLine.substring(0, eventLine.length - 5).trim();
            fileContents += eol.after(`<p begin='${timecodeLib.secToTc(event_.start, options.frameRate)}' end='${timecodeLib.secToTc(event_.end, options.frameRate)}' region='bottom' style='basic' tts:textAlign='center'>${eventLine}</p>`);

        }

        fileContents += eol.after("</div>");
        fileContents += eol.after("</body>");
        fileContents += eol.after("</tt>");


        var oParser = new DOMParser();
        fileContents = oParser.parseFromString(fileContents, "application/xml");
        // print the name of the root element or error message

        var oSerializer = new XMLSerializer();
        fileContents = oSerializer.serializeToString(fileContents);


        return format(fileContents, { collapseContent: true });

    },

    preProcess : {
        encode : function(events){
            return events;
        },

        decode : function(fileContents){
            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}