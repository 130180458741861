<template>
    <video id="player" poster="/assets/img/videoPoster.jpg" crossorigin></video>
</template>
<script>
    import Plyr from 'plyr';
    import throttle from 'lodash/throttle'
    export default {
        name: 'VideoPlayer',
        mounted: function () {
            window.mediaPlayer = new Plyr('#player', {
                "keyboard": {
                    focused: false,
                    global: false
                },
                controls : [],
                

            });
            
            mediaPlayer.on('playing', event => {
                this.updatePlayerStatus('playing');
            });

            mediaPlayer.on('pause', event => {
                this.updatePlayerStatus('pause');
            });

            var self = this;
            var updateTime = function(){
                self.updateCurrentTime(mediaPlayer.currentTime);
            }

            mediaPlayer.on('timeupdate',  event => { this.updateCurrentTime(mediaPlayer.currentTime);
            });

            

            mediaPlayer.on('seeked', event => {
                throttle(updateTime, 500)
            });

            mediaPlayer.on('seeking', event => {
                throttle(updateTime, 500)
            });

            mediaPlayer.on('ratechange', event => {
                this.rateChange();                
            });

            mediaPlayer.on('progress', event => {
                var videoTag = document.getElementsByTagName("video")[0]; 
                if (videoTag && videoTag.readyState === 4) {
                    this.updatePlayerProgress(true);

                } else if(videoTag){
                    this.updatePlayerProgress(false);
                }
            });

        },
        methods: {
            updatePlayerStatus: function (status) {
                this.$store.commit('updatePlayerStatus', status);
                if (this.$store.state.editor.duration != mediaPlayer.duration){
                    this.$store.commit('updateDuration', mediaPlayer.duration);
                }                
            },
            updatePlayerProgress : function(status){                
                this.$store.commit('updatePlayerLoadState', status);
                if (status){
                    this.$store.commit("generateWaveform");
                }
            },
            updateCurrentTime: function (time) {
                this.$store.commit('playerUpdateCurrentTime', time);
            },
            playerError : function(event){
                try {
                    var error = document.getElementsByTagName('video')[0].error.message;
                    this.$notify({
                        title: 'Player Error',
                        type: 'error',
                        text: 'The player has experienced an error: '+error
                    });
                } catch(e){
                    console.log(e.message);
                }
                
            },
            rateChange : function(){
                this.$store.commit('updatePlaybackRate', mediaPlayer.speed);
            }
        }
    }
</script>