// Initialize Firebase
import firebase from 'firebase/app';  
import 'firebase/functions';
const config = {
    apiKey: "AIzaSyBwFMusZjo7iqYk63nCFYM-64negqrWrxE",
    authDomain: "closed-caption-creator-app.firebaseapp.com",
    databaseURL: "https://closed-caption-creator-app.firebaseio.com",
    projectId: "closed-caption-creator-app",
    storageBucket: "closed-caption-creator-app.appspot.com",
    messagingSenderId: "472541817513",
    appId: "1:472541817513:web:60ce40822728e2a78157b8"
  };

firebase.initializeApp(config);
export default firebase