<template>
<XLrgModalTemplate Title="Load Project" ModalId="LoadProjectModal">
    <div>
        <button type="button" class="float-right btn btn-secondary" @click="refresh"><i class="fas fa-sync-alt"></i></button>
        <form v-on:submit.prevent>
            <div class="input-group mb-3 w-25">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                </div>
                <input v-model="filter" type="text" class="form-control" placeholder="Filter Projects By Title">
            </div>
        </form>
        <div class="table-responsive">
            <table class="table table-striped table-sm table-hover">
                <thead>
                    <tr>

                        <th scope="col">Title</th>
                        <th scope="col">UID</th>
                        <th scope="col">Frame Rate</th>
                        <th scope="col">Last Updated</th>
                        <th scope="col">App Version</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="project in filteredProjects" :key="project.uid" @mouseover="showByIndex = project.uid">

                        <th scope="row">{{project.title}}</th>
                        <td>{{project.uid}}</td>
                        <td>{{project.frameRate}}</td>
                        <td>{{new Date(project.updated).toLocaleDateString()}}</td>
                        <td>{{project.appVersion}}</td>
                        <td><button @click="openProject(project.uid)" class="btn-sm btn btn-success" v-show="showByIndex === project.uid"><i class="far fa-folder-open"></i> Open</button> <button v-show="showByIndex === project.uid" class="btn-sm btn btn-outline-light" @click="archiveProject(project.uid)"><i class="fas fa-archive"></i> Archive</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</XLrgModalTemplate>
</template>

<script>
import XLrgModalTemplate from './XLrgModalTemplate'
import firebase from '@/firebase/config'
import db from '@/firebase/firestore'
import storage from '@/firebase/storage'
export default {
    name: 'LoadProjectModal',
    data() {
        return {
            projects: [],
            showByIndex: null,
            filter: null,
        }
    },
    computed: {
        filteredProjects: function () {
            if (this.filter) {
                let regexFilter = new RegExp(this.filter, "gmi");
                return this.projects.filter(project => {
                    return project.title.match(regexFilter);
                });
            } else {
				return this.projects;
			}

        }
    },
    mounted: function () {
        this.refresh();
    },
    components: {
        XLrgModalTemplate
    },
    methods: {
        refresh: function () {
            var self = this;
            let userInfo = firebase.auth().currentUser;
            self.projects = [];
            db.collection("users").doc(userInfo.uid).collection("projects").where("archived", "==", false).orderBy("updated", "desc").get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    //console.log(doc.id, doc.data());
                    self.projects.push(doc.data());
                });
            }).catch(err => {
                console.log(err.message);
                self.$notify({
                    title: 'Load Project List',
                    type: 'error',
                    text: 'Failed to query for saved projects. ' + err.message
                });
            });
        },
        openProject: function (projectId) {
			//console.log("OPENING", projectId);
			let self = this;
            let userInfo = firebase.auth().currentUser;
			let loader = this.$loading.show();
			let projectFileRef = storage.ref().child('users/'+userInfo.uid+'/projects/'+projectId+'.ccprj');
			projectFileRef.getDownloadURL().then(url => {
				console.log(url);
				return fetch(url);
				
			}).then(res =>{
				return res.json();
				
			}).then(resJson =>{
                self.$store.dispatch('loadProjectFile', resJson);
				//self.$store.commit('loadProject', resJson);
				self.$notify({
                    title: 'Load Project',
                    type: 'success',
                    text: 'Project loaded successfully.'
                });
				loader.hide();
				$('#LoadProjectModal').modal('toggle');
			}).catch(err =>{
				console.log(err.message);
				self.$notify({
                    title: 'Load Project',
                    type: 'error',
                    text: 'Failed to load project. ' + err.message
                });
				loader.hide();
			});
        },
        archiveProject: function (projectId) {
            let self = this;
            let userInfo = firebase.auth().currentUser;
            let loader = this.$loading.show();
            let projectIndex = this.projects.findIndex(project => {
                return project.uid === projectId
			});
			
            db.collection("users").doc(userInfo.uid).collection('projects').doc(projectId).update({
                updated: Date.now(),
                archived: true
            }).then(res => {
                self.projects.splice(projectIndex, 1);
                self.$notify({
                    title: 'Manage Project',
                    type: 'success',
                    text: 'Project archived successfully.'
                });
                loader.hide();
            }).catch(error => {
                console.log(error.message);
                self.$notify({
                    title: 'Manage Project',
                    type: 'error',
                    text: 'Failed to archive project. ' + error.message
                });
                loader.hide();
            });
        }
    }
}
</script>
