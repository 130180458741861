const ccFunc = require('./ccFunc');

/* Post Process */
module.exports = {
    import: {
        all: function (captions, frameRate, dropFrame) {
            captions = ccFunc.correctForMissingTimecodes(captions, frameRate, dropFrame);
            captions = ccFunc.calculateTimecodes(captions, frameRate, dropFrame);
            for (var i = 0; i < captions.length; i++) {
                for(var j=0; j<captions[i].primaryLang.length; j++){
                    if(captions[i].primaryLang[j].captionText.length + parseInt(captions[i].primaryLang[j].positionX) > 32){
                        captions[i].primaryLang[j].positionX = parseInt(32-captions[i].primaryLang[j].captionText.length);
                    };
                }
            }
            return captions;
        },
        scc: function (captions, frameRate, dropFrame) {
            for (var i = 0; i < captions.length; i++) {
                for (var j = 0; j<4; j++){
                    captions[i].primaryLang[j].captionText = captions[i].primaryLang[j].captionText.replace(/AÀ/g, "À").replace(/AÂ/g, "Â").replace(/CÇ/g, "Ç").replace(/EÉ/g, "É").replace(/EÈ/g, "È").replace(/EÊ/g, "Ê").replace(/EË/g, "Ë").replace(/eë/g, "ë").replace(/IÎ/g, "Î").replace(/IÏ/g, "Ï").replace(/lï/g, "ï").replace(/OÔ/g, "Ô").replace(/UÙ/g, "Ù").replace(/uù/g, "ù").replace(/UÛ/g, "Û").replace(/AÃ/g, "Ã").replace(/aã/g, "ã").replace(/IÍ/g, "Í").replace(/IÌ/g, "Ì").replace(/lì/g, "ì").replace(/OÒ/g, "Ò").replace(/oò/g, "ò").replace(/OÕ/g, "Õ").replace(/oõ/g, "õ").replace(/AÄ/g, "Ä").replace(/aä/g, "ä").replace(/OÖ/g, "Ö").replace(/oö/g, "ö").replace(/AÅ/g, "Å").replace(/aå/g, "å").replace(/'’/g, "'").replace(/íI/g, "I");
                }
            }
            return captions;
        },
        vtt: function (captions) {
            
            return captions;
        },
        srt: function (captions) {
            return captions;
        },
        mcc: function (captions) {
            for (var i = 0; i < captions.length; i++) {
                for (var j = 0; j<4; j++){
                    captions[i].primaryLang[j].captionText = captions[i].primaryLang[j].captionText.replace(/AÀ/g, "À").replace(/AÂ/g, "Â").replace(/CÇ/g, "Ç").replace(/EÈ/g, "È").replace(/EÊ/g, "Ê").replace(/EË/g, "Ë").replace(/eë/g, "ë").replace(/IÎ/g, "Î").replace(/IÏ/g, "Ï").replace(/lï/g, "ï").replace(/OÔ/g, "Ô").replace(/UÙ/g, "Ù").replace(/uù/g, "ù").replace(/UÛ/g, "Û").replace(/AÃ/g, "Ã").replace(/aã/g, "ã").replace(/IÍ/g, "Í").replace(/IÌ/g, "Ì").replace(/lì/g, "ì").replace(/OÒ/g, "Ò").replace(/oò/g, "ò").replace(/OÕ/g, "Õ").replace(/oõ/g, "õ").replace(/AÄ/g, "Ä").replace(/aä/g, "ä").replace(/OÖ/g, "Ö").replace(/oö/g, "ö").replace(/AÅ/g, "Å").replace(/aå/g, "å").replace(/'’/g, "'").replace(/íI/g, "I");
                }
            }
            return captions;
        },
        sbv: function (captions) {
            return captions;
        },
        txt: function (captions) {
            return captions
        },
        stl: function (captions) {
            return captions
        },
        pac: function (captions) {
            return captions
        },
        xml: function (captions) {

            for (var i = 0; i<captions.length; i++){
                captions[i].primaryLang = ccFunc.verifyPositionOverlap(captions[i].primaryLang);
            }

            return captions;
        },
        ttml: function (captions) {

            for (var i = 0; i < captions.length; i++) {
                captions[i].primaryLang = ccFunc.verifyPositionOverlap(captions[i].primaryLang);
            }

            return captions;
        },
        cap: function (captions) {
            
            return captions;
        }
    },
    export: {

    }
}