<template>
	<ModalTemplate Title="Starting Line Number" ModalId="StartingLineNumberModal">
		<p>Update the starting position (y-coordinate) you wish to have your closed caption events start from.</p>
		<form>
			<div class="form-group col-md-12">
				<label for="starting-line-selection">Line Number</label>
				<input type="number" min="1" max="15" class="form-control" v-model="startingLine">
			</div>
			<div class="col-md-12">	
				<button @click="updateStartingLine" type="button" class="btn btn-primary btn-block" data-dismiss="modal"><i class="far fa-check-circle"></i> Update</button>
			</div>
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name : 'StartingLineNumber',
		data () {
			return {
				startingLine : 11
			}
		},
		components: {
			ModalTemplate
		},
		
		methods : {
			updateStartingLine : function(){
				this.$store.commit('updateStartingLine', parseInt(--this.startingLine));	
			}
		}
	}
</script>