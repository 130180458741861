const parser = require("./smpteTtml.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");
const format = require('xml-formatter');
module.exports = {
    decode : function(fileContents, options){
        return parser.decode(fileContents, options);
    },

    encode : function(events, options){
        let fileContents = eol.after("<?xml version=\"1.0\" encoding=\"UTF-8\"?>");

        fileContents += eol.after("<tt ttp:timeBase=\"media\" xml:lang=\"en\" xmlns:tt=\"http://www.w3.org/ns/ttml\" xmlns:ttp=\"http://www.w3.org/ns/ttml#parameter\" xmlns:tts=\"http://www.w3.org/ns/ttml#styling\" xmlns:ebuttm=\"urn:ebu:tt:metadata\" tts:extent=\"1080 720px\" ttp:dropMode=\"nonDrop\" ttp:frameRate=\"25\" ttp:frameRateMultiplier=\"1 1\">");


        fileContents += eol.after("<head>");
        fileContents += eol.after("<metadata>");
        fileContents += eol.after("<ebuttm:desc>Closed Caption Creator</ebuttm:desc>");
        fileContents += eol.after("</metadata>");
        fileContents += eol.after("<styling>");
        fileContents += eol.after("<style xml:id='basic' tts:color='white' tts:backgroundColor='transparent' tts:fontFamily='monospace' tts:fontSize='100%' tts:fontWeight='normal' tts:fontStyle='normal'/>");
        fileContents += eol.after("</styling>");
        fileContents += eol.after("<layout>");

        //These are hardcoded (for now). Sample file 'WebCast EBU-TT.xml' provides a region for each subtitle line there are (a lot of regions). EBU-TT format should
        //only provide unique region elements for every subtitle (so subtitles may share the same region). Probably want to change in future
        fileContents += eol.after("<region xml:id=\"bottom\" tts:origin=\"10% 10%\" tts:extent=\"80% 80%\" tts:padding=\"0c\" tts:displayAlign=\"after\" tts:writingMode=\"lrtb\"/>");
        fileContents += eol.after("</layout>");
        fileContents += eol.after("</head>");
        fileContents += eol.after("<body>");
        fileContents += eol.after("<div>");

        for (const event_ of events) {

            let eventLine = "";
            for (let i = 0; i < event_.lines.length; i++) {

                eventLine += (`${event_.lines[i].text}<br/>`).trim();
            }
            eventLine = eventLine.substring(0, eventLine.length - 5).trim();
            fileContents += eol.after(`<p begin="${timecodeLib.secToTc(event_.start)}" end="${timecodeLib.secToTc(event_.end)}" style="basic" region="bottom">${eventLine}</p>`);

        }


        fileContents += eol.after("</div>");
        fileContents += eol.after("</body>");
        fileContents += eol.after("</tt>");

        var oParser = new DOMParser();
        fileContents = oParser.parseFromString(fileContents, "application/xml");
        // print the name of the root element or error message

        var oSerializer = new XMLSerializer();
        fileContents = oSerializer.serializeToString(fileContents);


        return format(fileContents, { collapseContent: true });
    },

    preProcess : {
        encode : function(events){
            return events;
        },

        decode : function(fileContents){
            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}