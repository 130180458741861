<template>
	<div id="TimelineRuler" @click="updateCurrentTime" :style="{height: (this.numberOfMarkers*125 +125)+'px'}">
		<p class="small text-muted" style="pointer-events: none;">00:00:00</p>
		<p v-for="marker in numberOfMarkers" :key="marker" @click="updateCurrentTimeByMarker(marker*2.5)" class="small text-muted TimelineMarker" :style="{top: marker*125 +'px'}">{{markerValue(marker)}}</p>
		
	</div>
</template>

<script>
	import moment from 'moment'
	export default {
		name: 'Ruler',
		data : function () {
			return {
			}
		},
		computed : {
			durationSec : function(){
				return this.$store.state.editor.duration;
			},
			numberOfMarkers : function() {
				return Math.ceil(this.durationSec/2.5);
			},
		},
		methods : {
			markerValue : function(marker){
				return moment.utc(marker * 2500).format('HH:mm:ss');
			},

			updateCurrentTime : function(event){				
				var currentTime = event.offsetY/50;
				this.$store.commit('cueToTimeSec', currentTime);
								
			},
		}
	}
</script>
<style scoped>

	#TimelineRuler{
		float:left;
		padding: 0 15px;
		margin: 0;
		width: 75px;		
		background: rgb(14, 14, 14);
	}
	.TimelineMarker {
		position: absolute;
		user-select: none;
		pointer-events: none;
	}
</style>