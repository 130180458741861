<template>
<transition name="custom-classes-transition" enter-active-class="animated fadeIn">
	<ul class="navbar-nav centre-nav mx-auto d-none d-md-flex">
		<div class="progress" style="margin:0.7em; width: 50em">
			<div class="progress-bar progress-bar-striped progress-bar-animated color-white" role="progressbar" v-bind:class="displayStatus" v-bind:style="{ width: progress + '%' }">{{progress}}% - {{message}}</div>
		</div>
	</ul>
</transition>
</template>

<script>
export default {
	name : 'ProgressBar',
	computed : {
		progress : function() {
			return this.$store.state.editor.status.progress;
		},
		message : function() {
			return this.$store.state.editor.status.msg;
		},
		displayStatus : function() {
			if (this.$store.state.editor.status.status === 'complete'){
				return 'bg-success';
			} else if (this.$store.state.editor.status.status === 'error'){
				return 'bg-danger'
			} else {
				return '';
			}			
		}
	}
}
</script>