const striptags = require('striptags')

module.exports = {
    jsonOption : {
        xmlHeader: true,
        prettyPrint: true
    },
    dropModeMapping : {
        "23.976" : "nonDrop", 
        "24": "nonDrop",
        "25": "nonDrop",
        "29.97": "dropNTSC",
        "30": "nonDrop",
        "50": "nonDrop",
        "59.94": "dropNTSC",
        "60": "nonDrop"
    },
    frameRateMapping : {
        "23.976": "24",
        "24": "24",
        "25": "25",
        "29.97": "30",
        "30": "30",
        "50": "50",
        "59.94": "60",
        "60": "60"
    },
    frameRateMultiplierMap : {
        "23.976": "1000 1001",
        "24": "1 1",
        "25": "1 1",
        "29.97": "1000 1001",
        "30": "1 1",
        "50": "1 1",
        "59.94": "1000 1001",
        "60": "1 1"
    },
    newCaptionLine: function (begin, end, origin, extent, region, captionText) {
        return {
            name: "p",
            attrs: {
                "begin": begin,
                "end": end,
                "region": region,
                "style": "basic",
                "tts:origin": origin,
                "tts:extent": extent
            },
            text: captionText,
        }
    },
    newCaptionLineItalic: function (begin, end, origin, extent, region, captionText) {
        return {
            name: "p",
            attrs: {
                "begin": begin,
                "end": end,
                "region": region,
                "style": "basic",
                "tts:origin": origin,
                "tts:extent" : extent
            },
            children: [
                {
                    name: "span",
                    text: captionText,
                    attrs: {
                        "tts:fontStyle": "italic"
                    }
                }
            ]
        }
    },
    captionData: function (dropMode, frameRate, frameRateMultiplier, captionLines) {
        return [
            {
                name:'tt',
                attrs : {
                    "xml:lang" : "en",
                    "xmlns": "http://www.w3.org/ns/ttml",
                    "xmlns:m608": "http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608",
                    "xmlns:smpte": "http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt",
                    "xmlns:ttm": "http://www.w3.org/ns/ttml#metadata",
                    "xmlns:ttp": "http://www.w3.org/ns/ttml#parameter",
                    "xmlns:tts": "http://www.w3.org/ns/ttml#styling",
                    "ttp:dropMode": dropMode,
                    "ttp:frameRate": frameRate,
                    "ttp:frameRateMultiplier": frameRateMultiplier,
                    "ttp:timeBase": "media"
                },
                children : [
                    {
                        name : "head",
                        children : [
                            {
                                name: "metadata",
                                children : [
                                    {
                                        name: "ttm:desc",
                                        text : "Closed Caption Converter V2"
                                    },
                                    {
                                        name: "smpte:information",
                                        attrs: {
                                            "xmlns:m608": "http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608",
                                            "origin": "http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608",
                                            "mode": "Preserved",
                                            "m608:channel" : "CC1",
                                            "m608:programName" : "NONE",
                                            "m608:captionService":"F1C1CC"
                                        }
                                    }
                                    
                                ]
                            },
                            {
                                name : "styling",
                                children : [
                                    {
                                        name : "style",
                                        attrs: {
                                            "xml:id": "basic",
                                            "tts:color": "white",
                                            "tts:backgroundColor":"black",
                                            "tts:fontFamily": "monospace",
                                            "tts:fontSize": "80%"
                                        }
                                    }
                                ]
                            },
                            {
                                name : "layout",
                                children: [
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "pop1",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "pop2",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "pop3",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "pop4",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "paint1",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "paint2",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "paint3",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "paint4",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "rollup2",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "rollup3",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                    {
                                        name: "region",
                                        attrs: {
                                            "tts:backgroundColor": "transparent",
                                            "xml:id": "rollup4",
                                            "tts:showBackground": "whenActive"
                                        }
                                    },
                                ]
                            },
                            
                        ]
                    },
                    {
                        name : "body",
                        children : [
                            {
                                name : "div",
                                children : captionLines
                                
                            }
                        ]
                    }
                ]
            }
        ]
    }
}