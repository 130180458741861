<template>
	<div id="ccVideoContainer">
		<CaptionPreviewSubtitle v-if="activeView==='subtitle'"></CaptionPreviewSubtitle>
		<CaptionPreviewMobile v-else-if="aspectRatio === '9:16' && activeView != 'transcript' && activeView != 'audio description'"></CaptionPreviewMobile>
		<CaptionPreview v-else-if="activeView != 'transcript' && activeView != 'audio description'"></CaptionPreview>
		<Player/>
	</div>
</template>
<script>
	import CaptionPreview from './CaptionPreview'
	import CaptionPreviewMobile from './CaptionPreviewMobile'
	import CaptionPreviewSubtitle from './CaptionPreviewSubtitle'
	import Player from './Player'
	export default {
		name: 'Viewer',
		computed : {
			aspectRatio : function(){
				return this.$store.state.editor.player.aspectRatio;
			},
			activeView: function () {
            	return this.$store.state.editor.view;
        	}
		},
		components: {
			CaptionPreview,
			CaptionPreviewMobile,
			CaptionPreviewSubtitle,
			Player
		},
	}
</script>