const dfxp = require("./smpteTtml.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");
const format = require('xml-formatter');

module.exports = {
    decode : function(fileContents, options){
        return dfxp.decode(fileContents, options);
    },

    encode : function(events, options){
        let fileContents = eol.after("<?xml version=\"1.0\" encoding=\"UTF-8\"?>");

        fileContents += eol.after("<tt xml:lang=\"en\" xmlns=\"http://www.w3.org/ns/ttml\"");
        fileContents += eol.after("xmlns:tts=\"http://www.w3.org/ns/ttml#styling\"");
        fileContents += eol.after("xmlns:ttm=\"http://www.w3.org/ns/ttml#metadata\">");


        fileContents += eol.after("<head>");

        fileContents += eol.after("<styling>");
        fileContents += eol.after("<style xml:id=\"defaultCaption\" tts:fontSize=\"10\" tts:fontFamily=\"SansSerif\""); 
        fileContents += eol.after("tts:fontWeight=\"normal\" tts:fontStyle=\"normal\"");
        fileContents += eol.after("tts:textDecoration=\"none\" tts:color=\"white\"");
        fileContents += eol.after("tts:backgroundColor=\"black\" />");
        fileContents += eol.after("</styling>");

        fileContents += eol.after("</head>");
        fileContents += eol.after("<body>");
        fileContents += eol.after("<div style=\"defaultCaption\" xml:lang=\"en\">");

        for (const event_ of events) {

            let eventLine = "";
            for (let i = 0; i < event_.lines.length; i++) {

                eventLine += (`${event_.lines[i].text}<br />`).trim();
            }
            eventLine = eventLine.substring(0, eventLine.length - 6).trim();
            fileContents += eol.after(`<p begin="${timecodeLib.secToTcMs(event_.start).replace(',', '.')}" end="${timecodeLib.secToTcMs(event_.end).replace(',', '.')}">${eventLine}</p>`);

        }

        fileContents += eol.after("</div>");
        fileContents += eol.after("</body>");
        fileContents += eol.after("</tt>");
        


        var oParser = new DOMParser();
        fileContents = oParser.parseFromString(fileContents, "application/xml");


        var oSerializer = new XMLSerializer();
        fileContents = oSerializer.serializeToString(fileContents);
        return format(fileContents, { collapseContent: true });
    },

    preProcess : {
        encode : function(events){
            return events;
        },

        decode : function(fileContents){
            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}