const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const capFunc = require('../functions/profiles/cheetahBin.js');
module.exports = {
    decode: function (fileContents, options) {
        let events = [], event, char, hexBuffer, header, tcFlag = false, textFlag = false, formatting, incodeHex, outcodeHex;

        hexBuffer = fileContents.match(/(..?)/g);
        header = hexBuffer.splice(0, 128);
        /* debug logger */
        //console.log(header.join(" ") + "\n");
        //console.log(hexBuffer.join(" "));
        while (hexBuffer.length > 0) {
            char = hexBuffer.shift();
            if (!tcFlag) {
                if (char === "61" || char === "62" || char === "63") {
                    tcFlag = true;
                    event = new ccEvent();;
                    event.insertLine();

                    incodeHex = hexBuffer.splice(0, 4);
                    outcodeHex = hexBuffer.splice(0, 4);

                    /* Timecode Debug */
                    //console.log(incodeHex + "-->" + capFunc.decodeTc(incodeHex));
                    //console.log(outcodeHex + "-->" + capFunc.decodeTc(outcodeHex));

                    event.start = timecodeLib.tcToSec(capFunc.decodeTc(incodeHex), options.frameRate);
                    event.end = timecodeLib.tcToSec(capFunc.decodeTc(outcodeHex), options.frameRate);

                    hexBuffer.splice(0, 9);
                    textFlag = true;
                } else if (char === "40" || char === "41" || char === "42") {
                    tcFlag = true;
                    event = new ccEvent();
                    event.insertLine();
                    incodeHex = hexBuffer.splice(0, 4);

                    /* Timecode Debug */
                    //console.log(incodeHex + "-->" + capFunc.decodeTc(incodeHex));

                    event.start = timecodeLib.tcToSec(capFunc.decodeTc(incodeHex), options.frameRate);
                    if (char !== "40"){
                        hexBuffer.splice(0, 9);
                        textFlag = true;
                    }
                }
            } else if (!textFlag && tcFlag) {
                if (char === "22") {
                    textFlag = true;
                    formatting = hexBuffer.splice(0, 8);
                } else if (char + hexBuffer[0] + hexBuffer[1] + hexBuffer[2] === "00000000") {
                    event.insertLine();
                    tcFlag = false;
                }
            } else if (textFlag) {
                if (char + hexBuffer[0] + hexBuffer[1] + hexBuffer[2] === "00000000") {
                    event.insertLine();
                    /* Remove the rest of the null codes */
                    hexBuffer.splice(0, 3);
                } else if (char === "00" && hexBuffer[0] != "00") {
                    tcFlag = false;
                    textFlag = false;

                    event.alignTextCenter();
                    event.syncSubtitleText();
                    events.push(event);
                    hexBuffer.splice(0, 1);
                } else if (parseInt(char, 16) >= 129 && parseInt(char, 16) <= 149) {
                    /* Extended Characters */
                    event.lines[event.lines.length - 1].text += capFunc.charLookup[char];
                } else if (char === "d0" || char === "c0") {
                    event.lines[event.lines.length - 1].italics = true;
                    event.lines[event.lines.length - 1].text += " ";
                } else {
                    event.lines[event.lines.length - 1].text += capFunc.decodeChar(char, 1252);
                }
            }
        }

        return events;
    },

    encode: function (events, options) {
        throw new Error("Cheetah Cap binary files are not supported for encoding by CC Convert.");
    },

    preProcess: {
        encode: function (events) {
            return events;
        },

        decode: function (fileContents) {
            return fileContents.toLowerCase();
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            events.forEach(function (event, index, events) {
                if (event.end === undefined && events[index + 1]) {
                    events[index].end = events[index + 1].start;
                } else {
                    events[index].end = events[index].start + 5;
                }               
            });
            return events;
        }
    },

}