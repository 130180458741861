const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");

/*
//This format is similar to the parser I wrote for SMPTE-TT format. However, it has enough differences to put in its own file.
*/


const timeColonFormatTicks_ = /^(?:(\d{2,}):)?(\d{2}):(\d{2}\:\d{0,3})$/; //HH:MM:SS:TTT A “tick” is defined as 4 msec and has a range of 0 to 249. 
const timeColonFormatFrames_ = /^(\d{2,}):(\d{2}):(\d{2})\.?(\d+)?$/; //HH = hours, MM = minutes, SS = seconds, and sss = decimal fractions of a second. 
module.exports = {
    decode: function (fileContents, options) {
        let events = [];
        const parser = new DOMParser();


        const xml = parser.parseFromString(fileContents, 'text/xml');

        const tts = xml.getElementsByTagName('DCSubtitle');
        const tt = tts[0];

        let subtitles = tt.getElementsByTagName("Font")[0].getElementsByTagName("Subtitle");

        for (const subtitle of subtitles) {
            const event = parseEvent_(subtitle);
            if (event) {
                events.push(event);
            }
        }

        events.forEach(function (event) {
            event.syncSubtitleText();
            event.alignTextCenter();
        });
        return events;
    },

    encode: function (events, options) {
        let fileContents = eol.after("<?xml version=\"1.0\" encoding=\"UTF-8\"?>");
        fileContents += eol.after("<!-- *** Created by Closed Caption Creator *** -->");
        fileContents += eol.after("<DCSubtitle Version=\"1.1\">");
        fileContents += eol.after(`<SubtitleID>${generateUUID()}</SubtitleID>`);
        fileContents += eol.after("<MovieTitle></MovieTitle>");
        fileContents += eol.after("<ReelNumber></ReelNumber>");
        fileContents += eol.after("<Language>en</Language>");
        fileContents += eol.after("<LoadFont Id=\"Font1\" URI=\"Arial.ttf\"/>");
        fileContents += eol.after("<Font Id=\"Font1\" Color=\"FFFFFFFF\" Effect=\"border\" EffectColor=\"FF000000\" Size=\"47\" Weight=\"normal\">");

        for (let i = 0; i < events.length; ++i) {
            fileContents += eol.after(`<Subtitle SpotNumber="${i + 1}" TimeIn="${timecodeLib.secToTicksSec(events[i].start)}" TimeOut="${timecodeLib.secToTicksSec(events[i].end)}" FadeUpTime="20" FadeDownTime="20">`);
            for (let j = 0; j < events[i].lines.length; j++) {
                fileContents += eol.after(`<Text HAlign="center" HPosition="0.0" VAlign="bottom" VPosition="${getVPosition(events[i].lines[j].posY)}">${events[i].lines[j].text}</Text>`);
            }
            fileContents += eol.after(`</Subtitle>`);
        }

        fileContents += eol.after("</Font>");
        fileContents += eol.after("</DCSubtitle>");



        var oParser = new DOMParser();
        fileContents = oParser.parseFromString(fileContents, "application/xml");
        // print the name of the root element or error message

        var oSerializer = new XMLSerializer();
        fileContents = oSerializer.serializeToString(fileContents);


        return fileContents;
    },

    preProcess: {
        encode: function (events) {
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode: function (fileContents) {
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    },
}


const getVPosition = function (pos) {
    return Math.fround(100 - pos).toFixed(1);
}
const parseEvent_ = function (cueElement) {

    const event = new ccEvent();;

    const isTextContentEmpty = /^[\s\n]*$/.test(cueElement.textContent);

    const hasNoTimeAttributes = cueElement.nodeType == Node.ELEMENT_NODE &&
        !cueElement.hasAttribute('TimeIn') &&
        !cueElement.hasAttribute('TimeOut');

    if (
        cueElement.nodeType != Node.ELEMENT_NODE ||
        (hasNoTimeAttributes && isTextContentEmpty) ||
        (hasNoTimeAttributes && !isNested)

    ) {
        return null;
    }


    let start = parseTime_(cueElement.getAttribute('TimeIn'));
    let end = parseTime_(cueElement.getAttribute('TimeOut'));



    event.updateStart(start);
    event.updateEnd(end);

    let payload = [];

    payload = sanitizeTextContent_(
        cueElement,
        true,
    );

    payload = payload.slice(0, payload.length - 1);

    payload.forEach(function (line) {
        event.insertLine({
            text: line,
            posX: 0,
            posY: 0,
            bold: /<b>|<\/b>/.test(line),
            italics: /<i>|<\/i>/.test(line),
            underline: /<u>|<\/u>/.test(line)
        });
    });


    return event;
}


const parseTime_ = function (timecode) {
    let ret = null;

    if (timeColonFormatTicks_.test(timecode)) {
        return timecodeLib.tcTicksToSec(timecode);
    }
    else if (timeColonFormatFrames_.test(timecode)) {
        return timecodeLib.tcFractionToSec(timecode);
    }

    return ret;
}

const sanitizeTextContent_ = function (element, whitespaceTrim) {
    let payload = '';

    for (const node of element.childNodes) {

        if (node.childNodes && node.childNodes.length > 0) {
            payload += (sanitizeTextContent_(
            /** @type {!Element} */(node),
                whitespaceTrim
            ) + '\n');



        } else if (whitespaceTrim) {
            // Trim leading and trailing whitespace.
            let trimmed = node.textContent.trim();
            // Collapse multiple spaces into one.
            trimmed = trimmed.replace(/\s+/g, ' ');

            payload += trimmed;
        } else {
            payload += node.textContent;
        }
    }

    return payload.split("\n");
}

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
