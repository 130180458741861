<template>
	<ModalTemplate Title="Import Caption File" ModalId="ImportCaptionsModal">
		<form>
			<div class="form-group">
				<label for="caption-Source-Selection">Caption Source</label>
				<select class="form-control" id="caption-source" v-model.lazy="captionSource">
					<option>Cloud</option>
					<option>Local</option>
				</select>
			</div>
			<template v-if="captionSource === 'Local'">
				<div class="form-group">
					<label for="caption-source">Select Caption File</label>
					<div>
						<input type="file" @change="updateSrcFile($event)" required id="captionFile" aria-describedby="source-project-input" accept=".scc,.srt,.mcc,.vtt,.xml,.sub,.sbv,.txt,.ttml,.dfxp,.stl,.pac,.cap"/>
						<small id="source-video-help" class="form-text text-muted">Formats: SCC, MCC, SRT, WebVTT, SUB, SBV, Avid DS (TXT), XML, PAC, CAP, TTML, DFXP and STL.</small>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="form-group row">
					<div class="col-md-12">
						<input type="text" v-model="cloudUrl" id="captionFile" class="form-control" placeholder="e.g. https://s3-us-west-2.amazonaws.com/bucket/caption.srt">
						<small id="source-video-help" class="form-text text-muted">Formats: SCC, MCC, SRT, WebVTT, SUB, SBV, Avid DS (TXT), XML, TTML, DFXP, CAP, PAC, and STL.</small>
					</div>
				</div>
			</template>
			<div class="form-group">
            <label>File Encoding</label>
            <select class="custom-select" v-model="fileEncoding">
                <option>UTF-8</option>
                <option>IBM866</option>
                <option>ISO-8859-2</option>
                <option>CP1251</option>
                <option>windows-874</option>
                <option>windows-1250</option>
                <option>windows-1251</option>
                <option>windows-1252</option>
                <option>windows-1254</option>
                <option>GBK</option>
                <option>EUC-KR</option>
                <option>UTF-16BE</option>
                <option>UTF-16LE</option>
            </select>
        </div>        
			<div class="custom-control custom-switch">
				<input type="checkbox" class="custom-control-input" id="spliceCaptionDataSwitch" v-model="spliceOption">
				<label class="custom-control-label" for="spliceCaptionDataSwitch">Splice To Project</label>
			</div>
			<small class="form-text text-muted">Append new caption events starting at line: {{this.$store.state.editor.selectedCaptionEvent+1}} (selected caption line)</small>
			<br/>
			<button @click="importCaptionFile" type="button" class="btn btn-primary" data-dismiss="modal"><i class="fas fa-file-import"></i> Import</button><br /> <br />
			<small><i class="fas fa-bolt"></i> Powered by <strong>Closed Caption Converter</strong></small>
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	import smpteTc from 'smpte-timecode';
	import nodeTc from 'node-timecodes';
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name : 'ImportCaptionsModal',
		data () {
			return {
				captionSource: "Local",
				fileExt : "",
				cloudUrl : "",
				spliceOption : false,
				fileEncoding : "UTF-8"
			}
		},
		components: {
			ModalTemplate
		},
		methods : {
			updateSrcFile: function (event) {
				try {
					this.fileIncode = "00:00:00:00";
					const file = event.target.files[0];
					var fileName = event.target.value.split('.')[0];
					this.fileExt = event.target.value.split('.').pop().toUpperCase();
				} catch(e){
					console.log(e.message);
				}
			},
			importCaptionFile : function(){
				/* Differentiate between local and cloud import */
				var self = this;
				if (this.captionSource === 'Local'){
					if (document.getElementById('captionFile').files[0] != undefined) {
						var srcFile = document.getElementById('captionFile').files[0];
						var URL = window.URL || window.webkitURL;
						var fileSize = srcFile.size;
						var fileExt = srcFile.name.split('.').pop();
						var fileUrl = URL.createObjectURL(srcFile);
						
						if (this.$store.state.editor.supportedCaptionFormats.indexOf(fileExt.toUpperCase()) === -1){
							this.$notify({
								title: 'Caption Import Failed',
								type : 'error',
								text: 'Format not supported'
							});
						} else {
							var reader = new FileReader();							
							reader.onload = function (e) {
								if (fileExt.toUpperCase() != "STL" && fileExt.toUpperCase() != "CAP" && fileExt.toUpperCase() != "PAC") {
									var captionData = reader.result;
								} else {
									var captionData = new Buffer.from(e.target.result).toString('hex');
								}

								var settings = {
									srcFile : srcFile,
									fileExt : fileExt,
									fileSize : fileSize,
									source : 'Local',
									captionData : captionData,
									spliceOption : self.spliceOption									
								}
		
								self.$notify({
									title: 'Importing Captions',
									type : 'warn',
									text: 'Caption import in progress...'
								});

								setTimeout(function(){
									self.$store.commit('importCaptionFile', settings);
								},1000)
							}

							try {
								if (fileExt.toUpperCase() != "STL" && fileExt.toUpperCase() != "CAP" && fileExt.toUpperCase() != "PAC") {
									reader.readAsText(srcFile, self.fileEncoding);
								} else {
									reader.readAsArrayBuffer(srcFile);
								}
							} catch (e) {
								console.log(e.message);
							}
							
						}						
					} else {
						this.$notify({
							title: 'Caption Import',
							type : 'error',
							text: 'Caption Import Failed'
						});
					}
				} else {
					try {
						var url = this.cloudUrl;
						var fileExt = url.split('.').pop();
						if (this.$store.state.editor.supportedCaptionFormats.indexOf(fileExt.toUpperCase()) === -1){
							this.$notify({
								title: 'Caption Import',
								type : 'error',
								text: 'Caption Import Failed - Format Not Supported'
							});
						} else {
							var request = new XMLHttpRequest();
							request.open('GET', url, true);
							request.send(null);
							request.onreadystatechange = function () {
								if (request.readyState === 4 && request.status === 200) {
									var captionData = request.responseText;
									var settings = {
										srcFile : null,
										fileExt : fileExt,
										fileSize : 10,
										source : 'Cloud',
										fileIncode : self.fileIncode,
										captionData : captionData,
										spliceOption : self.spliceOption
									}
									self.$store.commit('updateStatus', {
										progress	: 100,
										msg	: "Importing",
										status	: "processing",
										display	: true
									});

									setTimeout(function(){
										self.$store.commit('importCaptionFile', settings);
										self.$store.commit('updateStatus', {
											progress	: 100,
											msg	: "Complete",
											status	: "Complete",
											display	: false
										});
										self.$store.commit('updateDurationToLatest');
									},1000)
		
									self.$notify({
										title: 'Importing Captions',
										type : 'warn',
										text: 'Caption import in progress...'
									});
								}
							}
						}				
					}catch(e){
						console.log(e.message);
						this.$notify({
							title: 'Caption Import',
							type : 'error',
							text: 'Caption Import Failed'
						});
					}
				}				
      		}
		}
	}
</script>