module.exports = {
    options: class {
        constructor(
            profile = "subRip",
            formatOptions = {decode:[], encode:[]},
            frameRate = 29.97,
            dropFrame = true,
            incode = "00:00:00:00",
            offset = "00:00:00:00",
            offsetType = "add",
            trgExt = "srt",
            trgProfile = "subRip",
            encoding = "UTF-8",
            formatEncodingOptions = [],
            formatDecodingOptions  = [],
            window = {
                columns: 32,
                rows: 15
            }) {
            this.profile = profile;
            this.formatOptions = formatOptions;
            this.frameRate = frameRate;
            this.dropFrame = dropFrame;
            this.incode = incode;
            this.offset = offset;
            this.offsetType = offsetType;
            this.trgProfile = trgProfile;
            this.encoding = encoding;
            this.formatEncodingOptions = formatEncodingOptions;
            this.formatDecodingOptions = formatDecodingOptions;
            this.incode = incode;
            this.trgExt = trgExt;
            this.window = window;
        }
    },

    frameRate: 29.97,
    dropFrame: true,
    incode: "00:00:00:00",
    offset: "00:00:00:00",
    offsetType: "add",
    trgExt: "srt",
    trgProfile: "subRip",
    encoding: "UTF-8",
    formatEncodingOptions : [],
    formatDecodingOptions : [],
    window: {
        columns: 32,
        rows: 15
    }
}