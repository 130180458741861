<template>
  <div id="app">
    <router-view />
    <notifications position="bottom right"></notifications>
  </div>
</template>
<script>
  import Editor from '@/views/Editor'
  export default {
    name: "App",
    components: {
      Editor
  }
};
</script>