import smpteTc from 'smpte-timecode'
import nodeTc from 'node-timecodes'
import _Timecode from 'timecode'
import uniqueId from 'lodash/uniqueId'

export default {
    /* Returns an empty caption object */
    version: "1.7.2, ",
    captionEvent: function (incode, outcode, incodeSec, outcodeSec, incodeMs, outcodeMs, style, primaryLang, secondaryLang) {
        var event = {
            uniqueId : uniqueId(),
            incode: incode || "00:00:00:00",
            outcode: outcode || "00:00:03:00",
            incodeSec: incodeSec || 0.00,
            outcodeSec: outcodeSec || 3.00,
            incodeMs: incodeMs || "00:00:00.000",
            outcodeMs: outcodeMs || "00:00:03.000",
            style: style || "Pop-On",
            confidence : false,
            speaker : "",
            primaryLang: [{
                    id : 0,
                    captionText: "",
                    positionX: 0,
                    positionY: 10,
                    bold: false,
                    italics: false,
                    underline: false
                },
                {
                    id: 1,
                    captionText: "",
                    positionX: 0,
                    positionY: 11,
                    bold: false,
                    italics: false,
                    underline: false
                },
                {
                    id: 2,
                    captionText: "",
                    positionX: 0,
                    positionY: 12,
                    bold: false,
                    italics: false,
                    underline: false
                },
                {
                    id: 3,
                    captionText: "",
                    positionX: 0,
                    positionY: 13,
                    bold: false,
                    italics: false,
                    underline: false
                }
            ],
            secondaryLang: [{
                    id: 0,
                    captionText: "",
                    positionX: 0,
                    positionY: 10,
                    bold: false,
                    italics: false,
                    underline: false
                },
                {
                    id: 1,
                    captionText: "",
                    positionX: 0,
                    positionY: 11,
                    bold: false,
                    italics: false,
                    underline: false
                },
                {
                    id: 2,
                    captionText: "",
                    positionX: 0,
                    positionY: 12,
                    bold: false,
                    italics: false,
                    underline: false
                },
                {
                    id: 3,
                    captionText: "",
                    positionX: 0,
                    positionY: 13,
                    bold: false,
                    italics: false,
                    underline: false
                }
            ]
        }
        return event;
    },
    concatCaptionText: function (captionLines, seperator) {
        var seperator = seperator || "\n";
        return (captionLines[0].captionText.trim() + seperator + captionLines[1].captionText.trim() + seperator + captionLines[2].captionText.trim() + seperator + captionLines[3].captionText.trim()).trim();
    },
    
    getAlignment: function (captionText, position) {
        //console.log("***********************");
        var alignment = "center";
        var column = parseInt(position.split("_")[1]);
        var firstLine = this.sanitizeTxt(captionText, false, "</br>").split("<br>")[0] || "";
        var firstLineLength = firstLine.length;

        /* console.log("Getting alignment on import");
        console.log("text: " + firstLine);
        console.log("Column: "+column); */

        try {
            var difference = Math.abs(32 - (column + firstLineLength));
            //console.log("difference: " + difference);
            if ((difference + 2) > column || (difference - 2) < column) {
                alignment = 'center';
            } else if (difference > column) {
                alignment = 'left';
            } else {
                alignment = 'right';
            }
        } catch (e) {
            console.log("Get Alignment Failed");
            console.log(e.message);
        }
        /* console.log("FOUND ALIGNMENT: "+alignment);
        console.log("***********************"); */
        return alignment;
    },
    /* Compare two caption line objects and tells you if they're the same */
    captionLineMatch: function (caption01, caption02) {
        if ((caption01.captionText == caption02.captionText) && caption01.incode == caption02.incode) {
            return true;
        } else {
            return false;
        }
    },
    /* Removes duplicates from array and returns the cleaned up array */
    removeDuplicates: function (myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    },
    /* Get Key from object based on the value */
    getKeyByValue: function (object, value) {
        return Object.keys(object).find(key => object[key] === value);
    },
    pad: function (pad, num, padLeft) {
        if (typeof num === 'undefined')
            return pad;
        if (padLeft) {
            return (pad + num).slice(-pad.length);
        } else {
            return (num + pad).substring(0, pad.length);
        }
    },
    padInt: function (number) {
        return (number < 10 ? '0' : '') + number;
    },
    decodeHtml: function (html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    },
    sanitizeSrtCaptionText: function (captionText) {
        return captionText.replace(/{b}/gi, "<b>").replace(/{\/b}/gi, "</b>").replace(/{i}/gi, "<i>").replace(/{\/i}/gi, "</i>").replace(/{u}/g, "<u>").replace(/{\/u}/g, "</u>");
    },
    sanitizeStlCaptionText: function (captionText) {
        return captionText.replace(/\|/g, "<br />");
    },
    sanitizeSubCaptionText: function (captionText) {
        return captionText.replace(/\[br\]/g, "<br />");
    },
    sanitizeVttCaptionText: function (captionText) {
        return captionText.replace(/<(.*?)>/g, "");
    },
    createYoutubeEmbed: function (url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return 'error';
        }
    },
    createSmpteTc: function (tc, frameRate, dropFrame) {
        try {
            return smpteTc(tc, frameRate, dropFrame);
        } catch(e){
            try {
                var timecodeInSeconds = nodeTc.toSeconds(tc.replace(";", ":"));
                var timecodeCorrected = nodeTc.fromSeconds(timecodeInSeconds, {
                    frameRate: frameRate,
                    dropFrame: dropFrame
                });

                if (!dropFrame) {
                    return smpteTc(timecodeCorrected, frameRate, dropFrame);
                } else {
                    return smpteTc(timecodeCorrected, frameRate, dropFrame);
                }
            } catch(e){
                throw new Error("Invalid Timecode. "+e.message);
            }
        }        
    },
    /* Accepts tc in format hh:mm:ss */
    verifyTcSec: function (tc) {
        if (parseInt(tc.slice(-2)) > 59) {
            return tc.slice(0, -2) + "59";
        } else {
            return tc;
        }
    },
    /* Takes array of  hex values and returns a smpteTC: [ "e8", "03", "64", "00" ]*/
    formatPacTc: function (hexValues) {
        var hhmm = this.pad("0000", parseInt(hexValues[1] + hexValues[0], 16), true);
        var ssff = this.pad("0000", parseInt(hexValues[3] + hexValues[2], 16), true);
        var hh = hhmm.substring(0, 2);
        var mm = hhmm.substring(2, 4);
        var ss = ssff.substring(0, 2);
        var ff = ssff.substring(2, 4);
        var tc = hh + ":" + mm + ":" + ss + ":" + ff;
        return (tc);
    },
    /* Takes array of hex values and returns a SMPTE Tc */
    hexToDecimalTc: function (timecodeHex) {
        var timecode = [];
        for (var i = 0; i < timecodeHex.length; i++) {
            timecode.push(this.pad("00", parseInt(timecodeHex[i], 16), true));
        }
        //console.log(timecode);
        return timecode.join(":");
    },
    tcValidate: function (timecode, framerate) {
        timecode = timecode.trim().replace(/;/g, ":").toUpperCase();
        try {
            if ((/^\d\d:\d\d:\d\d:\d\d$/g).test(timecode)) {
                return timecode;
            } else if ((/^\d\d\d\d\d\d\d\d$/g).test(timecode)) {
                return this.createSmpteTc(timecode.match(/.{1,2}/g).join(":"), this.frameRateMap[framerate], this.tcFrameMap[framerate]).toString();
            } else if ((/^\d+[HMSF]$/g).test(timecode)) {
                if (timecode.indexOf("H") > -1) {
                    var hours = parseInt(timecode.split("H")[0]);
                    if (hours > 23) {
                        return "23:00:00:00";
                    } else if (hours < 10) {
                        return "0" + hours + ":00:00:00";
                    } else {
                        return hours + ":00:00:00";
                    }
                } else if (timecode.indexOf("M") > -1) {
                    var minutes = parseInt(timecode.split("M")[0]);
                    if (minutes > 59) {
                        return "00:59:00:00";
                    } else if (minutes < 10) {
                        return "00:0" + minutes + ":00:00";
                    } else {
                        return "00:" + minutes + ":00:00";
                    }
                } else if (timecode.indexOf("S") > -1) {
                    var seconds = parseInt(timecode.split("S")[0]);
                    if (seconds > 59) {
                        return "00:00:59:00";
                    } else if (seconds < 10) {
                        return "00:00:0" + seconds + ":00";
                    } else {
                        return "00:00:" + seconds + ":00";
                    }
                } else {
                    var frames = parseInt(timecode.split("F")[0]);
                    if (frames > parseInt(framerate)) {
                        return "00:00:00:" + parseInt(framerate);
                    } else if (frames < 10) {
                        return "00:00:00:0" + frames;
                    } else {
                        return "00:00:00:" + frames;
                    }
                }
            } else if ((/^\d+$/g).test(timecode)) {
                var seconds = timecode / framerate;
                return nodeTc.fromSeconds(seconds, {
                    frameRate: framerate
                });
            } else {
                return "00:00:00:00";
            }
        } catch (e) {
            return "";
        }
    },
    frameRateMap: {
        "23.976": 23.976,
        "24": 24,
        "25": 25,
        "29.97": 29.97,
        "30": 30,
        "50": 50,
        "59.94": 59.94,
        "60": 60
    },
    tcFrameMap: {
        "23.976": false,
        "24": false,
        "25": false,
        "29.97": true,
        "30": false,
        "50": false,
        "59.94": true,
        "60": false
    },
    trgExtensionMap: {
        "SCC": "SCC",
        "SRT": "SRT",
        "VTT": "VTT",
        "SBV": "SBV",
        "SUB": "SUB",
        "STL": "STL",
        "Avid DS (TXT)": "TXT",
        "MCC": "MCC",
        "XML": "XML",
        "TTML": "TTML",
        "DFXP": "DFXP",
        "FCPXML": "FCPXML"
    },

    /*accepts timecode string*/
    replaceLastIndexOf: function (string, value, newValue) {
        var position = string.lastIndexOf(value);
        return string.substring(0, position) + newValue + string.substring(position + 1);
    },
    /*accepts timecode string and dropFrame boolean*/
    formatTimecodeString: function (tc, dropFrame) {
        if (dropFrame) {
            var tcArray = tc.split(":");
            if (tcArray.length === 4) {
                tc = this.replaceLastIndexOf(tc, ":", ";");
            }
        } else {
            var tcArray = tc.split(":");
            if (tcArray.length === 3) {
                tc = this.replaceLastIndexOf(tc, ";", ":");
            }
        }

        return tc;
    },
    calculateTimecodeFromText: function (captionEvent, framerate, seconds, avgWordsPerSecond, incode) {
        seconds += (1 / framerate);
        var wordsInLine = this.concatCaptionText(captionEvent.primaryLang).split(" ").length;
        var duration = wordsInLine / avgWordsPerSecond;
        captionEvent.incodeSec = seconds;
        seconds += duration;
        captionEvent.outcodeSec = seconds;

        var incodeTCFix = _Timecode.Timecode.init({
            framerate: framerate,
            timecode: nodeTc.fromSeconds(captionEvent.incodeSec, {
                frameRate: framerate
            })
        });
        var outcodeTCFix = _Timecode.Timecode.init({
            framerate: framerate,
            timecode: nodeTc.fromSeconds(captionEvent.outcodeSec, {
                frameRate: framerate
            })
        });

        incodeTCFix.add(incode);
        outcodeTCFix.add(incode);

        captionEvent.incode = incodeTCFix.toString();
        captionEvent.outcode = outcodeTCFix.toString();
        return {
            event: captionEvent,
            time: seconds
        };
    },
    calculateTimecodes: function (captions, frameRate, dropFrame, projectIncode, debug) {
        for (var i = 0; i < captions.length; i++) {
            try {
                if (captions[i].captionText != undefined) {
                    captions[i].captionText = captions[i].captionText.replace(/<br \/>+|<br>+|<br\/>+/g, "<br />");
                    captions[i].captionText = captions[i].captionText.replace(/<br \/><br \/>|<br><br>/g, "<br />");
                    captions[i].captionText = captions[i].captionText.replace(/^<br \/>|^<br>|^<br\/>/g, "");
                    captions[i].captionText = captions[i].captionText.replace(/'’’/g, "'");
                    captions[i].captionText = captions[i].captionText.replace(/'’/g, "'");

                    if (captions[i].position == null || captions[i].position == undefined || captions[i].position == "") {
                        captions[i].position = "12_00";
                    }

                    if (captions[i].incode.match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g) != null) {
                        var incode = _Timecode.Timecode.init({
                            framerate: frameRate,
                            timecode: captions[i].incode.replace(";", ":")
                        });

                        var outcode = _Timecode.Timecode.init({
                            framerate: frameRate,
                            timecode: captions[i].outcode.replace(";", ":")
                        });

                        captions[i].incode = incode.toString();
                        captions[i].outcode = outcode.toString();

                        if (projectIncode != "00:00:00:00") {
                            try {
                                incode.subtract(projectIncode)
                            } catch (e) {
                                console.log(e.message);
                                var incode = _Timecode.Timecode.init({
                                    framerate: frameRate,
                                    timecode: "00:00:00:00"
                                });
                            }

                            try {
                                outcode.subtract(projectIncode);
                            } catch (e) {
                                console.log(e.message);
                                var outcode = _Timecode.Timecode.init({
                                    framerate: frameRate,
                                    timecode: "00:00:00:00"
                                });
                            }
                        }

                        captions[i].incodeSec = nodeTc.toSeconds(incode.toString().replace(/;/g, ":"));
                        captions[i].outcodeSec = nodeTc.toSeconds(outcode.toString().replace(/;/g, ":"));

                        captions[i].incodeMs = nodeTc.fromSeconds(captions[i].incodeSec, {
                            ms: true
                        });
                        captions[i].outcodeMs = nodeTc.fromSeconds(captions[i].outcodeSec, {
                            ms: true
                        });
                        captions[i].incodeMs = this.replaceCharAtIndex(captions[i].incodeMs, 8, ".");
                        captions[i].outcodeMs = this.replaceCharAtIndex(captions[i].outcodeMs, 8, ".");
                    } else if (captions[i].incodeSec != null && captions[i].incodeSec != undefined && captions[i].incodeSec != "") {
                        //console.log("USING INCODE SEC/OUTCODE SEC");                
                        captions[i].incodeMs = nodeTc.fromSeconds(captions[i].incodeSec, {
                            ms: true
                        });
                        captions[i].outcodeMs = nodeTc.fromSeconds(captions[i].outcodeSec, {
                            ms: true
                        });
                        captions[i].incodeMs = this.replaceCharAtIndex(captions[i].incodeMs, 8, ".");
                        captions[i].outcodeMs = this.replaceCharAtIndex(captions[i].outcodeMs, 8, ".");

                        captions[i].incode = nodeTc.fromSeconds(captions[i].incodeSec, {
                            frameRate: frameRate
                        });
                        captions[i].outcode = nodeTc.fromSeconds(captions[i].outcodeSec, {
                            frameRate: frameRate
                        });
                        captions[i].incode = this.createSmpteTc(captions[i].incode, frameRate, dropFrame).toString();
                        captions[i].outcode = this.createSmpteTc(captions[i].outcode, frameRate, dropFrame).toString();
                    } else {
                        //console.log("USING INCODE MS/OUTCODE MS");
                        captions[i].incodeMs = this.replaceCharAtIndex(captions[i].incodeMs, 8, ".");
                        captions[i].outcodeMs = this.replaceCharAtIndex(captions[i].outcodeMs, 8, ".");

                        var incodeFrames = this.pad("00", Math.floor(captions[i].incodeMs.split(".")[1] / 1000 * frameRate), true);
                        var outcodeFrames = this.pad("00", Math.floor(captions[i].outcodeMs.split(".")[1] / 1000 * frameRate), true);

                        captions[i].incode = captions[i].incodeMs.split(".")[0] + ":" + incodeFrames;
                        captions[i].outcode = captions[i].outcodeMs.split(".")[0] + ":" + outcodeFrames;

                        captions[i].incode = this.createSmpteTc(captions[i].incode, frameRate, dropFrame).toString();
                        captions[i].outcode = this.createSmpteTc(captions[i].outcode, frameRate, dropFrame).toString();

                        captions[i].incodeSec = nodeTc.toSeconds(captions[i].incode);
                        captions[i].outcodeSec = nodeTc.toSeconds(captions[i].outcode);
                    }
                }
            } catch (e) {
                console.log(e.message);
            }
        }
        return captions
    },
    replaceCharAtIndex: function (string, index, replacement) {
        return string.substr(0, index) + replacement + string.substr(index + replacement.length);
    },
    /* Subtract Incode */
    subtractIncode: function (incode, captions, frameRate, dropFrame, debug) {
        var incodeFrames = this.createSmpteTc(incode, frameRate, dropFrame).frameCount;
        //console.log(incodeFrames);
        for (var i = 0; i < captions.length; i++) {
            try {
                captions[i].incode = this.createSmpteTc(captions[i].incode, frameRate, dropFrame).subtract(incodeFrames).toString();
                captions[i].outcode = this.createSmpteTc(captions[i].outcode, frameRate, dropFrame).subtract(incodeFrames).toString();
            } catch (e) {
                console.log(e.message);
                console.log("Failed to apply source file incode value for caption line:");
                var captionLineIndex = i;
                console.log(captionLineIndex);
                console.log(captions[i]);
            }
        }
        return captions;
    },
    /* Apply offset to captionLine */
    applyOffset: function (offset, captions, frameRate, dropFrame, debug) {
        var frameOffset = false;
        var timecodeOffset = false;

        var framePattern = new RegExp(/^\d+$/g);
        var timecodePattern = new RegExp(/^\d\d:\d\d:\d\d:\d\d$/g);
        var timePattern = new RegExp(/^\d\d:\d\d:\d\d$/g);

        if (framePattern.test(offset)) {
            frameOffset = true;
        } else if (timecodePattern.test(offset)) {
            timecodeOffset = true;
        } else if (timePattern.test(offset)) {
            offset = offset + ":00";
            timecodeOffset = true;
        } else {
            throw new Error("Offset format is invalid. Please specify offset using hh:mm:ss:ff or ff format value: " + offset);
        }

        for (var i = 0; i < captions.length; i++) {
            captions[i].incode = this.createSmpteTc(captions[i].incode, frameRate, dropFrame).add(offset).toString();
            captions[i].outcode = this.createSmpteTc(captions[i].outcode, frameRate, dropFrame).add(offset).toString();
        }

        return captions;
    },
    /* Used in ai transcript import */
    getEndTimeWithPadding : function(endTime, padding, nextStartTime){
        var endTimeFloat = parseFloat(endTime.replace("s", ""));
        var startTimeFloat = parseFloat(nextStartTime.replace("s", ""));
        var endTimeWithPadding = endTimeFloat+padding;
        if (endTimeWithPadding < startTimeFloat){
            return endTimeWithPadding + "s";
        } else {
            return startTimeFloat - 0.1 + "s";
        }
    },
    segment: function (partialIncode, partialduration, captions, frameRate, dropFrame, debug) {
        var minIncode = this.createSmpteTc(partialIncode, frameRate, dropFrame);
        var maxIncode = this.createSmpteTc(partialIncode, frameRate, dropFrame).add(partialduration.toString());

        function checkIncodeOutcode(captionLine) {
            var incodeFrames = this.createSmpteTc(captionLine.incode, frameRate, dropFrame).frameCount;
            return incodeFrames >= minIncode.frameCount && incodeFrames <= maxIncode.frameCount;
        }
        return captions.filter(checkIncodeOutcode);
    },
    conform: function (edlData, captions, frameRate, dropFrame, debug) {
        captions = this.conformToEdl(edlData, captions, frameRate, dropFrame, debug);
        captions = this.calculateTimecodes(captions, frameRate, dropFrame, debug);
        return captions;
    },
    conformToEdl: function (edlData, captions, frameRate, dropFrame, debug) {
        var captionData = [];
        edlData = edlData.split("\n\n");
        for (var i = 0; i < edlData.length; i++) {
            var numberOfTimecodes = 0;
            var lineData = edlData[i].replace(/\s\s+/g, ' ').replace(/\n/g, ' ').split(" ");
            for (var j = 0; j < lineData.length; j++) {

                if (lineData[j].match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g) != null) {

                    /* timecode found */
                    if (numberOfTimecodes == 0) {
                        var incode = lineData[j];
                    } else if (numberOfTimecodes == 1) {
                        var outcode = lineData[j];

                    } else if (numberOfTimecodes == 2) {
                        var newIncode = lineData[j];

                    } else if (numberOfTimecodes == 3) {
                        var newOutcode = lineData[j];
                    } else if (numberOfTimecodes > 3) {
                        throw new Error("EDL format is not supported");
                    }
                    numberOfTimecodes++
                }
            }

            if (numberOfTimecodes > 3) {
                captionData = captionData.concat(this.calculateCaptionLineConform(incode, outcode, newIncode, newOutcode, captions, frameRate, dropFrame));

            }
        }
        return captionData;
    },
    calculateCaptionLineConform: function (incode, outcode, newIncode, newOutcode, captions, frameRate, dropFrame) {
        var incode = this.createSmpteTc(incode, frameRate, dropFrame);
        var outcode = this.createSmpteTc(outcode, frameRate, dropFrame);
        var newIncode = this.createSmpteTc(newIncode, frameRate, dropFrame);
        var newOutcode = this.createSmpteTc(newOutcode, frameRate, dropFrame);

        function checkIncodeOutcode(captionLine) {
            var incodeFrames = this.createSmpteTc(captionLine.incode, frameRate, dropFrame).frameCount;
            var outcodeFrames = this.createSmpteTc(captionLine.outcode, frameRate, dropFrame).frameCount;
            return outcodeFrames > incode.frameCount && incodeFrames < outcode.frameCount;
        }

        var conformedCaptions = captions.filter(checkIncodeOutcode);

        if (newIncode.frameCount < incode.frameCount) {
            /* Find out what the offset is for the caption data */
            var frameOffset = incode.frameCount - newIncode.frameCount;
            for (var i = 0; i < conformedCaptions.length; i++) {
                try {
                    var newCaptionLineIncode = this.createSmpteTc(conformedCaptions[i].incode, frameRate, dropFrame).subtract(frameOffset);
                    if (newCaptionLineIncode.frameCount < newIncode.frameCount) {
                        conformedCaptions[i].incode = newIncode.toString();
                    } else {
                        conformedCaptions[i].incode = newCaptionLineIncode.toString();
                    }
                } catch (e) {
                    conformedCaptions[i].incode = newIncode.toString();
                }

                try {
                    var newCaptionLineOutcode = this.createSmpteTc(conformedCaptions[i].outcode, frameRate, dropFrame).subtract(frameOffset);
                    if (newCaptionLineOutcode.frameCount > newOutcode.frameCount) {
                        conformedCaptions[i].outcode = newOutcode.toString();
                    } else {
                        conformedCaptions[i].outcode = newCaptionLineOutcode.toString();
                    }
                } catch (e) {
                    conformedCaptions[i].incode = newOutcode.toString();
                }
            }
        } else {
            var frameOffset = newIncode.frameCount - incode.frameCount;
            for (var i = 0; i < conformedCaptions.length; i++) {
                try {
                    var newCaptionLineIncode = this.createSmpteTc(conformedCaptions[i].incode, frameRate, dropFrame).add(frameOffset);
                    if (newCaptionLineIncode.frameCount < newIncode.frameCount) {
                        conformedCaptions[i].incode = newIncode.toString();
                    } else {
                        conformedCaptions[i].incode = newCaptionLineIncode.toString();
                    }
                } catch (e) {
                    conformedCaptions[i].incode = newIncode.toString();
                }

                try {
                    var newCaptionLineOutcode = this.createSmpteTc(conformedCaptions[i].outcode, frameRate, dropFrame).add(frameOffset);
                    if (newCaptionLineOutcode.frameCount > newOutcode.frameCount) {
                        conformedCaptions[i].outcode = newOutcode.toString();
                    } else {
                        conformedCaptions[i].outcode = newCaptionLineOutcode.toString();
                    }
                } catch (e) {
                    conformedCaptions[i].incode = newOutcode.toString();
                }
            }
        }
        return conformedCaptions;
    },
    autoFormatTextToLines: function (text, captionLines) {
        var textArray = text.split(" ");
        var currentCaptionLine = 0;
        for (var i = 0; i<textArray.length; i++){
            if (captionLines[currentCaptionLine].captionText.length + textArray[i].length <= 32 || captionLines[currentCaptionLine].captionText.length === 0) {
                captionLines[currentCaptionLine].captionText += " " + textArray[i].trim();
            } else {
                currentCaptionLine++;
                captionLines[currentCaptionLine].captionText += " " + textArray[i].trim();
            }
        }

        return captionLines
    },
    distributeTextEvenly : function (text, captionLines){
        var textArray = text.trim().split(" ");
        var avgWordsPerLine = textArray.length/4;
        var k = 0;
        for (var i = 0; i<4; i++){            
            captionLines[i].captionText = textArray.slice(k, k += avgWordsPerLine).join(" ");
        }
        console.log(captionLines);
        return captionLines;
    },
    secToTcString : function(timeSec, framerate, dropframe, incode){
        var tc = _Timecode.Timecode.init({
            framerate: framerate,
            timecode: nodeTc.fromSeconds(timeSec, {
                frameRate: framerate
            }),
            drop_frame: dropframe
        });

        if (incode){
            tc.add(incode);
        }

        return tc.toString();
    },
    
    getFirstEmptyLine: function (captionLines) {
        for (var i = 0; i < captionLines.length; i++) {
            if (captionLines[i].captionText == "") {
                return i;
            }
        }

        return 3;
    },
    getUserSubscription(email){
        return false;
    },

    spellcheck : function(errors, captionsLength, captions, view, spellchecker){
        while (errors.length === 0 && captionsLength != 0) {            
            if (view === 'subtitle') {
                var subtitleText = captions[captionsLength - 1].subtitleText.trim().replace(/\n/g, " ").replace(/  /g, " ");
            } else {
                var subtitleText = this.concatCaptionText(captions[captionsLength - 1].primaryLang, " ");
            }

            subtitleText.split(" ").forEach(word => {
                if (errors.length === 0) {
                    word = word.replace(/[^\w\s]|\d+/g, " ").split(" ")[0].trim();
                    if (word != "" && !spellchecker.correct(word)) {
                        var error = {
                            original : word,
                            suggestions : spellchecker.suggest(word),
                            event : captionsLength - 1
                        }

                        errors.push(error);
                
                    }
                }
            });

            captionsLength--;

        }

        return ({
            errors: errors,
            captionsLength : captionsLength
        })
    }
}