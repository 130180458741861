module.exports = function (lines) {
        let positions = [];
        let combinedLines = [];
        lines.forEach(function (line, index, lines) {
            let pos = line.posY;
            if (positions.indexOf(pos) === -1) {
                positions.push(pos);
                let matchingLines = [];
                lines.forEach(line => {
                    if (line.posY === pos) {
                        matchingLines.push(line);
                    }
                });

                if (matchingLines.length > 1) {
                    let text = "";
                    matchingLines.sort(function (a, b) {
                        return a.posX - b.posX;
                    }).forEach(line => {
                        text += " " + line.text;
                    });

                    matchingLines[0].text = text.trim();
                    combinedLines.push(matchingLines[0]);
                } else {
                    combinedLines.push(line);
                }
            }
        });
        return combinedLines;
    }