<template>
    <form>
        
        <div class="form-group">
            <label>Language</label>
            <select class="form-control" v-model="selectedLanguage">
                <option v-for="(language, index) in languages" :key="index">{{language}}</option>
            </select>
        </div>
        <div class="form-group">
            <label>Gender</label>
            <select class="form-control" v-model="gender">
                <option>MALE</option>
                <option>FEMALE</option>
            </select>
        </div>
        <div class="form-group">
            <label>Default Voice</label>
            <select class="form-control" v-model="selectedVoice">
                <option v-for="(voice, index) in voices[languageCodes[selectedLanguage]][gender]" :key="index">{{voice}}</option>
            </select>
        </div>
       <a href="https://cloud.google.com/text-to-speech/docs/voices" target="_blank">Support Languages and Voices</a>
    </form>
</template>
<script>
    import adLangs from '@/assets/js/adLanguages.js'
    export default {
        name : 'AudioDescription',
        data : function(){
            return {
                languages : adLangs.languages,
                languageCodes : adLangs.languageCodes,
                voices : adLangs.voices,
                gender : 'MALE',
                selectedLanguage : "English (US)",
            }
        },
        computed: {
            selectedVoice: {
                get() {
                    return this.$store.state.editor.projectSettings.voice;
                },
                set(voice) {
                    this.$store.commit('updateProjectSetting', {option: 'voice', value: voice});
                }
            },
        }
	}
</script>