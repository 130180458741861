const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const striptags = require("striptags");
const eol = require("eol");

module.exports ={
    decode : function(fileContents, options){
        let events = [];
        let event = false;
        let endFlag = false;
        let subtitleLines = fileContents.split("\n");
        subtitleLines.forEach(subtitleLine => {
            if (!endFlag){
                if (subtitleLine.split('\\').length === 2 && /\d\d:\d\d:\d\d\.\d\d/.test(subtitleLine.split("\\")[0]) && /\d\d:\d\d:\d\d\.\d\d/.test(subtitleLine.split("\\")[1])){
                    if(event){
                        event.syncSubtitleText();
                        event.alignTextCenter();
                        events.push(event);
                    }

                    event = new ccEvent();;
                    event.start = timecodeLib.tcToSec(subtitleLine.split("\\")[0].replace(".", ":"), options.frameRate); 
                    event.end = timecodeLib.tcToSec(subtitleLine.split("\\")[1].replace(".", ":"), options.frameRate);
                } else if (!/^\*/.test(subtitleLine)){
                    event.insertLine({
                        text : striptags(subtitleLine.replace(/^\[/g, "")).trim(),
                        posX : 0,
                        posY : 0,
                        bold : /<b>|<\/b>/.test(subtitleLine),
                        italics : /<i>|<\/i>/.test(subtitleLine),
                        underline : /<u>|<\/u>/.test(subtitleLine) 
                    });
                } else if (subtitleLine ==="*END*"){
                    endFlag = true;
                }
            }            
        });
        
        event.syncSubtitleText();
        event.alignTextCenter();
        events.push(event);
        return events;
    },

    encode : function(events, options){
        let fileContents = "*PART 1*\n";
        events.forEach(event =>{
            let tcIn = timecodeLib.secToTc(event.start, options.frameRate);
            let tcOut = timecodeLib.secToTc(event.end, options.frameRate);


            fileContents += tcIn.substring(0,8) + "." + tcIn.substring(9);
            fileContents+= "\\";
            fileContents += tcOut.substring(0,8) + "." + tcIn.substring(9);
            fileContents+= "\n";

            event.lines.forEach(line =>{
                fileContents += "["+line.text + "\n";
            });            
        });

        fileContents+="*END*\n*CODE*\n000000000000000\n*CAST*\n*GENERATOR*\n*FONTS*\n*READ*\n0,300 15,000 130,000 100,000 25,000\n*TIMING*\n"
        fileContents += "1 " + options.frameRate.toString().slice(0,2) + " 0\n";
        fileContents+= "*TIMED BACKUP NAME*\n"
        fileContents+="C:\\\n";
        fileContents+="*FORMAT SAMPLE ÅåÉéÌìÕõÛûÿ*\n";
        fileContents+="*READ ADVANCED*\n";
        fileContents+="< > 1 1 1,000\n"
        fileContents+="*MARKERS*\n";
        
        return fileContents;
    },

    preProcess : {
        encode : function(events){
            /* All */
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode : function(fileContents){
            /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();
            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}