<template>
	<div id='TimelinePlayhead' :style="{top: currentTime + 'px'}"></div>
</template>

<script>
	export default {
		name: 'Playhead',
		computed : {
			currentTime : function(){
				return this.$store.state.editor.currentTimeSec *50;
			}
		}
	}
</script>
<style scoped>
#TimelinePlayhead {
	position: relative;
	border: 1px solid rgb(133, 116, 21);
	height: 1px;
	width: 100%;	
	transition: top 0.1s linear;
	z-index: 501;
}
</style>