export default {
    frameRateMap: {
        "23.976": 23.976,
        "23.98": 23.976,
        "24": 24,
        "25": 25,
        "29.97": 29.97,
        "30": 30,
        "50": 50,
        "59.94": 59.94,
        "60": 60
    },
    tcFrameMap: {
        "23.976": false,
        "23.98": false,
        "24": false,
        "25": false,
        "29.97": true,
        "30": false,
        "50": false,
        "59.94": true,
        "60": false
    },
    framerateMap: {
        fcp: {
            '59.94': '1001/60000s',
            '50': '1/50s',
            '29.97': '1001/30000s',
            '25': '1/25s',
            '24': '1/24s',
            '23.98': '1001/24000s',
            denominator: {
                '59.94': 60000,
                '50': 50,
                '29.97': 30000,
                '25': 25,
                '24': 24,
                '23.98': 24000,
            }
        }
    },

    workflow : {
        "CC Required" : {
            "next" : "CC In Progress",
            "same": "CC Required",
            "back" : false,
            "skip" : false
        },
        "CC In Progress": {
            "next": "Review Required",
            "same": "CC In Progress",
            "back": "CC Required",
            "skip" : "Complete"
        },
        "Review Required": {
            "next": "Review In Progress",
            "same": "Review Required",
            "back": false
        },
        "Review In Progress": {
            "next": "Complete",
            "same": "Review In Progress",
            "back": "Review Required",
            "skip": false
        },
        "Complete": {
            "next": "Review In Progress",
            "same": "Complete",
            "back": false,
            "skip": false
        }
    }
}