<template>
	<LrgModalTemplate Title="Save Project" ModalId="SaveProjectModal">
		<p><small>Select a free spot or overwrite an existing project</small></p>
		<button v-for="project in savedProjects" v-bind:key="project.id" @click="save(project.id)" class="btn btn-outline-light btn-block" style="margin:0; border-radius: 0px;">
			<small>{{project.uid}}</small>   {{project.title}}   <i class="fas fa-stopwatch"></i> {{project.framerate}}   <i class="far fa-calendar"></i> {{project.last_edited}}
		</button>
		<div class="modal-footer">
			<button type="button" class="btn btn-secondary" data-dismiss="modal"><i class="fas fa-times"></i> Cancel</button>
		</div>
	</LrgModalTemplate>
</template>
<script>
	import LrgModalTemplate from './LrgModalTemplate'
	import firebase from '@/firebase/config'
	import db from '@/firebase/firestore'
	export default {
		name: 'SaveProjectModal',
		data () {
			return {
				savedProjects : [],
			}
		},
		mounted : function() {
			var self = this;
			var authId = firebase.auth().currentUser.uid;
			db.collection("users").doc(authId)
				.onSnapshot(function(userDoc) {
					self.savedProjects = [];
					for (var i = 0; i<10; i++){
						if (userDoc.data().projects[i]){
							try {
								var last_edited = userDoc.data().projects[i].last_edited.toDate();
							} catch(e){
								var last_edited = '';
							}
							
							self.savedProjects.push(
								{
									title : userDoc.data().projects[i].title,
									framerate : userDoc.data().projects[i].framerate,
									last_edited : last_edited,
									id : userDoc.data().projects[i].id,
									uid : userDoc.data().projects[i].uid
								}
							)
						} else {
							self.savedProjects.push(
								{
									title : '*EMPTY',
									framerate : '',
									last_edited : '',
									id : i,
									uid : ''
								}
							)
						}
						
					} 
				});
		},
		components: {
			LrgModalTemplate
		},
		methods: {
			save : function(id){
				var self = this;
				var authId = firebase.auth().currentUser.uid;
				var batch = db.batch();
				var confirmation = false;
				var projectsToSave = self.savedProjects.slice();

				if (projectsToSave[id].title != "*EMPTY"){
					confirmation = window.confirm("Are you sure you wish to overwrite this project?");
				} else {
					confirmation = true;
				}

				projectsToSave[id].title = this.$store.state.editor.projectSettings.title;
				projectsToSave[id].framerate = this.$store.state.editor.projectSettings.framerate;
				projectsToSave[id].uid = this.$store.state.editor.projectSettings.uid || '';
				projectsToSave[id].id = id;
				projectsToSave[id].last_edited = firebase.firestore.Timestamp.now();
				
				if (confirmation){
					/* Update the users project's list */
					var userRef = db.collection("users").doc(authId);
					batch.set(userRef, {	projects : projectsToSave }, { merge: true });

					/* Update the users project */
					var projectRef = db.collection("users").doc(authId).collection("projects").doc(id.toString());
					batch.set(projectRef, {	
                        id : id,
                        version: self.$store.state.editor.version,
						captions : JSON.stringify(self.$store.state.editor.captions),
						projectSettings : JSON.stringify(self.$store.state.editor.projectSettings),
						metadata : JSON.stringify(self.$store.state.editor.metadata)
					});

					batch.commit().then(function() {						
						self.$notify({
							title: 'Save Project',
							type: 'success',
							text: 'Project saved successfully'
						});
					})
					.catch(function(error) {
						console.log(error);
						self.$notify({
							title: 'Save Project',
							type: 'error',
							text: 'Project save failed. ' + error
						});
					});

					$('#SaveProjectModal').modal('toggle');
				} else {
					self.$notify({
						title: 'Save Project',
						type: 'warning',
						text: 'Project Save Aborted'
					});
				}
			}
		},
		computed: {

		}
	}
</script>