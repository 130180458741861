const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");
const DomParser = require('dom-parser');

//Regex variables for the different timecode formats
const timeColonFormatFrames_ = /^(\d{2,}):(\d{2}):(\d{2}):(\d{2})\.?(\d+)?$/; //00:00:40:07 (7 frames) or 00:00:40:07.1 (7 frames, 1 subframe)
const timeColonFormat_ = /^(?:(\d{2,}):)?(\d{2}):(\d{2})$/; //00:00:40 or 00:40
const timeColonFormatMilliseconds_ = /^(?:(\d{2,}):)?(\d{2}):(\d{2}\.\d{2,})$/; //01:02:43.0345555 or 02:43.03
const timeFramesFormat_ = /^(\d*(?:\.\d*)?)f$/; //75f or 75.5f
const timeTickFormat_ = /^(\d*(?:\.\d*)?)t$/; //50t or 50.5t
const timeHMSFormat_ = new RegExp(['^(?:(\\d*(?:\\.\\d*)?)h)?',
    '(?:(\\d*(?:\\.\\d*)?)m)?',
    '(?:(\\d*(?:\\.\\d*)?)s)?',
    '(?:(\\d*(?:\\.\\d*)?)ms)?$'].join(''));

module.exports = {
    decode: function (fileContents, options) {
        let events = [];
        const ttpNs = 'http://www.w3.org/ns/ttml#parameter';
        const parser = new DomParser();


        let frameRate = null;
        let subFrameRate = null;
        let frameRateMultiplier = null;
        let tickRate = null;

        const xml = parser.parseFromString(fileContents, 'text/xml');

        const tts = xml.getElementsByTagName('tt');
        const tt = tts[0];
        frameRate = getAttributeNS(tt, ttpNs, 'frameRate');
        subFrameRate = getAttributeNS(tt, ttpNs, 'subFrameRate');
        frameRateMultiplier = getAttributeNS(tt, ttpNs, 'frameRateMultiplier');
        tickRate = getAttributeNS(tt, ttpNs, 'tickRate');

        const rateInfo = new rateInfo_(
            frameRate, subFrameRate, frameRateMultiplier, tickRate);

        const textNodes = getLeafCues_(
            tt.getElementsByTagName('body')[0]);
        for (const node of textNodes) {
            const event = parseEvent_(node, rateInfo);
            if (event) {
                if (events.length > 0 && checkPreviousEvent(events[events.length - 1], event)) {
                } else {
                    events.push(event);
                }
            }
        }

        events.forEach(function (event) {
            event.syncSubtitleText();
            event.alignTextCenter();
        });
        return events;
    },

    encode: function (events, options) {
        let fileContents = eol.after("<?xml version=\"1.0\" encoding=\"UTF-8\"?>");
        fileContents += eol.after("<tt xml:lang=\"en\"");

        fileContents += eol.after("xmlns=\"http://www.w3.org/ns/ttml\"");
        fileContents += eol.after("xmlns:tts=\"http://www.w3.org/ns/ttml#styling\"");
        fileContents += eol.after("xmlns:ttm=\"http://www.w3.org/ns/ttml#metadata\"");
        fileContents += eol.after("xmlns:smpte=\"http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt\"");
        fileContents += eol.after("xmlns:m608=\"http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608\"");
        fileContents += eol.after("xmlns:ttp=\"http://www.w3.org/ns/ttml#parameter\"");
        fileContents += eol.after(`ttp:timeBase=\"media\" ttp:frameRate=\"${options.frameRate}\" ttp:frameRateMultiplier=\"1000 1001\">`);
        fileContents += eol.after("<head>");
        fileContents += eol.after("<metadata>");
        fileContents += eol.after("<ttm:desc>Generated by Closed Caption Converter</ttm:desc>");
        fileContents += eol.after("<smpte:information");
        fileContents += eol.after("xmlns:m608=\"http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608\"");
        fileContents += eol.after("origin=\"http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608\"");
        fileContents += eol.after("mode=\"Preserved\" m608:channel=\"CC1\" m608:captionService=\"F1C1CC\"");
        fileContents += eol.after("/>");
        fileContents += eol.after("</metadata>");
        fileContents += eol.after("<styling>");
        fileContents += eol.after("<style xml:id='basic' tts:color='white' tts:backgroundColor='black' tts:fontFamily='monospace' tts:fontSize='80%'/>");
        fileContents += eol.after("</styling>");
        fileContents += eol.after("<layout>");
        fileContents += eol.after("<region xml:id='pop1' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>");
        fileContents += eol.after("<region xml:id='pop2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>");
        fileContents += eol.after("<region xml:id='pop3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>");
        fileContents += eol.after("<region xml:id='pop4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>");
        fileContents += eol.after("</layout>");
        fileContents += eol.after("</head>");
        fileContents += eol.after("<body>");
        fileContents += eol.after("<div>");

        for (const event_ of events) {
            for (let i = 0; i < event_.lines.length; i++) {
                const extent_ = getExtent_(event_.lines[i], options.window);

                const origin = (`${event_.lines[i].posX.toFixed(2)}% ${event_.lines[i].posY.toFixed(2)}%`);
                const extent = (`${extent_.x.toFixed(2)}% ${extent_.y.toFixed(2)}%`);

                fileContents += eol.after(`<p region='pop${i + 1}' style='basic' begin='${timecodeLib.secToTc(event_.start, options.frameRate)}' end='${timecodeLib.secToTc(event_.end, options.frameRate)}' tts:origin='${origin}' tts:extent='${extent}'>${event_.lines[i].text}</p>`);
            }
        }

        fileContents += eol.after("</div>");
        fileContents += eol.after("</body>");
        fileContents += eol.after("</tt>");

        var oParser = new DomParser();
        //fileContents = oParser.parseFromString(fileContents, "application/xml");
        // print the name of the root element or error message

        return fileContents;

       // return pd.pd.xml(fileContents);
    },

    preProcess: {
        encode: function (events) {
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode: function (fileContents) {
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    },

}

const getAttributeNS = function (elem, ns, name) {
    return elem.hasAttributeNS(ns, name) ? elem.getAttributeNS(ns, name) : 29.97;
}

const getLeafCues_ = function (element) {
    if (!element) {
        return [];
    }

    let ret = [];
    // Recursively find any child elements that have a 'begin' attribute.
    for (const child of element.childNodes) {
        if (child instanceof Element) {
            if (child.hasAttribute('begin')) {
                ret.push(child);
            } else {
                ret = ret.concat(getLeafCues_(child));
            }
        }
    }
    return ret;
}


const parseEvent_ = function (cueElement, rateInfo) {

    const event = new ccEvent();;

    const isTextContentEmpty = /^[\s\n]*$/.test(cueElement.textContent);

    const hasNoTimeAttributes = cueElement.nodeType == Node.ELEMENT_NODE &&
        !cueElement.hasAttribute('begin') &&
        !cueElement.hasAttribute('end');

    if (
        cueElement.nodeType != Node.ELEMENT_NODE ||
        (hasNoTimeAttributes && isTextContentEmpty) ||
        (hasNoTimeAttributes && !isNested)

    ) {
        return null;
    }


    let start = parseTime_(cueElement.getAttribute('begin'), rateInfo);
    let end = parseTime_(cueElement.getAttribute('end'), rateInfo);

    event.updateStart(start);
    event.updateEnd(end);

    let payload = '';

    payload = sanitizeTextContent_(
        cueElement,
        true,
    );

    event.insertLine({
        text: payload,
        posX: 0,
        posY: 0,
        bold: /<b>|<\/b>/.test(payload),
        italics: /<i>|<\/i>/.test(payload),
        underline: /<u>|<\/u>/.test(payload)
    });

    return event;
}



const parseTime_ = function (timecode, rateInfo) {
    let ret = null;

    if (timeColonFormatFrames_.test(timecode)) {
        ret = timecodeLib.parseColonTimeWithFrames_(timecode, rateInfo, timeColonFormatFrames_);
    }

    else if (timeColonFormat_.test(timecode)) {
        ret = timecodeLib.parseTimeFromRegex_(timecode, timeColonFormat_);
    }

    else if (timeColonFormatMilliseconds_.test(timecode)) {
        ret = timecodeLib.parseTimeFromRegex_(timecode, timeColonFormatMilliseconds_);
    }

    else if (timeFramesFormat_.test(timecode)) {
        ret = timecodeLib.parseFramesTime_(timecode, rateInfo, timeFramesFormat_);
    }

    else if (timeTickFormat_.test(timecode)) {
        ret = timecodeLib.parseTickTime_(timecode, rateInfo, timeTickFormat_);
    }

    else if (timeHMSFormat_.test(timecode)) {
        ret = timecodeLib.parseTimeFromRegex_(timecode, timeHMSFormat_);
    }

    return ret;
}

const sanitizeTextContent_ = function (element, whitespaceTrim) {
    let payload = '';

    for (const node of element.childNodes) {
        if (node.nodeName == 'br' && element.childNodes[0] !== node) {
            payload += '\n';
        } else if (node.childNodes && node.childNodes.length > 0) {
            payload += sanitizeTextContent_(
            /** @type {!Element} */(node),
                whitespaceTrim
            );
        } else if (whitespaceTrim) {
            // Trim leading and trailing whitespace.
            let trimmed = node.textContent.trim();
            // Collapse multiple spaces into one.
            trimmed = trimmed.replace(/\s+/g, ' ');

            payload += trimmed;
        } else {
            payload += node.textContent;
        }
    }

    return payload;
}

const checkPreviousEvent = function (previousEvent, currentEvent) {
    if (currentEvent.start === previousEvent.start &&
        currentEvent.end === previousEvent.end) {
        previousEvent.lines.push(...currentEvent.lines);
        return true;
    }
    return false;
}

const getExtent_ = function (line, window) {
    let lineLength = line.text.length;
    const extentX = (lineLength / window.columns) * 100;
    return { x: extentX, y: 5.33 };
}


class rateInfo_ {
    constructor(frameRate, subFrameRate, frameRateMultiplier, tickRate) {

        this.frameRate = Number(frameRate) || 30;
        this.subFrameRate = Number(subFrameRate) || 1;
        this.tickRate = Number(tickRate);
        if (this.tickRate == 0) {
            if (frameRate) {
                this.tickRate = this.frameRate * this.subFrameRate;
            } else {
                this.tickRate = 1;
            }
        }
        if (frameRateMultiplier) {
            const multiplierResults = /^(\d+) (\d+)$/g.exec(frameRateMultiplier);
            if (multiplierResults) {
                const numerator = Number(multiplierResults[1]);
                const denominator = Number(multiplierResults[2]);
                const multiplierNum = numerator / denominator;
                this.frameRate *= multiplierNum;
            }
        }
    }
}