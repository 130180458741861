const ccFunc = require('../ccFunc')
module.exports = {
    encodeText: function (captionLines, style) {
        var data = "";
        var encodeTime = 0;
        var sccHexCount = 0;
        var positionY = 0;
        var positionX = 0;
        var tabs = 0;        
        var italicsOn = false;
        var italicsOff = false;
        var underlineOn = false;
        var underlineOff = false;

        /* Remove blank caption lines */
        captionLines = captionLines.filter(captionLine =>{
            return captionLine.captionText.length > 0;
        });
        
        for (var j = 0; j < captionLines.length; j++) {
            if (style != "Roll-Up" || style != "Paint-On") {
                var tabs = captionLines[j].positionX % 4;
                var adjustedColumn = ccFunc.pad('00', captionLines[j].positionX - tabs, true);

                
                var tabover = "{TO" + ccFunc.pad('00', tabs, true) + "}"
                var totalLineLength = tabs + parseInt(adjustedColumn) + captionLines[j].captionText.length;

                if (totalLineLength >= 32){
                    tabs = 0;
                    adjustedColumn = "00";
                }

                var sccPosition = "{" + ccFunc.pad('00', captionLines[j].positionY, true) + "_" + adjustedColumn + "}";
                
                data += ccFunc.getKeyByValue(this.ccCharsetCh01, sccPosition) + " ";
                encodeTime++;

                if (tabs != 0) {
                    data += ccFunc.getKeyByValue(this.ccCharsetCh01, tabover) + " ";
                    encodeTime++;
                }
            }
            
            if (captionLines[j].underline && captionLines[j].italics) {
                data += "912f ";
                encodeTime++;
            } else if (captionLines[j].italics) {
                data += "91ae ";
                encodeTime++;
            } else if (captionLines[j].underline){
                data += "91a1 ";
                encodeTime++;
            } else if (captionLines[j].bold) {
                data += "91ae ";
                encodeTime++;
            }
            
            var captionTextArray = captionLines[j].captionText.trim().split('');
            for (var i = 0; i < captionTextArray.length; i++) {
                var captionValue = ccFunc.getKeyByValue(this.ccCharsetCh01, captionTextArray[i]) || "";
                if (this.specialCharacters[captionValue]) {
                    var captionValuePrime = ccFunc.getKeyByValue(this.ccCharsetCh01, this.specialCharacters[captionValue]);

                    if (captionValuePrime.length === 4 && sccHexCount % 4 !== 0) {
                        data += "80 " + captionValuePrime;
                        encodeTime += 1.5;
                        sccHexCount += 6; //6 = the 80 above plus the captionValue Length
                    } else {
                        data += captionValuePrime;
                        sccHexCount += captionValuePrime.length;
                        encodeTime += captionValuePrime.length / 4;
                    }

                    if (sccHexCount % 4 === 0) {
                        data += " ";
                    }
                }

                if (captionValue.length === 4 && sccHexCount % 4 !== 0) {
                    data += "80 " + captionValue;
                    encodeTime += 1.5;
                    sccHexCount += 6; //6 = the 80 above plus the captionValue Length
                } else {
                    data += captionValue;
                    sccHexCount += captionValue.length;
                    encodeTime += captionValue.length / 4;
                }

                if (sccHexCount % 4 === 0) {
                    data += " ";
                }
            }

            if (sccHexCount % 4 !== 0) {
                data += "80 ";
                encodeTime += 0.5;
            }

            sccHexCount = 0;
        }

        return {
            data: data.trim(),
            encodeTime: encodeTime
        }
    },
    ccCharset: {
            //Commands and Spacing 
            "94ae": "{ENM}", //Erase Non-displayed [buffer] Memory
            "1cae": "{ENM}", //CH02
            "15ae": "{ENM}", //CH03
            "9dae": "{ENM}", //CH04
            /*Clear the current screen of any displayed captions or text.*/
            "942c": "{EDM}", //Erase Displayed Memory
            "1c2c": "{EDM}", //CH02
            "152c": "{EDM}", //CH03
            "9d2c": "{EDM}", //CH04
            "80": "{FILLER}", //filler to time out the frames until the caption needs to be displayed
            "942f": "{EOC}", //display the caption in the buffer on the screen
            "1c2f": "{EOC}", //CH02
            "152f": "{EOC}", //CH03
            "9d2f": "{EOC}", //CH04
            "9420": "{RCL}", //Resume Caption Loading CH01
            "1c20": "{RCL}", //Resume Caption Loading CH02
            "1520": "{RCL}", //Resume Caption Loading CH03
            "9d20": "{RCL}", //Resume Caption Loading CH04
            "9429": "{RDC}", //Paint-on caption CH01
            "1c29": "{RDC}", //Paint-on caption CH02
            "1529": "{RDC}", //Paint-on caption CH03
            "9d29": "{RDC}", //Paint-on caption CH04
            "97a1": "{TO01}", //Tab Over
            "97a2": "{TO02}", //Tab Over 2
            "9723": "{TO03}", //Tab Over 3
            "1fa1": "{TO01}", //Tab Over
            "1fa2": "{TO02}", //Tab Over 2
            "1f23": "{TO03}", //Tab Over 3
            "94a1": "{BS}", //Backspace
            "1ca1": "{BS}", //Backspace
            "15a1": "{BS}", //Backspace
            "9da1": "{BS}", //Backspace
            "94a4": "{DER}", //Delete End of Row
            "1ca4": "{DER}", //Delete End of Row
            "15a4": "{DER}", //Delete End of Row
            "9da4": "{DER}", //Delete End of Row
            "94ad": "{CR}", //Carriage Return/New Line
            "1cad": "{CR}", //Carriage Return/New Line
            "15ad": "{CR}", //Carriage Return/New Line
            "9dad": "{CR}", //Carriage Return/New Line
            /*Starts a roll-up caption in rows 14 and 15, 
            unless followed by a PAC and/or TO 
            (which will establish the bottom row). 
            All following codes and characters will be 
            applied directly to the caption screen. 
            This command also clears the screen and buffer 
            of any pop-on or paint-on captions.*/
            "9425": "{RU2}", //CH01
            "1c25": "{RU2}", //CH02
            "1525": "{RU2}", //CH03
            "9d25": "{RU2}", //CH04
            /*Starts a roll-up caption in rows 13 through 15, 
            unless followed by a PAC and/or TO 
            (which will establish the bottom row). 
            All following codes and characters will be applied 
            directly to the caption screen. 
            This command also clears the screen and buffer of any 
            pop-on or paint-on captions.*/
            "9426": "{RU3}", //CH01
            "1c26": "{RU3}", //CH02
            "1526": "{RU3}", //CH03
            "9d26": "{RU3}", //CH04
            /*Starts a roll-up caption in rows 12 through 15, 
            unless followed by a PAC and/or TO 
            (which will establish the bottom row). 
            All following codes and characters will 
            be applied directly to the caption screen. 
            This command also clears the screen and buffer 
            of any pop-on or paint-on captions.*/
            "94a7": "{RU4}", //CH01
            "1ca7": "{RU4}", //CH02
            "15a7": "{RU4}", //CH03
            "9da7": "{RU4}", //CH04
            /*Starts a text message at the upper rightmost 
            position and clears the text screen. All following 
            codes and characters will be applied directly to 
            the text screen, which by default has an opaque 
            black background that blocks out the TV signal behind it.*/
            "942a": "{TR}", //CH01
            "1c2a": "{TR}", //CH02
            "152a": "{TR}", //CH03
            "9d2a": "{TR}", //CH04
            /*Switches to Text Mode to continue a text 
            message begun with {TR}.*/
            "94ab": "{RTD}", //CH01
            "1cab": "{RTD}", //CH02
            "15ab": "{RTD}", //CH03
            "9dab": "{RTD}", //CH04
            //Formating
            "9120": "{NOFORMAT}",
            "91ae": "{ITALICS}",
            "912f": "{ITALICS_UNDERLINE}",
            "94a8": "{FLASH}",
            "1ca8": "{FLASH}",
            "15a8": "{FLASH}",
            "9da8": "{FLASH}",
            "91a1": "{UNDERLINE}",
            "91a2": "{NOFORMAT}",
            "9123": "{UNDERLINE}",
            "91a4": "{NOFORMAT}",
            "9125": "{UNDERLINE}",
            "9126": "{NOFORMAT}",
            "91a7": "{UNDERLINE}",
            "91a8": "{NOFORMAT}",
            "9129": "{UNDERLINE}",
            "912a": "{NOFORMAT}",
            "91ab": "{UNDERLINE}",
            "912c": "{NOFORMAT}",
            "91ad": "{UNDERLINE}",
            "1920": "{NOFORMAT}",
            "19ae": "{ITALICS}",
            "192f": "{ITALICS_UNDERLINE}",
            "19a1": "{UNDERLINE}",
            "19a2": "{NOFORMAT}",
            "1923": "{UNDERLINE}",
            "19a4": "{NOFORMAT}",
            "1925": "{UNDERLINE}",
            "1926": "{NOFORMAT}",
            "19a7": "{UNDERLINE}",
            "19a8": "{NOFORMAT}",
            "1929": "{UNDERLINE}",
            "192a": "{NOFORMAT}",
            "19ab": "{UNDERLINE}",
            "192c": "{NOFORMAT}",
            "19ad": "{UNDERLINE}",
            "97ae": "{NOFORMAT}",
            "1fae": "{NOFORMAT}",
            "972f": "{UNDERLINE}",
            "1f2f": "{UNDERLINE}",
            /*Reserved Codes*/
            "9423": "",
            "1c23": "",
            "1523": "",
            "9d23": "",
            "94a2": "",
            "1ca2": "",
            "15a2": "",
            "9da2": "",
            "0000": "",
            /*Background Color Codes*/
            "1020": '',
            "10a1": '',
            "10a2": '',
            "1023": '',
            "10a4": '',
            "1025": '',
            "1026": '',
            "10a7": '',
            "10a8": '',
            "1029": '',
            "102a": '',
            "10ab": '',
            "102c": '',
            "10ad": '',
            "10ae": '',
            "102f": '',
            "97ad": '',
            "9820": '',
            "98a1": '',
            "98a2": '',
            "9823": '',
            "98a4": '',
            "9825": '',
            "9826": '',
            "98a7": '',
            "98a8": '',
            "9829": '',
            "982a": '',
            "98ab": '',
            "982c": '',
            "98ad": '',
            "98ae": '',
            "982f": '',
            "1fad": '',
            /*Closed Group Codes*/
            "97a4": '',
            "9725": '',
            "9726": '',
            "97a7": '',
            "97a8": '',
            "9729": '',
            "972a": '',
            "1fa4": '',
            "1f25": '',
            "1f26": '',
            "1fa7": '',
            "1fa8": '',
            "1f29": '',
            "1f2a": '',
            //PAC Position ROW_COLUMN
            //Ch01&03
            "9140": "{00_00}",
            "91c1": "{00_00}{UNDERLINE}",
            "91c2": "{00_00}",
            "9143": "{00_00}{UNDERLINE}",
            "91c4": "{00_00}",
            "9145": "{00_00}{UNDERLINE}",
            "9146": "{00_00}",
            "91c7": "{00_00}{UNDERLINE}",
            "91c8": "{00_00}",
            "9149": "{00_00}{UNDERLINE}",
            "914a": "{00_00}",
            "91cb": "{00_00}{UNDERLINE}",
            "914c": "{00_00}",
            "91cd": "{00_00}{UNDERLINE}",
            "91ce": "{00_00}{ITALICS}",
            "914f": "{00_00}{ITALICS_UNDERLINE}",
            "91d0": "{00_00}",
            "9151": "{00_00}{UNDERLINE}",
            "9152": "{00_04}",
            "91d3": "{00_04}{UNDERLINE}",
            "9154": "{00_08}",
            "91d5": "{00_08}{UNDERLINE}",
            "91d6": "{00_12}",
            "9157": "{00_12}{UNDERLINE}",
            "9158": "{00_16}",
            "91d9": "{00_16}{UNDERLINE}",
            "91da": "{00_20}",
            "915b": "{00_20}{UNDERLINE}",
            "91dc": "{00_24}",
            "915d": "{00_24}{UNDERLINE}",
            "915e": "{00_28}",
            "91df": "{00_28}{UNDERLINE}",
            "91e0": "{01_00}",
            "9161": "{01_00}{UNDERLINE}",
            "9162": "{01_00}",
            "91e3": "{01_00}{UNDERLINE}",
            "9164": "{01_00}",
            "91e5": "{01_00}{UNDERLINE}",
            "91e6": "{01_00}",
            "9167": "{01_00}{UNDERLINE}",
            "9168": "{01_00}",
            "91e9": "{01_00}{UNDERLINE}",
            "91ea": "{01_00}",
            "916b": "{01_00}{UNDERLINE}",
            "91ec": "{01_00}",
            "916d": "{01_00}{UNDERLINE}",
            "916e": "{01_00}",
            "91ef": "{01_00}{ITALICS_UNDERLINE}",
            "9170": "{01_00}",
            "91f1": "{01_00}{UNDERLINE}",
            "91f2": "{01_04}",
            "9173": "{01_04}{UNDERLINE}",
            "91f4": "{01_08}",
            "9175": "{01_08}{UNDERLINE}",
            "9176": "{01_12}",
            "91f7": "{01_12}{UNDERLINE}",
            "91f8": "{01_16}",
            "9179": "{01_16}{UNDERLINE}",
            "917a": "{01_20}",
            "91fb": "{01_20}{UNDERLINE}",
            "917c": "{01_24}",
            "917d": "{01_24}{UNDERLINE}",
            "91fe": "{01_28}",
            "917f": "{01_28}{UNDERLINE}",
            "9240": "{02_00}",
            "92c1": "{02_00}{UNDERLINE}",
            "92c2": "{02_00}",
            "9243": "{02_00}{UNDERLINE}",
            "92c4": "{02_00}",
            "9245": "{02_00}{UNDERLINE}",
            "9246": "{02_00}",
            "92c7": "{02_00}{UNDERLINE}",
            "92c8": "{02_00}",
            "9249": "{02_00}{UNDERLINE}",
            "924a": "{02_00}",
            "92cb": "{02_00}{UNDERLINE}",
            "924c": "{02_00}",
            "92cd": "{02_00}{UNDERLINE}",
            "92ce": "{02_00}",
            "924f": "{02_00}{UNDERLINE}",
            "92d0": "{02_00}",
            "9251": "{02_00}{UNDERLINE}",
            "9252": "{02_04}",
            "92d3": "{02_04}{UNDERLINE}",
            "9254": "{02_08}",
            "92d5": "{02_08}{UNDERLINE}",
            "92d6": "{02_12}",
            "9257": "{02_12}{UNDERLINE}",
            "9258": "{02_16}",
            "92d9": "{02_16}{UNDERLINE}",
            "92da": "{02_20}",
            "925b": "{02_20}{UNDERLINE}",
            "92dc": "{02_24}",
            "925d": "{02_24}{UNDERLINE}",
            "925e": "{02_28}",
            "92df": "{02_28}{UNDERLINE}",
            "92e0": "{03_00}",
            "9261": "{03_00}{UNDERLINE}",
            "9262": "{03_00}",
            "92e3": "{03_00}{UNDERLINE}",
            "9264": "{03_00}",
            "92e5": "{03_00}{UNDERLINE}",
            "92e6": "{03_00}",
            "9267": "{03_00}{UNDERLINE}",
            "9268": "{03_00}",
            "92e9": "{03_00}{UNDERLINE}",
            "92ea": "{03_00}",
            "926b": "{03_00}{UNDERLINE}",
            "92ec": "{03_00}",
            "926d": "{03_00}{UNDERLINE}",
            "926e": "{03_00}",
            "92ef": "{03_00}{UNDERLINE}",
            "9270": "{03_00}",
            "92f1": "{03_00}{UNDERLINE}",
            "92f2": "{03_04}",
            "9273": "{03_04}{UNDERLINE}",
            "92f4": "{03_08}",
            "9275": "{03_08}{UNDERLINE}",
            "9276": "{03_12}",
            "92f7": "{03_12}{UNDERLINE}",
            "92f8": "{03_16}",
            "9279": "{03_16}{UNDERLINE}",
            "927a": "{03_20}",
            "92fb": "{03_20}{UNDERLINE}",
            "927c": "{03_24}",
            "92fd": "{03_24}{UNDERLINE}",
            "92fe": "{03_28}",
            "927f": "{03_28}{UNDERLINE}",
            "1540": "{04_00}",
            "15c1": "{04_00}{UNDERLINE}",
            "15c2": "{04_00}",
            "1543": "{04_00}{UNDERLINE}",
            "15c4": "{04_00}",
            "1545": "{04_00}{UNDERLINE}",
            "1546": "{04_00}",
            "15c7": "{04_00}{UNDERLINE}",
            "15c8": "{04_00}",
            "1549": "{04_00}{UNDERLINE}",
            "154a": "{04_00}",
            "15cb": "{04_00}{UNDERLINE}",
            "154c": "{04_00}",
            "15cd": "{04_00}{UNDERLINE}",
            "15ce": "{04_00}",
            "154f": "{04_00}{UNDERLINE}",
            "15d0": "{04_00}",
            "1551": "{04_00}{UNDERLINE}",
            "1552": "{04_04}",
            "15d3": "{04_04}{UNDERLINE}",
            "1554": "{04_08}",
            "15d5": "{04_08}{UNDERLINE}",
            "15d6": "{04_12}",
            "1557": "{04_12}{UNDERLINE}",
            "1558": "{04_16}",
            "15d9": "{04_16}{UNDERLINE}",
            "15da": "{04_20}",
            "155b": "{04_20}{UNDERLINE}",
            "15dc": "{04_24}",
            "155d": "{04_24}{UNDERLINE}",
            "155e": "{04_28}",
            "15df": "{04_28}{UNDERLINE}",
            "15e0": "{05_00}",
            "1561": "{05_00}{UNDERLINE}",
            "1562": "{05_00}",
            "15e3": "{05_00}{UNDERLINE}",
            "1564": "{05_00}",
            "15e5": "{05_00}{UNDERLINE}",
            "15e6": "{05_00}",
            "1567": "{05_00}{UNDERLINE}",
            "1568": "{05_00}",
            "15e9": "{05_00}{UNDERLINE}",
            "15ea": "{05_00}",
            "156b": "{05_00}{UNDERLINE}",
            "15ec": "{05_00}",
            "156d": "{05_00}{UNDERLINE}",
            "156e": "{05_00}",
            "15ef": "{05_00}{UNDERLINE}",
            "1570": "{05_00}",
            "15f1": "{05_00}{UNDERLINE}",
            "15f2": "{05_04}",
            "1573": "{05_04}{UNDERLINE}",
            "15f4": "{05_08}",
            "1575": "{05_08}{UNDERLINE}",
            "1576": "{05_12}",
            "15f7": "{05_12}{UNDERLINE}",
            "15f8": "{05_16}",
            "1579": "{05_16}{UNDERLINE}",
            "157a": "{05_20}",
            "15fb": "{05_20}{UNDERLINE}",
            "157c": "{05_24}",
            "15fd": "{05_24}{UNDERLINE}",
            "15fe": "{05_28}",
            "157f": "{05_28}{UNDERLINE}",
            "1640": "{06_00}",
            "16c1": "{06_00}{UNDERLINE}",
            "16c2": "{06_00}",
            "1643": "{06_00}{UNDERLINE}",
            "16c4": "{06_00}",
            "1645": "{06_00}{UNDERLINE}",
            "1646": "{06_00}",
            "16c7": "{06_00}{UNDERLINE}",
            "16c8": "{06_00}",
            "1649": "{06_00}{UNDERLINE}",
            "164a": "{06_00}",
            "16cb": "{06_00}{UNDERLINE}",
            "164c": "{06_00}",
            "16cd": "{06_00}{UNDERLINE}",
            "16ce": "{06_00}",
            "164f": "{06_00}{UNDERLINE}",
            "16d0": "{06_00}",
            "1651": "{06_00}{UNDERLINE}",
            "1652": "{06_04}",
            "16d3": "{06_04}{UNDERLINE}",
            "1654": "{06_08}",
            "16d5": "{06_08}{UNDERLINE}",
            "16d6": "{06_12}",
            "1657": "{06_12}{UNDERLINE}",
            "1658": "{06_16}",
            "16d9": "{06_16}{UNDERLINE}",
            "16da": "{06_20}",
            "165b": "{06_20}{UNDERLINE}",
            "16dc": "{06_24}",
            "165d": "{06_24}{UNDERLINE}",
            "165e": "{06_28}",
            "16df": "{06_28}{UNDERLINE}",
            "16e0": "{07_00}",
            "1661": "{07_00}{UNDERLINE}",
            "1662": "{07_00}",
            "16e3": "{07_00}{UNDERLINE}",
            "1664": "{07_00}",
            "16e5": "{07_00}{UNDERLINE}",
            "16e6": "{07_00}",
            "1667": "{07_00}{UNDERLINE}",
            "1668": "{07_00}",
            "16e9": "{07_00}{UNDERLINE}",
            "16ea": "{07_00}",
            "166b": "{07_00}{UNDERLINE}",
            "16ec": "{07_00}",
            "166d": "{07_00}{UNDERLINE}",
            "166e": "{07_00}",
            "16ef": "{07_00}{UNDERLINE}",
            "1670": "{07_00}",
            "16f1": "{07_00}{UNDERLINE}",
            "16f2": "{07_04}",
            "1673": "{07_04}{UNDERLINE}",
            "16f4": "{07_08}",
            "1675": "{07_08}{UNDERLINE}",
            "1676": "{07_12}",
            "16f7": "{07_12}{UNDERLINE}",
            "16f8": "{07_16}",
            "1679": "{07_16}{UNDERLINE}",
            "167a": "{07_20}",
            "16fb": "{07_20}{UNDERLINE}",
            "167c": "{07_24}",
            "16fd": "{07_24}{UNDERLINE}",
            "16fe": "{07_28}",
            "167f": "{07_28}{UNDERLINE}",
            "9740": "{08_00}",
            "97c1": "{08_00}{UNDERLINE}",
            "97c2": "{08_00}",
            "9743": "{08_00}{UNDERLINE}",
            "97c4": "{08_00}",
            "9745": "{08_00}{UNDERLINE}",
            "9746": "{08_00}",
            "97c7": "{08_00}{UNDERLINE}",
            "97c8": "{08_00}",
            "9749": "{08_00}{UNDERLINE}",
            "974a": "{08_00}",
            "97cb": "{08_00}{UNDERLINE}",
            "974c": "{08_00}",
            "97cd": "{08_00}{UNDERLINE}",
            "97ce": "{08_00}{ITALICS}",
            "974f": "{08_00}{ITALICS_UNDERLINE}",
            "97d0": "{08_00}",
            "9751": "{08_00}{UNDERLINE}",
            "9752": "{08_04}",
            "97d3": "{08_04}{UNDERLINE}",
            "9754": "{08_08}",
            "97d5": "{08_08}{UNDERLINE}",
            "97d6": "{08_12}",
            "9757": "{08_12}{UNDERLINE}",
            "9758": "{08_16}",
            "97d9": "{08_16}{UNDERLINE}",
            "97da": "{08_20}",
            "975b": "{08_20}{UNDERLINE}",
            "97dc": "{08_24}",
            "975d": "{08_24}{UNDERLINE}",
            "975e": "{08_28}",
            "97df": "{08_28}{UNDERLINE}",
            "97e0": "{09_00}",
            "9761": "{09_00}{UNDERLINE}",
            "9762": "{09_00}",
            "97e3": "{09_00}{UNDERLINE}",
            "9764": "{09_00}",
            "97e5": "{09_00}{UNDERLINE}",
            "97e6": "{09_00}",
            "9767": "{09_00}{UNDERLINE}",
            "9768": "{09_00}",
            "97e9": "{09_00}{UNDERLINE}",
            "97ea": "{09_00}",
            "976b": "{09_00}{UNDERLINE}",
            "97ec": "{09_00}",
            "976d": "{09_00}{UNDERLINE}",
            "976e": "{09_00}{ITALICS}",
            "97ef": "{09_00}{ITALICS_UNDERLINE}",
            "9770": "{09_00}",
            "97f1": "{09_00}{UNDERLINE}",
            "97f2": "{09_04}",
            "9773": "{09_04}{UNDERLINE}",
            "97f4": "{09_08}",
            "9775": "{09_08}{UNDERLINE}",
            "9776": "{09_12}",
            "97f7": "{09_12}{UNDERLINE}",
            "97f8": "{09_16}",
            "9779": "{09_16}{UNDERLINE}",
            "977a": "{09_20}",
            "97fb": "{09_20}{UNDERLINE}",
            "977c": "{09_24}",
            "97fd": "{09_24}{UNDERLINE}",
            "97fe": "{09_28}",
            "977f": "{09_28}{UNDERLINE}",
            "1040": "{10_00}",
            "10c1": "{10_00}{UNDERLINE}",
            "10c2": "{10_00}",
            "1043": "{10_00}{UNDERLINE}",
            "10c4": "{10_00}",
            "1045": "{10_00}{UNDERLINE}",
            "1046": "{10_00}",
            "10c7": "{10_00}{UNDERLINE}",
            "10c8": "{10_00}",
            "1049": "{10_00}{UNDERLINE}",
            "104a": "{10_00}",
            "10cb": "{10_00}{UNDERLINE}",
            "104c": "{10_00}",
            "10cd": "{10_00}{UNDERLINE}",
            "10ce": "{10_00}{ITALICS}",
            "104f": "{10_00}{ITALICS_UNDERLINE}",
            "10d0": "{10_00}",
            "1051": "{10_00}UNDERLINE}",
            "1052": "{10_04}",
            "10d3": "{10_04}{UNDERLINE}",
            "1054": "{10_08}",
            "10d5": "{10_08}{UNDERLINE}",
            "10d6": "{10_12}",
            "1057": "{10_12}{UNDERLINE}",
            "1058": "{10_16}",
            "10d9": "{10_16}{UNDERLINE}",
            "10da": "{10_20}",
            "105b": "{10_20}{UNDERLINE}",
            "10dc": "{10_24}",
            "105d": "{10_24}{UNDERLINE}",
            "105e": "{10_28}",
            "10df": "{10_28}{UNDERLINE}",
            "1340": "{11_00}",
            "13c1": "{11_00}{UNDERLINE}",
            "13c2": "{11_00}",
            "1343": "{11_00}{UNDERLINE}",
            "13c4": "{11_00}",
            "1345": "{11_00}{UNDERLINE}",
            "1346": "{11_00}",
            "13c7": "{11_00}{UNDERLINE}",
            "13c8": "{11_00}",
            "1349": "{11_00}{UNDERLINE}",
            "134a": "{11_00}",
            "13cb": "{11_00}{UNDERLINE}",
            "134c": "{11_00}",
            "13cd": "{11_00}{UNDERLINE}",
            "13ce": "{11_00}{ITALICS}",
            "134f": "{11_00}{ITALICS_UNDERLINE}",
            "13d0": "{11_00}",
            "1351": "{11_00}{UNDERLINE}",
            "1352": "{11_04}",
            "13d3": "{11_04}{UNDERLINE}",
            "1354": "{11_08}",
            "13d5": "{11_08}{UNDERLINE}",
            "13d6": "{11_12}",
            "1357": "{11_12}{UNDERLINE}",
            "1358": "{11_16}",
            "13d9": "{11_16}{UNDERLINE}",
            "13da": "{11_20}",
            "135b": "{11_20}{UNDERLINE}",
            "13dc": "{11_24}",
            "135d": "{11_24}{UNDERLINE}",
            "135e": "{11_28}",
            "13df": "{11_28}{UNDERLINE}",
            "13e0": "{12_00}",
            "1361": "{12_00}{UNDERLINE}",
            "1362": "{12_00}",
            "13e3": "{12_00}{UNDERLINE}",
            "1364": "{12_00}",
            "13e5": "{12_00}{UNDERLINE}",
            "13e6": "{12_00}",
            "1367": "{12_00}{UNDERLINE}",
            "1368": "{12_00}",
            "13e9": "{12_00}{UNDERLINE}",
            "13ea": "{12_00}",
            "136b": "{12_00}{UNDERLINE}",
            "13ec": "{12_00}",
            "136d": "{12_00}{UNDERLINE}",
            "136e": "{12_00}{ITALICS}",
            "13ef": "{12_00}{ITALICS_UNDERLINE}",
            "1370": "{12_00}",
            "13f1": "{12_00}{UNDERLINE}",
            "13f2": "{12_04}",
            "1373": "{12_04}{UNDERLINE}",
            "13f4": "{12_08}",
            "1375": "{12_08}{UNDERLINE}",
            "1376": "{12_12}",
            "13f7": "{12_12}{UNDERLINE}",
            "13f8": "{12_16}",
            "1379": "{12_16}{UNDERLINE}",
            "137a": "{12_20}",
            "13fb": "{12_20}{UNDERLINE}",
            "13fc": "{12_24}",
            "13fd": "{12_24}{UNDERLINE}",
            "13fe": "{12_28}",
            "137f": "{12_28}{UNDERLINE}",
            "9440": "{13_00}",
            "94c1": "{13_00}{UNDERLINE}",
            "94c2": "{13_00}",
            "9443": "{13_00}{UNDERLINE}",
            "94c4": "{13_00}",
            "9445": "{13_00}{UNDERLINE}",
            "9446": "{13_00}",
            "94c7": "{13_00}{UNDERLINE}",
            "94c8": "{13_00}",
            "9449": "{13_00}{UNDERLINE}",
            "944a": "{13_00}",
            "94cb": "{13_00}{UNDERLINE}",
            "944c": "{13_00}",
            "94cd": "{13_00}{UNDERLINE}",
            "94ce": "{13_00}{ITALICS}",
            "944f": "{13_00}{ITALICS_UNDERLINE}",
            "94d0": "{13_00}",
            "9451": "{13_00}{UNDERLINE}",
            "9452": "{13_04}",
            "94d3": "{13_04}{UNDERLINE}",
            "9454": "{13_08}",
            "94d5": "{13_08}{UNDERLINE}",
            "94d6": "{13_12}",
            "9457": "{13_12}{UNDERLINE}",
            "9458": "{13_16}",
            "94d9": "{13_16}{UNDERLINE}",
            "94da": "{13_20}",
            "945b": "{13_20}{UNDERLINE}",
            "94dc": "{13_24}",
            "945d": "{13_24}{UNDERLINE}",
            "945e": "{13_28}",
            "94df": "{13_28}{UNDERLINE}",
            "94e0": "{14_00}",
            "9461": "{14_00}{UNDERLINE}",
            "9462": "{14_00}",
            "94e3": "{14_00}{UNDERLINE}",
            "9464": "{14_00}",
            "94e5": "{14_00}{UNDERLINE}",
            "94e6": "{14_00}",
            "9467": "{14_00}{UNDERLINE}",
            "9468": "{14_00}",
            "94e9": "{14_00}{UNDERLINE}",
            "94ea": "{14_00}",
            "946b": "{14_00}{UNDERLINE}",
            "94ec": "{14_00}",
            "946d": "{14_00}{UNDERLINE}",
            "946e": "{14_00}{ITALICS}",
            "94ef": "{14_00}{ITALICS_UNDERLINE}",
            "9470": "{14_00}",
            "94f1": "{14_00}{UNDERLINE}",
            "94f2": "{14_04}",
            "9473": "{14_04}{UNDERLINE}",
            "94f4": "{14_08}",
            "9475": "{14_08}{UNDERLINE}",
            "9476": "{14_12}",
            "94f7": "{14_12}{UNDERLINE}",
            "94f8": "{14_16}",
            "9479": "{14_16}{UNDERLINE}",
            "947a": "{14_20}",
            "94fb": "{14_20}{UNDERLINE}",
            "947c": "{14_24}",
            "94fd": "{14_24}{UNDERLINE}",
            "94fe": "{14_28}",
            "947f": "{14_28}{UNDERLINE}",
            //CH02 & 04
            "1940": "{00_00}",
            "19c1": "{00_00}{UNDERLINE}",
            "19c2": "{00_00}",
            "1943": "{00_00}{UNDERLINE}",
            "19c4": "{00_00}",
            "1945": "{00_00}{UNDERLINE}",
            "1946": "{00_00}",
            "19c7": "{00_00}{UNDERLINE}",
            "19c8": "{00_00}",
            "1949": "{00_00}{UNDERLINE}",
            "194a": "{00_00}",
            "19cb": "{00_00}{UNDERLINE}",
            "194c": "{00_00}",
            "19cd": "{00_00}{UNDERLINE}",
            "19ce": "{00_00}{ITALICS}",
            "194f": "{00_00}{ITALICS_UNDERLINE}",
            "19d0": "{00_00}",
            "1951": "{00_00}{UNDERLINE}",
            "1952": "{00_04}",
            "19d3": "{00_04}{UNDERLINE}",
            "1954": "{00_08}",
            "19d5": "{00_08}{UNDERLINE}",
            "19d6": "{00_12}",
            "1957": "{00_12}{UNDERLINE}",
            "1958": "{00_16}",
            "19d9": "{00_16}{UNDERLINE}",
            "19da": "{00_20}",
            "195b": "{00_20}{UNDERLINE}",
            "19dc": "{00_24}",
            "195d": "{00_24}{UNDERLINE}",
            "195e": "{00_28}",
            "19df": "{00_28}{UNDERLINE}",
            "19e0": "{01_00}",
            "1961": "{01_00}{UNDERLINE}",
            "1962": "{01_00}",
            "19e3": "{01_00}{UNDERLINE}",
            "1964": "{01_00}",
            "19e5": "{01_00}{UNDERLINE}",
            "19e6": "{01_00}",
            "1967": "{01_00}{UNDERLINE}",
            "1968": "{01_00}",
            "19e9": "{01_00}{UNDERLINE}",
            "19ea": "{01_00}",
            "196b": "{01_00}{UNDERLINE}",
            "19ec": "{01_00}",
            "196d": "{01_00}{UNDERLINE}",
            "196e": "{01_00}",
            "19ef": "{01_00}{ITALICS_UNDERLINE}",
            "1970": "{01_00}",
            "19f1": "{01_00}{UNDERLINE}",
            "19f2": "{01_04}",
            "1973": "{01_04}{UNDERLINE}",
            "19f4": "{01_08}",
            "1975": "{01_08}{UNDERLINE}",
            "1976": "{01_12}",
            "19f7": "{01_12}{UNDERLINE}",
            "19f8": "{01_16}",
            "1979": "{01_16}{UNDERLINE}",
            "197a": "{01_20}",
            "19fb": "{01_20}{UNDERLINE}",
            "19fc": "{01_24}",
            "19fd": "{01_24}{UNDERLINE}",
            "19fe": "{01_28}",
            "197f": "{01_28}{UNDERLINE}",
            "1a40": "{02_00}",
            "1ac1": "{02_00}{UNDERLINE}",
            "1ac2": "{02_00}",
            "1a43": "{02_00}{UNDERLINE}",
            "1ac4": "{02_00}",
            "1a45": "{02_00}{UNDERLINE}",
            "1a46": "{02_00}",
            "1ac7": "{02_00}{UNDERLINE}",
            "1ac8": "{02_00}",
            "1a49": "{02_00}{UNDERLINE}",
            "1a4a": "{02_00}",
            "1acb": "{02_00}{UNDERLINE}",
            "1a4c": "{02_00}",
            "1acd": "{02_00}{UNDERLINE}",
            "1ace": "{02_00}",
            "1a4f": "{02_00}{UNDERLINE}",
            "1ad0": "{02_00}",
            "1a51": "{02_00}{UNDERLINE}",
            "1a52": "{02_04}",
            "1ad3": "{02_04}{UNDERLINE}",
            "1a54": "{02_08}",
            "1ad5": "{02_08}{UNDERLINE}",
            "1ad6": "{02_12}",
            "1a57": "{02_12}{UNDERLINE}",
            "1a58": "{02_16}",
            "1ad9": "{02_16}{UNDERLINE}",
            "1ada": "{02_20}",
            "1a5b": "{02_20}{UNDERLINE}",
            "1adc": "{02_24}",
            "1a5d": "{02_24}{UNDERLINE}",
            "1a5e": "{02_28}",
            "1adf": "{02_28}{UNDERLINE}",
            "1ae0": "{03_00}",
            "1a61": "{03_00}{UNDERLINE}",
            "1a62": "{03_00}",
            "1ae3": "{03_00}{UNDERLINE}",
            "1a64": "{03_00}",
            "1ae5": "{03_00}{UNDERLINE}",
            "1ae6": "{03_00}",
            "1a67": "{03_00}{UNDERLINE}",
            "1a68": "{03_00}",
            "1ae9": "{03_00}{UNDERLINE}",
            "1aea": "{03_00}",
            "1a6b": "{03_00}{UNDERLINE}",
            "1aec": "{03_00}",
            "1a6d": "{03_00}{UNDERLINE}",
            "1a6e": "{03_00}",
            "1aef": "{03_00}{UNDERLINE}",
            "1a70": "{03_00}",
            "1af1": "{03_00}{UNDERLINE}",
            "1af2": "{03_04}",
            "1a73": "{03_04}{UNDERLINE}",
            "1af4": "{03_08}",
            "1a75": "{03_08}{UNDERLINE}",
            "1a76": "{03_12}",
            "1af7": "{03_12}{UNDERLINE}",
            "1af8": "{03_16}",
            "1a79": "{03_16}{UNDERLINE}",
            "1a7a": "{03_20}",
            "1afb": "{03_20}{UNDERLINE}",
            "1afc": "{03_24}",
            "1afd": "{03_24}{UNDERLINE}",
            "1afe": "{03_28}",
            "1a7f": "{03_28}{UNDERLINE}",
            "9d40": "{04_00}",
            "9dc1": "{04_00}{UNDERLINE}",
            "9dc2": "{04_00}",
            "9d43": "{04_00}{UNDERLINE}",
            "9dc4": "{04_00}",
            "9d45": "{04_00}{UNDERLINE}",
            "9d46": "{04_00}",
            "9dc7": "{04_00}{UNDERLINE}",
            "9dc8": "{04_00}",
            "9d49": "{04_00}{UNDERLINE}",
            "9d4a": "{04_00}",
            "9dcb": "{04_00}{UNDERLINE}",
            "9d4c": "{04_00}",
            "9dcd": "{04_00}{UNDERLINE}",
            "9dce": "{04_00}",
            "9d4f": "{04_00}{UNDERLINE}",
            "9dd0": "{04_00}",
            "9d51": "{04_00}{UNDERLINE}",
            "9d52": "{04_04}",
            "9dd3": "{04_04}{UNDERLINE}",
            "9d54": "{04_08}",
            "9dd5": "{04_08}{UNDERLINE}",
            "9dd6": "{04_12}",
            "9d57": "{04_12}{UNDERLINE}",
            "9d58": "{04_16}",
            "9dd9": "{04_16}{UNDERLINE}",
            "9dda": "{04_20}",
            "9d5b": "{04_20}{UNDERLINE}",
            "9ddc": "{04_24}",
            "9d5d": "{04_24}{UNDERLINE}",
            "9d5e": "{04_28}",
            "9ddf": "{04_28}{UNDERLINE}",
            "9de0": "{05_00}",
            "9d61": "{05_00}{UNDERLINE}",
            "9d62": "{05_00}",
            "9de3": "{05_00}{UNDERLINE}",
            "9d64": "{05_00}",
            "9de5": "{05_00}{UNDERLINE}",
            "9de6": "{05_00}",
            "9d67": "{05_00}{UNDERLINE}",
            "9d68": "{05_00}",
            "9de9": "{05_00}{UNDERLINE}",
            "9dea": "{05_00}",
            "9d6b": "{05_00}{UNDERLINE}",
            "9dec": "{05_00}",
            "9d6d": "{05_00}{UNDERLINE}",
            "9d6e": "{05_00}",
            "9def": "{05_00}{UNDERLINE}",
            "9d70": "{05_00}",
            "9df1": "{05_00}{UNDERLINE}",
            "9df2": "{05_04}",
            "9d73": "{05_04}{UNDERLINE}",
            "9df4": "{05_08}",
            "9d75": "{05_08}{UNDERLINE}",
            "9d76": "{05_12}",
            "9df7": "{05_12}{UNDERLINE}",
            "9df8": "{05_16}",
            "9d79": "{05_16}{UNDERLINE}",
            "9d7a": "{05_20}",
            "9dfb": "{05_20}{UNDERLINE}",
            "9dfc": "{05_24}",
            "9dfd": "{05_24}{UNDERLINE}",
            "9dfe": "{05_28}",
            "9d7f": "{05_28}{UNDERLINE}",
            "9e40": "{06_00}",
            "9ec1": "{06_00}{UNDERLINE}",
            "9ec2": "{06_00}",
            "9e43": "{06_00}{UNDERLINE}",
            "9ec4": "{06_00}",
            "9e45": "{06_00}{UNDERLINE}",
            "9e46": "{06_00}",
            "9ec7": "{06_00}{UNDERLINE}",
            "9ec8": "{06_00}",
            "9e49": "{06_00}{UNDERLINE}",
            "9e4a": "{06_00}",
            "9ecb": "{06_00}{UNDERLINE}",
            "9e4c": "{06_00}",
            "9ecd": "{06_00}{UNDERLINE}",
            "9ece": "{06_00}",
            "9e4f": "{06_00}{UNDERLINE}",
            "9ed0": "{06_00}",
            "9e51": "{06_00}{UNDERLINE}",
            "9e52": "{06_04}",
            "9ed3": "{06_04}{UNDERLINE}",
            "9e54": "{06_08}",
            "9ed5": "{06_08}{UNDERLINE}",
            "9ed6": "{06_12}",
            "9e57": "{06_12}{UNDERLINE}",
            "9e58": "{06_16}",
            "9ed9": "{06_16}{UNDERLINE}",
            "9eda": "{06_20}",
            "9e5b": "{06_20}{UNDERLINE}",
            "9edc": "{06_24}",
            "9e5d": "{06_24}{UNDERLINE}",
            "9e5e": "{06_28}",
            "9edf": "{06_28}{UNDERLINE}",
            "9ee0": "{07_00}",
            "9e61": "{07_00}{UNDERLINE}",
            "9e62": "{07_00}",
            "9ee3": "{07_00}{UNDERLINE}",
            "9e64": "{07_00}",
            "9ee5": "{07_00}{UNDERLINE}",
            "9ee6": "{07_00}",
            "9e67": "{07_00}{UNDERLINE}",
            "9e68": "{07_00}",
            "9ee9": "{07_00}{UNDERLINE}",
            "9eea": "{07_00}",
            "9e6b": "{07_00}{UNDERLINE}",
            "9eec": "{07_00}",
            "9e6d": "{07_00}{UNDERLINE}",
            "9e6e": "{07_00}",
            "9eef": "{07_00}{UNDERLINE}",
            "9e70": "{07_00}",
            "9ef1": "{07_00}{UNDERLINE}",
            "9ef2": "{07_04}",
            "9e73": "{07_04}{UNDERLINE}",
            "9ef4": "{07_08}",
            "9e75": "{07_08}{UNDERLINE}",
            "9e76": "{07_12}",
            "9ef7": "{07_12}{UNDERLINE}",
            "9ef8": "{07_16}",
            "9e79": "{07_16}{UNDERLINE}",
            "9e7a": "{07_20}",
            "9efb": "{07_20}{UNDERLINE}",
            "9efc": "{07_24}",
            "9efd": "{07_24}{UNDERLINE}",
            "9efe": "{07_28}",
            "9e7f": "{07_28}{UNDERLINE}",
            "1f40": "{08_00}",
            "1fc1": "{08_00}{UNDERLINE}",
            "1fc2": "{08_00}",
            "1f43": "{08_00}{UNDERLINE}",
            "1fc4": "{08_00}",
            "1f45": "{08_00}{UNDERLINE}",
            "1f46": "{08_00}",
            "1fc7": "{08_00}{UNDERLINE}",
            "1fc8": "{08_00}",
            "1f49": "{08_00}{UNDERLINE}",
            "1f4a": "{08_00}",
            "1fcb": "{08_00}{UNDERLINE}",
            "1f4c": "{08_00}",
            "1fcd": "{08_00}{UNDERLINE}",
            "1fce": "{08_00}{ITALICS}",
            "1f4f": "{08_00}{ITALICS_UNDERLINE}",
            "1fd0": "{08_00}",
            "1f51": "{08_00}{UNDERLINE}",
            "1f52": "{08_04}",
            "1fd3": "{08_04}{UNDERLINE}",
            "1f54": "{08_08}",
            "1fd5": "{08_08}{UNDERLINE}",
            "1fd6": "{08_12}",
            "1f57": "{08_12}{UNDERLINE}",
            "1f58": "{08_16}",
            "1fd9": "{08_16}{UNDERLINE}",
            "1fda": "{08_20}",
            "1f5b": "{08_20}{UNDERLINE}",
            "1fdc": "{08_24}",
            "1f5d": "{08_24}{UNDERLINE}",
            "1f5e": "{08_28}",
            "1fdf": "{08_28}{UNDERLINE}",
            "1fe0": "{09_00}",
            "1f61": "{09_00}{UNDERLINE}",
            "1f62": "{09_00}",
            "1fe3": "{09_00}{UNDERLINE}",
            "1f64": "{09_00}",
            "1fe5": "{09_00}{UNDERLINE}",
            "1fe6": "{09_00}",
            "1f67": "{09_00}{UNDERLINE}",
            "1f68": "{09_00}",
            "1fe9": "{09_00}{UNDERLINE}",
            "1fea": "{09_00}",
            "1f6b": "{09_00}{UNDERLINE}",
            "1fec": "{09_00}",
            "1f6d": "{09_00}{UNDERLINE}",
            "1f6e": "{09_00}{ITALICS}",
            "1fef": "{09_00}{ITALICS_UNDERLINE}",
            "1f70": "{09_00}",
            "1ff1": "{09_00}{UNDERLINE}",
            "1ff2": "{09_04}",
            "1f73": "{09_04}{UNDERLINE}",
            "1ff4": "{09_08}",
            "1f75": "{09_08}{UNDERLINE}",
            "1f76": "{09_12}",
            "1ff7": "{09_12}{UNDERLINE}",
            "1ff8": "{09_16}",
            "1f79": "{09_16}{UNDERLINE}",
            "1f7a": "{09_20}",
            "1ffb": "{09_20}{UNDERLINE}",
            "1ffc": "{09_24}",
            "1ffd": "{09_24}{UNDERLINE}",
            "1ffe": "{09_28}",
            "1f7f": "{09_28}{UNDERLINE}",
            "9840": "{10_00}",
            "98c1": "{10_00}{UNDERLINE}",
            "98c2": "{10_00}",
            "9843": "{10_00}{UNDERLINE}",
            "98c4": "{10_00}",
            "9845": "{10_00}{UNDERLINE}",
            "9846": "{10_00}",
            "98c7": "{10_00}{UNDERLINE}",
            "98c8": "{10_00}",
            "9849": "{10_00}{UNDERLINE}",
            "984a": "{10_00}",
            "98cb": "{10_00}{UNDERLINE}",
            "984c": "{10_00}",
            "98cd": "{10_00}{UNDERLINE}",
            "98ce": "{10_00}{ITALICS}",
            "984f": "{10_00}{ITALICS_UNDERLINE}",
            "98d0": "{10_00}",
            "9851": "{10_00}UNDERLINE}",
            "9852": "{10_04}",
            "98d3": "{10_04}{UNDERLINE}",
            "9854": "{10_08}",
            "98d5": "{10_08}{UNDERLINE}",
            "98d6": "{10_12}",
            "9857": "{10_12}{UNDERLINE}",
            "9858": "{10_16}",
            "98d9": "{10_16}{UNDERLINE}",
            "98da": "{10_20}",
            "985b": "{10_20}{UNDERLINE}",
            "98dc": "{10_24}",
            "985d": "{10_24}{UNDERLINE}",
            "985e": "{10_28}",
            "98df": "{10_28}{UNDERLINE}",
            "9b40": "{11_00}",
            "9bc1": "{11_00}{UNDERLINE}",
            "9bc2": "{11_00}",
            "9b43": "{11_00}{UNDERLINE}",
            "9bc4": "{11_00}",
            "9b45": "{11_00}{UNDERLINE}",
            "9b46": "{11_00}",
            "9bc7": "{11_00}{UNDERLINE}",
            "9bc8": "{11_00}",
            "9b49": "{11_00}{UNDERLINE}",
            "9b4a": "{11_00}",
            "9bcb": "{11_00}{UNDERLINE}",
            "9b4c": "{11_00}",
            "9bcd": "{11_00}{UNDERLINE}",
            "9bce": "{11_00}{ITALICS}",
            "9b4f": "{11_00}{ITALICS_UNDERLINE}",
            "9bd0": "{11_00}",
            "9b51": "{11_00}{UNDERLINE}",
            "9b52": "{11_04}",
            "9bd3": "{11_04}{UNDERLINE}",
            "9b54": "{11_08}",
            "9bd5": "{11_08}{UNDERLINE}",
            "9bd6": "{11_12}",
            "9b57": "{11_12}{UNDERLINE}",
            "9b58": "{11_16}",
            "9bd9": "{11_16}{UNDERLINE}",
            "9bda": "{11_20}",
            "9b5b": "{11_20}{UNDERLINE}",
            "9bdc": "{11_24}",
            "9b5d": "{11_24}{UNDERLINE}",
            "9b5e": "{11_28}",
            "9bdf": "{11_28}{UNDERLINE}",
            "9be0": "{12_00}",
            "9b61": "{12_00}{UNDERLINE}",
            "9b62": "{12_00}",
            "9be3": "{12_00}{UNDERLINE}",
            "9b64": "{12_00}",
            "9be5": "{12_00}{UNDERLINE}",
            "9be6": "{12_00}",
            "9b67": "{12_00}{UNDERLINE}",
            "9b68": "{12_00}",
            "9be9": "{12_00}{UNDERLINE}",
            "9bea": "{12_00}",
            "9b6b": "{12_00}{UNDERLINE}",
            "9bec": "{12_00}",
            "9b6d": "{12_00}{UNDERLINE}",
            "9b6e": "{12_00}{ITALICS}",
            "9bef": "{12_00}{ITALICS_UNDERLINE}",
            "9b70": "{12_00}",
            "9bf1": "{12_00}{UNDERLINE}",
            "9bf2": "{12_04}",
            "9b73": "{12_04}{UNDERLINE}",
            "9bf4": "{12_08}",
            "9b75": "{12_08}{UNDERLINE}",
            "9b76": "{12_12}",
            "9bf7": "{12_12}{UNDERLINE}",
            "9bf8": "{12_16}",
            "9b79": "{12_16}{UNDERLINE}",
            "9b7a": "{12_20}",
            "9bfb": "{12_20}{UNDERLINE}",
            "9bfc": "{12_24}",
            "9bfd": "{12_24}{UNDERLINE}",
            "9bfe": "{12_28}",
            "9b7f": "{12_28}{UNDERLINE}",
            "1c40": "{13_00}",
            "1cc1": "{13_00}{UNDERLINE}",
            "1cc2": "{13_00}",
            "1c43": "{13_00}{UNDERLINE}",
            "1cc4": "{13_00}",
            "1c45": "{13_00}{UNDERLINE}",
            "1c46": "{13_00}",
            "1cc7": "{13_00}{UNDERLINE}",
            "1cc8": "{13_00}",
            "1c49": "{13_00}{UNDERLINE}",
            "1c4a": "{13_00}",
            "1ccb": "{13_00}{UNDERLINE}",
            "1c4c": "{13_00}",
            "1ccd": "{13_00}{UNDERLINE}",
            "1cce": "{13_00}{ITALICS}",
            "1c4f": "{13_00}{ITALICS_UNDERLINE}",
            "1cd0": "{13_00}",
            "1c51": "{13_00}{UNDERLINE}",
            "1c52": "{13_04}",
            "1cd3": "{13_04}{UNDERLINE}",
            "1c54": "{13_08}",
            "1cd5": "{13_08}{UNDERLINE}",
            "1cd6": "{13_12}",
            "1c57": "{13_12}{UNDERLINE}",
            "1c58": "{13_16}",
            "1cd9": "{13_16}{UNDERLINE}",
            "1cda": "{13_20}",
            "1c5b": "{13_20}{UNDERLINE}",
            "1cdc": "{13_24}",
            "1c5d": "{13_24}{UNDERLINE}",
            "1c5e": "{13_28}",
            "1cdf": "{13_28}{UNDERLINE}",
            "1ce0": "{14_00}",
            "1c61": "{14_00}{UNDERLINE}",
            "1c62": "{14_00}",
            "1ce3": "{14_00}{UNDERLINE}",
            "1c64": "{14_00}",
            "1ce5": "{14_00}{UNDERLINE}",
            "1ce6": "{14_00}",
            "1c67": "{14_00}{UNDERLINE}",
            "1c68": "{14_00}",
            "1ce9": "{14_00}{UNDERLINE}",
            "1cea": "{14_00}",
            "1c6b": "{14_00}{UNDERLINE}",
            "1cec": "{14_00}",
            "1c6d": "{14_00}{UNDERLINE}",
            "1c6e": "{14_00}{ITALICS}",
            "1cef": "{14_00}{ITALICS_UNDERLINE}",
            "1c70": "{14_00}",
            "1cf1": "{14_00}{UNDERLINE}",
            "1cf2": "{14_04}",
            "1c73": "{14_04}{UNDERLINE}",
            "1cf4": "{14_08}",
            "1c75": "{14_08}{UNDERLINE}",
            "1c76": "{14_12}",
            "1cf7": "{14_12}{UNDERLINE}",
            "1cf8": "{14_16}",
            "1c79": "{14_16}{UNDERLINE}",
            "1c7a": "{14_20}",
            "1cfb": "{14_20}{UNDERLINE}",
            "1cfc": "{14_24}",
            "1cfd": "{14_24}{UNDERLINE}",
            "1cfe": "{14_28}",
            "1c7f": "{14_28}{UNDERLINE}",
            //Characters
            //Channels 1&3
            "91b0": "®",
            "9131": "°",
            "9132": "½",
            "91b3": "¿",
            "9134": "™",
            "91b5": "¢",
            "91b6": "£",
            "9137": "♪",
            "9138": "à",
            "91b9": " ", //transparent space
            "91ba": "è",
            "913b": "â",
            "91bc": "ê",
            "913d": "î",
            "913e": "ô",
            "91bf": "û",
            //Channels 2&4
            "19b0": "®",
            "1931": "°",
            "1932": "½",
            "19b3": "¿",
            "1934": "™",
            "19b5": "¢",
            "19b6": "£",
            "1937": "♪",
            "1938": "à",
            "19b9": " ", //transparent space
            "19ba": "è",
            "193b": "â",
            "19bc": "ê",
            "193d": "î",
            "193e": "ô",
            "19bf": "û",
            //Channels 1&3
            "9220": "Á",
            "92a1": "É",
            "92a2": "Ó",
            "9223": "Ú",
            "92a4": "Ü",
            "9225": "ü",
            "9226": "‘",
            "92a7": "¡",
            "92a8": "*",
            "9229": "’",
            "922a": "-",
            "92ab": "©",
            "922c": "℠",
            "92ad": "•",
            "92ae": '"',
            "922f": '"',
            "92b0": "À",
            "9231": "Â",
            "9232": "Ç",
            "92b3": "È",
            "9234": "Ê",
            "92b5": "Ë",
            "92b6": "ë",
            "9237": "Î",
            "9238": "Ï",
            "92b9": "ï",
            "92ba": "Ô",
            "923b": "Ù",
            "92bc": "ù",
            "923d": "Û",
            "923e": "«",
            "92bf": "»",
            //Channels 2&4
            "1a20": "Á",
            "1aa1": "É",
            "1aa2": "Ó",
            "1a23": "Ú",
            "1aa4": "Ü",
            "1a25": "ü",
            "1a26": "‘",
            "1aa7": "¡",
            "1aa8": "*",
            "1a29": "’",
            "1a2a": "-",
            "1aab": "©",
            "1a2c": "℠",
            "1aad": "•",
            "1aae": '"',
            "1a2f": '"',
            "1ab0": "À",
            "1a31": "Â",
            "1a32": "Ç",
            "1ab3": "È",
            "1a34": "Ê",
            "1ab5": "Ë",
            "1ab6": "ë",
            "1a37": "Î",
            "1a38": "Ï",
            "1ab9": "ï",
            "1aba": "Ô",
            "1a3b": "Ù",
            "1abc": "ù",
            "1a3d": "Û",
            "1a3e": "«",
            "1abf": "»",
            //Channels 1&3
            "1320": "Ã",
            "13a1": "ã",
            "13a2": "Í",
            "1323": "Ì",
            "13a4": "ì",
            "1325": "Ò",
            "1326": "ò",
            "13a7": "Õ",
            "13a8": "õ",
            "1329": "{",
            "132a": "}",
            "13ab": "\\",
            "132c": "^",
            "13ad": "_",
            "13ae": "¦",
            "132f": "~",
            "13b0": "Ä",
            "1331": "ä",
            "1332": "Ö",
            "13b3": "ö",
            "1334": "ß",
            "13b5": "¥",
            "13b6": "¤",
            "1337": "|",
            "1338": "Å",
            "13b9": "å",
            "13ba": "Ø",
            "133b": "ø",
            "133c": "┌",
            "133d": "┐",
            "133e": "└",
            "13bf": "┘",
            //Channel 2&4
            "9b20": "Ã",
            "9ba1": "ã",
            "9ba2": "Í",
            "9b23": "Ì",
            "9ba4": "ì",
            "9b25": "Ò",
            "9b26": "ò",
            "9ba7": "Õ",
            "9ba8": "õ",
            "9b29": "{",
            "9b2a": "}",
            "9bab": "\\",
            "9b2c": "^",
            "9bad": "_",
            "9bae": "¦",
            "9b2f": "~",
            "9bb0": "Ä",
            "9b31": "ä",
            "9b32": "Ö",
            "9bb3": "ö",
            "9b34": "ß",
            "9bb5": "¥",
            "9bb6": "¤",
            "9b37": "|",
            "9b38": "Å",
            "9bb9": "å",
            "9bba": "Ø",
            "9b3b": "ø",
            "9b3c": "┌",
            "9b3d": "┐",
            "9b3e": "└",
            "9bbf": "┘",
            "80": "",
            "00": "",
            "20": " ",
            "a1": "!",
            "a2": '"',
            "23": "#",
            "a4": "$",
            "25": "%",
            "26": "&",
            "a7": "'",
            "a8": "(",
            "29": ")",
            "2a": "á",
            "ab": "+",
            "2c": ",",
            "ad": "-",
            "ae": ".",
            "2f": "/",
            "b0": "0",
            "31": "1",
            "32": "2",
            "b3": "3",
            "34": "4",
            "b5": "5",
            "b6": "6",
            "37": "7",
            "38": "8",
            "b9": "9",
            "ba": ":",
            "3b": ";",
            "bc": "<",
            "3d": "=",
            "3e": ">",
            "bf": "?",
            "40": "@",
            "c1": "A",
            "c2": "B",
            "43": "C",
            "c4": "D",
            "45": "E",
            "46": "F",
            "c7": "G",
            "c8": "H",
            "49": "I",
            "4a": "J",
            "cb": "K",
            "4c": "L",
            "cd": "M",
            "ce": "N",
            "4f": "O",
            "d0": "P",
            "51": "Q",
            "52": "R",
            "d3": "S",
            "54": "T",
            "d5": "U",
            "d6": "V",
            "57": "W",
            "58": "X",
            "d9": "Y",
            "da": "Z",
            "5b": "[",
            "dc": "é",
            "5d": "]",
            "5e": "í",
            "df": "ó",
            "e0": "ú",
            "61": "a",
            "62": "b",
            "e3": "c",
            "64": "d",
            "e5": "e",
            "e6": "f",
            "67": "g",
            "68": "h",
            "e9": "i",
            "ea": "j",
            "6b": "k",
            "ec": "l",
            "6d": "m",
            "6e": "n",
            "ef": "o",
            "70": "p",
            "f1": "q",
            "f2": "r",
            "73": "s",
            "f4": "t",
            "75": "u",
            "76": "v",
            "f7": "w",
            "f8": "x",
            "79": "y",
            "7a": "z",
            "fb": "ç",
            "7c": "÷",
            "fd": "Ñ",
            "fe": "ñ",
            "7f": "█"
        },
        /* EXPORT LIBRARY */
        ccCharsetCh01: {
            //Commands and Spacing
            "94ae": "{ENM}", //Erase Non-displayed [buffer] Memory
            /*Clear the current screen of any displayed captions or text.*/
            "942c": "{EDM}", //Erase Displayed Memory
            "8080": "{FILLER}", //filler to time out the frames until the caption needs to be displayed
            "942f": "{EOC}", //display the caption in the buffer on the screen
            "9420": "{RCL}", //Resume Caption Loading CH01
            "9429": "{RDC}", //Paint-on caption CH01
            "97a1": "{TO01}", //Tab Over
            "97a2": "{TO02}", //Tab Over 2
            "9723": "{TO03}", //Tab Over 3
            "94a1": "{BS}", //Backspace
            "94a4": "{DER}", //Delete End of Row
            "94ad": "{CR}", //Carriage Return/New Line
            /*Starts a roll-up caption in rows 14 and 15, 
            unless followed by a PAC and/or TO 
            (which will establish the bottom row). 
            All following codes and characters will be 
            applied directly to the caption screen. 
            This command also clears the screen and buffer 
            of any pop-on or paint-on captions.*/
            "9425": "{RU2}", //CH01
            /*Starts a roll-up caption in rows 13 through 15, 
            unless followed by a PAC and/or TO 
            (which will establish the bottom row). 
            All following codes and characters will be applied 
            directly to the caption screen. 
            This command also clears the screen and buffer of any 
            pop-on or paint-on captions.*/
            "9426": "{RU3}", //CH01
            /*Starts a roll-up caption in rows 12 through 15, 
            unless followed by a PAC and/or TO 
            (which will establish the bottom row). 
            All following codes and characters will 
            be applied directly to the caption screen. 
            This command also clears the screen and buffer 
            of any pop-on or paint-on captions.*/
            "94a7": "{RU4}", //CH01
            /*Starts a text message at the upper rightmost 
            position and clears the text screen. All following 
            codes and characters will be applied directly to 
            the text screen, which by default has an opaque 
            black background that blocks out the TV signal behind it.*/
            "942a": "{TR}", //CH01
            /*Switches to Text Mode to continue a text 
            message begun with {TR}.*/
            "94ab": "{RTD}", //CH01
            //Formating
            "9120": "{NOFORMAT}",
            "91ae": "{ITALICS}",
            "912f": "{ITALICS_UNDERLINE}",
            "94a8": "{FLASH}",
            "91a1": "{UNDERLINE}",
            //PAC Position ROW_COLUMN
            //Ch01&03
            "9140": "{00_00}",
            "91c1": "{00_00}{UNDERLINE}",
            "91ce": "{00_00}{ITALICS}",
            "914f": "{00_00}{ITALICS_UNDERLINE}",
            "9152": "{00_04}",
            "91d3": "{00_04}{UNDERLINE}",
            "9154": "{00_08}",
            "91d5": "{00_08}{UNDERLINE}",
            "91d6": "{00_12}",
            "9157": "{00_12}{UNDERLINE}",
            "9158": "{00_16}",
            "91d9": "{00_16}{UNDERLINE}",
            "91da": "{00_20}",
            "915b": "{00_20}{UNDERLINE}",
            "91dc": "{00_24}",
            "915d": "{00_24}{UNDERLINE}",
            "915e": "{00_28}",
            "91df": "{00_28}{UNDERLINE}",
            "91e0": "{01_00}",
            "9161": "{01_00}{UNDERLINE}",
            "91ef": "{01_00}{ITALICS_UNDERLINE}",
            "91f2": "{01_04}",
            "9173": "{01_04}{UNDERLINE}",
            "91f4": "{01_08}",
            "9175": "{01_08}{UNDERLINE}",
            "9176": "{01_12}",
            "91f7": "{01_12}{UNDERLINE}",
            "91f8": "{01_16}",
            "9179": "{01_16}{UNDERLINE}",
            "917a": "{01_20}",
            "91fb": "{01_20}{UNDERLINE}",
            "917c": "{01_24}",
            "917d": "{01_24}{UNDERLINE}",
            "91fe": "{01_28}",
            "917f": "{01_28}{UNDERLINE}",
            "9240": "{02_00}",
            "92c1": "{02_00}{UNDERLINE}",
            "9252": "{02_04}",
            "92d3": "{02_04}{UNDERLINE}",
            "9254": "{02_08}",
            "92d5": "{02_08}{UNDERLINE}",
            "92d6": "{02_12}",
            "9257": "{02_12}{UNDERLINE}",
            "9258": "{02_16}",
            "92d9": "{02_16}{UNDERLINE}",
            "92da": "{02_20}",
            "925b": "{02_20}{UNDERLINE}",
            "92dc": "{02_24}",
            "925d": "{02_24}{UNDERLINE}",
            "925e": "{02_28}",
            "92df": "{02_28}{UNDERLINE}",
            "92e0": "{03_00}",
            "9261": "{03_00}{UNDERLINE}",
            "92f2": "{03_04}",
            "9273": "{03_04}{UNDERLINE}",
            "92f4": "{03_08}",
            "9275": "{03_08}{UNDERLINE}",
            "9276": "{03_12}",
            "92f7": "{03_12}{UNDERLINE}",
            "92f8": "{03_16}",
            "9279": "{03_16}{UNDERLINE}",
            "927a": "{03_20}",
            "92fb": "{03_20}{UNDERLINE}",
            "927c": "{03_24}",
            "92fd": "{03_24}{UNDERLINE}",
            "92fe": "{03_28}",
            "927f": "{03_28}{UNDERLINE}",
            "1540": "{04_00}",
            "15c1": "{04_00}{UNDERLINE}",
            "1552": "{04_04}",
            "15d3": "{04_04}{UNDERLINE}",
            "1554": "{04_08}",
            "15d5": "{04_08}{UNDERLINE}",
            "15d6": "{04_12}",
            "1557": "{04_12}{UNDERLINE}",
            "1558": "{04_16}",
            "15d9": "{04_16}{UNDERLINE}",
            "15da": "{04_20}",
            "155b": "{04_20}{UNDERLINE}",
            "15dc": "{04_24}",
            "155d": "{04_24}{UNDERLINE}",
            "155e": "{04_28}",
            "15df": "{04_28}{UNDERLINE}",
            "15e0": "{05_00}",
            "1561": "{05_00}{UNDERLINE}",
            "15f2": "{05_04}",
            "1573": "{05_04}{UNDERLINE}",
            "15f4": "{05_08}",
            "1575": "{05_08}{UNDERLINE}",
            "1576": "{05_12}",
            "15f7": "{05_12}{UNDERLINE}",
            "15f8": "{05_16}",
            "1579": "{05_16}{UNDERLINE}",
            "157a": "{05_20}",
            "15fb": "{05_20}{UNDERLINE}",
            "157c": "{05_24}",
            "15fd": "{05_24}{UNDERLINE}",
            "15fe": "{05_28}",
            "157f": "{05_28}{UNDERLINE}",
            "1640": "{06_00}",
            "16c1": "{06_00}{UNDERLINE}",
            "1652": "{06_04}",
            "16d3": "{06_04}{UNDERLINE}",
            "1654": "{06_08}",
            "16d5": "{06_08}{UNDERLINE}",
            "16d6": "{06_12}",
            "1657": "{06_12}{UNDERLINE}",
            "1658": "{06_16}",
            "16d9": "{06_16}{UNDERLINE}",
            "16da": "{06_20}",
            "165b": "{06_20}{UNDERLINE}",
            "16dc": "{06_24}",
            "165d": "{06_24}{UNDERLINE}",
            "165e": "{06_28}",
            "16df": "{06_28}{UNDERLINE}",
            "16e0": "{07_00}",
            "1661": "{07_00}{UNDERLINE}",
            "16f2": "{07_04}",
            "1673": "{07_04}{UNDERLINE}",
            "16f4": "{07_08}",
            "1675": "{07_08}{UNDERLINE}",
            "1676": "{07_12}",
            "16f7": "{07_12}{UNDERLINE}",
            "16f8": "{07_16}",
            "1679": "{07_16}{UNDERLINE}",
            "167a": "{07_20}",
            "16fb": "{07_20}{UNDERLINE}",
            "167c": "{07_24}",
            "16fd": "{07_24}{UNDERLINE}",
            "16fe": "{07_28}",
            "167f": "{07_28}{UNDERLINE}",
            "9740": "{08_00}",
            "97c1": "{08_00}{UNDERLINE}",
            "9752": "{08_04}",
            "97d3": "{08_04}{UNDERLINE}",
            "9754": "{08_08}",
            "97d5": "{08_08}{UNDERLINE}",
            "97d6": "{08_12}",
            "9757": "{08_12}{UNDERLINE}",
            "9758": "{08_16}",
            "97d9": "{08_16}{UNDERLINE}",
            "97da": "{08_20}",
            "975b": "{08_20}{UNDERLINE}",
            "97dc": "{08_24}",
            "975d": "{08_24}{UNDERLINE}",
            "975e": "{08_28}",
            "97df": "{08_28}{UNDERLINE}",
            "97e0": "{09_00}",
            "9761": "{09_00}{UNDERLINE}",
            "976e": "{09_00}{ITALICS}",
            "97ef": "{09_00}{ITALICS_UNDERLINE}",
            "97f2": "{09_04}",
            "9773": "{09_04}{UNDERLINE}",
            "97f4": "{09_08}",
            "9775": "{09_08}{UNDERLINE}",
            "9776": "{09_12}",
            "97f7": "{09_12}{UNDERLINE}",
            "97f8": "{09_16}",
            "9779": "{09_16}{UNDERLINE}",
            "977a": "{09_20}",
            "97fb": "{09_20}{UNDERLINE}",
            "977c": "{09_24}",
            "97fd": "{09_24}{UNDERLINE}",
            "97fe": "{09_28}",
            "977f": "{09_28}{UNDERLINE}",
            "10d0": "{10_00}",
            "10c1": "{10_00}{UNDERLINE}",
            "10ce": "{10_00}{ITALICS}",
            "104f": "{10_00}{ITALICS_UNDERLINE}",
            "1052": "{10_04}",
            "10d3": "{10_04}{UNDERLINE}",
            "1054": "{10_08}",
            "10d5": "{10_08}{UNDERLINE}",
            "10d6": "{10_12}",
            "1057": "{10_12}{UNDERLINE}",
            "1058": "{10_16}",
            "10d9": "{10_16}{UNDERLINE}",
            "10da": "{10_20}",
            "105b": "{10_20}{UNDERLINE}",
            "10dc": "{10_24}",
            "105d": "{10_24}{UNDERLINE}",
            "105e": "{10_28}",
            "10df": "{10_28}{UNDERLINE}",
            "1340": "{11_00}",
            "13c1": "{11_00}{UNDERLINE}",
            "13ce": "{11_00}{ITALICS}",
            "134f": "{11_00}{ITALICS_UNDERLINE}",
            "1352": "{11_04}",
            "13d3": "{11_04}{UNDERLINE}",
            "1354": "{11_08}",
            "13d5": "{11_08}{UNDERLINE}",
            "13d6": "{11_12}",
            "1357": "{11_12}{UNDERLINE}",
            "1358": "{11_16}",
            "13d9": "{11_16}{UNDERLINE}",
            "13da": "{11_20}",
            "135b": "{11_20}{UNDERLINE}",
            "13dc": "{11_24}",
            "135d": "{11_24}{UNDERLINE}",
            "135e": "{11_28}",
            "13df": "{11_28}{UNDERLINE}",
            "13e0": "{12_00}",
            "1361": "{12_00}{UNDERLINE}",
            "136e": "{12_00}{ITALICS}",
            "13ef": "{12_00}{ITALICS_UNDERLINE}",
            "13f2": "{12_04}",
            "1373": "{12_04}{UNDERLINE}",
            "13f4": "{12_08}",
            "1375": "{12_08}{UNDERLINE}",
            "1376": "{12_12}",
            "13f7": "{12_12}{UNDERLINE}",
            "13f8": "{12_16}",
            "1379": "{12_16}{UNDERLINE}",
            "137a": "{12_20}",
            "13fb": "{12_20}{UNDERLINE}",
            "137c": "{12_24}",
            "13fd": "{12_24}{UNDERLINE}",
            "13fe": "{12_28}",
            "137f": "{12_28}{UNDERLINE}",
            "9440": "{13_00}",
            "94c1": "{13_00}{UNDERLINE}",
            "94ce": "{13_00}{ITALICS}",
            "944f": "{13_00}{ITALICS_UNDERLINE}",
            "9452": "{13_04}",
            "94d3": "{13_04}{UNDERLINE}",
            "9454": "{13_08}",
            "94d5": "{13_08}{UNDERLINE}",
            "94d6": "{13_12}",
            "9457": "{13_12}{UNDERLINE}",
            "9458": "{13_16}",
            "94d9": "{13_16}{UNDERLINE}",
            "94da": "{13_20}",
            "945b": "{13_20}{UNDERLINE}",
            "94dc": "{13_24}",
            "945d": "{13_24}{UNDERLINE}",
            "945e": "{13_28}",
            "94df": "{13_28}{UNDERLINE}",
            "94e0": "{14_00}",
            "9461": "{14_00}{UNDERLINE}",
            "946e": "{14_00}{ITALICS}",
            "94ef": "{14_00}{ITALICS_UNDERLINE}",
            "94f2": "{14_04}",
            "9473": "{14_04}{UNDERLINE}",
            "94f4": "{14_08}",
            "9475": "{14_08}{UNDERLINE}",
            "9476": "{14_12}",
            "94f7": "{14_12}{UNDERLINE}",
            "94f8": "{14_16}",
            "9479": "{14_16}{UNDERLINE}",
            "947a": "{14_20}",
            "94fb": "{14_20}{UNDERLINE}",
            "947c": "{14_24}",
            "94fd": "{14_24}{UNDERLINE}",
            "94fe": "{14_28}",
            "947f": "{14_28}{UNDERLINE}",
            //Characters
            //Channels 1&3
            "91b0": "®",
            "9131": "°",
            "9132": "½",
            "91b3": "¿",
            "9134": "™",
            "91b5": "¢",
            "91b6": "£",
            "9137": "♪",
            "9138": "à",
            "91b9": " ", //transparent space
            "91ba": "è",
            "913b": "â",
            "91bc": "ê",
            "913d": "î",
            "913e": "ô",
            "91bf": "û",
            //Channels 1&3
            "9220": "Á",
            "92a1": "É",
            "92a2": "Ó",
            "9223": "Ú",
            "92a4": "Ü",
            "9225": "ü",
            "9226": "‘",
            "92a7": "¡",
            "92a8": "*",
            "a7": '’',
            "a7": "’",
            "a2": "“",
            "a7": "’",
            "a2": "”",
            "ad": "-",
            "ad": "—",
            "92ab": "©",
            "922c": "℠",
            "92ad": "•",
            "a2": '"',
            "a2": '"',
            "92b0": "À",
            "9231": "Â",
            "9232": "Ç",
            "92b3": "È",
            "9234": "Ê",
            "92b5": "Ë",
            "92b6": "ë",
            "9237": "Î",
            "9238": "Ï",
            "92b9": "ï",
            "92ba": "Ô",
            "923b": "Ù",
            "92bc": "ù",
            "923d": "Û",
            "923e": "«",
            "92bf": "»",
            //Channels 1&3
            "1320": "Ã",
            "13a1": "ã",
            "13a2": "Í",
            "1323": "Ì",
            "13a4": "ì",
            "1325": "Ò",
            "1326": "ò",
            "13a7": "Õ",
            "13a8": "õ",
            "1329": "{",
            "132a": "}",
            "13ab": "\\",
            "132c": "^",
            "13ad": "_",
            "13ae": "¦",
            "132f": "~",
            "13b0": "Ä",
            "1331": "ä",
            "1332": "Ö",
            "13b3": "ö",
            "1334": "ß",
            "13b5": "¥",
            "13b6": "¤",
            "1337": "|",
            "1338": "Å",
            "13b9": "å",
            "13ba": "Ø",
            "133b": "ø",
            "133c": "┌",
            "133d": "┐",
            "133e": "└",
            "13bf": "┘",
            //All Channels
            "80": "",
            "20": " ",
            "a1": "!",
            "a2": '"',
            "23": "#",
            "a4": "$",
            "25": "%",
            "26": "&",
            "a7": "'",
            "a8": "(",
            "29": ")",
            "2a": "á",
            "ab": "+",
            "2c": ",",
            "ad": "-",
            "ae": ".",
            "2f": "/",
            "b0": "0",
            "31": "1",
            "32": "2",
            "b3": "3",
            "34": "4",
            "b5": "5",
            "b6": "6",
            "37": "7",
            "38": "8",
            "b9": "9",
            "ba": ":",
            "3b": ";",
            "bc": "<",
            "3d": "=",
            "3e": ">",
            "bf": "?",
            "40": "@",
            "c1": "A",
            "c2": "B",
            "43": "C",
            "c4": "D",
            "45": "E",
            "46": "F",
            "c7": "G",
            "c8": "H",
            "49": "I",
            "4a": "J",
            "cb": "K",
            "4c": "L",
            "cd": "M",
            "ce": "N",
            "4f": "O",
            "d0": "P",
            "51": "Q",
            "52": "R",
            "d3": "S",
            "54": "T",
            "d5": "U",
            "d6": "V",
            "57": "W",
            "58": "X",
            "d9": "Y",
            "da": "Z",
            "5b": "[",
            "dc": "é",
            "5d": "]",
            "5e": "í",
            "df": "ó",
            "e0": "ú",
            "61": "a",
            "62": "b",
            "e3": "c",
            "64": "d",
            "e5": "e",
            "e6": "f",
            "67": "g",
            "68": "h",
            "e9": "i",
            "ea": "j",
            "6b": "k",
            "ec": "l",
            "6d": "m",
            "6e": "n",
            "ef": "o",
            "70": "p",
            "f1": "q",
            "f2": "r",
            "73": "s",
            "f4": "t",
            "75": "u",
            "76": "v",
            "f7": "w",
            "f8": "x",
            "79": "y",
            "7a": "z",
            "fb": "ç",
            "7c": "÷",
            "fd": "Ñ",
            "fe": "ñ",
            "7f": "█"
        },
        specialCharacters: {
            "9220": "A",
            "92a1": "E",
            "92a2": "O",
            "9223": "U",
            "92a4": "U",
            "9225": "u",
            "92b0": "A",
            "9231": "A",
            "9232": "C",
            "92b3": "E",
            "9234": "E",
            "92b5": "E",
            "92b6": "e",
            "9237": "I",
            "9238": "I",
            "92b9": "I",
            "92ba": "O",
            "923b": "U",
            "92bc": "u",
            "923d": "U",
            "1320": "A",
            "13a1": "a",
            "13a2": "I",
            "1323": "I",
            "13a4": "I",
            "1325": "O",
            "1326": "o",
            "13a7": "O",
            "13a8": "o",
            "13b0": "A",
            "1331": "a",
            "1332": "O",
            "13b3": "o",
            "1338": "A",
            "13b9": "a",
        },
};