const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");

module.exports = {
    decode: function (fileContents, options) {
        let events = [];
        let subtitleEvent;
        let subtitleBlocks = fileContents.split("\n\n");
        subtitleBlocks.forEach(block => {
            let tcFlag = false;
            block.split("\n").forEach(line => {
                let lineArray = line.split(" ");
                
                if (/\d\d.\d\d.\d\d.\d\d/g.test(lineArray[0]) && /\d\d.\d\d.\d\d.\d\d/g.test(lineArray[1]) && !tcFlag) {
                    tcFlag = true;
                    subtitleEvent = new ccEvent();;
                    subtitleEvent.start = timecodeLib.tcToSec(lineArray[0].replace(/;/g, ":"), options.frameRate);
                    subtitleEvent.end = timecodeLib.tcToSec(lineArray[1].replace(/;/g, ":"), options.frameRate);
                } else if (tcFlag) {
                    subtitleEvent.insertLine({
                        text: line,
                        posX: 0,
                        posY: 0,
                        bold: false,
                        italics: false,
                        underline: false
                    });
                }
            });

            if (subtitleEvent) {
                subtitleEvent.syncSubtitleText();
                subtitleEvent.alignTextCenter();
                events.push(subtitleEvent);
            }
        });
        return events;
    },

    encode: function (events, options) {
        let fileContents = "@ This file written with CC Converter\n\n";
        fileContents += "<begin subtitles>\n";
        events.forEach(event => {
            fileContents += timecodeLib.secToTc(event.start, options.frameRate).replace(/;/g, ":") + " ";
            fileContents += timecodeLib.secToTc(event.end, options.frameRate).replace(/;/g, ":") + "\n";
            fileContents += event.text + "\n\n";
        });

        fileContents += "<end subtitles>";

        return fileContents;
    },

    preProcess: {
        encode: function (events) { /* All */
            return events.filter(event => {
                return event.start !== "" && event.end !== "" && event.text !== ""
            });
        },

        decode: function (fileContents) { /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();

            /* Specific */
            fileContents = fileContents.replace(/'(\n){3,}'/gim, "\n\n");
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            fileContents = fileContents.trim()
            return eol.crlf(fileContents);
        },

        decode: function (events) {
            return events;
        }
    }

}
