<template>
	<LrgModalTemplate Title="New Project" ModalId="NewProjectModal">
		<form>
			<div class="form-group row">
				<label class="col-2 col-form-label">Project Name</label>				
				<div class="col">
					<input type="text" class="form-control" v-model="title" aria-describedby="project-title" placeholder="Project Name">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-2 col-form-label">Framerate</label>				
				<div class="col">
					<select class="form-control" v-model="framerate">
						<option>60</option>
						<option>59.94</option>
						<option>50</option>
						<option>30</option>
						<option>29.97</option>
						<option>25</option>
						<option>24</option>
						<option>23.98</option>
					</select>
				</div>
			</div>
<!-- 
			<div class="form-group row">
				<label class="col-2 col-form-label">Incode</label>
				
				<div class="col">
					<input type="text" class="form-control timecodeInput" placeholder="Incode" v-model.lazy="incode">
				</div>
			</div> -->
			
			<div class="form-group row">
				<label class="col-2 col-form-label">Media Source</label>
				<div class="col">
					<select class="form-control" v-model="updateMediaSource">
						<option>Cloud URL</option>
						<option>Local Storage</option>
						<option>Vimeo</option>
						<option>YouTube</option>
					</select>
				</div>
			</div>
			<div class="form-group row" v-if="mediaSource === 'Local Storage'">
				<label for="Video Source" class="col-2 col-form-label">Select Media</label>
				<div class="col custom-file">
					<input type="file" ref="sourceMedia" accept="video/*,audio/*" @change="updateMediaUrl">
					<small id="source-video-help" class="form-text text-muted">Select your source video file</small>
				</div>
				<br/><br/>
				<br/>
			</div>
			<div class="form-group row" v-else-if="mediaSource === 'Vimeo' ||  mediaSource === 'YouTube'">
				<label for="Video Source" class="col-2 col-form-label">Video URL</label>
				<div class="col">
					<input type="text" v-model="mediaUrl" class="form-control" placeholder="e.g. https://vimeo.com/280242294 or https://www.youtube.com/watch?v=rNKMrEcrNZQ">
					<small id="source-video-help" class="form-text text-muted">Copy the video url or ID for your video.</small>
				</div>
			</div>
			<div class="form-group row" v-else>
				<label for="Video Source" class="col-2 col-form-label">Cloud URL</label>
				<div class="col">
					<input type="text" v-model="mediaUrl" class="form-control" placeholder="e.g. https://s3-us-west-2.amazonaws.com/bucket/video.mp4">
					<small id="source-video-clip" class="form-text text-muted">Copy the URL from your cloud service provider (Support for AWS S3, and Google Storage)<br /> Please note that the video must be made Public prior to captioning.</small>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-2 col-form-label">Aspect Ratio</label>				
				<div class="col-2">
					<select class="form-control" v-model="aspectRatio">
						<option>16:9</option>
						<option>4:3</option>
						<option title="Mobile">9:16</option>
					</select>
				</div>
			</div>
			<button @click="createNewProject" type="button" class="btn btn-primary float-right" data-dismiss="modal"><i class="fas fa-check"></i> Create</button>
		</form>
	</LrgModalTemplate>
</template>
<script>
	import LrgModalTemplate from './LrgModalTemplate'
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name : 'NewProjectModal',
		components: {
			LrgModalTemplate
		},
		data : function(){
			return {
				title : "Untitled",
				framerate : "29.97",
				projectIncode : "00:00:00:00",
				mediaSource : "Local Storage",
				mediaUrl : "",
				aspectRatio : "16:9"
			}
		},
		computed : {
			/* incode : {
				get() {
					return this.projectIncode;
				},
				set(incode){
					this.projectIncode = globalFunc.tcValidate(incode, this.framerate);				
				}
			}, */
			updateMediaSource : {
				get() {
					return this.mediaSource;
				},
				set(source){
					this.mediaUrl = "";
					this.mediaSource = source;				
				}
			}
		},
		
		methods : {
			updateMediaUrl : function() {
				this.mediaUrl = this.$refs.sourceMedia.files;
			},
			createNewProject : function() {
				this.$store.commit('createNewProject', {
					title : this.title,
					framerate : this.framerate,
					projectIncode : this.projectIncode,
				});

				this.$store.commit('importMedia', {
					source : this.mediaSource,
					url : this.mediaUrl,
					aspectRatio : this.aspectRatio
				});

				/* Reset component data */
				this.title = "Untitled";
				this.framerate = "29.97";
				this.projectIncode = "00:00:00:00";
				this.mediaSource = "Local Storage";
				this.mediaUrl = "";
			}
		}
	}
</script>

