<template>
	<ModalTemplate Title="Automatic Translation" ModalId="AutomaticTranslationModal">
		<form>
			<div class="form-group">
                <label>Language</label>
                <select class="form-control" v-model="language">
                    <option v-for="(language, index) in languages" :key="index">{{language}}</option>
                </select>
            </div>
			<div class="form-group">
				<button @click="automaticTranslation" type="button" class="btn btn-primary btn-block" data-dismiss="modal"><i class="far fa-check-square"></i> Translate</button>
			</div>
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	import supportedLanguages from '@/assets/js/translationLanguages'
	export default {
		name : 'AutomaticTranslation',
		data () {
			return {
				language : "French",
				languages : supportedLanguages.languages,
			}
		},
		components: {
			ModalTemplate
		},
		methods : {
			automaticTranslation : function(){
				var confirmation = false;
				var target = this.language;
				var langCode = Object.keys(supportedLanguages.languages).find(key => supportedLanguages.languages[key] === target);
				confirmation = confirm("Please ensure you have a backup of the original captions.\nThis process can not be undone");
				if (confirmation){
					if (this.$store.state.editor.view === "subtitle") {
						this.$store.commit('copySubtitlesToCaptions');
					}
					this.$store.commit('automaticTranslation', langCode);					
				}
				
			}
		}
	}
</script>