const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");

module.exports = {
    decode: function (fileContents/*, options*/) {
        let events = [];
        const captionLines = fileContents.split("\n");
        const tcRegex = /\[\d{2}:\d{2}:\d{2}\.\d{2}\]/g;

        let timeScale = 30;
        let font = "Arial";
        let fontSize = 12;


        for (let i = 0; i < captionLines.length; i++) {

            if (captionLines[i].includes("font")) {
                font = captionLines[i].split(/\{font:/g)[1].trim().slice(0, -1); //Get the font type and remove the '}'
            }

            if (captionLines[i].includes("size")) {
                fontSize = captionLines[i].split(/\{size:/g)[1].trim().slice(0, -1); //Get the font type and remove the '}'
            }

            if (captionLines[i].includes("timeScale")) {
                timeScale = parseInt(captionLines[i].split(/\{timeScale:/g)[1].trim().slice(0, -1)); //Get the font type and remove the '}'
            }


            if (tcRegex.test(captionLines[i])) {
                const event = new ccEvent();;

                event.font = font;
                event.size = fontSize;

                captionLines[i] = captionLines[i].replace(/\[|\]/g, "");
                event.updateStart(timecodeLib.tcQuickTimeToSec(captionLines[i], timeScale));

                ++i;

                while (!tcRegex.test(captionLines[i])) {
                    event.insertLine({
                        text: captionLines[i].trim(),
                        posX: 0,
                        posY: 0,
                        bold: /<b>|<\/b>/.test(captionLines[i]),
                        italics: /<i>|<\/i>/.test(captionLines[i]),
                        underline: /<u>|<\/u>/.test(captionLines[i])
                    });
                    ++i;
                }

                captionLines[i] = captionLines[i].replace(/\[|\]/g, "");
                event.updateEnd(timecodeLib.tcQuickTimeToSec(captionLines[i], timeScale));


                event.syncSubtitleText();
                event.alignTextCenter();
                events.push(event);
            }
        }

        console.dir(events);

        return events;
    },

    encode: function (events, options) {
        let fileContents = "";

        fileContents += eol.after("{QTtext} {font:Tahoma}");
        fileContents += eol.after("{plain} {size:20}");
        fileContents += eol.after("{timeScale:30}");
        fileContents += eol.after("{width:160} {height:32}");
        fileContents += eol.after("{timestamps:absolute} {language:0}");


        for (let i = 0; i < events.length; i++) {
            const caption = events[i];

            const startTime = timecodeLib.secToTc(caption.start, options.frameRate);
            const endTime = timecodeLib.secToTc(caption.end, options.frameRate);
            const text = caption.text;

            fileContents += eol.after(`[${startTime.replace(/(\d{2}:\d{2}:\d{2}):/, '$1.')}]`);
            fileContents += eol.after(`${text}`);
            fileContents += eol.after(`[${endTime.replace(/(\d{2}:\d{2}:\d{2}):/, '$1.')}]\n`);

        }
        return fileContents;
    },

    preProcess: {
        encode: function (events) {
            return events;
        },

        decode: function (fileContents) {
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    },

}