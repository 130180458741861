const ccEvent = require("../event.js");
const striptags = require("striptags");

module.exports ={
    decode: function (fileContents/*, options*/) {
        let events = [];
        let isValidLine = false;
        let captionLines = fileContents.split("\n");

        for (let i = 0; i < captionLines.length; i++) {
            let timeCodeSearch = captionLines[i].match(/(<SYNC Start=)(\d+)/g);
            let startTime = "";

            //Start times
            if (timeCodeSearch !== null) {
                startTime = (timeCodeSearch[0].match(/\d+/g)[0]);
                isValidLine = true;
            }

            if (isValidLine) {
                const event = new ccEvent();;
                event.updateStart(startTime / 1000);
                //Get the index + 1 of timecode
                const startIndex = captionLines[i].indexOf(startTime) + startTime.length + 1; // + '>'
                let CaptionLine = captionLines[i].substring(startIndex);

                //Check if it belongs to a class and strip that?
                if (CaptionLine.includes("<P Class=")) {
                    CaptionLine = getClass(CaptionLine);

                    //Check for line breaks and break up text
                    const lines = getLines(CaptionLine);

                    lines.forEach(function (line) {
                        event.insertLine({
                            text: striptags(line.trim()),
                            posX: 0,
                            posY: 0,
                            bold: /<b>|<\/b>/.test(line),
                            italics: /<i>|<\/i>/.test(line),
                            underline: /<u>|<\/u>/.test(line)
                        });
                    });

                    //Check for atleast 1 caption event
                    if (events.length > 0) {
                        getEndTime(startTime, events[events.length - 1])
                    }
                    event.syncSubtitleText();
                    event.alignTextCenter();
                    events.push(event);
                }
                isValidLine = false;
            }
        }
        return events;
    },

    encode: function (events, options) {

        let fileContents = "<SAMI>\n\
        <HEAD>\n\
        <TITLE>_TITLE_</TITLE>\n\
        <SAMIParam>\n\
        Metrics {time:ms;}\n\
        Spec {MSFT:1.0;}\n\
        </SAMIParam>\n\
        </HEAD>\n\
        <BODY>\n\
        <-- Open play menu, choose Captions and Subtiles, On if available -->\n\
        <-- Open tools menu, Security, Show local captions when present -->\n";


        let previousEndTime = 0;

        events.forEach(function (event) {
            const textLine = event.text.replace(/\n/g, "<br>");

            if ((parseFloat(event.start) * 1000) !== previousEndTime && (previousEndTime !== 0)) {
                fileContents += (`<SYNC Start=${previousEndTime}>\n`);
            }
            fileContents += (`<SYNC Start=${parseFloat(event.start) * 1000}>${textLine}\n`);
            previousEndTime = parseFloat(event.end) * 1000;
        });
        fileContents += "</BODY>\n</SAMI>";

        return fileContents;
    },

    preProcess: {
        encode: function (events) {
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode: function (fileContents) {
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            //Remove '' from events which signifies no captions
            return removeFixedSpaces(events);;
        }
    },
}

const getLines = function (line) {
    return line.split("<br>");
}

const getEndTime = function (currentStartTime, previousCaption) {
    previousCaption.updateEnd(currentStartTime / 1000);
}

const removeFixedSpaces = function (captions) {
    return captions.filter((caption => caption.lines.find(line => line.text !== "")));
}

const getClass = function (classname) {
    let className = classname.match(/(?<=<P Class=)[\w]+/g)[0]; //Assign this to something in the object.. has style properties
    const startIndex = classname.indexOf(className) + className.length + 1; // + '>'
    return classname.substring(startIndex);
}

