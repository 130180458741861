<template>
	<XLrgModalTemplate Title="Options" ModalId="OptionsModal">
		<ul class="nav nav-pills nav-fill justify-content-center" id="optionsNav">
		  <li class="nav-item">
		    <button type="button" class="nav-link btn-link btn" href="#" v-bind:class="{active : this.$store.state.editor.optionsNav === 'general'}" v-on:click="updateMenu('general')">General</button>
		  </li>
		  <li class="nav-item">
		    <button type="button" class="nav-link btn-link btn" href="#" v-bind:class="{active : this.$store.state.editor.optionsNav === 'metadata'}" v-on:click="updateMenu('metadata')">Metadata</button>
		  </li>
		  <li class="nav-item">
		    <button type="button" class="nav-link btn-link btn" href="#" v-bind:class="{active : this.$store.state.editor.optionsNav === 'timing'}" v-on:click="updateMenu('timing')">Timing &amp; Duration</button>
		  </li>
		  <li class="nav-item">
		    <button type="button" class="nav-link btn-link btn" href="#" v-bind:class="{active : this.$store.state.editor.optionsNav === 'Format'}" v-on:click="updateMenu('Format')">Style Guide</button>
		  </li>
		  <li class="nav-item">
		    <button type="button" class="nav-link btn-link btn" href="#" v-bind:class="{active : this.$store.state.editor.optionsNav === 'Speakers'}" v-on:click="updateMenu('Speakers')">Speakers</button>
		  </li>
		  <li class="nav-item">
		    <button type="button" class="nav-link btn-link btn" href="#" v-bind:class="{active : this.$store.state.editor.optionsNav === 'audioDescription'}" v-on:click="updateMenu('audioDescription')">Audio Description</button>
		  </li>
		</ul>
		<!-- General -->
		<div class="optionsFormWrapper" v-if="this.$store.state.editor.optionsNav === 'general'">
			<General></General>
		</div>
		<!-- Metadata -->
		<div class="optionsFormWrapper" v-if="this.$store.state.editor.optionsNav === 'metadata'">
			<Metadata></Metadata>
		</div>
		<!-- Timing/Duration -->
		<div class="optionsFormWrapper" v-if="this.$store.state.editor.optionsNav === 'timing'">
			<TimingDuration></TimingDuration>
		</div>
		<!-- Auto Format -->
		<div class="optionsFormWrapper" v-if="this.$store.state.editor.optionsNav === 'Format'">
			<AutoFormat></AutoFormat>
		</div>
		<!-- Speakers -->
		<div class="optionsFormWrapper" v-if="this.$store.state.editor.optionsNav === 'Speakers'">
			<Speakers/>
		</div>
		<!-- Audio Description -->
		<div class="optionsFormWrapper" v-if="this.$store.state.editor.optionsNav === 'audioDescription'">
			<AudioDescription/>
		</div>
	</XLrgModalTemplate>
</template>
<script>
	import XLrgModalTemplate from './XLrgModalTemplate'
	import General from './Options/General'
	import Metadata from './Options/Metadata'
	import TimingDuration from './Options/TimingDuration'
	import AutoFormat from './Options/AutoFormat'
	import Speakers from './Options/Speakers'
	import AudioDescription from './Options/AudioDescription'
	export default {
		name : 'OptionsModal',
		components: {
			XLrgModalTemplate,
			General,
			Metadata,
			TimingDuration,
			AutoFormat,
			Speakers,
			AudioDescription
		},
		methods : {
			updateMenu : function(navOption){
				this.$store.commit('updateOptionsNav', navOption)
			}
		}
	}
</script>