<template>
	<ModalTemplate Title="Update Project Incode" ModalId="ProjectIncodeModal">
		<form>
			<div class="form-group col-md-12">
				<label for="Offset Type Selection">Current Incode: {{this.$store.state.editor.projectSettings.incode}}</label>
			</div>
			<div class="form-group col-md-12">
				<label for="framerate-selection">New Incode</label>
				<input type="text" class="form-control" v-model.lazy="incode">
			</div>
			<div class="col-md-12">	
				<button @click="updateIncode" type="button" class="btn btn-primary btn-block" data-dismiss="modal"><i class="far fa-check-circle"></i> Update</button>
			</div>
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name : 'ProjectIncodeModal',
		data () {
			return {
				newIncode : "00:00:00:00"
			}
		},
		components: {
			ModalTemplate
		},
		computed : {
			incode : {
				get() {
					return this.newIncode;
				},
				set(incode){
					this.newIncode = globalFunc.tcValidate(incode, this.$store.state.editor.projectSettings.framerate);		
				}
			}, 
		},
		methods : {
			updateIncode : function(){
				this.$store.commit('updateProjectIncode', this.newIncode.trim());	
			}
		}
	}
</script>