<template>
	<div id="subtitleWrapper">
		<pre id="subtitleText" class="rounded" :class="{'d-none' : eventSubtitleText.length===0}">{{eventSubtitleText}}</pre>
	</div>
</template>

<script>
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name: 'CaptionPreviewSubtitle',
		computed: {
			eventSubtitleText: function () {
				if (this.$store.state.editor.player.captionPreview.previewPlay && this.$store.state.editor.player.status == 'playing') {
					var currentTimeSec = this.$store.state.editor.currentTimeSec;

					function getCurrentCaptionLine(captionData) {
						if (captionData) {							
							return (captionData.incodeSec) <= currentTimeSec && (captionData.outcodeSec) >=
								currentTimeSec;
						}
					}

					var captionEvent = this.$store.state.editor.captions.filter(getCurrentCaptionLine)[0];

					if (captionEvent){
						if (this.$store.state.editor.player.captionPreview.captionLock){
							var eventId = this.$store.state.editor.captions.map(function(x) {return x.uniqueId; }).indexOf(captionEvent.uniqueId);
							this.$store.commit("selectCaptionEvent", eventId);
							this.$store.commit('focusCaptionEvent', eventId);
						}
						return captionEvent.subtitleText;

					} else {
						return "";
					}
					

				} else {
					if (this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent]){
						return this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent].subtitleText;
					} else {
						return this.$store.state.editor.captions[0].subtitleText;
					}					 
				}
			}
		}
	}
</script>
<style scoped>
#subtitleWrapper {
	z-index: 1000;
	width: 100%;
	position: absolute;
	bottom: 3%;
	overflow: hidden;
	pointer-events: none;
	text-align: center;
}

#subtitleText {
	background-color: #000;
	color: #fff;
	padding: 1% 2%;
	display: inline-block;
	margin: 0;
	text-align: center;
	font-size: 1.2em;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	pointer-events: none
}
</style>