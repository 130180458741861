<template id="ModalTemplate">
	<!-- Modal -->
	<div class="modal fade" v-bind:id="ModalId" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content modalColor">
				<div class="modal-header">
					<h5 class='modal-title'>{{ Title }}</h5>
					<button type="button" class="close color-white" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'ModalTemplate',
		props : ['Title', 'ModalId'],
		data () {
			return {
				msg: 'Modal Mounted'
			}
		}
	}
</script>

