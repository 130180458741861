<template>
<div class='form-row captionEvent'>
    <div class="col-1">
        <p class="text-muted">{{this.Id + 1}}</p>
        <p class="small">{{wpm}} WPM</p>
        <p class="small" v-if="confidence"><i class="fas fa-brain"></i> {{confidence}}</p>
    </div>
    <div class="col-3">
        <div class="input-group mb-2">
            <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary" v-on:click='setIncode($event)'><i class="far fa-clock"></i></button>
            </div>
            <input type="text" class="form-control timecodeInput" v-model.lazy="incode" v-on:click="updateCurrentTime('incodeSec')" v-on:keyup.up.exact="updateTcByFrame({tc : 'incode',add : true, subtract : false})" v-on:keyup.down.exact="updateTcByFrame({tc : 'incode',add : false, subtract : true})" placeholder="Incode">
        </div>
        <div class="input-group">
            <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary" v-on:click='setOutcode($event)'><i class="far fa-clock"></i></button>
            </div>
            <input type="text" class="form-control timecodeInput" v-model.lazy="outcode" v-on:click="updateCurrentTime('outcodeSec')" v-on:keyup.up.exact="updateTcByFrame({tc : 'outcode',add : true, subtract : false})" v-on:keyup.down.exact="updateTcByFrame({tc : 'outcode',add : false, subtract : true})" placeholder="Outcode">
        </div>
        <div class='form-group mt-2'>
            <select class="form-control" v-model="captionStyle">
                <option>Pop-On</option>
                <option>Roll-Up</option>
                <option>Paint-On</option>
            </select>
        </div>
    </div>
    <TimelineEventEditorInput />
    <div class="form-group col-1">
        <div class="btn-group" role="group">
            <button type="button" class="btn btn-success btn-sm" v-on:click='addEvent' title="New Event"><i class="fa fa-plus" aria-hidden="true"></i></button>
            <button type="button" class="btn btn-danger btn-sm" v-on:click='deleteEvent' title="Delete Event"><i class="fa fa-times" aria-hidden="true"></i></button>
            <button type="button" class="btn btn-primary btn-sm" v-on:click='focusEvent' title="Focus"><i class="fas fa-glasses" aria-hidden="true"></i></button>
        </div>
    </div>
</div>
</template>

<script>
import TimelineEventEditorInput from './TimelineEventEditorInput'
import globalFunc from '../../assets/js/functions/globalFunc'
import globalSetting from '../../assets/js/functions/globalSettings';
import smpteTc from 'smpte-timecode'; 
export default {
    name: 'TimelineEventEditor',
    components: {
        TimelineEventEditorInput
    },
    computed: {
        Id: function () {
            return this.$store.state.editor.selectedCaptionEvent || 0;
        },

        wpm: function () {
            var numberOfWords = globalFunc.concatCaptionText(this.$store.state.editor.captions[this.Id].primaryLang, ' ').split(" ").length;

            return (
                Math.round((numberOfWords / (this.$store.state.editor.captions[this.Id].outcodeSec - this.$store.state.editor.captions[this.Id].incodeSec)) * 60) || "-"
            );
        },
        confidence: function () {
            return this.$store.state.editor.captions[this.Id].confidence;
        },
        captionStyle: {
            get() {
                return this.$store.state.editor.captions[this.Id].style;
            },
            set(style) {
                this.$store.commit('updateCaptionStyle', {
                    id: this.Id,
                    style: style
                });
            }
        },
        incode: {
            get() {
                return this.$store.state.editor.captions[this.Id].incode;
            },
            set(incode) {
                incode = globalFunc.tcValidate(incode, this.$store.state.editor.projectSettings.framerate);
                this.$store.commit('updateIncode', {
                    id: this.Id,
                    timecode: incode,
                    target: 'captions'
                });
            }
        },
        outcode: {
            get() {
                return this.$store.state.editor.captions[this.Id].outcode;
            },
            set(outcode) {
                outcode = globalFunc.tcValidate(outcode, this.$store.state.editor.projectSettings.framerate);
                this.$store.commit('updateCaptionOutcode', {
                    id: this.Id,
                    timecode: outcode
                });
            }
        },
    },
    methods: {
        updateTcByFrame : function(tcOptions){
            try {  
                var framerate = parseFloat(this.$store.state.editor.projectSettings.framerate) || 29.97;
                var dropFrame = globalSetting.tcFrameMap[framerate] || true;

                var tc = smpteTc(this[tcOptions.tc], framerate, dropFrame);
                if (tcOptions.add){
                    tc.add(1);
                } else {
                    tc.subtract(1);
                }

                if (tcOptions.tc === 'incode'){
                    this.$store.commit("updateIncode", {
                        id: this.Id,
                        timecode: tc.toString(),
                        target: "captions"
                    });
                } else {
                    this.$store.commit("updateCaptionOutcode", {
                        id: this.Id,
                        timecode: tc.toString()
                    });
                } 
                
                if (this.$store.state.editor.player.captionPreview.videoLock) {
                    this.$store.commit("cueToTimeSec", this.$store.state.editor.captions[this.Id][tcOptions.tc+'Sec']);
                }
                
            } catch(e){
                console.log(e.message);
            }            
        },
        setIncode: function (event) {
            this.$store.commit('setIncode', this.Id);
        },
        setOutcode: function (event) {
            this.$store.commit('setOutcode', this.Id);
        },
        deleteEvent: function () {
            this.$store.commit('deleteCaptionEvent', this.Id);
        },
        addEvent: function () {
            this.$store.commit("insertCaptionEvent");
        },
        focusEvent: function () {
            this.$store.commit('focusCaptionEvent', this.Id);
        },
        updateCurrentTime: function (event) {
            try {
                if (event === 'incodeSec') {
                    var currentTime = this.$store.state.editor.captions[this.Id].incodeSec || 0;
                } else {
                    var currentTime = this.$store.state.editor.captions[this.Id].outcodeSec || 0;
                }

                if (this.$store.state.editor.player.captionPreview.videoLock) {
                    this.$store.commit('cueToTimeSec', currentTime);
                }

            } catch (e) {
                console.log('Warning: Failed to update current time. ' + e.message);
            }
        }
    }
}
</script>
