const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");

module.exports = {
    decode: function (fileContents/*, options*/) {
        let events = [];
        var columns = null;
        const captionLines = fileContents.split(/\r?\n\s*\r?\n/);
      

        for (let i = 0; i < captionLines.length; i++) {
            // This has to be declared inside the loop
            const tcRegex = /^\s*\[([^\]]+)\]\r?\n([\s\S]*)(\r?\n)*$/gi;
            const match = tcRegex.exec(captionLines[i]);

            if (match) {
                let tag = match[1];
                let lines = match[2].split(/\r?\n/);

                for (let j = 0; j < lines.length; j++) {
                    let line = lines[j];
                    let m = /^\s*([^:]+):\s*(.*)(\r?\n)?$/.exec(line);

                    if (m) {

                        if (tag == "V4 Styles" || tag == "V4+ Styles") {
                            const name = m[1].trim();
                            const value = m[2].trim();
                            if (name == "Format") {
                                columns = value.split(/\s*,\s*/g);
                                continue;
                            }
                            if (name == "Style") {
                                const values = value.split(/\s*,\s*/g);
                                var captionStyle = {};
                                captionStyle.type = "style";
                                captionStyle.data = {};
                                for (var c = 0; c < columns.length && c < values.length; c++) {
                                    captionStyle.data[columns[c]] = values[c];
                                }

                                continue;
                            }
                        }

                        if (tag == "Events") {
                            const name = m[1].trim();
                            const value = m[2].trim();

                            if (name == "Format") {
                                columns = value.split(/\s*,\s*/g);
                                continue;
                            }

                            if (name == "Dialogue") {
                                const values = value.split(/\s*,\s*/g);

                                const caption = {};
                                caption.type = "caption";
                                caption.data = {};

                                for (var c = 0; c < columns.length && c < values.length; c++) {
                                    caption.data[columns[c]] = values[c];
                                }

                                const event = new ccEvent();;

                                event.font = captionStyle.data["Fontname"] || "Ariel";
                                event.size = captionStyle.data["Fontsize"] || 12;
                                event.updateStart(timecodeLib.centisecToSeconds(caption.data["Start"]));
                                event.updateEnd(timecodeLib.centisecToSeconds(caption.data["End"]));


                                function getPosition(s, search, index) {
                                    return s.split(search, index).join(search).length;
                                }

                                var indexOfText = getPosition(value, ',', columns.length - 1) + 1;
                                caption.content = value.substr(indexOfText);


                                const lines = caption.content
                                    .replace(/\{[^\}]+\}/g, "").split(/\\N/g); //"\N" for new line

                                lines.forEach(function (line) {
                                    event.insertLine({
                                        text: line,
                                        posX: 0,
                                        posY: 0,
                                        bold: Boolean(Number(captionStyle.data["Bold"])),
                                        italics: Boolean(Number(captionStyle.data["Italic"])),
                                        underline: Boolean(Number(captionStyle.data["Underline"])),
                                    });
                                });

                                event.syncSubtitleText();
                                event.alignTextCenter();
                                events.push(event);
                            }
                        }
                    }
                }
            }
        }
        return events;
    },

    encode: function (events, options) {
        let fileContents = "";

        fileContents += eol.after("[Script Info]");
        fileContents += eol.after("; Script generated by Closed Caption Creator");
        fileContents += eol.after("ScriptType: v4.00+");
        fileContents += eol.after("Collisions: Normal");
        fileContents += eol.after("PlayDepth: 0");
        fileContents += String(eol.auto);
        fileContents += eol.after("[V4+ Styles]");
        fileContents += eol.after("Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding");
        fileContents += eol.after("Style: Default, Arial,20,&H00B4FCFC,&H00B4FCFC,&H00000008,&H80000008,-1,0,0,0,100,100,0.00,0.00,1,1.00,2.00,2,30,30,30,0");


        fileContents += String(eol.auto);
        fileContents += eol.after("[Events]");
        fileContents += eol.after("Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text");


        for (var i = 0; i < events.length; i++) {
            const caption = events[i];

            const startTime = timecodeLib.secToTc(caption.start, options.frameRate);
            const endTime = timecodeLib.secToTc(caption.end, options.frameRate);
            const text = caption.text.replace(/\r?\n/g, "\\N");

            fileContents += eol.after(`Dialogue: 0,${startTime},${endTime},Default,,0,0,0,,${text}`);
        }



        return fileContents;
    },

    preProcess: {
        encode: function (events) {
            return events;
        },

        decode: function (fileContents) {
            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    },
}