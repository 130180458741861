<template>
<div class="editorWrapper">    
    <audio id="adPreviewPlayer" autobuffer="autobuffer" preload="metadata">
        <source src="" type="audio/wav">
    </audio>
    <form class="editorForm">
        <virtualList 
            style="height: 75vh; overflow-y: auto;"
            :data-key="'uniqueId'"
            :data-sources="adEvents"
            :data-component="itemComponent" 
            :estimate-size="130"
            :keeps="50"
            :wrap-style="{'overflow-x':'hidden', 'overflow-y':'hidden'}">
        >
        </virtualList>
    </form>

</div>
</template>

<script>
import AudioDescriptionEvent from './AudioDescriptionEvent' 
import virtualList from 'vue-virtual-scroll-list'
export default {    
    name: "AudioDescriptionEditor",
    mounted : function(){
        document.getElementById("adPreviewPlayer").muted = false;
    },
    data () {
      return {
        itemComponent: AudioDescriptionEvent,
      }
    },
    computed: {
        adEvents : function(){
            return this.$store.state.editor.audioDescription;
        },
    },
    components: {
        AudioDescriptionEvent,
        virtualList
    }
}
</script>
