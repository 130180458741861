<template>
<ModalTemplate Title="Scene Change Detection" ModalId="SceneChangeModal">
    <p class="text-muted small"><i class="fas fa-info-circle"></i> Detect scene changes in your video and automatically snap events to the closest scene change value.</p>
    <div class="row">        
        <div class="col">
            <form v-on:submit.prevent="detectScenes">
                <div class="form-group">
                    <label><i class="far fa-images"></i> Scene Changes</label>
                    <select class="form-control" size="15" disabled>
                        <option v-for="(sceneChange, index) in sceneChangesTc" :key="index">{{sceneChange}}</option>
                    </select>
                </div>
                <div class="input-group">
                    <label>Snap Threshold</label>
                    <input type="range" class="custom-range" min="1" max="1500" step="1" v-model="threshhold" :title="'Snap Threshold ('+threshhold+')'">
                    <p class="small">{{threshhold}}ms</p>
                </div>
                <button type="button" class="btn btn-primary" @click="detectScenes" :disabled="status.show || sceneChanges.length > 0 "><i class="fas fa-binoculars"></i> Detect Scenes</button>
                <button type="button" class="btn btn-light ml-2" @click="snapToScenes" :disabled="status.show || sceneChanges.length === 0" data-dismiss="modal"><i class="far fa-check-square"></i> Snap To Scene Change</button>
                <button type="button" class="btn btn-light float-right" @click="resetSceneChange" :disabled="status.show">RESET</button>
                <div v-show="status.show" class='row p-2 mt-4'>
                    <div class="col">
                        <div class="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>

</ModalTemplate>
</template>

<script>
import ModalTemplate from './ModalTemplate'
import nodeTc from 'node-timecodes'
export default {
    name: 'SceneChangeDetection',
    data() {
        return {
            sceneChanges: [],
            status: {
                show: false,
            },
            threshhold : 100
        }
    },
    computed: {
        sceneChangesTc : function(){
            var tc = [];
            this.sceneChanges.forEach(sceneChange => {
                tc.push(nodeTc.fromSeconds(sceneChange, {
                    frameRate : parseFloat(this.$store.state.editor.projectSettings.framerate)
                }));
            });

            return tc;
        }
    },
    components: {
        ModalTemplate
    },
    methods: {
        resetSceneChange : function(){
            this.sceneChanges = [];
        },
        snapToScenes : function(){
            this.$store.commit('snapToSceneChanges', {
                sceneChanges : this.sceneChanges,
                threshhold : this.threshhold
            });

            this.$store.commit("fixReversal");
            this.$store.commit("fixOrder");
            this.$store.commit("fixOverlap");

        },
        detectScenes: function () {
            try {
                /* 1. Extract Audio */
                this.status.show = true;
                this.sceneChanges = [];
                const ffmpegPath = require('electron').remote.getGlobal('ffmpegpath');
                const fs = require("fs");
                console.log(ffmpegPath);
                const exec = require('child_process').exec;
                var self = this;
                var videoFilePath = this.$store.state.editor.projectSettings.mediaInfo.path;

                var proc = exec('"'+ffmpegPath + '" -i "' + videoFilePath + '" -filter:v "select=\'gt(scene,0.3)\',showinfo" -f null -');

                proc.stdout.on('data', function (data) {
                    console.log(data.toString());
                });

                proc.stderr.on('data', (data) => {
                    //console.log(data.toString());
                    var logLine = data.toString().split(" ");
                    var sceneChange = logLine.filter(line => {
                        if (line.indexOf("pts_time:") > -1) {
                            self.sceneChanges.push(line.split(":")[1]);
                            return true;
                        }
                    });
                });

                proc.on('close', function () {
                    console.log("Scene Detection Complete");
                    self.status.show = false;

                });
            } catch (e) {
                console.log(e.message);
                this.status.show = false;
                this.$notify({
                    title: 'Automatic Scene Detection',
                    type: 'error',
                    text: 'Automatic scene detection failed. ' + e.message
                });
            }
        }
    }
}
</script>

<style scoped>
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
