<template>
	<div id="captionPreviewWrapper">
		<table id="CaptionGrid">
			<tr v-for="row in 15" v-bind:key="row">
				<td v-for="column in 32" v-bind:key="column" @dragover="updateNewPosition($event)" :id="row+'_'+column"></td>
			</tr>
		</table>
		<p draggable="true" v-for="captionLine in captionEvent" v-bind:key="captionLine.id" :id="'preview_'+captionLine.id"
			@dragstart="updateCaptionLine($event)" @dragend="updatePosition($event)"   class="captionPreview" :class="{'d-none' : captionLine.captionText.length===0, 'bold' : captionLine.bold, 'italics' : captionLine.italics, 'underline' : captionLine.underline}"
			v-bind:style="{top: captionLine.positionY*6.56 + '%', left: captionLine.positionX*3.1 + '%'}">
			{{captionLine.captionText}}
		</p>
	</div>
</template>

<script>
	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name: 'CaptionPreview',
		data : function(){
			return {
				newPosition : "",
				captionLine : ""

			}
		},
		computed: {
			captionEvent: function () {
				if (this.$store.state.editor.player.captionPreview.previewPlay && this.$store.state.editor.player.status == 'playing') {
					var currentTimeSec = this.$store.state.editor.currentTimeSec;

					function getCurrentCaptionLine(captionData) {
						if (captionData) {							
							return (captionData.incodeSec) <= currentTimeSec && (captionData.outcodeSec) >=
								currentTimeSec;
						}
					}

					var captionEvent = this.$store.state.editor.captions.filter(getCurrentCaptionLine)[0];

					if (captionEvent){
						if (this.$store.state.editor.player.captionPreview.captionLock){
							var eventId = this.$store.state.editor.captions.map(function(x) {return x.uniqueId; }).indexOf(captionEvent.uniqueId);
							this.$store.commit("selectCaptionEvent", eventId);
							if (this.$store.state.editor.view!="default"){
								this.$store.commit('focusCaptionEvent', eventId);
							}							
						}
						return captionEvent.primaryLang;
					} else {
						return [];
					}
					

				} else {
					if (this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent]){						
						return this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent].primaryLang
					} else {
						return this.$store.state.editor.captions[0].primaryLang;
					}					 
				}
			}
		},
		methods: {
			updateNewPosition: function (event) {
				event.preventDefault();
				this.newPosition = event.target.id;
				event.dataTransfer.dropEffect = "move";
			},
			updateCaptionLine: function (event) {
				this.captionLine = event.target.id;
			},
			updatePosition: function (event) {
				var targetLine = this.captionLine.split("_")[1];
				var positionX = this.newPosition.split('_')[1]-1; 
				var positionY = this.newPosition.split('_')[0]-1;

				if (positionX >= 0 && positionY >= 0) {
					if (this.$store.state.editor.player.status == 'playing') {
						var currentTimeSec = this.$store.state.editor.currentTimeSec;

						function getCurrentCaptionLine(captionData) {
							if (captionData) {
								return (captionData.incodeSec) <= currentTimeSec && (captionData.outcodeSec) >=
									currentTimeSec;
							}
						}
						var captionEvent = this.$store.state.editor.captions.filter(getCurrentCaptionLine)[0];
						var eventId = this.$store.state.editor.captions.indexOf(captionEvent);

					} else {
						if (this.$store.state.editor.captions[this.$store.state.editor.selectedCaptionEvent]){
							var eventId = this.$store.state.editor.selectedCaptionEvent;
						} else {
							var eventId = 0;
						}						
					}			

					this.$store.commit('updateCaptionLinePosition', 
						{
							event: eventId,
							id: targetLine,
							x: positionX,
							y: positionY
						}
					)
				}
			}
		}
	}
</script>
<style scoped>
#captionPreviewWrapper {
	z-index: 1000;
	position: absolute;
	top: 0;
	width: 72.7%;
	height: 80.5%;
	margin: 5.7% 0 0 13.7%;
	overflow: hidden;
}
.captionPreview {
	background-color: #000;
	display: inline-block;
	padding: 0.75% 0% 0.75% 0.6%;
	margin: 0;
	font-size: 0.8vw;
	position: absolute;
	font-family: "Courier New", Courier, monospace;

	white-space: nowrap;
}

.captionPreview:active{
	background-color: rgba(90, 94, 37, 0.356);
}


@media screen and (min-width: 1200px) {
	.captionPreview {		
		letter-spacing: 0.425vw;
		line-height: 1.9vh;
	}
}

@media screen and (min-width: 1920px) {
	.captionPreview {
		letter-spacing: 0.438vw;
		line-height: 1.9vh;
	}
}

@media screen and (min-width: 2400px) {
	.captionPreview {
		letter-spacing: 0.438vw;
		line-height: 1.9vh;
	}
}
</style>