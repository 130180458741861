module.exports = {
    import: {
        formats: ["scc", "mcc", "srt", "vtt", "sbv", "sub", "txt", "stl", "pac", "cap", "xml", "ttml"]
    },

    export: {
        formats: ["scc", "mcc", "srt", "vtt", "sbv", "sub", "txt", "stl", "xml", "ttml", "csv", "fcpxml"]
    },
    extensionMapping: {
        "scc": "scc",
        "mcc": "mcc",
        "srt": "srt",
        "vtt": "vtt",
        "sbv": "sbv",
        "sub": "sbv",
        "txt": "txt",
        "avid ds (txt)": 'txt',
        "stl": "stl",
        "pac": "pac",
        "cap": "cap",
        "xml": "xml",
        "ttml": "ttml",
        "dfxp": "xml"
    },
    defaults: {
        frameRate: 29.97,
        incode: "00:00:00:00",
        offset: "00:00:00:00"
    },
    frameRateMap: {
        "23.976": 23.976,
        "23.98": 23.976,
        "24": 24,
        "25": 25,
        "29.97": 29.97,
        "30": 30,
        "50": 50,
        "59.94": 59.94,
        "60": 60
    },
    tcDropFrameMap: {
        "23.976": false,
        "24": false,
        "25": false,
        "29.97": true,
        "30": false,
        "50": false,
        "59.94": true,
        "60": false
    }
}