const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");

/*

0001	00:00:08:00	00:00:09:24	DEMOo from the wi Mech Hoffman DEMO.

0002	00:00:11:13	00:00:14:13	DEMO pretty amazing times that DEMOe experiencing right now

0003	00:00:14:13	00:00:16:23	and DEMOuse of that we want to DEMO sure we're giving out the

0004	00:00:16:23	00:00:20:20	DEMO guidance we can for our DEMOols as we're going through

*/


const timeFormatRegex_ = /(\d{2}):(\d{2}):(\d{2}):(\d{2})/g;

module.exports = {
    decode: function (fileContents, options) {
        let events = [];
        let subtitleLines = eol.split(fileContents);
        subtitleLines.forEach(line => {

            if (timeFormatRegex_.test(line)) {

                const timecodes = line.match(timeFormatRegex_);


                const event = getTimeCodes(timecodes);


                const text = line.split(timecodes[1])[1];

                event.insertLine({
                    text: text,
                    posX: 0,
                    posY: 0,
                    bold: /<b>|<\/b>/.test(text),
                    italics: /<i>|<\/i>/.test(text),
                    underline: /<u>|<\/u>/.test(text)
                });

                event.syncSubtitleText();
                event.alignTextCenter();
                events.push(event);
            }

        });

        return events;
    },

    encode: function (events, options) {
        let fileContents = "";

        let lineIndex = 1;

        events.forEach(event => {

            fileContents += (`${String(lineIndex).padStart(4, '0')}\t${timecodeLib.secToTc(event.start)}	${timecodeLib.secToTc(event.end)}	${event.text}\n\n`);

            ++lineIndex;

        });

        return fileContents;
    },

    preProcess: {
        encode: function (events) {
            return events;
        },

        decode: function (fileContents) {
            /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();

            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    },

}

const getTimeCodes = function (timeCodes) {

    const event = new ccEvent();;

    event.start = timecodeLib.tcToSec(timeCodes[0]);

    event.end = timecodeLib.tcToSec(timeCodes[1]);

    return event;

}