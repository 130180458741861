const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");

module.exports = {
    decode: function (fileContents, options) {
        let events = [];
        const captionLines = fileContents.split(/\r?\n/g);

        for (let i = 0; i < captionLines.length; i++) {
            // This has to be declared inside the loop
            const tcRegex = /\{(\d+)\}\{(\d+)\}(.*)/gi;

            let match = tcRegex.exec(captionLines[i]);
      
            if (match) {
                const event = new ccEvent();;

                const startTime = parseInt(match[1]);
                const endTime = parseInt(match[2]);
                event.updateStart(timecodeLib.framesToSec(startTime, options.frameRate));

                event.updateEnd(timecodeLib.framesToSec(endTime, options.frameRate));

                const lines = match[3].split(/\|/g);

                lines.forEach(function (line) {
                    event.insertLine({
                        text: line,
                        posX: 0,
                        posY: 0,
                        bold: /<b>|<\/b>/.test(line),
                        italics: /<i>|<\/i>/.test(line),
                        underline: /<u>|<\/u>/.test(line)
                    });

                });
                event.syncSubtitleText();
                event.alignTextCenter();
                events.push(event);
            }
        }
        return events;
    },

    encode: function (events, options) {
        let fileContents = "";

        for (let i = 0; i < events.length; i++) {
            const caption = events[i];

            const startFrame = timecodeLib.secToFrames(caption.start, options.frameRate);
            const endFrame = timecodeLib.secToFrames(caption.end, options.frameRate);
            const text = caption.text.replace(/\r?\n/g, "|");
            fileContents += (`{${startFrame}}{${endFrame}}${eol.after(text)}`);
        }

        return fileContents;

    },

    preProcess: {
        encode: function (events) {
            return events;
        },

        decode: function (fileContents) {
            /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();

            return fileContents;
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {
            return events;
        }
    },

}