const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const striptags = require("striptags");
const eol = require("eol");
const groupLinesByPosition = require("../functions/helpers/groupLinesByPosition.js");
const vttFunc = require('../functions/profiles/webVtt.js');

module.exports = {
    decode : function(fileContents, options){
        let events = [];
        let subtitleBlocks = fileContents.split("\n\n");
        subtitleBlocks.forEach(subtitleBlock => {
            let tcFlag = false;
            let alignment = false;
            let posX = false;
            let posY = false;

            let event = new ccEvent();;

            let blockLines = subtitleBlock.split("\n").filter(blockLine => {
                return blockLine !== "";
            });

            if (blockLines.length > 1){
                blockLines.forEach(blockLine =>{
                    if (blockLine.split(" --> ").length > 1){
                        tcFlag = true;
                        let lineInfo = blockLine.split(" ");
                        let tcIn = "00:"+lineInfo[0];
                        let tcOut = "00:"+lineInfo[2];

                        event.updateStart(timecodeLib.tcMsToSec(tcIn.substring(tcIn.length-12,tcIn.length)));                        
                        event.updateEnd(timecodeLib.tcMsToSec(tcOut.substring(tcOut.length-12, tcOut.length)));

                        if (events.length > 0 && (event.start === events[events.length-1].start || event.end === events[events.length-1].end)){
                            event.lines = events[events.length-1].lines;
                            events.pop();
                        }

                        /* Check for extra metadata */
                        if (lineInfo.length > 3){
                            lineInfo.forEach(info =>{
                                 if (/align:/g.test(info)){
                                    alignment = info.split(":")[1];
                                 } else if (/position:/g.test(info)){
                                    posX = parseFloat(info.split(":")[1].replace("%", ""));
                                 } else if (/line:/g.test(info)){
                                    posY = parseFloat(info.split(":")[1].replace("%", ""));
                                 }
                            });
                        }


                    } else if (tcFlag){
                        event.insertLine({
                            text : striptags(blockLine),
                            posX : posX || 0,
                            posY : posY || 0,
                            bold : /<b>|<\/b>/.test(blockLine),
                            italics : /<i>|<\/i>/.test(blockLine),
                            underline : /<u>|<\/u>/.test(blockLine) 
                        });
                        
                        if (!posX && !posY){
                            switch(alignment){
                                case 'left':
                                    event.vertAlignBottom();
                                    event.lines.forEach(line=>{
                                        line.horzAlignLeft();
                                    });
                                    break;
                                case 'right':
                                    event.vertAlignBottom();
                                    event.lines.forEach(line=>{
                                        line.horzAlignRight();
                                    });
                                    break;
                                default:
                                    event.alignTextCenter();
                            }
                        }
                    }
                });                
            }

            if (tcFlag){
                event.syncSubtitleText();
                events.push(event);
            }
        });

        return events;
    },

    encode : function(events, options){
        let encodingOptions = {}, formatting, fontStyleCues = false;
        if (options.formatOptions && options.formatOptions.length > 0){
            options.formatOptions.forEach (formatOption =>{
                encodingOptions[formatOption.name] =  formatOption.selected;
            });
        }        

        let fileContents = "WEBVTT\n\n";
        if (encodingOptions["Encode Font Styles"]){
            fontStyleCues = true;
            fileContents += vttFunc.fontStyleCues + "\n\n";
        }
        
        if (encodingOptions["Encode Metadata Notes"] && encodingOptions["Metadata Notes"]){
            fileContents += "NOTE\n";
            fileContents += encodingOptions["Metadata Notes"] + "\n\n";
        }

        events.forEach(function(event){
            fileContents += timecodeLib.secToTcMs(event.start).replace(",", ".") + " --> " + timecodeLib.secToTcMs(event.end).replace(",", ".");
            
            if (encodingOptions["Encode Position"] && encodingOptions["Encode Position"] != "false"){
                let line = event.lines[0].posY + "%";
                let position = event.lines[0].posX + "%";

                if (event.alignment && ["left", "right", "center"].indexOf(event.alignment) > -1){
                    fileContents += " align:"+event.alignment+", line:"+line;
                } else {
                    fileContents += " align:start, line:"+line+" position:"+position;
                }
                
            }
            if (fontStyleCues && event.color!=="#FFFFFF"){
                fileContents+= "\n<c."+vttFunc.colorMapping[event.color]+">";
            }
            event.lines.filter(line => {
                return line.text != "";
            }).forEach(line =>{
                if (encodingOptions["Encode Formatting"] && encodingOptions["Encode Formatting"] != "false"){
                    formatting = vttFunc.getFormatting(line);
                } else {
                    formatting = {prefix : "", suffix:""};
                }
                if (fontStyleCues && event.color!=="#FFFFFF"){
                    fileContents+= formatting.prefix + line.text.trim() + formatting.suffix;
                } else {
                    fileContents+= "\n" + formatting.prefix + line.text.trim() + formatting.suffix;
                }
                
                
            });
            if (fontStyleCues && event.color!=="#FFFFFF"){
                fileContents += "</c>";
            }
            fileContents += "\n\n";
        });

        return fileContents.trim();
    },

    preProcess : {
        encode : function(events){
            /* All */
            return events.filter(event => {
                event.lines = groupLinesByPosition(event.lines);
                event.syncSubtitleText();
                return event.start !== "" && event.end !== "" && event.text !== ""
            });
            
        },

        decode : function(fileContents){
            /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();

            /* Specific */
            fileContents = fileContents.replace(/'(\n){3,}'/gim, "\n\n");

            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            fileContents = fileContents.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}