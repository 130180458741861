const sccLookup = require('./formats/scc')
module.exports = {
    scc: function (fileContents) {
        var fileLines = fileContents.split("\n");
        var decoded = "";
        fileLines.forEach(fileLine => {
            if (fileLine.split("\t")[0].match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g) != null) {
                decoded += ("\n\n" + fileLine.split("\t")[0] + "\n");
                var ancData = fileLine.split("\t")[1].toLowerCase().split(" ");
                var ancDataBuffer = "";
                for (var i = 0; i < ancData.length; i++) {
                    /* Figure out if this is a 4 character command or two 2 character commands */
                    if (sccLookup.ccCharset[ancData[i]] != undefined) {
                        //console.log(sccLookup.ccCharset[ancData[i]]);
                        ancDataBuffer += sccLookup.ccCharset[ancData[i]];
                    } else {
                        var sccCodes = [ancData[i].substring(0, 2), ancData[i].substring(2, 4)];
                        sccCodes.forEach(sccCode => {
                            if (sccLookup.ccCharset[sccCode] != undefined) {
                                ancDataBuffer += sccLookup.ccCharset[sccCode];
                            }
                        });
                    }
                }
                decoded += (ancDataBuffer);
            } else {
                decoded += (fileLine);
            }
        });

        return decoded;
    }
}