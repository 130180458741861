<template>
		<div class="search p-2" v-on:keyup.enter="search">
			<div class="container">
				<div v-if="confidence" class="row">
					<div class='col'>
						<label for="confidenceValueRange">Confidence (&lt;{{confidenceValue}}%)</label>
						<input type="range" class="custom-range" min="0" max="100" step="1" id="confidenceValueRange"
							v-model="confidenceValue">
					</div>
				</div>
				<div v-else class='row'>
					<div class='col-md-6'>
						<label>Search</label>
						<input type="text" name="search" class="form-control" placeholder="Search" v-model="searchTerm">
					</div>
					<div class='col-md-6'>
						<label>Replace</label>
						<input type="text" name="replace" class="form-control" placeholder="Replace"
							v-model="replaceTerm">
					</div>
				</div>
				<div class='row'>
					<div class="col-auto" v-show="!confidence">
						<div class="form-check">
							<input v-model="caseSensitive" type="checkbox" class="form-check-input">
							<label class="form-check-label">Case Sensitive</label>
						</div>
					</div>
					<div class="col-auto" v-show="!confidence">
						<div class="form-check">
							<input v-model="replaceAll" type="checkbox" class="form-check-input">
							<label class="form-check-label">Replace All</label>
						</div>
					</div>
					<div class="col-auto" v-show="!confidence">
						<div class="form-check">
							<input v-model="errors" type="checkbox" class="form-check-input">
							<label class="form-check-label">Errors</label>
						</div>
					</div>
					<div class="col-auto">
						<div class="form-check">
							<input v-model="confidence" type="checkbox" class="form-check-input">
							<label class="form-check-label">Confidence</label>
						</div>
					</div>
				</div>
				<br />
				<div class='form-group'>
					<div class="btn-group" role="group" aria-label="Basic example">
						<button type="button" class='btn btn-secondary' v-on:click='search'><i class="fa fa-search"
								></i> Search</button>
						<button v-if="!confidence" type="button" class='btn btn-secondary' v-on:click='replace'>
							<i class="fa fa-retweet" ></i>
							Replace</button>
						<button type="button" class='btn btn-secondary' v-on:click='navigateSearchResult(-1)'> <i
								class="fa fa-arrow-left" ></i> </button>
						<button type="button" class='btn btn-secondary' v-on:click='navigateSearchResult(1)'> <i
								class="fa fa-arrow-right" ></i> </button>
						<button type="button" class='btn btn-info disabled' v-on:click='navigateSearchResult(1)'>
							{{selectedResult}}/{{totalResults}}</button>
					</div>
					<small class="red" v-if="totalResults===0"> No results found</small>
				</div>
			</div>
		</div>
</template>

<script>
	export default {
		name: 'Search',
		data: function () {
			return {
				searchTerm: "",
				replaceTerm: "",
				caseSensitive: false,
				replaceAll: false,
				errors: false,
				confidence: false,
				confidenceValue: 50
			}
		},
		methods: {
			search: function () {
				var searchInfo = {
					searchTerm: this.searchTerm,
					caseSensitive: this.caseSensitive,
					errors: this.errors,
					confidence: this.confidence,
					confidenceValue: this.confidenceValue
				}

				this.$store.commit('search', searchInfo);
				this.$store.commit('updateSearchResult', 0);
				if (this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult] != undefined) {
					this.$store.commit('selectCaptionEvent', this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult]);
					this.$store.commit('focusCaptionEvent', this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult]);
				}
			},
			replace: function () {
				var searchInfo = {
					searchTerm: this.searchTerm || "",
					replaceTerm: this.replaceTerm || "",
					replaceAll: this.replaceAll,
				}

				if (this.totalResults > 0){
					this.$store.commit('replace', searchInfo);
					this.$store.commit("updateSearchResult", 1);
					if (this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult] != undefined) {
						this.$store.commit('selectCaptionEvent', this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult]);
						this.$store.commit('focusCaptionEvent', this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult]);
					}
				}
			},
			navigateSearchResult: function (direction) {
				this.$store.commit("updateSearchResult", direction);
				if (this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult] != undefined) {
					this.$store.commit('selectCaptionEvent', this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult]);
					this.$store.commit('focusCaptionEvent', this.$store.state.editor.searchOptions.searchResults[this.$store.state.editor.searchOptions.searchResult]);
				}

			}
		},
		computed: {
			
			selectedResult: function () {
				return this.$store.state.editor.searchOptions.searchResult + 1;
			},
			totalResults: function () {
				return this.$store.state.editor.searchOptions.searchResults.length;
			},
		}
	}
</script>
<style scoped>
.search {
	background-color: #252526;
	max-width: 1000;
}

.search .container .row {
	margin-top: 1%;
}

</style>