module.exports = {
    ignoredCommands: ["F9", "80", "FF", "0A", "A7", "19"],
    /* Auto-detect language based on filename for PAC imports */
    detectLanguage: function (fileName) {
        if (fileName.toUpperCase().indexOf("ARA") > -1) {
            return 'arabic';
        } else if (fileName.toUpperCase().indexOf("BUL") > -1) {
            return 'cyrillic'
        } else if (fileName.toUpperCase().indexOf("ENG") > -1 || fileName.toUpperCase().indexOf("EST") > -1) {
            return 'latin'
        } else if (fileName.toUpperCase().indexOf("AR") > -1) {
            return 'arabic'
        } else {
            return 'greek'
        }
    },
    ccCharset: {
        default: {
            "20": " ",
            "41": "A",
            "42": "B",
            "43": "C",
            "44": "D",
            "45": "E",
            "46": "F",
            "47": "G",
            "48": "H",
            "49": "I",
            "4a": "J",
            "4b": "K",
            "4c": "L",
            "4d": "M",
            "4e": "N",
            "4f": "O",
            "50": "P",
            "51": "Q",
            "52": "R",
            "53": "S",
            "54": "T",
            "55": "U",
            "56": "V",
            "57": "W",
            "58": "X",
            "59": "Y",
            "5a": "Z",
            "61": "a",
            "62": "b",
            "63": "c",
            "64": "d",
            "65": "e",
            "66": "f",
            "67": "g",
            "68": "h",
            "69": "i",
            "6a": "j",
            "6b": "k",
            "6c": "l",
            "6d": "m",
            "6e": "n",
            "6f": "o",
            "70": "p",
            "71": "q",
            "72": "r",
            "73": "s",
            "74": "t",
            "75": "u",
            "76": "v",
            "77": "w",
            "78": "x",
            "79": "y",
            "7a": "z",
            "31": "1",
            "32": "2",
            "33": "3",
            "34": "4",
            "35": "5",
            "36": "6",
            "37": "7",
            "38": "8",
            "39": "9",
            "30": "0",
            "2d": "-",
            "22": '"',
            "27": "'",
            "3a": ":",
            "3b": ";",
            "3e": ">",
            "3c": "<",
            "5b": "[",
            "5d": "]",
            "3f": "?"
        },
        latin: {
            "e041": "Ã",
            "e04e": "Ñ",
            "e04f": "Õ",
            "e061": "ã",
            "e06e": "ñ",
            "e06f": "õ",
            "e161": "å",
            "e141": "Å",
            "618a": "ā",
            "418a": "Ā",
            "458a": "Ē",
            "658a": "ē",
            "498a": "Ī",
            "698a": "ī",
            "4f8a": "Ō",
            "6f8a": "ō",
            "558a": "Ū",
            "758a": "ū",
            "23": "£",
            "7c": "æ",
            "7d": "ø",
            "7e": "§",
            "80": "#",
            "5c": "Æ",
            "5d": "Ø",
            "5e": "÷",
            "2d": "-",
            "5f": "–",
            "E54f": "Ö",
            "E56f": "ö",
            "e541": "Ä",
            "e561": "ä",
            "e555": "Ü",
            "e575": "ü",
            "81": "ß",
            "82": "²",
            "e241": "Á",
            "e249": "Í",
            "e255": "Ú",
            "e259": "Ý",
            "e261": "á",
            "e265": "é",
            "e269": "í",
            "e245": "É",
            "e275": "ú",
            "e279": "ý",
            "e361": "à",
            "e365": "è",
            "e36f": "ò",
            "e345": "È",
            "e349": "Ì",
            "e34f": "Ò",
            "e369": "ì",
            "e443": "Ĉ",
            "e447": "Ĝ",
            "e448": "Ĥ",
            "e44A": "Ĵ",
            "e453": "Ŝ",
            "eA55": "Ŭ",
            "e463": "ĉ",
            "e467": "ĝ",
            "e468": "ĥ",
            "e46A": "ĵ",
            "e473": "ŝ",
            "eA75": "ŭ",
            "e341": "À",
            "e441": "Â",
            "e461": "â",
            "e643": "Ç",
            "e663": "ç",
            "e445": "Ê",
            "e465": "ê",
            "e545": "Ë",
            "e565": "ë",
            "e449": "Î",
            "e469": "î",
            "e549": "Ï",
            "e569": "ï",
            "e44F": "Ô",
            "e46F": "ô",
            "e355": "Ù",
            "e375": "ù",
            "e455": "Û",
            "e475": "û",
            "e559": "Ÿ",
            "e579": "ÿ",
            "eb41": "Ą",
            "eb61": "ą",
            "e243": "Ć",
            "e263": "ć",
            "eB45": "Ę",
            "eB65": "ę",
            "9c": "Ł",
            "bc": "ł",
            "e24e": "Ń",
            "e26e": "ń",
            "e24f": "Ó",
            "e26f": "ó",
            "e253": "Ś",
            "e273": "ś",
            "e25a": "Ź",
            "e27a": "ź",
            "e85a": "Ż",
            "e87a": "ż",
            "87": "þ",
            "89": "ð",
            "88": "Þ",
            "8c": "Ð",
            "e653": "Ş",
            "e673": "ş",
            "7b": "ı",
            "ea67": "ğ",
            "ea47": "Ğ",
            "e849": "İ",
            "e75a": "Ž",
            "e753": "Š",
            "e743": "Č",
            "e77a": "ž",
            "e773": "š",
            "e763": "č",
            "ae": "đ",
            "a8": "¿",
            "ad": "¡",
            "a6": "ª",
            "a7": "º",
            "ab": "«",
            "bb": "»",
            "b3": "³",
            "1c": "“",
            "1d": "”",
            "18": "‘",
            "19": "’",
            "13": "–",
            "14": "—",
        },
        hebrew: {
            "a0": "א",
            "a1": "ב",
            "a2": "ג",
            "a3": "ד",
            "a4": "ה",
            "a5": "ו",
            "a6": "ז",
            "a7": "ח",
            "a8": "ט",
            "a9": "י",
            "aa": "ך",
            "ab": "כ",
            "ac": "ל",
            "ad": "ם",
            "ae": "מ",
            "af": "ן",
            "b0": "נ",
            "b1": "ס",
            "b2": "ע",
            "b3": "ף",
            "b4": "פ",
            "b5": "ץ",
            "b6": "צ",
            "b7": "ק",
            "b8": "ר",
            "b9": "ש",
            "ba": "ת",
            "2c": "،",
        },
        arabic: {
            "e081": "أ",
            "e09b": "ؤ",
            "e09c": "ئ",
            "e181": "إ",
            "e281": "آ",
            "e781": "اً",
            "80": "ـ",
            "81": "ا",
            "82": "ب",
            "83": "ت",
            "84": "ث",
            "85": "ج",
            "86": "ح",
            "87": "خ",
            "88": "د",
            "89": "ذ",
            "8A": "ر",
            "8b": "ز",
            "8c": "س",
            "8d": "ش",
            "8e": "ص",
            "8f": "ض",
            "90": "ظ",
            "91": "ط",
            "92": "ع",
            "93": "غ",
            "94": "ف",
            "95": "ق",
            "96": "ك",
            "97": "ل",
            "98": "م",
            "99": "ن",
            "9a": "ه",
            "9b": "و",
            "9c": "ى",
            "9d": "ة",
            "9f": "ي",
            "e09f": "ي",
            "a0": "ء",
            "3f": "؟",
            "2c": "،",
        },
        cyrillic: {
            "20": " ",
            "21": "!",
            "22": "Э",
            "23": "/",
            "24": "?",
            "25": ":",
            "26": ".",
            "27": "э",
            "28": "(",
            "29": ")",
            "2b": "+",
            "2c": ",",
            "2d": "_",
            "2e": "ю",
            "3a": "Ж",
            "3b": "Ж",
            "3c": ">",
            "41": "Ф",
            "42": "И",
            "43": "C",
            "44": "В",
            "45": "У",
            "46": "A",
            "47": "П",
            "48": "Р",
            "49": "Ш",
            "4a": "О",
            "4b": "Л",
            "4c": "Д",
            "4d": "Ь",
            "4e": "Т",
            "4f": "Щ",
            "50": "З",
            "52": "К",
            "53": "Ы",
            "54": "Е",
            "55": "Г",
            "56": "М",
            "57": "Ц",
            "58": "Ч",
            "59": "Н",
            "5a": "Я",
            "5b": "х",
            "5d": "ъ",
            "5e": ",",
            "5f": "-",
            "61": "ф",
            "62": "и",
            "63": "c",
            "64": "в",
            "65": "у",
            "66": "a",
            "67": "п",
            "68": "p",
            "69": "ш",
            "6a": "о",
            "6b": "л",
            "6c": "д",
            "6d": "ь",
            "6e": "т",
            "6f": "щ",
            "70": "з",
            "72": "к",
            "73": "ы",
            "74": "e",
            "75": "г",
            "76": "м",
            "77": "ц",
            "78": "ч",
            "79": "н",
            "7a": "я",
            "7b": "Х",
            "7d": "Ъ",
            "80": "Б",
            "81": "Ю",
            "88": "Ј",
            "8a": "Њ",
            "8f": "Џ",
            "92": "ђ",
            "94": ",",
            "95": "-",
            "96": "і",
            "98": "ј",
            "99": "љ",
            "9a": "њ",
            "9b": "ћ",
            "9d": "§",
            "9f": "џ",
            "a2": "%",
            "ac": "C",
            "ad": "D",
            "ae": "E",
            "af": "F",
            "b0": "G",
            "b1": "H",
            "b2": "'",
            "b3": ")",
            "b4": "I",
            "b5": "J",
            "b6": "K",
            "b7": "L",
            "b8": "M",
            "b9": "N",
            "ba": "P",
            "bb": "Q",
            "bc": "R",
            "bd": "S",
            "be": "T",
            "bf": "U",
            "c0": "V",
            "c2": "W",
            "c3": "X",
            "c4": "Y",
            "c5": "Z",
            "c6": "b",
            "c7": "c",
            "c8": "d",
            "c9": "e",
            "ca": "f",
            "cb": "g",
            "cc": "h",
            "cd": "i",
            "ce": "j",
            "cf": "k",
            "d0": ")",
            "d1": "l",
            "d2": "m",
            "d3": "n",
            "d4": "o",
            "d5": "p",
            "d6": "q",
            "d7": "r",
            "d8": "s",
            "d9": "t",
            "da": "u",
            "db": "v",
            "dc": "w",
            "dd": "э",
            "de": "ю",
            "df": "z",
            "e3": "ѐ",
            "e065": "ў",
            "e574": "ё",
            "e252": "Ќ",
            "e272": "ќ",
            "e596": "ї",
            "6938": "ш",
        },
        greek: {
            "20": " ",
            "20": " ",
            "21": "!",
            "22": "\\",
            "23": "£",
            "24": "$",
            "25": "%",
            "26": "&",
            "27": "'",
            "28": "(",
            "29": ")",
            "2a": "*",
            "2c": ",",
            "2e": ".",
            "2f": "/",
            "3a": ":",
            "3b": ";",
            "3d": "=",
            "3f": "?",
            "40": "@",
            "41": "A",
            "42": "Β",
            "43": "Γ",
            "44": "Δ",
            "45": "E",
            "46": "Ζ",
            "47": "Η",
            "48": "Θ",
            "49": "Ι",
            "4a": "Κ",
            "4b": "Λ",
            "4c": "Μ",
            "4d": "Ν",
            "4e": "Ξ",
            "4f": "Ο",
            "50": "Π",
            "51": "Ρ",
            "53": "Σ",
            "54": "T",
            "55": "Υ",
            "56": "Φ",
            "57": "Χ",
            "58": "Ψ",
            "59": "Ω",
            "5f": "-",
            "61": "α",
            "62": "β",
            "63": "γ",
            "64": "δ",
            "65": "ε",
            "66": "ζ",
            "67": "η",
            "68": "θ",
            "69": "ι",
            "6a": "κ",
            "6b": "λ",
            "6c": "μ",
            "6d": "ν",
            "6e": "ξ",
            "6f": "ο",
            "70": "π",
            "71": "ρ",
            "72": "ς",
            "73": "σ",
            "74": "τ",
            "75": "υ",
            "76": "φ",
            "77": "χ",
            "78": "ψ",
            "79": "ω",
            "80": "#",
            "81": "ß",
            "82": "²",
            "83": "³",
            "84": "½",
            "85": "ŧ",
            "86": "Ŧ",
            "87": "þ",
            "88": "Þ",
            "89": "ð",
            "8c": "A",
            "8d": "B",
            "8e": "C",
            "8f": "D",
            "90": "E",
            "91": "F",
            "92": "G",
            "93": "H",
            "94": "I",
            "95": "J",
            "96": "K",
            "97": "L",
            "98": "M",
            "99": "N",
            "9a": "O",
            "9b": "P",
            "9c": "Q",
            "9d": "R",
            "9e": "S",
            "9f": "T",
            "a0": "U",
            "a1": "V",
            "a2": "W",
            "a3": "X",
            "a4": "Y",
            "a5": "Z",
            "ac": "a",
            "ad": "b",
            "ae": "c",
            "af": "d",
            "b0": "e",
            "b1": "f",
            "b2": "g",
            "b3": "h",
            "b4": "i",
            "b5": "j",
            "b6": "k",
            "b7": "l",
            "b8": "m",
            "b9": "n",
            "ba": "o",
            "bb": "p",
            "bc": "q",
            "bd": "r",
            "be": "s",
            "bf": "t",
            "c0": "u",
            "c1": "v",
            "c2": "w",
            "c3": "x",
            "c4": "y",
            "c5": "z",
            "202a": "®",
            "e241": "Ά",
            "e242": "Β́",
            "e243": "Γ́",
            "e244": "Δ́",
            "e245": "Έ",
            "e246": "Ζ́",
            "e247": "Ή",
            "e248": "Θ́",
            "e249": "Ί",
            "e24a": "Κ́",
            "e24b": "Λ́",
            "e24c": "Μ́",
            "e24d": "Ν́",
            "e24e": "Ξ́",
            "e24f": "Ό",
            "e258": "Ψ́",
            "e259": "Ώ",
            "e261": "ά",
            "e262": "β́",
            "e263": "γ́",
            "e264": "δ́",
            "e265": "έ",
            "e266": "ζ",
            "e267": "ή",
            "e268": "θ́",
            "e269": "ί",
            "e26a": "κ́",
            "e26b": "λ́",
            "e26c": "μ́",
            "e26d": "ν́",
            "e26e": "ξ",
            "e26f": "ό",
            "e270": "π",
            "e271": "ρ́",
            "e272": "ς́",
            "e273": "σ́",
            "e274": "τ́",
            "e275": "ύ",
            "e276": "φ́",
            "e277": "χ́",
            "e278": "ψ́",
            "e279": "ώ",
            "e27b": "ί",
            "e561": "α̈",
            "e562": "β̈",
            "e563": "γ̈",
            "e564": "δ̈",
            "e565": "ε̈",
            "e566": "ζ̈",
            "e567": "η̈",
            "e568": "θ̈",
            "e569": "ΐ",
            "e56a": "κ̈",
            "e56b": "λ̈",
            "e56c": "μ̈",
            "e56d": "ν̈",
            "e56e": "ξ̈",
            "e56f": "ο̈",
            "e570": "π̈",
            "e571": "ρ",
            "e572": "ς̈",
            "e573": "σ̈",
            "e574": "τ̈",
            "e575": "ϋ",
            "e576": "φ̈",
            "e577": "χ̈",
            "e578": "ψ̈",
            "e579": "ω̈",
            "e57b": "ϊ",
        }
    }
}