const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const pacFunc = require('../functions/profiles/ssPoliscript.js');

module.exports = {
    decode: function (fileContents, options) {
        /* Code Page Decode */
        /* pacFunc.codePages.forEach(codePage =>{
            console.log(codePage + " --> " +pacFunc.decodeChar("87", codePage));
        }); */
        let events = [],
            tcFlag = false,
            subtitleFlag = false,
            seperator = "fe",
            codePage = 28591,
            event,
            charBuffer,
            incodeHex,
            outcodeHex,
            decodingOptions = {};


        if (options.formatOptions && options.formatOptions.length > 0){
            options.formatOptions.forEach(formatOption =>{
                decodingOptions[formatOption.name] =  formatOption.selected;
            });
        }

        if (decodingOptions["Code Page"]){
            codePage = pacFunc.codePageMapping[decodingOptions["Code Page"]];
        } 

        let pacLines = fileContents.split(seperator);
        let pacLinesPostProcessing = [];
        while(pacLines.length > 0){
            var pacLinePreProcess = pacLines.shift();

            if (pacLinePreProcess.slice(-2) === "60" && pacLines[0] && pacLines[0].length === 6 && pacLines[1] && pacLines[1].match(/(..?)/g)[4] === "00"){
                pacLinePreProcess += "fe"+pacLines.shift()+"fe"+pacLines.shift();
            } else if (pacLinePreProcess.slice(-2) === "60" && pacLines[0] && pacLines[0].match(/(..?)/g)[6] === "00" && pacLines[0].match(/(..?)/g).length < 14){
                pacLinePreProcess += "fe"+pacLines.shift();
            }

            pacLinesPostProcessing.push(pacLinePreProcess);
        }
        
        pacLinesPostProcessing.forEach(function(pacLine){
            charBuffer = [];
            pacLine = pacLine.match(/(..?)/g);
            if(event){
                event.insertLine();
            }

            /* Debug Logger: */
            //console.log(pacLine.join(", ") + "\n");

            while (pacLine.length > 0) {
                let char = pacLine.shift();
                /* if (char === "60" && pacLine[9] === "00"){
                    console.log(pacLine.length);
                    console.log(pacLine.slice(0, 8));
                } */

                if (char === "60" && pacLine[9] === "00" && pacLine.length < 15) {
                    tcFlag = true;
                    subtitleFlag = true;
                    if (event) {
                        event.syncSubtitleText();
                        event.alignTextCenter();
                        events.push(event);
                    }

                    event = new ccEvent();;
                    event.insertLine();

                    incodeHex = pacLine.splice(0, 4);
                    outcodeHex = pacLine.splice(0, 4);

                    /* Timecode Debug */
                    //console.log(incodeHex + "-->" + pacFunc.decodeTc(incodeHex));
                    //console.log(outcodeHex + "-->" + pacFunc.decodeTc(outcodeHex));

                    event.start = timecodeLib.tcToSec(pacFunc.decodeTc(incodeHex), options.frameRate);
                    event.end = timecodeLib.tcToSec(pacFunc.decodeTc(outcodeHex), options.frameRate);
                    
                    /* remove end parts */
                    pacLine = [];

                } else if (tcFlag) {
                    if (parseInt(char, 16) >= 240 && parseInt(char, 16) <= 255) { /* control codes */
                       /*  if (char === "fe") {
                            event.insertLine();
                        } */

                    } else if (parseInt(char, 16) >= 0 && parseInt(char, 16) <= 31) {
                        // console.log("Garbage: " + char);
                        if (tcFlag && char === "00"){
                            subtitleFlag = false;
                        }
                    } else if (subtitleFlag){
                        if (parseInt(char, 16) === 128){
                            event.lines[event.lines.length - 1].text += "#";
                        } else if (parseInt(char, 16) >= 129 && parseInt(char, 16) < 160) {
                            
                        } else {
                            if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]){
                                event.lines[event.lines.length - 1].text +=pacFunc.specialLatinChars[char + pacLine.shift()];
                            } else {
                                event.lines[event.lines.length - 1].text += pacFunc.decodeChar(char, codePage);
                            }
                        }
                        
                    }
                }
            }


        });

        if (decodingOptions["Ignore Metadata"]){
            try {
                if (timecodeLib.secToTc(events[0].start, options.frameRate) === "00:00:00:00" && timecodeLib.secToTc(events[0].end, options.frameRate) === "00:00:00:08"){
                    events.shift();
                }
            } catch(e){
                console.log("Unable to detect metadata in PAC source");
            }
            
        }
        
        return events;
    },

    encode: function (events, options) {
        throw new Error("PAC files are not supported for encoding by Closed Caption Covnerter.");
    },

    preProcess: {
        encode: function (events) {
            return events;
        },

        decode: function (fileContents) {
            return fileContents.toLowerCase();
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {            
            return events;
        }
    }

}
