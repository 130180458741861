<template>
    <div id="player" class="webPlayerContainer" >
        <iframe
        src="https://www.youtube.com/embed/bTqVqk7FSmY?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
        allowfullscreen
        allowtransparency
        allow="autoplay"
    ></iframe>
    </div>
</template>
<script>
import Plyr from 'plyr';
export default {    
    name: 'YouTubePlayer',
    mounted : function(){        
        window.mediaPlayer = new Plyr('#player', {
            "keyboard" : { 
                focused: false,
                global: false 
            },
            controls : [],
        });
    }
}
</script>
