const cptable = require('codepage');
module.exports = {
  decodeChar: function (charCode, codePage) {
    try {
        let char = cptable[codePage].dec[parseInt(charCode, 16)];
        return char;
    } catch (e) {
        console.log(e.message);
        return("");
    }
},
/* Takes array of  hex values and returns a smpteTC: [ "e8", "03", "64", "00" ] -> 10:00:01:00*/
decodeTc: function (hexTc) {
    let timecode = [];
    hexTc.forEach(tcElement => {
        timecode.push(parseInt(tcElement, 16).toString().padStart(2, "0"));
    });

    return timecode.join(":");
},
}