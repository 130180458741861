module.exports = {
    getFormatting : function(line){
        let formatting = {
            prefix : "",
            suffix : ""
        }

        if (line.bold){
            formatting.prefix += "<b>";
            formatting.suffix = "</b>" + formatting.suffix;
        }

        if (line.italics){
            formatting.prefix += "<i>";
            formatting.suffix = "</i>" + formatting.suffix;
        }

        if (line.underline) {
            formatting.prefix += "<u>";
            formatting.suffix = "</u>" + formatting.suffix;
        }

        return formatting;
    },
    fontStyleCues : `
STYLE
::cue(.black) {
    color: #000000;
}

STYLE
::cue(.red) {
    color: #FF0000;
}

STYLE
::cue(.green) {
    color: #008000;
}

STYLE
::cue(.yellow) {
    color: #FFFF00;
}

STYLE
::cue(.blue) {
    color: #0000FF;
}

STYLE
::cue(.magenta) {
    color: #FF00FF;
}

STYLE
::cue(.cyan) {
    color: #00FFFF;
}

STYLE
::cue(.white) {
    color: #FFFFFF;
}`,
    colorMapping : {
        "#000000" : "black", //black
        "#FF0000" : "red", //red
        "#008000" : "green", //green
        "#FFFF00" : "yellow", //yellow
        "#0000FF" : "blue", //blue
        "#FF00FF" : "magenta", //megenta
        "#00FFFF" : "cyan", //cyan
        "#FFFFFF" : "white"  //white
    },
   
}