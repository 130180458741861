<template>
<div class="editorWrapper">
    <form class="editorForm">
        <virtualList
            style="height: 75vh; overflow-y: auto;"
            :data-key="'uniqueId'"
            :data-sources="captionEvents"
            :data-component="itemComponent" 
            :estimate-size="180"
            :keeps="20"
            :wrap-style="{'overflow-x':'hidden', 'overflow-y':'hidden'}">
        </virtualList>
    </form>
</div>
</template>

<script>
import SubtitleEvent from './SubtitleEvent'
import virtualList from 'vue-virtual-scroll-list'
export default {
    name: "SubtitleEditor",
    data () {
      return {
        itemComponent: SubtitleEvent,
      }
    },
    components: {
        SubtitleEvent,
        virtualList
    },
    computed: {
        captionEvents: function () {
            try {
                this.$nextTick(function () {
                    if(document.getElementById('captionEventInput' + this.$store.state.editor.selectedCaptionEvent) != null){
                        document.getElementById('captionEventInput' + this.$store.state.editor.selectedCaptionEvent).focus();
                    } else {
                        $('section').scrollTop(this.$store.state.editor.selectedCaptionEvent * 180);
                    }                    
                });
            } catch (e) {
                console.log(e.message);
            }

            return this.$store.state.editor.captions;
        },
    }
}
</script>
