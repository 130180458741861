<template>
	<XLrgModalTemplate Title="Shortcuts Menu" ModalId="ShortcutKeys">
		<div class="row">
			<div class="form-group col-6">
				<label>Workspace View</label>
				<select class="form-control" v-model="currentWorkspace">
					<option>default</option>
					<option>subtitle</option>
					<option>transcript</option>
					<option>classic</option>
					<option v-if="appEnv === 'desktop' && plugins.indexOf('ad')>-1">audio description</option>
				</select>
			</div>
		</div>
		<form>
			
			<div class="row">
				<div class="form-group col-6">
					<label>Shortcut</label>
					<select size="15" class="form-control" v-model="selectedShortcut">
						<option v-for="(shortcut, index) in shortcuts[currentWorkspace]" :key="index">{{shortcut.name}}</option>
					</select>
				</div>
				<div class="form-group col-6">
					<label>Description</label>
					<p v-if="shortcuts[currentWorkspace][selectedShortcutIndex]">{{shortcuts[currentWorkspace][selectedShortcutIndex].description}}</p>
					<p v-else></p>
					<label><strong>Key Command</strong></label>
					<div class="form-inline">
						<input v-if="shortcuts[currentWorkspace][selectedShortcutIndex]" type="text" class="form-control col-8 mr-2" v-model.lazy="shortcuts[currentWorkspace][selectedShortcutIndex].keyCmd" @keydown.prevent="updateShortcut($event)"/>
						<button type="button" class="btn btn-danger col" @click="updateShortcut('remove')"><i class="fas fa-times"></i> Remove</button>
					</div>
				</div>				
			</div>
		</form>
	</XLrgModalTemplate>
</template>
<script>
	import XLrgModalTemplate from './XLrgModalTemplate'
	export default {
		name : 'ShortcutKeys',
		data : function(){
			return {
				selectedShortcut : "Play/Pause Video",			
				shortcuts : {
					default : [
						{
							name : "New Caption Event",
							description: "Creates a new caption event starting at the current time",
							shortName : "insertCaptionEvent",
							keyCmd : "Tab"
						},
						{
							name : "Play/Pause Video",
							description: "Play or pause video playback",
							shortName : "playPause",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Frame",
							description: "Go back one frame in the video",
							shortName : "reverse",
							keyCmd : ""
						},
						{
							name : "Forward 1 Frame",
							description: "Go forward one frame in the video",
							shortName : "forward",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Second",
							description: "Go back one second in the video",
							shortName : "reverseOneSec",
							keyCmd : ""
						},
						{
							name : "Forward 1 Second",
							description: "Go forward one second in the video",
							shortName : "forwardOneSec",
							keyCmd : ""
						},
						{
							name : "Reverse 3 Second",
							description: "Go back three seconds in the video",
							shortName : "reverseThreeSec",
							keyCmd : ""
						},
						{
							name : "Forward 3 Second",
							description: "Go forward three seconds in the video",
							shortName : "forwardThreeSec",
							keyCmd : ""
						},
						{
							name : "Take Now",
							description: "(Caption Timing Controls) Update the incode of the selected caption and the outcode of the previous event to be updated to the current time.",
							shortName : "takeNow",
							keyCmd : ""
						},
						{
							name : "Clear",
							description: "(Caption Timing Controls) Update the outcode time of the previously selected event to be the current time",
							shortName : "clear",
							keyCmd : ""
						},
						{
							name : "Insert Incode",
							description: "Update the incode of the selected event to be the current time",
							shortName : "insertIncode",
							keyCmd : ""
						},
						{
							name : "Insert Outcode",
							description: "Update the outcode of the selected event to be the current time",
							shortName : "insertOutcode",
							keyCmd : ""
						},
						{
							name : "Export Caption File",
							description: "Opens the Caption Export menu",
							shortName : "exportMenu",
							keyCmd : ""
						},
						{
							name : "New Project",
							description: "Opens the New Project menu",
							shortName : "newProjectMenu",
							keyCmd : ""
						},
						{
							name : "Previous Caption Event",
							description: "Select the previous caption event",
							shortName : "prevCaptionEvent",
							keyCmd : ""
						},
						{
							name : "Next Caption Event",
							description: "Select the next caption event",
							shortName : "nxtCaptionEvent",
							keyCmd : ""
						},
						{
							name : "Shift Word Up",
							description: "Shift the first word in the selected event to be the last word in the previous event",
							shortName : "shiftWordPrev",
							keyCmd : ""
						},
						{
							name : "Shift Word Down",
							description: "Shift the last word in the selected event to be the first word in the next event",
							shortName : "shiftWordNext",
							keyCmd : ""
						},
						{
							name : "Increase Playback Speed",
							description: "Increase the players playback speed (this will cycle through the playback speed options)",
							shortName : "increasePlayback",
							keyCmd : ""
						},
						{
							name : "Decrease Playback Speed",
							description: "Decrease the players playback speed (this will cycle through the playback speed options)",
							shortName : "decreasePlayback",
							keyCmd : ""
						},
						{
							name : "Go To Event Start",
							description: "Update the player time to the beginning of the selected event",
							shortName : "goToStartOfEvent",
							keyCmd : ""
						},
						{
							name : "Go To Event End",
							description: "Update the player time to the end of the selected event",
							shortName : "goToEndOfEvent",
							keyCmd : ""
						},
						{
							name : "Go To Start",
							description: "Return to the start of video",
							shortName : "goToStart",
							keyCmd : ""
						},
						{
							name : "Go To End",
							description: "Go to the end of the last caption event",
							shortName : "goToEnd",
							keyCmd : ""
						},
						{
							name : "Save Project",
							description: "Open the save project dialogue",
							shortName : "saveProject",
							keyCmd : ""
						},
						{
							name : "Position Top Left",
							description: "Adjust the position of the selected caption event to the top left quadrant",
							shortName : "posTopLeft",
							keyCmd : ""
						},
						{
							name : "Position Top Center",
							description: "Adjust the position of the selected caption event to the top center quadrant",
							shortName : "posTopCenter",
							keyCmd : ""
						},
						{
							name : "Position Top Right",
							description: "Adjust the position of the selected caption event to the top right quadrant",
							shortName : "posTopRight",
							keyCmd : ""
						},
						{
							name : "Position Center Left",
							description: "Adjust the position of the selected caption event to the center left quadrant",
							shortName : "posCenterLeft",
							keyCmd : ""
						},
						{
							name : "Position Center",
							description: "Adjust the position of the selected caption event to the center quadrant",
							shortName : "posCenter",
							keyCmd : ""
						},
						{
							name : "Position Center Right",
							description: "Adjust the position of the selected caption event to the center right quadrant",
							shortName : "posCenterRight",
							keyCmd : ""
						},
						{
							name : "Position Bottom Left",
							description: "Adjust the position of the selected caption event to the bottom left quadrant",
							shortName : "posBottomLeft",
							keyCmd : ""
						},
						{
							name : "Position Bottom Center",
							description: "Adjust the position of the selected caption event to the bottom center quadrant",
							shortName : "posBottomCenter",
							keyCmd : ""
						},
						{
							name : "Position Bottom Right",
							description: "Adjust the position of the selected caption event to the bottom right quadrant",
							shortName : "posBottomRight",
							keyCmd : ""
						},
						{
							name : "Auto Format",
							description: "Auto format the currently selected event based on the configured settings in the options menu",
							shortName : "autoFormat",
							keyCmd : ""
						}
					],					
					classic : [
						{
							name : "New Caption Event",
							description: "Creates a new caption event starting at the current time",
							shortName : "insertCaptionEventInplace",
							keyCmd : "Tab"
						},
						{
							name : "Play/Pause Video",
							description: "Play or pause video playback",
							shortName : "playPause",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Frame",
							description: "Go back one frame in the video",
							shortName : "reverse",
							keyCmd : ""
						},
						{
							name : "Forward 1 Frame",
							description: "Go forward one frame in the video",
							shortName : "forward",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Second",
							description: "Go back one second in the video",
							shortName : "reverseOneSec",
							keyCmd : ""
						},
						{
							name : "Forward 1 Second",
							description: "Go forward one second in the video",
							shortName : "forwardOneSec",
							keyCmd : ""
						},
						{
							name : "Reverse 3 Second",
							description: "Go back three seconds in the video",
							shortName : "reverseThreeSec",
							keyCmd : ""
						},
						{
							name : "Forward 3 Second",
							description: "Go forward three seconds in the video",
							shortName : "forwardThreeSec",
							keyCmd : ""
						},
						{
							name : "Take Now",
							description: "(Caption Timing Controls) Update the incode of the selected caption and the outcode of the previous event to be updated to the current time.",
							shortName : "takeNow",
							keyCmd : ""
						},
						{
							name : "Clear",
							description: "(Caption Timing Controls) Update the outcode time of the previously selected event to be the current time",
							shortName : "clear",
							keyCmd : ""
						},
						{
							name : "Insert Incode",
							description: "Update the incode of the selected event to be the current time",
							shortName : "insertIncode",
							keyCmd : ""
						},
						{
							name : "Insert Outcode",
							description: "Update the outcode of the selected event to be the current time",
							shortName : "insertOutcode",
							keyCmd : ""
						},
						{
							name : "Export Caption File",
							description: "Opens the Caption Export menu",
							shortName : "exportMenu",
							keyCmd : ""
						},
						{
							name : "New Project",
							description: "Opens the New Project menu",
							shortName : "newProjectMenu",
							keyCmd : ""
						},
						{
							name : "Previous Caption Event",
							description: "Select the previous caption event",
							shortName : "prevCaptionEvent",
							keyCmd : ""
						},
						{
							name : "Next Caption Event",
							description: "Select the next caption event",
							shortName : "nxtCaptionEvent",
							keyCmd : ""
						},
						{
							name : "Shift Word Up",
							description: "Shift the first word in the selected event to be the last word in the previous event",
							shortName : "shiftWordPrev",
							keyCmd : ""
						},
						{
							name : "Shift Word Down",
							description: "Shift the last word in the selected event to be the first word in the next event",
							shortName : "shiftWordNext",
							keyCmd : ""
						},
						{
							name : "Increase Playback Speed",
							description: "Increase the players playback speed (this will cycle through the playback speed options)",
							shortName : "increasePlayback",
							keyCmd : ""
						},
						{
							name : "Decrease Playback Speed",
							description: "Decrease the players playback speed (this will cycle through the playback speed options)",
							shortName : "decreasePlayback",
							keyCmd : ""
						},
						{
							name : "Go To Event Start",
							description: "Update the player time to the beginning of the selected event",
							shortName : "goToStartOfEvent",
							keyCmd : ""
						},
						{
							name : "Go To Event End",
							description: "Update the player time to the end of the selected event",
							shortName : "goToEndOfEvent",
							keyCmd : ""
						},
						{
							name : "Go To Start",
							description: "Return to the start of video",
							shortName : "goToStart",
							keyCmd : ""
						},
						{
							name : "Go To End",
							description: "Go to the end of the last caption event",
							shortName : "goToEnd",
							keyCmd : ""
						},
						{
							name : "Save Project",
							description: "Open the save project dialogue",
							shortName : "saveProject",
							keyCmd : ""
						},
						{
							name : "Position Top Left",
							description: "Adjust the position of the selected caption event to the top left quadrant",
							shortName : "posTopLeft",
							keyCmd : ""
						},
						{
							name : "Position Top Center",
							description: "Adjust the position of the selected caption event to the top center quadrant",
							shortName : "posTopCenter",
							keyCmd : ""
						},
						{
							name : "Position Top Right",
							description: "Adjust the position of the selected caption event to the top right quadrant",
							shortName : "posTopRight",
							keyCmd : ""
						},
						{
							name : "Position Center Left",
							description: "Adjust the position of the selected caption event to the center left quadrant",
							shortName : "posCenterLeft",
							keyCmd : ""
						},
						{
							name : "Position Center",
							description: "Adjust the position of the selected caption event to the center quadrant",
							shortName : "posCenter",
							keyCmd : ""
						},
						{
							name : "Position Center Right",
							description: "Adjust the position of the selected caption event to the center right quadrant",
							shortName : "posCenterRight",
							keyCmd : ""
						},
						{
							name : "Position Bottom Left",
							description: "Adjust the position of the selected caption event to the bottom left quadrant",
							shortName : "posBottomLeft",
							keyCmd : ""
						},
						{
							name : "Position Bottom Center",
							description: "Adjust the position of the selected caption event to the bottom center quadrant",
							shortName : "posBottomCenter",
							keyCmd : ""
						},
						{
							name : "Position Bottom Right",
							description: "Adjust the position of the selected caption event to the bottom right quadrant",
							shortName : "posBottomRight",
							keyCmd : ""
						},
						{
							name : "Auto Format",
							description: "Auto format the currently selected event based on the configured settings in the options menu",
							shortName : "autoFormat",
							keyCmd : ""
						}
					],
					subtitle : [
						{
							name : "New Caption Event",
							description: "Creates a new caption event starting at the current time",
							shortName : "insertCaptionEventInplace",
							keyCmd : "Tab"
						},
						{
							name : "Play/Pause Video",
							description: "Play or pause video playback",
							shortName : "playPause",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Frame",
							description: "Go back one frame in the video",
							shortName : "reverse",
							keyCmd : ""
						},
						{
							name : "Forward 1 Frame",
							description: "Go forward one frame in the video",
							shortName : "forward",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Second",
							description: "Go back one second in the video",
							shortName : "reverseOneSec",
							keyCmd : ""
						},
						{
							name : "Forward 1 Second",
							description: "Go forward one second in the video",
							shortName : "forwardOneSec",
							keyCmd : ""
						},
						{
							name : "Reverse 3 Second",
							description: "Go back three seconds in the video",
							shortName : "reverseThreeSec",
							keyCmd : ""
						},
						{
							name : "Forward 3 Second",
							description: "Go forward three seconds in the video",
							shortName : "forwardThreeSec",
							keyCmd : ""
						},
						{
							name : "Take Now",
							description: "(Caption Timing Controls) Update the incode of the selected caption and the outcode of the previous event to be updated to the current time.",
							shortName : "takeNow",
							keyCmd : ""
						},
						{
							name : "Clear",
							description: "(Caption Timing Controls) Update the outcode time of the previously selected event to be the current time",
							shortName : "clear",
							keyCmd : ""
						},
						{
							name : "Insert Incode",
							description: "Update the incode of the selected event to be the current time",
							shortName : "insertIncode",
							keyCmd : ""
						},
						{
							name : "Insert Outcode",
							description: "Update the outcode of the selected event to be the current time",
							shortName : "insertOutcode",
							keyCmd : ""
						},
						{
							name : "Export Caption File",
							description: "Opens the Caption Export menu",
							shortName : "exportMenu",
							keyCmd : ""
						},
						{
							name : "New Project",
							description: "Opens the New Project menu",
							shortName : "newProjectMenu",
							keyCmd : ""
						},
						{
							name : "Previous Caption Event",
							description: "Select the previous caption event",
							shortName : "prevCaptionEvent",
							keyCmd : ""
						},
						{
							name : "Next Caption Event",
							description: "Select the next caption event",
							shortName : "nxtCaptionEvent",
							keyCmd : ""
						},
						{
							name : "Shift Word Up",
							description: "Shift the first word in the selected event to be the last word in the previous event",
							shortName : "shiftWordPrev",
							keyCmd : ""
						},
						{
							name : "Shift Word Down",
							description: "Shift the last word in the selected event to be the first word in the next event",
							shortName : "shiftWordNext",
							keyCmd : ""
						},
						{
							name : "Increase Playback Speed",
							description: "Increase the players playback speed (this will cycle through the playback speed options)",
							shortName : "increasePlayback",
							keyCmd : ""
						},
						{
							name : "Decrease Playback Speed",
							description: "Decrease the players playback speed (this will cycle through the playback speed options)",
							shortName : "decreasePlayback",
							keyCmd : ""
						},
						{
							name : "Go To Event Start",
							description: "Update the player time to the beginning of the selected event",
							shortName : "goToStartOfEvent",
							keyCmd : ""
						},
						{
							name : "Go To Event End",
							description: "Update the player time to the end of the selected event",
							shortName : "goToEndOfEvent",
							keyCmd : ""
						},
						{
							name : "Go To Start",
							description: "Return to the start of video",
							shortName : "goToStart",
							keyCmd : ""
						},
						{
							name : "Go To End",
							description: "Go to the end of the last caption event",
							shortName : "goToEnd",
							keyCmd : ""
						},
						{
							name : "Save Project",
							description: "Open the save project dialogue",
							shortName : "saveProject",
							keyCmd : ""
						},
						{
							name : "Auto Format",
							description: "Auto format the currently selected event based on the configured settings in the options menu",
							shortName : "autoFormat",
							keyCmd : ""
						}
					],
					'audio description' : [
						{
							name : "New Audio Event",
							description: "Creates a new audio description event starting at the current time",
							shortName : "insertAdEvent",
							keyCmd : "Tab"
						},
						{
							name : "Play/Pause Video",
							description: "Play or pause video playback",
							shortName : "playPause",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Frame",
							description: "Go back one frame in the video",
							shortName : "reverse",
							keyCmd : ""
						},
						{
							name : "Forward 1 Frame",
							description: "Go forward one frame in the video",
							shortName : "forward",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Second",
							description: "Go back one second in the video",
							shortName : "reverseOneSec",
							keyCmd : ""
						},
						{
							name : "Forward 1 Second",
							description: "Go forward one second in the video",
							shortName : "forwardOneSec",
							keyCmd : ""
						},
						{
							name : "Reverse 3 Second",
							description: "Go back three seconds in the video",
							shortName : "reverseThreeSec",
							keyCmd : ""
						},
						{
							name : "Forward 3 Second",
							description: "Go forward three seconds in the video",
							shortName : "forwardThreeSec",
							keyCmd : ""
						},		
						{
							name : "Insert Incode",
							description: "Update the incode of the selected event to be the current time",
							shortName : "insertAdIncode",
							keyCmd : ""
						},				
						{
							name : "Increase Playback Speed",
							description: "Increase the players playback speed (this will cycle through the playback speed options)",
							shortName : "increasePlayback",
							keyCmd : ""
						},
						{
							name : "Decrease Playback Speed",
							description: "Decrease the players playback speed (this will cycle through the playback speed options)",
							shortName : "decreasePlayback",
							keyCmd : ""
						},		
						{
							name : "Previous AD Event",
							description: "Select the previous AD event",
							shortName : "prevAdEvent",
							keyCmd : ""
						},
						{
							name : "Next AD Event",
							description: "Select the next AD event",
							shortName : "nxtAdEvent",
							keyCmd : ""
						},				
						{
							name : "Save Project",
							description: "Open the save project dialogue",
							shortName : "saveProject",
							keyCmd : ""
						},
						
					],
					transcript : [
						{
							name : "Play/Pause Video",
							description: "Play or pause video playback",
							shortName : "playPause",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Frame",
							description: "Go back one frame in the video",
							shortName : "reverse",
							keyCmd : ""
						},
						{
							name : "Forward 1 Frame",
							description: "Go forward one frame in the video",
							shortName : "forward",
							keyCmd : ""
						},
						{
							name : "Reverse 1 Second",
							description: "Go back one second in the video",
							shortName : "reverseOneSec",
							keyCmd : ""
						},
						{
							name : "Forward 1 Second",
							description: "Go forward one second in the video",
							shortName : "forwardOneSec",
							keyCmd : ""
						},
						{
							name : "Reverse 3 Second",
							description: "Go back three seconds in the video",
							shortName : "reverseThreeSec",
							keyCmd : ""
						},
						{
							name : "Forward 3 Second",
							description: "Go forward three seconds in the video",
							shortName : "forwardThreeSec",
							keyCmd : ""
						},
						{
							name : "Insert Timecode",
							description: "Insert timecode at selection",
							shortName : "insertTimecode",
							keyCmd : ""
						},
						{
							name : "Save Project",
							description: "Open the save project dialogue",
							shortName : "saveProject",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 01",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker01",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 02",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker02",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 03",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker03",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 04",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker04",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 05",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker05",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 06",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker06",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 07",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker07",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 08",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker08",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 09",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker09",
							keyCmd : ""
						},
						{
							name : "Insert Speaker 10",
							description: "Inserts Speaker name",
							shortName : "insertSpeaker10",
							keyCmd : ""
						},
						{
							name : "Export Caption File",
							description: "Opens the Caption Export menu",
							shortName : "exportMenu",
							keyCmd : ""
						},
						{
							name : "New Project",
							description: "Opens the New Project menu",
							shortName : "newProjectMenu",
							keyCmd : ""
						},
						{
							name : "Increase Playback Speed",
							description: "Increase the players playback speed (this will cycle through the playback speed options)",
							shortName : "increasePlayback",
							keyCmd : ""
						},
						{
							name : "Decrease Playback Speed",
							description: "Decrease the players playback speed (this will cycle through the playback speed options)",
							shortName : "decreasePlayback",
							keyCmd : ""
						},
					],
				}
			}
		},
		computed : {
			appEnv : function(){
				return process.env.VUE_APP_ENV
			},
			plugins : function(){
				return this.$store.state.editor.pluginList;
			},
			selectedShortcutIndex : function(){
				var selectedShortcut = this.selectedShortcut;
				return this.shortcuts[this.currentWorkspace].findIndex(shortcut => {
					return shortcut.name === selectedShortcut;
				});
			},
			currentWorkspace : {
				get : function(){
					return this.$store.state.editor.view;
				},
				set : function(view){
					this.selectedShortcut = "Play/Pause Video"
					this.updateShortcut("update to view");
					this.$store.commit("updateView", view);
				}
				
			}
		},
		created: function () {
			var self = this;
			if (typeof (Storage) !== "undefined") {
				if (localStorage.getItem('shortcutsV713b')) {
					this.shortcuts = JSON.parse(localStorage.getItem('shortcutsV713b'));
				}
			}

			if (window.loadedShortcuts == undefined){
				window.loadedShortcuts = true;
				window.addEventListener('keydown', function initShortcuts(event) {
					addShortcuts(self.shortcuts, event);
				});

				function addShortcuts(shortcuts, event){								
					var shortCut = [];				
					if (event.shiftKey){
						shortCut.push('Shift');
					}

					if (event.ctrlKey){
						shortCut.push('Ctrl');
					}
					
					if (event.altKey){
						shortCut.push('Alt');
					}

					if (event.metaKey){
						shortCut.push('Meta(CMD)');
					}

					if (["MetaLeft","MetaRight", "AltLeft", "AltRight", "ControlLeft", "ControlRight","ShiftLeft","ShiftRight"].indexOf(event.code) === -1){
						shortCut.push(event.code);
					}

					var cmdIndex = shortcuts[self.currentWorkspace].findIndex(action => {
						return action.keyCmd === shortCut.join("+");
					});

					if (cmdIndex !== -1){
						event.preventDefault();
						if (!window.$('body').hasClass("modal-open")){							
							self[shortcuts[self.currentWorkspace][cmdIndex].shortName]();
						}
					}
				}
			}
		},
		destroyed : function(){
			this.updateShortcut('removeAll');
		},
		methods : {
			updateShortcut : function(event){
				var self = this;
				if (event === 'remove'){
					this.shortcuts[this.currentWorkspace][this.selectedShortcutIndex].keyCmd = "";
				} else if (event === 'update to view'){
					console.log("View was updated. Reloading shortcuts...");
				} else if (event === 'removeAll'){
					console.log("Removing all shortcuts");
				} else {
					var shortCut = [];
					if (event.shiftKey){
						shortCut.push('Shift');
					}

					if (event.ctrlKey){
						shortCut.push('Ctrl');
					}
					
					if (event.altKey){
						shortCut.push('Alt');
					}

					if (event.metaKey){
						shortCut.push('Meta(CMD)');
					}

					if (["MetaLeft","MetaRight", "AltLeft", "AltRight", "ControlLeft", "ControlRight","ShiftLeft","ShiftRight"].indexOf(event.code) === -1){
						shortCut.push(event.code);
					}

					/* Check if there is another shortcut with the same command */
					this.shortcuts[this.currentWorkspace].forEach(shortcut =>{
						if (shortcut.keyCmd === shortCut.join("+")){
							shortcut.keyCmd = "";	
						}
					});
					/* Update the list of shortcuts */
					this.shortcuts[this.currentWorkspace][this.selectedShortcutIndex].keyCmd = shortCut.join("+");
				}
				

				/* Save the list to local storage */
				if (typeof(Storage) !== "undefined" && event != "removeAll") {
					localStorage.setItem("shortcutsV713b", JSON.stringify(this.shortcuts));
				}

				window.addEventListener('keydown', function initShortcuts(event) {
					window.removeEventListener('keydown', initShortcuts);
					if (event != "removeAll"){
						addShortcuts(self.shortcuts, event);
					} else {
						window.loadedShortcuts = undefined;
					}					
				});

				/* This is the function that fires each time a shortcut is used */
				function addShortcuts(shortcuts, event){								
					var shortCut = [];				
					if (event.shiftKey){
						shortCut.push('Shift');
					}

					if (event.ctrlKey){
						shortCut.push('Ctrl');
					}
					
					if (event.altKey){
						shortCut.push('Alt');
					}

					if (event.metaKey){
						shortCut.push('Meta(CMD)');
					}

					if (["MetaLeft","MetaRight", "AltLeft", "AltRight", "ControlLeft", "ControlRight","ShiftLeft","ShiftRight"].indexOf(event.code) === -1){
						shortCut.push(event.code);
					}

					var cmdIndex = shortcuts[self.currentWorkspace].findIndex(action => {
						return action.keyCmd === shortCut.join("+");
					});

					if (cmdIndex !== -1){
						event.preventDefault();
						if (!window.$('body').hasClass("modal-open")){							
							self[shortcuts[self.currentWorkspace][cmdIndex].shortName]();
						}
					}
				}				    
			},
			insertCaptionEvent: function () {
				this.$store.commit('insertCaptionEvent');	
				this.$store.commit("selectCaptionEvent", this.$store.state.editor.captions.length-1);			
			},
			insertCaptionEventInplace : function(){
				var eventId = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit("insertCaptionEventInPlace", eventId+1);
				this.$store.commit("selectCaptionEvent", eventId+1);
			},
			insertAdEvent : function(){
				var eventId = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('insertAdEvent', eventId + 1);
            	this.$store.commit('setIncode', eventId+1);
			},
			insertAdIncode : function(){
				var eventId = this.$store.state.editor.selectedCaptionEvent;
            	this.$store.commit('setIncode', eventId);
			},
			prevCaptionEvent: function () {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent - 1;
				if (captionEvent > -1) {
					this.$store.commit('selectCaptionEvent', captionEvent);					
					this.$store.commit('focusCaptionEvent', captionEvent);
					if (this.$store.state.editor.player.captionPreview.videoLock){
						this.$store.commit('cueToCaptionEvent', captionEvent);
					}
				}
			},
			nxtCaptionEvent: function () {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent + 1;
				if (captionEvent < this.$store.state.editor.captions.length) {
					this.$store.commit('selectCaptionEvent', captionEvent);					
					this.$store.commit('focusCaptionEvent', captionEvent);
					if (this.$store.state.editor.player.captionPreview.videoLock){
						this.$store.commit('cueToCaptionEvent', captionEvent);
					}
				}
			},

			prevAdEvent: function () {
				var adEvent = this.$store.state.editor.selectedCaptionEvent - 1;
				if (adEvent > -1) {
					this.$store.commit('selectCaptionEvent', adEvent);					
					this.$store.commit('focusCaptionEvent', adEvent);
					if (this.$store.state.editor.player.captionPreview.videoLock){
						this.$store.commit('cueToAdEvent', adEvent);
					}
				}
			},
			nxtAdEvent: function () {
				var adEvent = this.$store.state.editor.selectedCaptionEvent + 1;
				if (adEvent < this.$store.state.editor.audioDescription.length) {
					this.$store.commit('selectCaptionEvent', adEvent);					
					this.$store.commit('focusCaptionEvent', adEvent);
					if (this.$store.state.editor.player.captionPreview.videoLock){
						this.$store.commit('cueToAdEvent', adEvent);
					}
				}
			},

			reverse: function () {
				var time = Math.abs(mediaPlayer.currentTime - (1 / this.$store.state.editor.projectSettings.framerate));
				this.$store.commit('cueToTimeSec', time);
			},
			forward: function () {
				var time = Math.abs(mediaPlayer.currentTime + (1 / this.$store.state.editor.projectSettings.framerate));
				this.$store.commit('cueToTimeSec', time);
			},
			reverseOneSec: function () {
				var time = Math.abs(mediaPlayer.currentTime - 1);
				this.$store.commit('cueToTimeSec', time);
			},
			forwardOneSec: function () {
				var time = mediaPlayer.currentTime + 1;
				this.$store.commit('cueToTimeSec', time);
			},
			reverseThreeSec: function () {
				var time = Math.abs(mediaPlayer.currentTime - 3);
				this.$store.commit('cueToTimeSec', time);
			},
			forwardThreeSec: function () {
				var time = mediaPlayer.currentTime + 3;
				this.$store.commit('cueToTimeSec', time);
			},
			takeNow : function(){
				var captionEvent = this.$store.state.editor.selectedCaptionEvent;
				if (!this.$store.state.editor.clear){
					this.$store.commit('setOutcode', captionEvent - 1);
				} else {
					this.$store.commit('updateClearStatus', false);
				}
				
				this.$store.commit('setIncode', captionEvent);
				if (this.$store.state.editor.captions[captionEvent + 1]) {
					this.$store.commit('selectCaptionEvent', captionEvent + 1);
					if (this.$store.state.editor.view != "default"){
						this.$store.commit('focusCaptionEvent', captionEvent + 1);
					}	
				} else {
					mediaPlayer.pause();
				}
			},
			clear : function(){
				this.$store.commit('updateClearStatus', true);
				var captionEvent = this.$store.state.editor.selectedCaptionEvent - 1;
				this.$store.commit('setOutcode', captionEvent);
			},
			insertIncode: function () {
				this.$store.commit('setIncode', this.$store.state.editor.selectedCaptionEvent);
			},
			insertOutcode: function () {
				this.$store.commit('setOutcode', this.$store.state.editor.selectedCaptionEvent);
			},
			newProjectMenu: function () {
				$('#NewProjectModal').modal('toggle');
			},
			exportMenu: function () {
				$('#ExportCaptionModal').modal('toggle')
			},
			shiftWordPrev: function () {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent;
				if (captionEvent != 0) {
					this.$store.commit('clearEmptyLines', captionEvent);
                	this.$store.commit('shiftWord', {id: captionEvent, direction: "up"});
                	this.$store.commit('clearEmptyLines', captionEvent);
				}	
			},
			shiftWordNext: function () {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent;
				if (captionEvent != this.$store.state.editor.captions.length-1) {
					this.$store.commit('clearEmptyLines', captionEvent);
                	this.$store.commit('shiftWord', {id: captionEvent, direction: "down"});
                	this.$store.commit('clearEmptyLines', captionEvent);
				}				
			},
			playPause: function () {
				this.$store.commit('togglePlayback');
			},
			saveProject: function () {
				if (process.env.VUE_APP_MODE==='standalone'){
					this.$store.commit('saveProject');
				} else {
					var confirmation = true;
					confirmation = window.confirm("Would you like to publish your work?");
					if (confirmation){
						this.$store.dispatch("publish");
					}
				}        		
			},
			increasePlayback: function () {
				var playbackRateIndex = this.$store.state.editor.playbackRates.indexOf(this.$store.state.editor.playbackRate);
				var playback = this.$store.state.editor.playbackRates[playbackRateIndex + 1] || 1.25;
				this.$store.commit('updatePlaybackRate', playback);
			},
			decreasePlayback: function () {
				var playbackRateIndex = this.$store.state.editor.playbackRates.indexOf(this.$store.state.editor.playbackRate);
				var playback = this.$store.state.editor.playbackRates[playbackRateIndex - 1] || 1.00;
				this.$store.commit('updatePlaybackRate', playback);
			},
			goToStart: function () {
				mediaPlayer.stop();
				this.$store.commit('selectCaptionEvent', 0);
				this.$store.commit('focusCaptionEvent', 0);
				this.$store.commit('cueToCaptionEvent', 0);
			},
			goToEnd: function () {
				var lastEvent = this.$store.state.editor.captions.length - 1;
				this.$store.commit('selectCaptionEvent', lastEvent);
				this.$store.commit('focusCaptionEvent', lastEvent);
				this.$store.commit('cueToCaptionEvent', lastEvent);
			},
			posTopLeft: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "left"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "top"})
			},
			posTopCenter: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "center"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "top"})
			},
			posTopRight: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "right"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "top"})
			},
			posCenterLeft: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "left"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "middle"})
			},
			posCenter: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "center"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "middle"})
			},
			posCenterRight: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "right"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "middle"})
			},
			posBottomLeft: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "left"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "bottom"})
			},
			posBottomCenter: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "center"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "bottom"})
			},
			posBottomRight: function () {
				var captionLine = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('alignCaptionEvent', {id: captionLine, align: "right"});
            	this.$store.commit('alignCaptionEvent', {id: captionLine, align: "bottom"})
			},
			autoFormat : function() {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent;
				this.$store.commit('autoFormat', captionEvent);
			},
			goToStartOfEvent: function () {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent;
				var time = this.$store.state.editor.captions[captionEvent].incodeSec;
				this.$store.commit('cueToTimeSec', time);
			},
			goToEndOfEvent: function () {
				var captionEvent = this.$store.state.editor.selectedCaptionEvent;
				var time = this.$store.state.editor.captions[captionEvent].outcodeSec;
				this.$store.commit('cueToTimeSec', time);
			},
			/* Transcript View Only */
			insertTimecode: function () {
				var transcriptEditor = window.quillEditor;
				var range = transcriptEditor.getSelection();
				if (range && range.length == 0) {
					var tc = this.$store.state.editor.currentTime || "00:00:00:00";
					transcriptEditor.insertText(range.index, "\n\n"+tc+"\n", 'link', '#'+tc, '');
					transcriptEditor.focus();
				}
			},
			insertSpeaker01 : function(){
				
				if (this.$store.state.editor.speakers.length>=1){
					var speaker = this.$store.state.editor.speakers[0];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
				
			},
			insertSpeaker02 : function(){
				if (this.$store.state.editor.speakers.length>=2){
					var speaker = this.$store.state.editor.speakers[1];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			},
			insertSpeaker03 : function(){
				if (this.$store.state.editor.speakers.length>=3){
					var speaker = this.$store.state.editor.speakers[2];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			},
			insertSpeaker04 : function(){
				if (this.$store.state.editor.speakers.length>=4){
					var speaker = this.$store.state.editor.speakers[3];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			},
			insertSpeaker05 : function(){
				if (this.$store.state.editor.speakers.length>=5){
					var speaker = this.$store.state.editor.speakers[4];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			},
			insertSpeaker06 : function(){
				if (this.$store.state.editor.speakers.length>=6){
					var speaker = this.$store.state.editor.speakers[5];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			},
			insertSpeaker07 : function(){
				if (this.$store.state.editor.speakers.length>=7){
					var speaker = this.$store.state.editor.speakers[6];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			},
			insertSpeaker08 : function(){
				if (this.$store.state.editor.speakers.length>=8){
					var speaker = this.$store.state.editor.speakers[7];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			},
			insertSpeaker09 : function(){
				if (this.$store.state.editor.speakers.length>=9){
					var speaker = this.$store.state.editor.speakers[8];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			},
			insertSpeaker10 : function(){
				if (this.$store.state.editor.speakers.length>=10){
					var speaker = this.$store.state.editor.speakers[9];
					var transcriptEditor = window.quillEditor;
					var range = transcriptEditor.getSelection();
					if (range && range.length == 0) {
						transcriptEditor.insertText(
							range.index,
							speaker + ":\n", {
								'color': '#00ffcc',
								'italic': true
							}
						);
						transcriptEditor.focus();
					}
				}
			}
		},
		components: {
			XLrgModalTemplate
		}
	}
</script>