<template>
    <div>
        <VimeoPlayer v-if="playerType == 'vimeo'"/>
        <AudioPlayer v-else-if="playerType == 'audio'" />
        <YouTubePlayer v-else-if="playerType == 'youtube'" />
        <VideoPlayer v-else />
    </div>
</template>
<script>
    import VideoPlayer from './VideoPlayer'
    import AudioPlayer from './AudioPlayer'
    import YouTubePlayer from './YouTubePlayer'
    import VimeoPlayer from './VimeoPlayer'
    export default {
        name: 'Player',
        components : {            
            VideoPlayer,
            AudioPlayer,
            YouTubePlayer,
            VimeoPlayer
        },
        methods : {
            playerType : function() {
                console.log("Player type updated to:",this.$store.state.editor.projectSettings.playerType)
                return this.$store.state.editor.projectSettings.playerType;
            }
        },
        destroyed : function(){
            if (mediaPlayer){
				mediaPlayer.destroy();
			} 
        }
    }
</script>
