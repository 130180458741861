import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "./firebase/config";
import Notifications from "vue-notification";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Notifications);
Vue.use(Loading, {
  "is-full-page" : true,
  color : "#1eb4b2",
  backgroundColor : "#000",
  opacity: 0.3,
  height: 150,
  width: 150
});

window.$ = require("jquery");
require("bootstrap");
router.beforeEach((to, from, next) => {
  if (!to.meta.protected) {
    //route is public, don't check for authentication
    next();
  } else {
    //route is protected, if authenticated, proceed. Else, login
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        next();
      } else {
        router.push({
          path: "/login",
          query: {
            prevPath: window.location.href,
          },
        });
      }
    });
  }
});

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    // Prevent blank screen in Electron builds
    this.$router.push("/");
  },
}).$mount("#app");
