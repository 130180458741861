const dfxp = require("./smpteTtml.js");
const timecodeLib = require("../timecode.js");
const eol = require("eol");
const format = require('xml-formatter');

module.exports ={
    decode : function(fileContents, options){
        return dfxp.decode(fileContents, options);
    },

    encode : function(events, options){
        let fileContents = eol.after("<?xml version=\"1.0\" encoding=\"UTF-8\"?>");

        fileContents += ("<tt xmlns=\"http://www.w3.org/ns/ttml\" ");


        fileContents += ("xmlns:ttm=\"http://www.w3.org/ns/ttml#metadata\" ");
        fileContents += ("xmlns:tts=\"http://www.w3.org/ns/ttml#styling\" ");
        fileContents += eol.after("xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\">");

        fileContents += eol.after("<head>");
        fileContents += eol.after("<metadata>");
        fileContents += eol.after("<ttm:title>Closed Caption Creator</ttm:title>");
        fileContents += eol.after("</metadata>");

        fileContents += eol.after("<styling>");
        fileContents += eol.after("<style tts:fontStyle=\"normal\" tts:fontWeight=\"normal\" xml:id=\"s1\" tts:color=\"white\" tts:fontFamily=\"Arial\" tts:fontSize=\"100%\"></style>");
        fileContents += eol.after("</styling>");
        fileContents += eol.after("<layout>");
        fileContents += eol.after("<region tts:extent=\"80% 40%\" tts:origin=\"10% 10%\" tts:displayAlign=\"before\" tts:textAlign=\"center\" xml:id=\"topCenter\" />");
        fileContents += eol.after("<region tts:extent=\"80% 40%\" tts:origin=\"10% 50%\" tts:displayAlign=\"after\" tts:textAlign=\"center\" xml:id=\"bottomCenter\" />");
        fileContents += eol.after("</layout>");
        fileContents += eol.after("</head>");
        fileContents += eol.after("<body>");
        fileContents += eol.after("<div style=\"s1\" xml:id=\"d1\">");



        let counter = 1;
        for (const event_ of events) {

            let eventLine = "";
            for (let i = 0; i < event_.lines.length; i++) {

                eventLine += (`${event_.lines[i].text}<br/>`).trim();
            }
            eventLine = eventLine.substring(0, eventLine.length - 5).trim();
            fileContents += eol.after(`<p xml:id="p${counter++}" begin="${timecodeLib.secToTcMs(event_.start).replace(',', '.')}" end="${timecodeLib.secToTcMs(event_.end).replace(',', '.')}" region="bottomCenter">${eventLine}</p>`);

        }

        fileContents += eol.after("</div>");
        fileContents += eol.after("</body>");
        fileContents += eol.after("</tt>");
        


        var oParser = new DOMParser();
        fileContents = oParser.parseFromString(fileContents, "application/xml");


        var oSerializer = new XMLSerializer();
        fileContents = oSerializer.serializeToString(fileContents);
        return format(fileContents, { collapseContent: true });
    },

    preProcess : {
        encode : function(events){
            return events;
        },

        decode : function(fileContents){
            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}