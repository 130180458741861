const sccLookup = require('../../dict/608.js');
module.exports = {
    colorMapping : {
        "WHITE" : "#FFFFFF",
        "GREEN" : "#008000",
        "BLUE" : "#0000FF",
        "CYAN" : "#00FFFF",
        "RED" : "#FF0000",
        "YELLOW" : "#FFFF00",
        "MAGENTA" : "#FF00FF",
        "BLACK" : "#000000",
        "NONE" : "",
    },
    getCodeByCmd: function (codes, cmd) {
        let sccCode = Object.keys(codes).find(code => codes[code] === cmd);
        return sccCode || "80";
    },
    
    getSccPosition : function(line){
        let posX = parseInt((line.posX/100) * 32);
        let posY = parseInt((line.posY/100) * 15);

        if (posX === 32){
            posX = 31
        }

        if (posY === 15){
            posY = 14
        }

        let tabs = posX%4;
        let column = (posX-tabs).toString().padStart(2, '0');
        let tabCode = "{TAB0" + tabs + "}"
        let posCode = "{" + posY.toString().padStart(2, '0') + "_" + column.toString().padStart(2, '0') + "}";

        return {
            posCode : posCode,
            tabCode : tabCode
        }        

    },

    encodeEvent : function(event, channel){
        let encodedCmds = [];
        if (event.style === "Pop-On"){
            encodedCmds.push(this.getCodeByCmd(sccLookup[channel], "{RESUME LOADING}"));
        } else if (event.style === "Roll-Up"){
            encodedCmds.push(this.getCodeByCmd(sccLookup[channel], "{ROLLUP2}"));
        } else {
            /* Paint-On */
            encodedCmds.push(this.getCodeByCmd(sccLookup[channel], "{PAINT ON}"));
        }

        event.lines.forEach(line => {
            if (event.style === "Roll-Up"){
                encodedCmds.push(this.getCodeByCmd(sccLookup[channel], "{NEW LINE}"));
                encodedCmds.push(this.getCodeByCmd(sccLookup[channel], "{14_00}"));
            } else {
                let position = this.getSccPosition(line);
                encodedCmds.push(this.getCodeByCmd(sccLookup[channel], position.posCode));
                if (position.tabCode !== "{TAB00}"){
                    encodedCmds.push(this.getCodeByCmd(sccLookup[channel], position.tabCode));
                }
            }

            line.text.split("").forEach(character=>{
                encodedCmds.push(this.getCodeByCmd(sccLookup[channel], character));
            });
        });

        return encodedCmds;
    },

    encodedTextToString : function(encodedText){
        let text = "";
        for (var i = 0; i<encodedText.length; i++){
            if (encodedText[i].length === 4){
                text += " "+encodedText[i];
            } else if (encodedText[i+1] && encodedText[i].length === 2 && encodedText[i+1].length === 2){
                text += " "+ encodedText[i] + encodedText[++i];
            } else if (encodedText[i].length === 2 && (!encodedText[i+1] || encodedText[i+1].length === 4)){
                text += " " + encodedText[i] + "80";
            }
        }

        return text.trim();
    },
    calculateEncodeTime : function(encodedTextString) {
        return encodedTextString.split(" ").length;
    },

    verifyFormatting : function(lines){
        let goodFormatting = true;
        lines.forEach(line =>{
            if (line.text && ((line.posX/100)*32) + line.text.length > 32){
                goodFormatting = false;
            }
        });

        return goodFormatting;
    }
}