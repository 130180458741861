<template>
	<ModalTemplate Title="Export Data" ModalId="ExportData">
		<form>
			<div class="form-group">
				<label for="Data Formats">Format</label>
				<select class="form-control" id="Data Format" v-model="format">
					<option>JSON</option>			
				</select>
			</div>
			<button @click="exportDataFile" type="button" class="btn btn-primary" data-dismiss="modal"><i class="fas fa-file-export"></i> Export</button><br/><br />
			<small><i class="fas fa-bolt"></i> Powered by <strong>Closed Caption Converter</strong></small>
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	export default {
		name : 'ExportDataModal',
		data () {
			return {
				format : 'JSON'
			}
		},
		components: {
			ModalTemplate
		},
		methods : {
			exportDataFile : function(){
				this.$store.commit('updateStatus', {
					progress	: 100,
					msg	: "Exporting",
					status	: "processing",
					display	: true
				});

				var self = this;
				setTimeout(function(){
					self.$store.commit('exportDataFile', {format: self.format});
					self.$store.commit('updateStatus', {
						progress	: 100,
						msg	: "Complete",
						status	: "Export Complete",
						display	: false
					});
				},1000);					
      		}
		}
	}
</script>