<template>
<form>
  <p class="small"><i class="fas fa-info-circle"></i> Enable and customize your style guide in order to view custom errors in your projects</p>
  <div class="form-check mb-2">
    <input class="form-check-input" type="checkbox" value="" id="enableStyleGuide" v-model="enableStyleGuide">
    <label class="form-check-label" for="enableStyleGuide">
      Enable Style Guide
    </label>
  </div>
  <hr/>
  <div class="form-group">
    <label>Max Lines Per Event</label>
    <select class="form-control" v-model="maxLines" :disabled="!enableStyleGuide" :class="{'bg-dark' : !enableStyleGuide}">
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
    </select>
    <p class="small">Set the maximum number of lines per event.</p>
  </div>
  <div class="form-group">
    <label>Max Characters Per Line</label>
    <select class="form-control" v-model="maxChars" :disabled="!enableStyleGuide" :class="{'bg-dark' : !enableStyleGuide}">
      <option>28</option>
      <option>32</option>
      <option>35</option>
      <option>42</option>
    </select>
    <p class="small">Set the total max characters per line.</p>
  </div>
  <div class="form-group">
    <label>Top Line (y-position)</label>
    <input class="form-control" type='number' max="15" min="1" v-model="topLine" :disabled="!enableStyleGuide" :class="{'bg-dark' : !enableStyleGuide}"/>
    <p class="small">Set the default position of where the first line of each event should start</p>
  </div>
</form>
</template>

<script>
export default {
  name: 'AutoFormat',
  created: function () {
    if (typeof (Storage) !== "undefined") {
      if (localStorage.getItem('defaultMaxLines')) {
        var maxLines = localStorage.getItem('defaultMaxLines');
        this.$store.commit('updateFormatOption', {
          option: 'maxLines',
          value: maxLines
        });
      }

      if (localStorage.getItem('defaultMaxChars')) {
        var maxChars = localStorage.getItem('defaultMaxChars');
        this.$store.commit('updateFormatOption', {
          option: 'maxChars',
          value: maxChars
        });
      }

      if (localStorage.getItem('defaultTopLine')) {
        var topLine = localStorage.getItem('defaultTopLine');
        this.$store.commit('updateFormatOption', {
          option: 'topLine',
          value: topLine
        });
      }
    }
  },
  computed: {
    enableStyleGuide : {
        get : function(){
            return this.$store.state.editor.autoFormat.enable
        },
        set : function(value){
            this.$store.commit('updateFormatOption', {
                option: 'enable',
                value: value
            });

            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("styleGuideEnabled", value);
            }
        }
    },
    maxLines: {
      get: function () {
        return this.$store.state.editor.autoFormat.maxLines
      },
      set: function (value) {
        this.$store.commit('updateFormatOption', {
          option: 'maxLines',
          value: value
        });
        if (typeof (Storage) !== "undefined") {
          localStorage.setItem("defaultMaxLines", value);
        }
      }
    },
    maxChars: {
      get: function () {
        return this.$store.state.editor.autoFormat.maxChars
      },
      set: function (value) {
        this.$store.commit('updateFormatOption', {
          option: 'maxChars',
          value: value
        });
        if (typeof (Storage) !== "undefined") {
          localStorage.setItem("defaultMaxChars", value);
        }
      }
    },
    topLine: {
      get: function () {
        return this.$store.state.editor.autoFormat.topLine
      },
      set: function (value) {
        this.$store.commit('updateFormatOption', {
          option: 'topLine',
          value: value
        });
        if (typeof (Storage) !== "undefined") {
          localStorage.setItem("defaultTopLine", value);
        }
      }
    }
  }
}
</script>
