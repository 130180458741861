<template>
	<ModalTemplate Title="Open Project" ModalId="ImportProjectModal">
		<form>
			<div class="form-group">
				<label for="video-source">Select .ccprj Project File</label><br/>
				<input type="file" id="projectFile" aria-describedby="source-project-input" accept=".ccprj" />
				<small id="source-video-help" class="form-text text-muted">Select your previously saved project file.</small>
			</div>
			<button @click="importProjectFile" type="button" class="btn btn-primary btn-block" data-dismiss="modal"><i class="fa fa-download" aria-hidden="true"></i> Import</button>
		</form>
		<br/>
		<small><i class="fas fa-bolt"></i> Powered by <strong>Closed Caption Converter</strong></small>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	export default {
		name : 'ImportProjectModal',
		data () {
			return {
				msg: 'Import Project Modal Mounted'
			}
		},
		components: {
			ModalTemplate
		},
		methods : {
			importProjectFile : function(){
				if (document.getElementById('projectFile').files[0] != undefined) {
					var srcFile = document.getElementById('projectFile').files[0];
					var URL = window.URL || window.webkitURL;
					var fileSize = srcFile.size;
					var fileExt = srcFile.name.split('.').pop();
					var fileUrl = URL.createObjectURL(srcFile);
					var settings = {
						srcFile : srcFile,
						fileExt : fileExt,
						fileSize : fileSize
					}
					
					this.$store.dispatch('importProjectFile', settings);
				} else {
					this.$notify({
						title: 'Project Import',
						type : 'error',
						text: 'Project Import Failed'
					});
				}
      		}
		}
	}
</script>