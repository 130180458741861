const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const striptags = require("striptags");
const eol = require("eol");
const groupLinesByPosition = require("../functions/helpers/groupLinesByPosition.js");
const vttFunc = require('../functions/profiles/webVtt.js'); //Used for getting the formatting

module.exports = {
    decode : function(fileContents){
        let events = [];
        let subtitleBlocks = fileContents.split("\n\n");
        subtitleBlocks.forEach(block => {
            let event = new ccEvent();;
            let blockInfo = block.split("\n").filter(blockLine => {
                return blockLine !== "";
            });

            if (blockInfo.length > 1){
                event.updateStart(timecodeLib.tcMsToSec(blockInfo[1].split(" --> ")[0]));
                event.updateEnd(timecodeLib.tcMsToSec(blockInfo[1].split(" --> ")[1]));
                for (let i = 2; i < blockInfo.length; i++){
                    event.insertLine({
                        text : striptags(blockInfo[i]).trim(),
                        posX : 0,
                        posY : 0,
                        bold : /<b>|<\/b>/.test(blockInfo[i]),
                        italics : /<i>|<\/i>/.test(blockInfo[i]),
                        underline : /<u>|<\/u>/.test(blockInfo[i]) 
                    });
                }
                event.syncSubtitleText();
                event.alignTextCenter();
                events.push(event);
            }
        });
        return events;
    },

    encode : function(events, options){
        let fileContents = "",
            encodingOptions = {}, 
            formatting, 
            fontStyleCues = false;
        
        if (options.formatOptions && options.formatOptions.length > 0){
            options.formatOptions.forEach (formatOption =>{
                encodingOptions[formatOption.name] =  formatOption.selected;
            });
        }

        events.forEach(function(event, id){
            fileContents += (id+1) + "\n";
            fileContents += timecodeLib.secToTcMs(event.start) + " --> " + timecodeLib.secToTcMs(event.end) + "\n";
            if (encodingOptions["Encode Formatting"] && encodingOptions["Encode Formatting"] != "false"){
                fileContents += event.html + "\n\n";
            } else {
                fileContents += event.text + "\n\n";
            }            
        });

        return fileContents.trim();
    },

    preProcess : {
        encode : function(events){
            /* All */
            return events.filter(event => {
                event.lines = groupLinesByPosition(event.lines);
                event.syncSubtitleText();
                event.syncHtmlText();
                return event.start !== "" && event.end !== "" && event.text !== ""
            });
        },

        decode : function(fileContents){
            /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();

            /* Specific */
            fileContents = fileContents.replace(/'(\n){3,}'/gim, "\n\n");

            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            fileContents = fileContents.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}