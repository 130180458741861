<template>
<XLrgModalTemplate Title="Import Ai Transcript" ModalId="ImportTranscriptModal">
    <div class="row">
        <div class="col-7">
            <h6>Preview</h6>
            <div class="form-group">
                <textarea class="form-control" rows="30" disabled v-model="previewText"></textarea>
            </div>
        </div>
        <div class="col">
            <h6>Import Options</h6>
            <form v-on:submit.prevent="importTranscript('preview')">
                <div class="form-group">
                    <label>Maximum Characters Per Line</label>
                    <select class="form-control" v-model="maxCharacter">
                        <option>28</option>
                        <option>32</option>
                        <option>35</option>
                        <option>42</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Maximum Lines Per Event</label>
                    <select class="form-control" v-model="maxLines">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                    </select>
                </div>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="endLineOnPunctuation">
                    <label class="custom-control-label" for="customSwitch1">End event on special punctuations
                        (.?!)</label>
                </div>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="speakerIdentity" v-model="endLineOnSpeaker">
                    <label class="custom-control-label" for="speakerIdentity">End event on new speaker</label>
                </div>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="appendOption" v-model="append">
                    <label class="custom-control-label" for="appendOption">Append</label>
                </div>
                <br />
                <label for="maxWordDurationRange">Maximum Duration Between Words ({{maxWordDuration}} sec) <i class="fas fa-info-circle" title="A caption event will be terminated if the duration between words is greater than the selected value."></i></label>
                <input type="range" class="custom-range" min="0.25" max="5" step="0.25" id="maxWordDurationRange" v-model.number="maxWordDuration">

                <label for="outcodePadding">Outcode Padding ({{padding}} sec) <i class="fas fa-info-circle" title="The amount of padding in seconds added to the outcode of an event when there is no event immediately following."></i></label>
                <input type="range" class="custom-range" min="0" max="3" step="0.1" id="outcodePadding" v-model.number="padding">
                <br /><br />
                <button type="button" class="btn btn-outline-light mr-2" @click="importTranscript('preview')"><i class="fas fa-sync-alt"></i> Refresh Preview</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" @click="importTranscript('project')"><i class="fas fa-file-import"></i> Import</button>
                <button type="button" class="btn btn-outline-info ml-2" data-dismiss="modal" @click="downloadRaw"><i class="fa fa-download"></i> Download Transcript</button>
                <button type="button" class="btn btn-link" @click="advancedOptions.show=!advancedOptions.show">Advanced Options <i class="fas fa-chevron-down" v-if="advancedOptions.show"></i> <i class="fas fa-chevron-right" v-else></i></button>
                <div v-show="advancedOptions.show" class="pt-4">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="segmentImportSwitch" v-model="advancedOptions.segmentImport">
                        <label class="custom-control-label" for="segmentImportSwitch">Segment Import</label>
                    </div>

                    <div class="form-group row pt-4" v-if="advancedOptions.segmentImport">
                        <label class="col-4 col-form-label">Incode (Seconds)</label>
                        <div class="col-6">
                            <input type="number" min="0" step="0.25" class="form-control timecodeInput" placeholder="Incode" v-model.number="advancedOptions.segIncodeSec">
                        </div>
                    </div>
                    <div class="form-group row" v-if="advancedOptions.segmentImport">
                        <label class="col-4 col-form-label">Outcode (Seconds)</label>
                        <div class="col-6">
                            <input type="number" min="0" step="0.25" class="form-control" :class="{'is-invalid' : advancedOptions.segOutcodeSec< advancedOptions.segIncodeSec}" placeholder="Outcode" v-model.number="advancedOptions.segOutcodeSec">
                            <div class="invalid-feedback">
                                Outcode must be greater than incode.
                            </div>
                        </div>
                    </div>
                    <div class="btn-group mt-2" role="group" v-if="advancedOptions.segmentImport">
                        <button type="button" class="btn btn-secondary" @click="prevSegment"><i class="fas fa-chevron-left"></i></button>
                        <button type="button" class="btn btn-secondary" @click="nextSegment"><i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</XLrgModalTemplate>
</template>

<script>
import XLrgModalTemplate from './XLrgModalTemplate'
import globalFunc from '../../assets/js/functions/globalFunc'
import timecodes  from 'node-timecodes';
import fileSaver from 'file-saver'
export default {
    name: 'ImportTranscriptModal',
    data() {
        return {
            maxCharacter: 32,
            maxLines: 2,
            maxWordDuration: 1,
            padding: 0,
            append: false,
            endLineOnPunctuation: false,
            endLineOnSpeaker: false,
            previewText: "",
            advancedOptions: {
                show: false,
                segmentImport: false,
                segIncodeSec: 0,
                segOutcodeSec: 60,
            }
        }
    },
    components: {
        XLrgModalTemplate
    },
    methods: {
        downloadRaw : function(){
            let outputData = "";
            let fileName = this.$store.state.editor.projectSettings.title.replace(/[^a-z0-9]/gi, '').toLowerCase() + ".txt";

            try {
                JSON.parse(this.$store.state.editor.assetTranscription.text).forEach(result => {
                    try {
                        if (result.alternatives[0].transcript){
                            outputData += timecodes.fromSeconds(parseFloat(result.alternatives[0].words[0].startTime),{ms: true}) +" --> "+ timecodes.fromSeconds(parseFloat(result.alternatives[0].words[result.alternatives[0].words.length-1].endTime),{ms: true}) +"\n"+result.alternatives[0].transcript.trim() + "\n\n";
                        }
                        
                    } catch(err){
                        console.log(err);
                    }
                    
                });
            } catch(e){
                outputData = "";
            }

            var blob = new Blob([outputData], {type: "text/plain;charset=utf-8"});
            fileSaver.saveAs(blob, fileName);
        },
        prevSegment : function(){
            var duration = this.advancedOptions.segOutcodeSec - this.advancedOptions.segIncodeSec;
            if (this.advancedOptions.segIncodeSec - duration >= 0){
                this.advancedOptions.segOutcodeSec = this.advancedOptions.segIncodeSec;
                this.advancedOptions.segIncodeSec = this.advancedOptions.segIncodeSec - duration;
            }
        },
        nextSegment : function(){
            var duration = this.advancedOptions.segOutcodeSec - this.advancedOptions.segIncodeSec;
            this.advancedOptions.segIncodeSec = this.advancedOptions.segOutcodeSec;
            this.advancedOptions.segOutcodeSec = this.advancedOptions.segOutcodeSec + duration;
        },
        importTranscript: function (target) {
            var newCaptionLine = true;
            this.previewText = "";
            var captionBuffer = [];
            var captionLine = {
                startTime: "",
                endTime: "",
                text: "",
                breaks: 0,
                confidence: []
            };

            var rawTranscript = this.$store.state.editor.assetTranscription.text;

            if (rawTranscript === "") {
                this.previewText = "No Ai Transcript to Display";
            } else {
                rawTranscript = JSON.parse(rawTranscript);
                var transcriptWords = rawTranscript[rawTranscript.length - 1].alternatives.filter(words => {
                    return words.words != undefined;
                })[0].words;

                for (var j = 0; j < transcriptWords.length; j++) {
                    if (this.advancedOptions.show && this.advancedOptions.segmentImport && parseFloat(transcriptWords[j].startTime.replace("s", "")) >= this.advancedOptions.segIncodeSec && parseFloat(transcriptWords[j].startTime.replace("s", "")) <= this.advancedOptions.segOutcodeSec || (!this.advancedOptions.show || !this.advancedOptions.segmentImport)) {
                        var text = transcriptWords[j];
                        if (newCaptionLine) {
                            captionLine.startTime = text.startTime;
                            newCaptionLine = false;
                        }

                        if (j > 0 && parseFloat(text.startTime.replace("s", "")) - parseFloat(transcriptWords[j - 1]
                                .endTime.replace("s", "")) >= this.maxWordDuration) {
                            captionLine.endTime = globalFunc.getEndTimeWithPadding(transcriptWords[j - 1].endTime, this.padding, text.startTime);
                            captionBuffer.push(captionLine);
                            var captionLine = {
                                startTime: text.startTime,
                                endTime: "",
                                text: text.word + " ",
                                breaks: 0,
                                confidence: [text.confidence]
                            };
                        } else if (this.endLineOnSpeaker && j > 0 && text.speakerTag != transcriptWords[j - 1].speakerTag) {
                            captionLine.endTime = globalFunc.getEndTimeWithPadding(transcriptWords[j - 1].endTime, this.padding, text.startTime);
                            captionBuffer.push(captionLine);
                            var captionLine = {
                                startTime: text.startTime,
                                endTime: "",
                                text: text.word + " ",
                                breaks: 0,
                                confidence: [text.confidence]
                            };
                        } else if ((captionLine.text.split("<br>")[captionLine.text.split("<br>").length - 1].length +
                                text.word.length) >= this.maxCharacter) {
                            if ((captionLine.breaks + 1) == this.maxLines) {
                                if (transcriptWords[j + 1]) {
                                    captionLine.endTime = globalFunc.getEndTimeWithPadding(text.startTime, this.padding, transcriptWords[j + 1].startTime);
                                } else {
                                    captionLine.endTime = parseFloat(text.startTime.replace("s", "")) + this.padding + "s";
                                }

                                captionBuffer.push(captionLine);
                                var captionLine = {
                                    startTime: text.startTime,
                                    endTime: "",
                                    text: text.word + " ",
                                    breaks: 0,
                                    confidence: [text.confidence]
                                };
                            } else {
                                captionLine.text += "<br>" + text.word + " ";
                                captionLine.confidence.push(text.confidence);
                                captionLine.breaks++;
                                if (this.endLineOnPunctuation && ["!", ".", "?"].indexOf(text.word[text.word.length -
                                        1]) > -1) {

                                    if (transcriptWords[j + 1]) {
                                        captionLine.endTime = globalFunc.getEndTimeWithPadding(text.startTime, this.padding, transcriptWords[j + 1].startTime);
                                    } else {
                                        captionLine.endTime = parseFloat(text.startTime.replace("s", "")) + this.padding + "s";;
                                    }

                                    captionBuffer.push(captionLine);
                                    var captionLine = {
                                        startTime: text.startTime,
                                        endTime: "",
                                        text: "",
                                        breaks: 0,
                                        confidence: []
                                    };
                                }
                            }

                        } else {
                            captionLine.text += text.word + " ";
                            captionLine.confidence.push(text.confidence);
                            if (this.endLineOnPunctuation && ["!", ".", "?"].indexOf(text.word[text.word.length - 1]) >
                                -1) {

                                if (transcriptWords[j + 1]) {
                                    captionLine.endTime = globalFunc.getEndTimeWithPadding(text.startTime, this.padding, transcriptWords[j + 1].startTime);
                                } else {
                                    captionLine.endTime = parseFloat(text.startTime.replace("s", "")) + this.padding + "s";
                                }

                                captionBuffer.push(captionLine);
                                var captionLine = {
                                    startTime: text.startTime,
                                    endTime: "",
                                    text: "",
                                    breaks: 0,
                                    confidence: []
                                };
                            }
                        }
                    }
                }

                if (captionLine.text != "") {
                    captionLine.endTime = parseFloat(text.endTime.replace("s", "")) + this.padding + "s";
                    captionBuffer.push(captionLine);
                    var captionLine = {
                        startTime: "",
                        endTime: "",
                        text: "",
                        breaks: 0,
                        confidence: []
                    };
                    newCaptionLine = true;
                }

                if (captionLine.text != "") {
                    captionLine.endTime = parseFloat(text.endTime.replace("s", "")) + this.padding + "s";
                    captionBuffer.push(captionLine);
                }

                captionBuffer.forEach(captionEvent =>{
                    captionEvent.text = captionEvent.text.trim().replace(/\s\s+/g, ' ');
                });

                if (target === 'project') {
                    this.$store.commit('importTranscript', {captions : captionBuffer, append : this.append});
                } else {
                    var preview = "";
                    captionBuffer.forEach(captionLine => {
                        if (captionLine.text != "") {
                            try {
                                var confSum = captionLine.confidence.reduce(function (a, b) {
                                    return a + b;
                                });
                                var confAvg = Math.floor((confSum / captionLine.confidence.length) * 100);
                                preview += captionLine.startTime + " --> " + captionLine.endTime + "\n";
                                preview += captionLine.text.replace(/<br>/gi, "\n") + " (" + confAvg + ")";
                                preview += "\n\n";
                            } catch (e) {
                                console.log("Failed to create preview");
                                console.log(e.message);
                                console.log(captionLine);
                            }
                        }
                    });
                    this.previewText = preview;
                }
            }
        }
    }
}
</script>
