<template>
	<LrgModalTemplate Title="Welcome" ModalId="ProjectsModal">
		<h3 class="text-center"><img src="@/icon.png" width="35px" class="mr-2"/>Welcome to Closed Caption Creator</h3>
		<p>Recent Projects</p>

		<p class="text-center" v-if="savedProjects.length == 0"><i>Is this your first time?</i><br/>
		Watch our Getting Started series on YouTube.<br/><br/>
		
		<a href="https://www.youtube.com/watch?v=y-fURd4JmC4" target="_BLANK"><button class="btn btn-warning"><i class="far fa-play-circle"></i><strong> Help me get started</strong></button></a><br/><br/>
		Not your first time? Click the <i>New Project</i> button below to start.</p>

		<!-- Projects List -->
		<button v-for="project in savedProjects" v-bind:key="project.id" @click="load(project.id)" class="btn btn-outline-light btn-block" style="margin:0; border-radius: 0px;">
			<small>{{project.uid}}</small>   {{project.title}}   <i class="fas fa-stopwatch"></i> {{project.framerate}}   <i class="far fa-calendar"></i> {{project.last_edited}}
		</button>


		<div class="modal-footer">
			<a class="btn-primary btn" href="#" data-toggle="modal" data-target="#NewProjectModal" data-dismiss="modal">
				<i class="fas fa-plus"></i> New Project
			</a>
		</div>

	</LrgModalTemplate>
</template>
<script>
	import LrgModalTemplate from './LrgModalTemplate'
	import firebase from '@/firebase/config'
	import db from '@/firebase/firestore'
	export default {
		name: 'ProjectsModal',
		data () {
			return {
				savedProjects : [],
			}
		},
		mounted : function() {
			if (this.$store.state.editor.onlineStatus){
				var self = this;
				var authId = firebase.auth().currentUser.uid;
				var userRef = db.collection("users").doc(authId);
				userRef.get().then(function(doc) {
					if (doc.exists) {
						db.collection("users").doc(authId)
						.onSnapshot(function(userDoc) {
							self.savedProjects = userDoc.data().projects.filter(function(projectData){
								try {
									projectData.last_edited = projectData.last_edited.toDate();
								} catch(e){
									projectData.last_edited = '';
								}							
								return  projectData.title != "*EMPTY"
							}).sort(function(a, b) {
								var lastEditedA = a.last_edited;
								var lastEditedB = b.last_edited;
								return new Date(lastEditedB).getTime() - new Date(lastEditedA).getTime();
							});							
						});
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
						var batch = db.batch();
						batch.set(userRef, {
							first_name: "",
							last_name: "",
							projects : [
								{
									framerate : "",
									id : 0,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 1,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 2,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 3,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 4,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 5,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 6,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 7,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 8,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
								{
									framerate : "",
									id : 9,
									last_edited : "",
									title : "*EMPTY",
									uid : ""
								},
							]
						});

						var projectRef = db.collection("users").doc(authId).collection("projects").doc("0");
						batch.set(projectRef, {});
						batch.commit().then(function () {
							db.collection("users").doc(authId)
							.onSnapshot(function(userDoc) {
								self.savedProjects = userDoc.data().projects.filter(function(projectData){
									try {
										projectData.last_edited = projectData.last_edited.toDate();
									} catch(e){
										projectData.last_edited = '';
									}							
									return  projectData.title != "*EMPTY"
								}).sort(function(a, b) {
									var lastEditedA = a.last_edited;
									var lastEditedB = b.last_edited;
									return new Date(lastEditedA).getTime() - new Date(lastEditedB).getTime();
								});							
							});
						});
					}
				}).catch(function(error) {
					console.log("Error getting document:", error);
				});
			}
			
			
		},
		components: {
			LrgModalTemplate
		},
		methods: {
			load : function(id){
				var self = this;
				var authId = firebase.auth().currentUser.uid;
				var projectRef = db.collection("users").doc(authId).collection("projects").doc(id.toString());
				projectRef.get().then(function(doc) {
					if (doc.exists) {
						var project = doc.data();
						self.$store.commit("updateOrCreateProjectId");
						self.$store.commit('importCaptionsFromStore', project);
						self.$store.commit('importSettingsFromStore', project);
						self.$store.commit('importMetadataFromStore', project);
						self.$store.commit('importSpeakersFromStore', project);
						self.$store.commit('importTranscriptFromProject', project);						
						self.$store.commit('importAdFromProject', project);
            			self.$store.commit('importAiTranscriptFromProject', project);

						if (project.version && project.version.split(".")[1] == self.$store.state.editor.version.split(".")[1]){
							if (project.projectSettings.mediaInfo.source === "Local Storage"){
								self.$store.commit('importMedia', {
									projectImport : true,
									source : project.projectSettings.mediaInfo.source,
									url : [{
									type : project.projectSettings.mediaInfo.fileType,
									path: project.projectSettings.mediaInfo.path,
									name : project.projectSettings.mediaInfo.fileName
									}]  
								});
							} else {
								self.$store.commit('importMedia', {
									source : project.projectSettings.mediaInfo.source,
									url : project.projectSettings.fileUrl
								});
							}
						}

					} else {
						self.$notify({
							title: 'Load Project',
							type: 'danger',
							text: 'Project data does not exist'
						});
					}
				}).catch(function(error) {
					self.$notify({
						title: 'Load Project',
						type: 'danger',
						text: 'Project load failed. ' + error
					});
				});
				$('#ProjectsModal').modal('toggle');				
			}
		}
	}
</script>