export default {
    languages: [
        "Arabic",
        "Czech (Czech Republic)",
        "Danish (Denmark)",
        "Dutch (Netherlands)",
        "English (Australia)",
        "English (India)",
        "English (UK)",
        "English (US)",
        "Filipino (Philippines)",
        "Finnish (Finland)",
        "French (Canada)",
        "French (France)",
        "German (Germany)",
        "Greek (Greece)",
        "Hindi (India)",
        "Hungarian (Hungary)",
        "Indonesian (Indonesia)",
        "Italian (Italy)",
        "Japanese (Japan)",
        "Korean (South Korea)",
        "Mandarin Chinese",
        "Norwegian (Norway)",
        "Polish (Poland)",
        "Portuguese (Brazil)",
        "Portuguese (Portugal)",
        "Russian (Russia)",
        "Slovak (Slovakia)",
        "Swedish (Sweden)",
        "Turkish (Turkey)",
        "Ukrainian (Ukraine)",
        "Vietnamese (Vietnam)",
    ],
    languageCodes: {
        "Arabic": "ar-XA",
        "Czech (Czech Republic)": "cs-CZ",
        "Danish (Denmark)": "da-DK",
        "Dutch (Netherlands)": "nl-NL",
        "English (Australia)": "en-AU",
        "English (India)": "en-IN",
        "English (UK)": "en-GB",
        "English (US)": "en-US",
        "Filipino (Philippines)": "fil-PH",
        "Finnish (Finland)": "fi-FI",
        "French (Canada)": "fr-CA",
        "French (France)": "fr-FR",
        "German (Germany)": "de-DE",
        "Greek (Greece)": "el-GR",
        "Hindi (India)": "hi-IN",
        "Hungarian (Hungary)": "hu-HU",
        "Indonesian (Indonesia)": "id-ID",
        "Italian (Italy)": "it-IT",
        "Japanese (Japan)": "ja-JP",
        "Korean (South Korea)": "ko-KR",
        "Mandarin Chinese": "cmn-CN",
        "Norwegian (Norway)": "nb-NO",
        "Polish (Poland)": "pl-PL",
        "Portuguese (Brazil)": "pt-BR",
        "Portuguese (Portugal)": "pt-PT",
        "Russian (Russia)": "ru-RU",
        "Slovak (Slovakia)": "sk-SK",
        "Swedish (Sweden)": "sv-SE",
        "Turkish (Turkey)": "tr-TR",
        "Ukrainian (Ukraine)": "uk-UA",
        "Vietnamese (Vietnam)": "vi-VN"
    },
    voices : {
        "ar-XA" : {
            MALE : ["ar-XA-Wavenet-B", "ar-XA-Wavenet-C"],
            FEMALE:["ar-XA-Wavenet-A"]
        },
        "cs-CZ" : {
            MALE : [],
            FEMALE:["cs-CZ-Wavenet-A"]
        },
        "da-DK" : {
            MALE : [],
            FEMALE:["da-DK-Wavenet-A"]
        },
        "nl-NL" : {
            MALE : ["nl-NL-Wavenet-B", "nl-NL-Wavenet-C"],
            FEMALE:["nl-NL-Wavenet-A", "nl-NL-Wavenet-D", "nl-NL-Wavenet-E"]
        },
        "en-AU" : {
            MALE : ["en-AU-Wavenet-B","en-AU-Wavenet-D"],
            FEMALE:["en-AU-Wavenet-A","en-AU-Wavenet-C"]
        },
        "en-IN" : {
            MALE : ["en-IN-Wavenet-B", "en-IN-Wavenet-C"],
            FEMALE:["en-IN-Wavenet-A"]
        },
        "en-GB" : {
            MALE : ["en-GB-Wavenet-B","en-GB-Wavenet-D"],
            FEMALE:["en-GB-Wavenet-A","en-GB-Wavenet-C"]
        },
        "en-US" : {
            MALE : ["en-US-Wavenet-A", "en-US-Wavenet-B", "en-US-Wavenet-D"],
            FEMALE:["en-US-Wavenet-C", "en-US-Wavenet-E", "en-US-Wavenet-F"]
        },
        "fil-PH" : {
            MALE : [],
            FEMALE:["fil-PH-Wavenet-A"]
        },
        "fi-FI" : {
            MALE : [],
            FEMALE:["fi-FI-Wavenet-A"]
        },
        "fr-CA" : {
            MALE : ["fr-CA-Wavenet-B", "fr-CA-Wavenet-D"],
            FEMALE:["fr-CA-Wavenet-A", "fr-CA-Wavenet-C"]
        },
        "fr-FR" : {
            MALE : ["fr-FR-Wavenet-B", "fr-FR-Wavenet-D"],
            FEMALE:["fr-FR-Wavenet-A", "fr-FR-Wavenet-C"]
        },
        "de-DE" : {
            MALE : ["de-DE-Wavenet-B", "de-DE-Wavenet-D"],
            FEMALE:["de-DE-Wavenet-A", "de-DE-Wavenet-C"]
        },
        "el-GR" : {
            MALE : [],
            FEMALE:["el-GR-Wavenet-A"]
        },
        "hi-IN" : {
            MALE : ["hi-IN-Wavenet-B", "hi-IN-Wavenet-C"],
            FEMALE:["hi-IN-Wavenet-A"]
        },
        "hu-HU" : {
            MALE : [],
            FEMALE:["hu-HU-Wavenet-A"]
        },
        "id-ID" : {
            MALE : ["id-ID-Wavenet-B", "id-ID-Wavenet-C"],
            FEMALE:["id-ID-Wavenet-A"]
        },
        "it-IT" : {
            MALE : ["it-IT-Wavenet-C", "it-IT-Wavenet-D"],
            FEMALE:["it-IT-Wavenet-A", "it-IT-Wavenet-B"]
        },
        "ja-JP" : {
            MALE : ["ja-JP-Wavenet-C", "ja-JP-Wavenet-D"],
            FEMALE:["ja-JP-Wavenet-A", "ja-JP-Wavenet-B"]
        },
        "ko-KR" : {
            MALE : ["ko-KR-Wavenet-C", "ko-KR-Wavenet-D"],
            FEMALE:["ko-KR-Wavenet-A", "ko-KR-Wavenet-B"]
        },
        "cmn-CN" : {
            MALE : ["cmn-CN-Wavenet-B", "cmn-CN-Wavenet-C"],
            FEMALE:["cmn-CN-Wavenet-A"]
        },
        "nb-NO" : {
            MALE : ["nb-NO-Wavenet-B", "nb-NO-Wavenet-D"],
            FEMALE:["nb-NO-Wavenet-A", "nb-NO-Wavenet-C", "nb-NO-Wavenet-E"]
        },
        "pl-PL" : {
            MALE : ["pl-PL-Wavenet-B", "pl-PL-Wavenet-C"],
            FEMALE:["pl-PL-Wavenet-A", "pl-PL-Wavenet-D", "pl-PL-Wavenet-E"]
        },
        "pt-BR" : {
            MALE : [],
            FEMALE:["pt-BR-Wavenet-A"]
        },
        "pt-PT" : {
            MALE : ["pt-PT-Wavenet-B", "pt-PT-Wavenet-C"],
            FEMALE:["pt-PT-Wavenet-A", "pt-PT-Wavenet-D"]
        },
        "ru-RU" : {
            MALE : ["ru-RU-Wavenet-B", "ru-RU-Wavenet-D"],
            FEMALE:["ru-RU-Wavenet-A", "ru-RU-Wavenet-C"]
        },
        "sk-SK" : {
            MALE : [],
            FEMALE:["sk-SK-Wavenet-A"]
        },
        "sv-SE" : {
            MALE : [],
            FEMALE:["sv-SE-Wavenet-A"]
        },
        "tr-TR" : {
            MALE : ["tr-TR-Wavenet-B", "tr-TR-Wavenet-E"],
            FEMALE:["tr-TR-Wavenet-A", "tr-TR-Wavenet-C", "tr-TR-Wavenet-D"]
        },
        "uk-UA" : {
            MALE : [],
            FEMALE: ["uk-UA-Wavenet-A"]
        },
        "vi-VN" : {
            MALE : ["vi-VN-Wavenet-B", "vi-VN-Wavenet-D"],
            FEMALE:["vi-VN-Wavenet-A", "vi-VN-Wavenet-C"]
        },
    }    

}
