<template>
	<ModalTemplate Title="Export Captions" ModalId="ExportCaptionModal">
		<form>
			<div class="form-group">
				<label for="Caption Format">Format</label>
				<select class="form-control" v-model="captionFormat">
					<option>CSV</option>
					<option>DFXP</option>
					<!-- <option>FCPXML</option> -->
					<option :disabled="appVersion === 'starter'">MCC</option>
					<option>SBV</option>
					<option :disabled="appVersion === 'starter'">SCC</option>
					<option>SRT</option>
					<option :disabled="appVersion === 'starter'">STL</option>
					<!-- <option>SUB</option> -->
					<option :disabled="appVersion === 'starter'">TTML</option>
					<option>Avid DS (TXT)</option>
					<option>VTT</option>
					<option>XML</option>
				</select>
			</div>
			<div class="custom-control custom-switch" v-if="captionFormat==='VTT'">
				<input type="checkbox" class="custom-control-input" id="encodeMetadataSwitch" v-model="encodeMetadata">
				<label class="custom-control-label" for="encodeMetadataSwitch">Encode Metadata Notes</label>
			</div>
			<div class="custom-control custom-switch" v-if="captionFormat==='VTT'">
				<input type="checkbox" class="custom-control-input" id="encodePositionSwitch" v-model="encodePosition">
				<label class="custom-control-label" for="encodePositionSwitch">Encode Position Data</label>
				<br/><br/>
			</div>
			Notes:
			<div>
				<small v-if="captionFormat==='FCPXML'">FCPXML 1.5 is used to import caption/subtitle information into NLE
					applications such as Final Cut Pro X or Black
					Magic Design's Davinci Resolve</small>
				<small v-if="captionFormat==='CSV'">CSV files are a great interchange format that we've made available for use in
					programs such as Adobe's After Effects.</small>
				<small v-if="captionFormat==='SCC'">SCC Files are exported using Channel 1 codes and support positioning. SCC
					files are intended for DVD authoring software and television broadcast</small>
				<small v-if="captionFormat==='MCC'">MCC Files are encoded using both 608 (Channel 1) and 708 caption data.
					Compatible with Adobe Premiere Pro and MacCaption</small>
				<small v-if="captionFormat==='SRT'">SRT Files are exported using a 0 based timecode and support for basic
					formatting (underline, italics, and bolding). SRT files are supported by YouTube and other web platforms</small>
				<small v-if="captionFormat==='SBV'||captionFormat==='SUB'">SBV/SUB Files offer <strong>no</strong> support for
					formatting or positioning</small>
				<small v-if="captionFormat==='STL'">STL Files are exported using a 0 based timecode and support for position, and
					basic formatting (underline, italics, and bolding)</small>
				<small v-if="captionFormat==='VTT'">WebVTT Files are exported using a 0 based timecode and support for basic
					formatting (underline, italics, and bolding)</small>
				<small v-if="captionFormat==='Avid DS (TXT)'">Comptiable with Avid DS file format</small>
				<small v-if="captionFormat==='TTML'||captionFormat==='XML'||captionFormat==='DFXP'">TTML/SMPTE-TT Files are
					exported using <em>hours:minutes:seconds:frames</em> time formats and offers support for basic formatting and
					position</small>
				<br />
				<br />
			</div>
			<button @click="exportCaptionFile" type="button" class="btn btn-primary" data-dismiss="modal"><i class="fas fa-file-export"></i>
				Export
			</button>
			
			<br />
			<br />
			<small><i class="fas fa-bolt"></i> Powered by <strong>www.closedcaptionconverter.com</strong></small>
		</form>
	</ModalTemplate>
</template>
<script>
	import ModalTemplate from './ModalTemplate'
	import _SanitizeHtml from 'sanitize-html'
  	import globalFunc from '../../assets/js/functions/globalFunc'
	export default {
		name: 'ExportCaptionModal',
		components: {
			ModalTemplate
		},
		methods: {
			exportCaptionFile: function () {
				if (this.$store.state.editor.view === "subtitle") {
					this.$store.commit('copySubtitlesToCaptions');
				} else {
					this.$store.commit('copyCaptionsToSubtitles');
				}	
				console.log(this.$store.state.editor.captionFormat);
        this.$store.commit('exportCaptionFile');
			}
		},
		computed: {
			appVersion : function(){
				return this.$store.state.editor.applicationVersion;
			},
			captionFormat: {
				get() {
					return this.$store.state.editor.captionFormat;
				},
				set(captionFormat) {
					this.$store.commit('updateCaptionFormat', captionFormat);
				}
			},
			encodeMetadata : {
				get(){
					return this.$store.state.editor.webVttOptions.encodeMetadata;
				},
				set(setting){
					this.$store.commit('updateWebVttOption', {option : 'encodeMetadata', value : setting});
				}
			},
			encodePosition : {
				get(){
					return this.$store.state.editor.webVttOptions.encodePosition;
				},
				set(setting){
					this.$store.commit('updateWebVttOption', {
						option : 'encodePosition',
						value : setting
					});
				}
			}
		}
	}
</script>