const uniqueId = require('lodash/uniqueId.js');
const defaults = require('./defaults.js');
module.exports = class Line {
    constructor(options = {
        text : "",
        posX : 0,
        posY : 0,
        bold : false,
        italics : false,
        underline : false
    }) {
        this.id = uniqueId(),
        this.text = options.text || "",
        this.posX = options.posX || 0,
        this.posY = options.posY || 0,
        this.bold = options.bold,
        this.italics = options.italics,
        this.underline = options.underline
    }

    updateXPos(pos) {
        this.posX = pos;
    }

    updateYPos(pos) {
        this.posY = pos;
    }

    toggleBold() {
        this.bold = !this.bold;
    }

    toggleItalics(){
        this.italics = !this.italics;
    }

    toggleUnderline(){
        this.underline = !this.underline;
    }

    horzAlignLeft(){
        this.posX = 0;
    }
    horzAlignCenter(columns=defaults.window.columns) {
        let lineLength = this.text.trim().length;
        this.posX = ((Math.max(0, (columns - lineLength) / 2)) / columns) * 100;
    }

    horzAlignRight(columns = defaults.window.columns) {
        let lineLength = this.text.trim().length;
        this.posX = ((Math.max(0, columns - lineLength)) / columns) * 100;
    }
}
