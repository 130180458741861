const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const nciFunc = require('../functions/profiles/nciCap.js');
module.exports = {
    decode: function (fileContents, options) {
        let events = [], event, char, header, history, rawEvent, codePage = 1252, tcQueue = [], tcBuffer;

        hexBuffer = fileContents.match(/(..?)/g);
        tcBuffer = fileContents.split("ffff00000000ffffffffffffffffffff");
        header = hexBuffer.splice(0, 230);

        tcBuffer.shift(); //remove the header from the tc buffer
        tcBuffer.pop(); //remove text;
        
        tcBuffer = tcBuffer.filter(entry =>{
            return entry !== "00000000ffffffffffffffff";
        });

        tcBuffer = tcBuffer.join("").split("00000000ffffffffffffffff");

        tcBuffer.forEach(tcData => {
            if (tcData.length === 32){
                tcData = tcData.match(/(..?)/g);
                tcQueue.push(nciFunc.decodeTc(tcData.splice(6, 4)));
            } else if (tcData.length === 88){
                tcData = tcData.match(/(..?)/g);
                tcQueue.push(nciFunc.decodeTc(tcData.splice(6, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(30, 4)));
            } else if (tcData.length === 200){
                tcData = tcData.match(/(..?)/g);
                tcQueue.push(nciFunc.decodeTc(tcData.splice(6, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(30, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(54, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(78, 4)));
            } else if (tcData.length === 28) {
                tcData = tcData.match(/(..?)/g);
                tcQueue.push(nciFunc.decodeTc(tcData.splice(10, 4)));
            } else if (tcData.length === 256) {
                tcData = tcData.match(/(..?)/g);
                tcQueue.push(nciFunc.decodeTc(tcData.splice(10, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(30, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(58, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(78, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(102, 4)));
            } else if (tcData.length === 144) {
                tcData = tcData.match(/(..?)/g);
                tcQueue.push(nciFunc.decodeTc(tcData.splice(6, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(30, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(54, 4)));
            } else if (tcData.length === 312) {
                tcData = tcData.match(/(..?)/g);    
                tcQueue.push(nciFunc.decodeTc(tcData.splice(6, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(30, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(54, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(78, 4)));   
                tcQueue.push(nciFunc.decodeTc(tcData.splice(102, 4)));
                tcQueue.push(nciFunc.decodeTc(tcData.splice(126, 4)));
            }
        });
        
        while (hexBuffer.length > 0) {
            char = hexBuffer.shift();
            
            if (history !== "ff" && char === "ff" && hexBuffer[0] === "ff" && hexBuffer[1] !== "ff" && hexBuffer[63] === "ff" && hexBuffer[64]==="ff"){
                event = new ccEvent();
                event.insertLine();

                hexBuffer.shift(); //remove 'ff'
                rawEvent = hexBuffer.splice(0, 62);
                rawEvent.shift(); //Remove Metadata;
                rawEvent.shift(); //Remove Metadata;

                while (rawEvent.length > 0){
                    char = rawEvent.shift();
                    if (char === "00"){
                        rawEvent = [];
                        if (event.lines[0].text !== ""){

                            if (tcQueue.length > 0){
                                event.start = timecodeLib.tcToSec(tcQueue.shift(), options.frameRate);
                            } else {
                                event.start = 10;
                            }
                            
                            if (tcQueue.length > 0){
                                event.end = timecodeLib.tcToSec(tcQueue.shift(), options.frameRate);
                            } else {
                                event.end = 10;
                            }

                            event.alignTextCenter();                            
                            events.push(event);
                        }
                        
                    } else if (char === "0d"){
                        rawEvent.shift(); //Remove Metadata
                        rawEvent.shift(); //Remove Metadata
                        event.insertLine();
                    } else if (char === "87"){
                        event.lines[event.lines.length - 1].text += "♪";
                    } else if (char === "a0"){
                        event.lines[event.lines.length - 1].bold = true;
                    } else if (char === "a7"){
                        event.lines[event.lines.length - 1].italics = true;
                    } else { 
                        event.lines[event.lines.length - 1].text += nciFunc.decodeChar(char, codePage);
                    }
                }
                char = hexBuffer.shift(); //Remove "ff"
                char = hexBuffer.shift(); //Remove "ff"
            }  

            history = char;
        }
        return events;
    },

    encode: function (events, options) {
        throw new Error("NCI Caption files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (events) {
            return events;
        },

        decode: function (fileContents) {
            return fileContents.toLowerCase();
        }
    },

    postProcess: {
        encode: function (fileContents) {
            return fileContents;
        },

        decode: function (events) {

            return events;
        }
    },

}