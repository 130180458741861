<template>
<div class='container-fluid'>
    <div class='row pt-5 mt-2'>
        <div id='viewer' class='col-sm-12' :class="{'col-md-5' : aspectRatio!='9:16', 'col-md-3' : aspectRatio =='9:16'}">
            <Viewer></Viewer>
            <Search v-if="subViewerOption === 'search'"></Search>
            <!-- <Spellcheck v-else-if="subViewerOption === 'spellcheck' && ['subtitle', 'classic', 'default'].indexOf(activeView) > -1" /> -->
            <CaptionTimerControls v-else-if="['subtitle', 'classic', 'default'].indexOf(activeView) > -1"></CaptionTimerControls>
        </div>

        <div id='editor' class="col-sm-12" :class="{'col-md-7' : aspectRatio!='9:16', 'col-md-9' : aspectRatio =='9:16'}">
            <TranscriptEditor v-if="activeView === 'transcript'" />
            <ClassicEditor v-else-if="activeView === 'classic'" />
            <SubtitleEditor v-else-if="activeView === 'subtitle'" />
            <!-- <CCEditor v-else-if="activeView === 'ccEditor'" /> -->
            <AudioDescriptionEditor v-else-if="activeView === 'audio description' && plugins.indexOf('ad')>-1" />
            <TimelineEditor v-else />
            <ProjectInfo/>
        </div>
    </div>
    <div class='row d-none d-md-block'>
        <div id='ccTimeline'>
            <TimelineCompressed />
        </div>
    </div>
    <ExportVideo v-if="appEnv === 'desktop'" />
    <ExportAd v-if="appEnv === 'desktop' && plugins.indexOf('ad')>-1" />
    <NewProjectModal></NewProjectModal>
    <ImportVideoModal></ImportVideoModal>
    <ExportCaptionModal></ExportCaptionModal>
    <ExportTranscriptModal></ExportTranscriptModal>
    <ExportDataModal></ExportDataModal>
    <Projects />
    <LoadProject />
    <SaveProject />
    <Welcome />
    <TimecodeOffsetModal></TimecodeOffsetModal>
    <ProjectIncodeModal></ProjectIncodeModal>
    <AutomaticTranslation v-if="appVersion === 'pro'" />
    <AutomaticCaptioning v-if="appEnv === 'desktop' && appVersion === 'pro'" />
    <AutomaticTiming v-if="appEnv === 'desktop' && appVersion === 'pro'" />
    <SceneChange v-if="appEnv === 'desktop'"/>
    <StartingLineNumberModal></StartingLineNumberModal>
    <ProjectFramerateModal></ProjectFramerateModal>
    <ImportCaptionsModal></ImportCaptionsModal>
    <ImportPlainTextModal></ImportPlainTextModal>
    <ImportProjectModal></ImportProjectModal>
    <ImportTranscriptModal v-if="appVersion === 'pro'" />
    <OptionsModal></OptionsModal>
    <FeatureRequest></FeatureRequest>
    <BugReport></BugReport>
    <About></About>
    <ShortcutKeys></ShortcutKeys>
    <Toolbar></Toolbar>
</div>
</template>

<script>
import globalFunc from '../assets/js/functions/globalFunc'
import firebase from '@/firebase/config'
import Toolbar from '../components/Toolbar/Toolbar'
import Viewer from '../components/Viewer/Viewer'
import CaptionTimerControls from '../components/Viewer/CaptionTimerControls'
import TranscriptEditor from '../components/Transcript/TranscriptEditor'
import ClassicEditor from '../components/Classic/ClassicEditor'
import SubtitleEditor from '../components/Subtitle/SubtitleEditor'
/* import CCEditor from '../components/CCEditor/CCEditor' */
import AudioDescriptionEditor from '../components/AudioDescription/AudioDescriptionEditor'
import TimelineEditor from '../components/Editor/TimelineEditor'
import TimelineCompressed from '../components/Timeline/TimelineCompressed'
import Search from '../components/Viewer/Search'
//import Spellcheck from '../components/Viewer/Spellcheck'
import NewProjectModal from '../components/Modals/NewProject'
import ImportVideoModal from '../components/Modals/ImportVideo'
import Projects from '../components/Modals/Projects'
import LoadProject from '../components/Modals/LoadProject'
import SaveProject from '../components/Modals/SaveProject'
import ExportCaptionModal from '../components/Modals/ExportCaption'
import ExportTranscriptModal from '../components/Modals/ExportTranscript'
import ExportDataModal from '../components/Modals/ExportData'
import TimecodeOffsetModal from '../components/Modals/TimecodeOffset'
import ProjectIncodeModal from '../components/Modals/ProjectIncode'
import AutomaticTranslation from '../components/Modals/AutomaticTranslation'
import AutomaticCaptioning from '../components/Modals/AutomaticCaptioning'
import SceneChange from '../components/Modals/SceneChange'
import AutomaticTiming from '../components/Modals/AutomaticTiming'
import StartingLineNumberModal from '../components/Modals/StartingLineNumber'
import ProjectFramerateModal from '../components/Modals/ProjectFramerate'
import ImportCaptionsModal from '../components/Modals/ImportCaptions'
import ImportPlainTextModal from '../components/Modals/ImportPlainText'
import ImportTranscriptModal from '../components/Modals/ImportTranscript'
import ImportProjectModal from '../components/Modals/ImportProject'
import OptionsModal from '../components/Modals/Options'
import ShortcutKeys from '../components/Modals/ShortcutKeys'
import BugReport from '../components/Modals/BugReport'
import About from '../components/Modals/About'
import FeatureRequest from '../components/Modals/FeatureRequest'
import ExportVideo from "../components/Modals/ExportVideo";
import ExportAd from "../components/Modals/ExportAudioDescription";
import Welcome from "../components/Modals/Welcome";
import ProjectInfo from "../components/ProjectInfo";
export default {
    name: 'Editor',
    components: {
        Toolbar,
        Viewer,
        CaptionTimerControls,
        AudioDescriptionEditor,
        TranscriptEditor,
        ClassicEditor,
        SubtitleEditor,
        // CCEditor,
        TimelineEditor,
        TimelineCompressed,
        Search,
        //Spellcheck,
        NewProjectModal,
        ImportVideoModal,
        ExportCaptionModal,
        ExportTranscriptModal,
        ExportDataModal,
        ExportVideo,
        ExportAd,
        Projects,
        SaveProject,
        LoadProject,
        TimecodeOffsetModal,
        ProjectIncodeModal,
        StartingLineNumberModal,
        ProjectFramerateModal,
        AutomaticTranslation,
        AutomaticCaptioning,
        SceneChange,
        AutomaticTiming,
        ImportCaptionsModal,
        ImportPlainTextModal,
        ImportProjectModal,
        ImportTranscriptModal,
        OptionsModal,
        FeatureRequest,
        BugReport,
        About,
        ShortcutKeys,
        ProjectInfo,
        Welcome
    },
    computed: {
        appEnv: function () {
            return process.env.VUE_APP_ENV
        },
        appVersion: function () {
            return this.$store.state.editor.applicationVersion;
        },
        appMode: function () {
            return process.env.VUE_APP_MODE
        },
        plugins: function () {
            return this.$store.state.editor.pluginList;
        },
        subViewerOption: function () {
            return this.$store.state.editor.subViewerOption;
        },
        subViewerOption: function () {
            return this.$store.state.editor.subViewerOption;
        },
        aspectRatio: function () {
            return this.$store.state.editor.player.aspectRatio;
        },
        activeView: function () {
            return this.$store.state.editor.view;
        }
    },
    mounted: function () {
        $('#WelcomeModal').modal('toggle');
        var self = this;
        var email = firebase.auth().currentUser.email;
        firebase.functions().httpsCallable('checkUserSubscription')(email).then(res => {
            if (res && ["in_trial", "active", "non_renewing"].indexOf(res.data.status) > -1) {
                var subStatus = res.data.status
                var subPlan = res.data.plan_id.split("-")[0];
                var plugins = res.data.addons;

                if (subPlan === 'pro' || subPlan === 'starter') {
                    self.$store.commit("updateApplicationVersion", subPlan);
                    self.$store.commit("updatePluginList", plugins);
                    self.$store.commit("updateOrCreateProjectId");
                    
                    if (typeof (Storage) !== "undefined") {
                        localStorage.setItem("planDetails", JSON.stringify(res));
                    }
                } else {
                    alert("There was an error verifying your account. We were unable to find your subscription to Closed Caption Creator. If you do not have an active subscription please renew your license by visiting www.closedcaptioncreator.com or contact support@closedcaptioncreator.com for help.");
                    firebase.auth().signOut();
                    self.$router.push({
                        name: 'Login'
                    });
                }

            } else {
                alert("Oops! It appears your account is no longer active. Please renew your license or contact support@closedcaptioncreator.com for help!");
                firebase.auth().signOut();
                self.$router.push({
                    name: 'Login'
                });
            }
        }).catch(function (e) {
            console.log("ERROR ON PLAN LOOKUP");
            console.log(e.message);
            console.log("Checking local storage...");
            if (typeof (Storage) !== "undefined" && localStorage.getItem('planDetails')) {
                console.log("PlanDetails is set");
                var unixDateNow = Date.now().toString().substring(0, 10);
                console.log(unixDateNow);
                var res = JSON.parse(localStorage.getItem('planDetails'));
                console.log(res);

                if (res && ["in_trial", "active", "non_renewing"].indexOf(res.data.status) > -1) {
                    var subStatus = res.data.status
                    var subPlan = res.data.plan_id.split("-")[0];
                    var plugins = res.data.addons;
                    console.log("Subscription status: " + subStatus);
                    console.log("Plan Id: " + subPlan);

                    self.$store.commit("updateApplicationVersion", subPlan);
                    self.$store.commit("updatePluginList", plugins);
                    self.$store.commit("updateOrCreateProjectId");

                } else {
                    alert("Oops! It appears your account is no longer active. Please renew your license or contact support@closedcaptioncreator.com for help!");
                    firebase.auth().signOut();
                    self.$router.push({
                        name: 'Login'
                    });
                }
            } else {
                alert("Oops! It appears your account is no longer active. Please renew your license or contact support@closedcaptioncreator.com for help!");
                firebase.auth().signOut();
                self.$router.push({
                    name: 'Login'
                });
            }
        });

        firebase.functions().httpsCallable('getReleaseVersion')(email).then(version => {
            if (version.data > self.$store.state.editor.versionNumber) {
                self.$notify({title: 'Update Available', type: 'info', text: 'A new version of Creator is now available. www.closedcaptioncreator.com/upgrade.html'
                });
            }
        }).catch(function (e) {
            console.log("Unable to check current release version. "+e.message);
        });

    },
    beforeCreate: function () {
        this.$store.commit('updateOnlineStatus', window.navigator.onLine);
    },
    destroyed: function () {
        this.$store.commit('clearProjectStore');
        if (typeof (recognition) != "undefined") {
            recognition.stop();
        }
    }
}
</script>

<style src="../assets/external/css/bootstrap.css" lang="css"></style>
<style src="../assets/external/css/animate.css" lang="css"></style>
<style src="../assets/css/plyr.css" lang="css"></style>
<style src="../assets/css/ccCreatorStyle.css" lang="css"></style>
