const ccEvent = require("../event.js");
const timecodeLib = require("../timecode.js");
const striptags = require("striptags");
const eol = require("eol");
module.exports = {
    decode : function(fileContents, options){
        let events = [];
        let subtitleBlocks = fileContents.split("\n\n");
        subtitleBlocks.forEach(block => {
            let event = new ccEvent();;
            let tcFlag = false;
            let blockInfo = block.split("\n").filter(blockLine => {
                return blockLine !== "";
            });

            if (blockInfo.length > 1){
                blockInfo.forEach(line => {
                    if (tcFlag){
                        event.insertLine({
                            text : striptags(line),
                            posX : 0,
                            posY : 0,
                            bold : /<b>|<\/b>/.test(line),
                            italics : /<i>|<\/i>/.test(line),
                            underline : /<u>|<\/u>/.test(line) 
                        });
                    } else {
                        tcFlag = true;
                        let metadata = line.split(" ");
                        event.updateStart(timecodeLib.tcToSec(metadata[2], options.frameRate));
                        event.updateEnd(timecodeLib.tcToSec(metadata[3], options.frameRate));
                    }
                })

                event.syncSubtitleText();
                event.alignTextCenter();
                events.push(event);
            }
        });
        return events;
    },

    encode : function(events, options){
        let fileContents = "";
        events.forEach(function(event, index){
            fileContents+= ("0000" + (index+1)).slice(-4);
            fileContents += " : ";
            fileContents += timecodeLib.secToTc(event.start, options.frameRate) + " " + timecodeLib.secToTc(event.end, options.frameRate) + "\n";
            fileContents += event.text.trim();
            fileContents += "\n\n";
        });
        return fileContents.trim();
    },

    preProcess : {
        encode : function(events){
            /* All */
            return events.filter(event => {
                return event.start != "" && event.end != "" && event.text != ""
            });
        },

        decode : function(fileContents){
            /* All */
            fileContents = eol.lf(fileContents);
            fileContents = fileContents.trim();

            /* Specific */
            fileContents = fileContents.replace(/'(\n){3,}'/gim, "\n\n");

            return fileContents;
        }
    },

    postProcess : {
        encode : function(fileContents){
            fileContents = fileContents.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
            return fileContents;
        },

        decode : function(events){
            return events;
        }
    },

}