/* Closed Caption Library */
const ccFunc = require('./functions/ccFunc');
const ccSettings = require('./functions/ccSettings');
const ccPreProcess = require('./functions/ccPreProcess');
const ccPostProcess = require('./functions/ccPostProcess');
const ccDecode = require('./functions/ccDecode');
const ccImportCaption = require('./functions/ccImport');
const ccExportCaption = require('./functions/ccExport').default;
const nodeTc = require('node-timecodes');
const npmTc = require('timecode').Timecode;
const smpteTc = require('smpte-timecode');
module.exports = {
    import: function (fileContents, fileExt, frameRate, dropFrame) {
        fileExt = ccSettings.extensionMapping[fileExt.toLowerCase()];

        /* Check to see if format is supported */
        if (ccSettings.import.formats.indexOf(fileExt) === -1) {
            throw new Error("Failed to import closed captioning data - source format (" + fileExt + ") not supported");
        }
        
        /* 1. Apply general process */
        fileContents = ccPreProcess.import.all(fileContents);
        
        /* 2. Apply specific format process */
        fileContents = ccPreProcess.import[fileExt](fileContents);
        
        /* 3. Import data (convert to ccprj) */
        var captionData = ccImportCaption[fileExt](fileContents, frameRate, dropFrame);
        
        /* 4. Apply specific format post-process */
        captionData = ccPostProcess.import.all(captionData, frameRate, dropFrame);
        captionData = ccPostProcess.import[fileExt](captionData, frameRate, dropFrame);

        /* 5. Return data */
        return captionData;
        
    },
    createCaptionEvent : function(incode, outcode, incodeSec, outcodeSec, incodeMs, outcodeMs, style, primaryLang, secondaryLang, subtitleText){
        return ccFunc.captionEvent(incode, outcode, incodeSec, outcodeSec, incodeMs, outcodeMs, style, primaryLang, secondaryLang, subtitleText);
    },
    createAdEvent : function(incode, incodeSec, incodeMs, duration, speed, text){
        return ccFunc.adEvent(incode, incodeSec, incodeMs, duration, speed, text);
    },
    calculateTimecodes: function (captions, frameRate, dropFrame) {
        return ccFunc.calculateTimecodes(captions, frameRate, dropFrame);
    },
    correctForMissingTimecodes : function (captions, frameRate, dropFrame){
        return ccFunc.correctForMissingTimecodes(captions, frameRate, dropFrame);
    },
    export: function (captionEvents, fileExt, frameRate, dropFrame, metadata = null, webVttOptions = null, useMsTimecode, incode = null, dtv = false) {
        var fileExt = ccSettings.extensionMapping[fileExt.toLowerCase()],
        captionEvents = ccPreProcess.export.all(captionEvents, frameRate, dropFrame),
        captionEvents = ccPreProcess.export[fileExt](captionEvents, frameRate, dropFrame);
        return ccExportCaption[fileExt](captionEvents, frameRate, dropFrame, metadata, webVttOptions, useMsTimecode, incode, dtv);
    },
    applyJob: function (jobJson, captionEvents, frameRate, dropFrame) {
        return ccFunc.applyJobConfig(jobJson, captionEvents, frameRate, dropFrame);
    },
    applyIncodeOffset: function (offset, captions, frameRate, dropFrame) {
        if (offset != "00:00:00:00") {
            return ccFunc.applyOffset(offset, captions, frameRate, dropFrame);
        } else {
            return captions;
        }        
    },
    offset: function (captionEvents, frameRate, dropFrame, offset, offsetType) {
        for (var i = 0; i < captionEvents.length; i++) {
            var captionEvent = captionEvents[i];
            var incode = npmTc.init({
                framerate: frameRate,
                timecode: captionEvent.incode.replace(";", ":"),
                drop_frame: dropFrame
            });

            var outcode = npmTc.init({
                framerate: frameRate,
                timecode: captionEvent.outcode.replace(";", ":"),
                drop_frame: dropFrame
            });
            if (offsetType.toLowerCase() === "subtract") {
                try {
                    incode.subtract(offset)
                } catch (e) {
                    incode = "00:00:00:00";
                }
                try {
                    outcode.subtract(offset);
                } catch (e) {
                    outcode = "00:00:00:00";
                }

                captionEvents[i].incode = incode.toString();
                captionEvents[i].outcode = outcode.toString();
            } else {
                incode.add(offset);
                outcode.add(offset);
                captionEvents[i].incode = incode.toString();
                captionEvents[i].outcode = outcode.toString();
            }
        }        

        return ccFunc.calculateTimecodes(captionEvents, frameRate, dropFrame);
    },
    segment: function (incode, duration, captionEvents, frameRate, dropFrame) {
        return ccFunc.segment(incode, duration, captionEvents, frameRate, dropFrame);
    },
    edlConform : function (edlData, captionEvents, frameRate, dropFrame) {
        return ccFunc.conformToEdl(program.conform, captionEvents, frameRate, dropFrame, debug);
    },
    /* Used only when converting from SCC to SRT and being imported to Adobe Premiere Pro */
    correctForAdobeImportBug : function(captions, frameRate, dropFrame){
        for (var i = 0; i < captions.length; i++){
            var tcIn = npmTc.init({framerate: frameRate, timecode: "00:00:00:00", drop_frame:true});
            var tcOut = npmTc.init({framerate: frameRate, timecode: "00:00:00:00", drop_frame:true});
            var incode = ccFunc.createSmpteTc(captions[i].incode, frameRate, dropFrame);
            var outcode = ccFunc.createSmpteTc(captions[i].outcode, frameRate, dropFrame);
            
            tcIn.add(incode.frameCount);
            tcOut.add(outcode.frameCount);

            captions[i].incode = tcIn.toString();
            captions[i].outcode =tcOut.toString();
        }
        return ccFunc.calculateTimecodes(captions, frameRate, dropFrame);
    },
    decode: function (fileContents, fileExt) {        
        fileExt = fileExt.toLowerCase();
        /* Check to see if format is supported */
        if (ccSettings.import.formats.indexOf(fileExt) === -1) {
            throw new Error("Failed to import closed captioning data - source format (" + fileExt + ") not supported");
        }

        /* 1. Apply general process */
        fileContents = ccPreProcess.import.all(fileContents);

        /* 2. Apply specific format process */
        fileContents = ccPreProcess.import[fileExt](fileContents);

        /* 3. Return ccDecoded data */
        return ccDecode[fileExt](fileContents);
    },
    getAutomaticOffsetTime : function(captions){
        var incodeOfFirstEvent = captions[0].incode;
        return incodeOfFirstEvent.split(":")[0] + ":00:00:00";
    },
    getDefaultDropFrameValue : function(fr){
        return ccSettings.tcDropFrameMap[fr.toString()];
    },
    tcValidate : function(tc, fr){
        return ccFunc.tcValidate(tc, fr);
    },
    getSupportedSrcExtensions : function(){
        return ccSettings.import.formats;
    },
    getSupportedTrgExtensions : function(){
        return ccSettings.export.formats;
    },
    tc : {
        tcToSec : function(tc){
            return nodeTc.toSeconds(tc.replace(/;/g, ":"));
        },
        secToTc : function(tcSec, frameRate, dropFrame){
            var totalFrames = parseFloat(tcSec)*frameRate;
            var tc = new smpteTc(totalFrames,frameRate,dropFrame);
            return tc.toString();
        }
    },
    getFileContents : {
        fromFile : function(file){
            var URL = window.URL || window.webkitURL;
            var fileSize = file.size;
            var fileExt = file.name.split('.').pop();
            var fileUrl = URL.createObjectURL(file);
            
            var reader = new FileReader();
            reader.onload = function (e) {
                if (["STL", "CAP", "PAC"].indexOf(fileExt.toUpperCase()) > -1) {
                    return new Buffer.from(e.target.result).toString('hex');
                } else {
                    return reader.result;
                }
            }

            if (["STL", "CAP", "PAC"].indexOf(fileExt.toUpperCase()) > -1) {
                reader.readAsText(file);
            } else {
                reader.readAsArrayBuffer(file);
            }
        },
        fromUrl : function(url){
            var request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.send(null);

            request.onreadystatechange = function () {
                if (request.readyState === 4 && request.status === 200) {
                    return request.responseText;
                }
            }
        }
    }

}