<template>
<XLrgModalTemplate Title="Export Video" ModalId="ExportVideo">
    <div class="row">
        <div class="col-7 border border-dark m-3 p-3">
            <form>
                <div class="form-group">
                    <label>Format</label>
                    <select class="form-control" v-model="preset">
                        <option>MP4</option>
                        <option>MOV</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Font Family</label>
                    <select class="form-control" v-model="fontFamily">
                        <option>Arial</option>
                        <option>Verdana</option>
                        <option>Tahoma</option>
                        <option>Georgia</option>
                        <option>Helvetica</option>
                        <option>Times New Roman</option>
                        <option>Courier New</option>
                        <option>Trebuchet MS</option>
                        <option>Brush Script MT</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Font Size</label>
                    <input class="form-control" type="number" v-model="fontSize" min="6" max="64" step="1" />
                </div>
                <div class="form-group">
                    <label>Padding</label>
                    <input class="form-control" type="number" v-model="padding" min="1" max="100" step="1" />
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label>Font Color</label><br />
                            <input type="color" id="fontColorPicker" name="fontColor" v-model="fontColor" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Background Color</label><br />
              
                            <input class="form-check-input mr-2" type="checkbox" v-model="enableBackgroundColor">
                            <input type="color" id="backgroundColorPicker" name="backgroundColor" v-model="backgroundColor" :disabled="!enableBackgroundColor"/>
                        </div>
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label for="watermark file">Select Watermark File <span class="small text-muted">(Optional)</span></label>
                    <div class="custom-file">
                        <input type="file" ref="watermarkFile" accept="image/*" @change="updateWatermarkUrl" />
                        <small class="form-text text-muted">Select watermark image file (e.g. png, jpeg, etc.)</small>
                    </div>
                </div>
                <div class="form-group mt-5">
                    <label for="watermark file">Select Export Location</label><br />
                    <button type="button" class="btn btn-secondary" @click="selectDir">
                        <i class="far fa-folder"></i> Export Folder
                    </button>
                    <span class="ml-2">{{ trgFolder }}</span>
                    <small class="form-text text-muted">Select an export location for your video</small>
                </div>
                <hr />
                <button type="button" :disabled="trgFolder===''" class="btn btn-success mr-2" @click="exportVideo">
                    <i class="fas fa-play"></i> Export
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" aria-label="Close">
                    <i class="fas fa-times"></i> Close
                </button>
            </form>
        </div>
        <div class="col">
            <p>Preview:</p>
            <div id="exportPreview" class="text-center">
                <p style="display: inline-block;" :style="{'color': fontColor, 'background-color': getBackgroundColor, 'font-size': fontSize + 'px', 'font-family':fontFamily, 'padding' : padding +'px'}" v-html="previewText"></p>
            </div>
        </div>
    </div>

    <div id="videoExportProgressBar" v-if="showProgress" class="row">
        <div class="col">
            <label>{{ time }} ({{ frameNumber }}) | {{ speed }} ({{ fps }})</label>
        </div>
        <div class="col" v-if="showSpinner">
            <div id="loader"></div>
        </div>
    </div>
</XLrgModalTemplate>
</template>

<script>
import XLrgModalTemplate from "./XLrgModalTemplate";
import ccEvent from '@/external/cc-lib-3/event.js'
import ccExport from '@/external/cc-lib-3/encode.js'
import frLib from '@/external/cc-lib-3/frameRates.js';
export default {
    name: "ExportVideo",
    data: function () {
        return {
            trgFolder: "",
            filename: "Untitled",
            preset: "MP4",
            fontColor: "#fff700",
            padding: 5,
            enableBackgroundColor: true,
            backgroundColor: "#1b1818",
            fontSize: 20,
            fontFamily: "Verdana",
            watermarkFile: null,
            showProgress: false,
            showSpinner: false,
            frameNumber: 0,
            fps: 0,
            time: "",
            speed: 0,
            watermarkUrl: "",
        };
    },
    components: {
        XLrgModalTemplate,
    },
    mounted: function () {
        /* const ffmpegPath = require('electron').remote.getGlobal('ffmpegpath');
            console.log(ffmpegPath); */
        this.fontColor = localStorage.getItem("fontColor") || "#fff700";
        this.padding = localStorage.getItem("padding") || 5;
        this.backgroundColor = localStorage.getItem("backgroundColor") || "#1b1818";
        this.fontSize = localStorage.getItem("fontSize") || 20;
        this.fontFamily = localStorage.getItem("fontFamily") || "Verdana";
    },
    computed : {
      previewText : function(){
        return this.$store.state.editor.captions[0].subtitleText.replace("\n", "</br>") || "Hello World";        
      },
      getBackgroundColor : function(){
        if (this.enableBackgroundColor){
          return this.backgroundColor;
        } else {
          return "green"
        };
      }
    },
    methods: {
        updateWatermarkUrl: function () {
            this.watermarkUrl = this.$refs.watermarkFile.files;
        },
        exportVideo: function () {

            localStorage.setItem("fontColor", this.fontColor);
            localStorage.setItem("padding", this.padding);
            localStorage.setItem("backgroundColor", this.backgroundColor);
            localStorage.setItem("fontSize", this.fontSize);
            localStorage.setItem("fontFamily", this.fontFamily);

            let events = [];
            let forcedStylePref;
            let watermarkFilePath;
            let projectFrameRate = parseFloat(this.$store.state.editor.projectSettings.frameRate);
            let projectDropFrame = frLib.dropFrameMap[projectFrameRate];
            let primaryColor = "&H" + this.fontColor.replace("#", "").match(/(..?)/g).reverse().join("");
            let outlineColor = "&H" + this.backgroundColor.replace("#", "").match(/(..?)/g).reverse().join("");

            if (this.enableBackgroundColor){
                forcedStylePref = `'Fontname=${this.fontFamily},Fontsize=${this.fontSize},PrimaryColour=${primaryColor},OutlineColour=${outlineColor},BorderStyle=3,Outline=${this.padding}'`;
            } else {
                forcedStylePref = `'Fontname=${this.fontFamily},Fontsize=${this.fontSize},PrimaryColour=${primaryColor}'`;
            }            

            const ffmpegPath = require("electron").remote.getGlobal("ffmpegpath");
            const spawn = require("child_process").spawn;
            const fs = require("fs");
            const {
                shell
            } = require('electron');
            var self = this;
            //var videoFilePath = document.getElementById("srcVideoFile").files[0].path;

            let fileName = this.$store.state.editor.projectSettings.title;
            let captionFilePath = this.trgFolder + "//" + fileName + ".srt"
            let videoFilePath = this.$store.state.editor.projectSettings.mediaInfo.path;

            this.$store.commit("updateView", 'subtitle');
            this.$store.state.editor.captions.forEach(event => {
                events.push(new ccEvent({
                    start: event.incodeSec,
                    end: event.outcodeSec,
                    text: event.subtitleText
                }).syncCaptionText().alignTextCenter());
            });

            let fileContents = ccExport(events);
            fs.writeFileSync(captionFilePath, fileContents);

            // https://stackoverflow.com/questions/38361996/how-can-i-bundle-a-precompiled-binary-with-electron

            if (this.preset === "MP4") {
                var ext = ".mp4";
            } else {
                var ext = ".mov";
            }

            if (process.env.VUE_APP_OS === "Windows") {
                captionFilePath = captionFilePath.replace(/\/|\\/g, "\\\\\\\\")
                    .replace(/:/g, "\\\\:");
            }

            var trgFileFullPath = this.trgFolder + "/" + fileName + ext;
            console.log(ffmpegPath);
            console.log(videoFilePath);
            console.log(captionFilePath);
            console.log(trgFileFullPath);
            console.log(watermarkFilePath);
            /* https://superuser.com/questions/1247197/ffmpeg-absolute-path-error/1247232#1247232?s=ca98819444e14fceb9cd843c596609fe */

            if (this.watermarkUrl) {
                watermarkFilePath = this.watermarkUrl[0].path;
                const exec = require('child_process').exec;
                console.log('"' + ffmpegPath + '" -y -i "' + videoFilePath + '" -i "' + watermarkFilePath + '" -filter_complex "[0]subtitles=' + captionFilePath + "[v];[1][v]scale2ref=w='iw*10/100':h='ow/mdar'[wm][vid]; [vid][wm]overlay=main_w-overlay_w-5:main_h-overlay_h-5" + '"' + " -preset fast " + trgFileFullPath);

                var proc = exec('"' + ffmpegPath + '" -y -i "' + videoFilePath + '" -i "' + watermarkFilePath + '" -filter_complex "[0]subtitles=' + captionFilePath + ":force_style=" + forcedStylePref + "[v];[1][v]scale2ref=w='iw*10/100':h='ow/mdar'[wm][vid]; [vid][wm]overlay=main_w-overlay_w-5:main_h-overlay_h-5" + '"' + " -preset fast " + trgFileFullPath);

            } else {
                var args = [
                    "-y",
                    "-i",
                    videoFilePath,
                    "-vf",
                    "subtitles=" + captionFilePath + ":force_style=" + forcedStylePref,
                    trgFileFullPath,
                ];
                var proc = spawn(ffmpegPath, args);
            }

            proc.stdout.on("data", function (data) {
                console.log(data.toString());
                self.showProgress = true;
                self.showSpinner = true;
            });

            proc.stderr.on("data", function (data) {
                console.log(data.toString());
                self.showProgress = true;
                self.showSpinner = true;
                var ffmpegLog = data
                    .toString()
                    .replace(/= +/g, "=")
                    .split(" ")
                    .filter((logEntry) => {
                        return logEntry != "";
                    });

                if (ffmpegLog[0].indexOf("frame=") > -1) {
                    self.frameNumber = ffmpegLog[0];
                    self.fps = ffmpegLog[1];
                    self.time = ffmpegLog[4];
                    self.speed = ffmpegLog[6];
                }
            });

            proc.on("close", function () {
                self.frameNumber = "";
                self.fps = "";
                self.time = "COMPLETE";
                self.speed = "";
                self.showProgress = false;
                self.showSpinner = false;
                shell.openPath(self.trgFolder);
                self.$notify({
                    title: "Video Export",
                    type: "sucess",
                    text: "Video export complete",
                });
                //$("#ExportVideo").modal("toggle");
            });
        },

        selectDir: function () {
            const remote = require("electron").remote;
            var dialog = remote.require("electron").dialog;
            dialog
                .showOpenDialog({
                    properties: ["openDirectory"],
                })
                .then((path) => {
                    if (!path.canceled) {
                        this.trgFolder = path.filePaths[0];
                    }
                });
        },
    },
};
</script>

<style>
#videoExportProgressBar {
    margin: 2% 1% 0;
}

.progress-bar {
    color: #fff !important;
}

#loader {
    border: 16px solid #cecece;
    /* Light grey */
    border-top: 16px solid #1962c0;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#exportPreview {
    width: 100%;
    height: 25%;
    background-color: green;
    padding-top: 30%;
}
</style>
